import React from 'react';
import {Circle, Text, Layer, Rect, Stage, Tag, Image} from "react-konva";
import {hexToRgb, getGroupColor} from "@/views/Admin/Floors/Editor/utils/common";
import {useTranslation} from "react-i18next";

const PlaceChair = ({param, groupList, toolTipRef, toolTipTextRef, role, constEditableStatus}) => {

    // рендер кресла
    const {t} = useTranslation('common');
    const shapeRef = React.useRef();
    const tooltip = toolTipRef.current;
    const tooltipText = toolTipTextRef.current;
    let textStr = param.custom.column ? param.custom.column : '';
    if (textStr == 'null') textStr = 0;

    let color, textColor = null, statusLock = null;

    if (role === 'designer') {
        color = getGroupColor(param.group.id, groupList)
    }
    if (role === 'manager') {
        if (typeof param['status'] !== "undefined") {
            if (param.status == 0) {
                textColor = null;
            }
            if (constEditableStatus.indexOf(Number(param.status)) !== -1) {
                statusLock = true;
                color = '#DDDDDD';
                textColor = '#5E6B92';
            } else if (param.status == 2) {
                color = '#ffffff';
                textColor = '#5E6B92';
            } else if (param.status == 3) {
                color = '#DDDDDD';
                textColor = '#5E6B92';
            } else {
                color = getGroupColor(param.group.id, groupList)
            }
        } else {
            color = getGroupColor(param.group.id, groupList)
        }
    }

    if (role === 'user') {
        if (typeof param['status'] !== "undefined") {
            if (param.status == 0) {
                textColor = null;
                color = getGroupColor(param.group.id, groupList)
            } else if (param.status == 1) {
                color = '#DDDDDD';
                textColor = '#5E6B92';
            } else if (param.status == 2) {
                color = '#ffffff';
                textColor = '#5E6B92';
            } else if (param.status == 3) {
                color = '#DDDDDD';
                textColor = '#5E6B92';
            } else {
                // 4 - зарезервировано орг, 5 - занято орг.
                color = '#DDDDDD';
                textColor = '#5E6B92';
            }
        } else {
            color = getGroupColor(param.group.id, groupList)
        }
    }


    const hoverRect = (e, val, param) => {
        // подсказака ряд/место
        tooltip.setAttrs({
            visible: val,
            cornerRadius: 4,
            x: param.x - 42,
            y: param.y - 32,
            width: 110,
            height: 25,
            fill: "rgba(0, 0, 0, 0.9)"
        })
        tooltipText.setAttrs({
            visible: val, fill: "#fff", x: param.x - 34, y: param.y - 24,
        });

        tooltipText.text(`${t('row')} ${param.custom.row ? param.custom.row : param.row}, ${t('place')} ${param.custom.column ? param.custom.column : param.column}`);
        if (!param.selected) {
            if (val) {
                shapeRef.current.setAttrs({
                    "stroke": "#000", "strokeWidth": 2
                })
            } else {
                shapeRef.current.setAttrs({"stroke": ""})
            }
        }

    }

    const xText = (str) => {
        switch (`${str}`.length) {
            case 1:
                return 8
                break
            case 2:
                return 4
                break
            case 3:
                return 3
                break
            default:
                return 2
                break
        }
    }

    const yText = (str) => {
        let y = 6;
        if (`${str}`.length >= 2) {
            y = y + (`${str}`.length * 0.4)
        }
        return y
    }

    const sizeText = (str) => {
        let size = 13;
        if (`${str}`.length > 2) {
            size = size - (`${str}`.length * 1.3)
        }
        size < 2 ? size = 2 : ''
        return size
    }

    const colorStroke = (param) => {
        if (param.cart) {
            return '#000'
        }
        if (param.selected) {
            return '#0067F2'
        }
        return ''
    }



    return (<>
        <Rect
            {...param}
            width={24}
            height={24}
            stroke={colorStroke(param)}
            ref={shapeRef}
            name="Place"
            status={`${param.status}`}
            fill={color}
            onMouseEnter={e => {
                textStr && hoverRect(e, true, param)
            }}
            onMouseLeave={e => {
                textStr && hoverRect(e, false, param)
            }}
        />

        {param.error && <Text
            {...param}
            name="PlaceText"
            fontFamily={"Inter, sans-serif"}
            fontStyle={'bold'}
            x={param.x + 18}
            y={param.y - 6}
            fontSize={12}
            text={'❌'}
            stroke={'#000'}
            fill={'#000'}
            strokeWidth={0}
        />}
        {statusLock && <Text
            {...param}
            name="PlaceText"
            fontFamily={"Inter, sans-serif"}
            fontStyle={'bold'}
            x={param.x + 16}
            y={param.y - 6}
            fontSize={12}
            text={'🔒'}
            stroke={'#000'}
            fill={'#000'}
            strokeWidth={0}
        />}

        <Text
            {...param}
            name="PlaceText"
            fontFamily={"Inter, sans-serif"}
            fontStyle={'bold'}
            //x={param.x + clalcPaddingText(param.custom.column)}
            x={param.x + xText(textStr)}
            y={param.y + yText(textStr)}
            // opacity={!param.error ? 1 : '0.5'}
            fontSize={sizeText(textStr)}
            text={textStr}
            stroke={textColor === null ? hexToRgb(color) : textColor}
            fill={textColor === null ? hexToRgb(color) : textColor}
            strokeWidth={0}
            onMouseEnter={e => {
                hoverRect(e, true, param)
            }}
            onMouseLeave={e => {
                hoverRect(e, false, param)
            }}
        />
    </>);
};

export default PlaceChair;
