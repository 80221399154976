import React, {useEffect, useRef, useState} from 'react';
import Input from "../../../../../../../components/ui/Input";
import Toggletip from "../../../../../../../UIKit/component/toggletip/toggletip";
import QuestionIcon from '../../../../../../../assets/images/icons/16/question-circle.svg'
import TrashIcon from '../../../../../../../assets/images/icons/16/trash.svg'
import AddIcon from '../../../../../../../assets/images/icons/16/plus_simple.svg'
import Switcher from "../../../../../../../UIKit/component/switcher/Switcher";
import template from './Edit.data.json'
import './Edit.scss'
import scrollToElement from "../../../../../../../utils/scrollToElement";
import {decode} from 'html-entities';
import InfoIcon from "../../../../../../../assets/images/icons/16/info_cr.svg";

import CheckboxIcon from "../../../../../../../assets/images/icons/16/checkbox_16x.svg";
import RadioIcon from "../../../../../../../assets/images/icons/16/radiobtn_16x.svg";
import Select from "../../../../../../../UIKit/component/select/Select";
import {useSelector} from "react-redux";

const Edit = ({data, onSubmit, onCancel}) => {
    const [preloader, setPreloader] = useState(false)
    const ref = useRef(null)
    const selector = useSelector(state => state)
    const types = selector.admin.constants.forms
    const [current, setCurrent] = useState({...template, ...data, type_new: data.type_new || Object.keys(types)[0]})

    useEffect(() => {
        scrollToElement(ref, {position: 'top', offset: -100})
    }, [])

    /* Сохранение */
    const handleSubmit = async () => {
        setPreloader(true)
        handleValidate(await onSubmit(current))
        setPreloader(false)
    }

    /* Слушатель изменения поля внутри формы */
    const handleChange = (key, value, idx) => {
        let err = {...current.errors}

        if (key === 'variants') delete err[`variant_${idx}`]
        else delete err[key]

        setCurrent({...current, [key]: value, errors: err})
    }

    /* SSV (Server-Side Validation) */
    const handleValidate = (response) => {
        response ? setCurrent({
            ...current, errors: response.reduce((obj, {key, message}) => {
                obj[key] = message;
                return obj;
            }, {})
        }) : null
    }

    /* Проверка на необходимость вариантов в зависимости от выбранного типа поля */
    const checkMultipleVariants = (type) => {
        return [10, 11].includes(Number(type))
    }

    const checkNeedIcon = (data) => {
        let types = JSON.parse(JSON.stringify(data))

        types[9] = <span className={'ui-cp__form-fields__select-item'}>
            <CheckboxIcon/> {types[9]}
        </span>
        types[10] = <span className={'ui-cp__form-fields__select-item'}>
            <CheckboxIcon/> {types[10]}
        </span>
        types[11] = <span className={'ui-cp__form-fields__select-item'}>
            <RadioIcon/> {types[11]}
        </span>

        return types
    }

    useEffect(() => {
        console.log(current)
    }, [current])

    return (
        <form className={'ui-cp__ticket-editor'} ref={ref} onSubmit={e => e.preventDefault()}>
            <div className="ui-cp__ticket-editor_item ui-cp__ticket-editor_title">
                {`${data.name ? 'Редактирование' : 'Создание нового'} поля`}
            </div>
            <div className="ui-cp__ticket-editor_item">
                <div className={'ui-cp__ticket-editor_row'}>
                    <Input
                        label={'Наименование'}
                        placeholder={'Введите наименование поля'}
                        outside={1}
                        size={'small'}
                        value={decode(current.name) || null}
                        disabled={preloader}
                        error={current.errors.name}
                        handleChange={(event) => handleChange('name', event.target.value)}
                    />
                    <Toggletip
                        parent={document.querySelector('main')}
                        content={<>
                            *текст* — курсив
                            <br/>
                            **текст** — жирность
                            <br/>
                            [Nethouse](https://nethouse.ru) —
                            ссылка
                        </>}>
                        <button type={'button'} className={'ui-cp__ticket-editor__toggle'} disabled={preloader}>
                            <QuestionIcon/>
                        </button>
                    </Toggletip>
                </div>
                <div className={'ui-cp__ticket-editor_row'}>
                    <div className="ui-cp__ticket-editor_name">
                        <Select
                            label={'Тип'}
                            placeholder={'Выберите тип поля'}
                            size={'small'}
                            data={checkNeedIcon(types)}
                            error={current.errors.type_new}
                            value={current.type_new}
                            direction={['bottom']}
                            disabled={!current.deletable || preloader}
                            onChange={(key) => setCurrent({
                                ...current,
                                variants: checkMultipleVariants(key) ? [null, null] : null,
                                type_new: key
                            })}
                        />
                    </div>
                    <div className="ui-cp__ticket-editor_type">
                        <Switcher
                            id={'form-switcher__required'}
                            size={'medium'}
                            value={'Обязательное поле'}
                            checked={current.required}
                            disabled={!current.deletable || preloader}
                            error={false}
                            onChange={(boolean) => handleChange('required', boolean)}
                        />
                    </div>
                </div>
                {checkMultipleVariants(current.type_new) && current.variants ? current.variants.map((variant, variant_idx) =>
                    <div
                        key={variant_idx}
                        className={'ui-cp__ticket-editor_row'}>
                        <Input
                            label={`Вариант`}
                            outside={1}
                            size={'small'}
                            placeholder={'Введите наименование варианта'}
                            value={current.variants[variant_idx]}
                            error={current.errors[`variant_${variant_idx}`]}
                            disabled={preloader}
                            handleChange={(event) => {
                                handleChange('variants', current.variants.map((x, i) => i === variant_idx ? event.target.value : x), variant_idx)
                            }}
                        />
                        <button
                            type={'button'}
                            className={'cp-btn cp-btn_size_small cp-scheme_tertiary-ghost ui-cp__ticket-editor_delete'}
                            disabled={current.variants.length < 3 || preloader}
                            onClick={() => handleChange('variants', current.variants.filter((x, i) => i !== variant_idx))}>
                            <TrashIcon/>
                        </button>
                    </div>) : null}
                {checkMultipleVariants(current.type_new) && current.variants ? <div className="ui-cp__tickets-actions">
                    <button
                        className="cp-btn cp-btn_size_small cp-scheme_secondary-default cp-btn_icon_left"
                        type={'button'}
                        disabled={preloader}
                        onClick={() => handleChange('variants', [...current.variants, null])}>
                        <AddIcon/>
                        Добавить вариант
                    </button>
                    <Switcher
                        id={`form-switcher__custom`}
                        size={'medium'}
                        icon={<span className={'ui-cp__ticket-editor__toggle'}>
                            <InfoIcon/>
                        </span>}
                        placeholder={'Позволить участникам указывать свои варианты значений для этого поля'}
                        parent={document.querySelector('main')}
                        value={'Пользовательские варианты'}
                        checked={current.is_custom}
                        disabled={preloader}
                        onChange={(boolean) => handleChange('is_custom', boolean)}
                        error={false}
                    />
                </div> : null}
                {current.type_new == 8 ? <div className={'ui-cp__tickets-actions'}>
                    <div className="ui-cp__ticket-editor_count">
                        {current.min_length === null ? <span>Не ограничено</span> : null}
                        <Input
                            label={'Минимальная длина'}
                            outside={1}
                            size={'small'}
                            type={'number'}
                            value={current.min_length}
                            onKeyPress={e => (['e', 'E', '+', '-'].includes(e.key) || e.target.value.length > 2) && e.preventDefault()}
                            error={current.errors.min_length}
                            isInfinity={true}
                            min={1}
                            max={255}
                            onFocus={() => current.min_length === null ? handleChange('min_length', '') : null}
                            onBlur={() => !current.min_length ? handleChange('min_length', null) : null}
                            // onClick={(e) => handleChange('min_length', null)}
                            handleChange={(e) => handleChange('min_length', Number(e.target.value))}
                            onPicClick={(e) => handleChange('min_length', null)}
                        />
                    </div>
                    <div className="ui-cp__ticket-editor_count">
                        {current.max_length === null ? <span>Не ограничено</span> : null}
                        <Input
                            label={'Максимальная длина'}
                            outside={1}
                            size={'small'}
                            type={'number'}
                            value={current.max_length}
                            onKeyPress={e => (['e', 'E', '+', '-'].includes(e.key) || e.target.value.length > 2) && e.preventDefault()}
                            error={current.errors.max_length}
                            isInfinity={true}
                            min={1}
                            max={255}
                            onFocus={() => current.max_length === null ? handleChange('max_length', '') : null}
                            onBlur={() => !current.max_length ? handleChange('max_length', null) : null}
                            // onClick={(e) => handleChange('max_length', '')}
                            handleChange={(e) => handleChange('max_length', Number(e.target.value))}
                            onPicClick={(e) => handleChange('max_length', null)}
                        />
                    </div>
                </div> : null}
            </div>
            <div className="ui-cp__ticket-editor_item ui-cp__ticket-editor_actions">
                <button
                    className="cp-btn cp-btn_size_small cp-scheme_tertiary-ghost"
                    type={'reset'}
                    disabled={preloader}
                    onClick={onCancel}>
                    отмена
                </button>
                <button
                    className="cp-btn cp-btn_size_small cp-scheme_primary-default"
                    type={'submit'}
                    disabled={preloader}
                    onClick={handleSubmit}>
                    сохранить
                </button>
            </div>
        </form>
    );
};

export default Edit;
