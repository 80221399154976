import React, {useEffect, useRef, useState} from 'react';
import '../../../../../scss/pages/Statistic/Statistic.scss'
import '../../../../../scss/pages/Statistic/Sessions.scss'
import {postData} from "../../../../../utils/sendData";
import {API} from "../../../../../api/API";
import Preloader from "../../../../../widget/components/preloader";
import {getFormattedDate} from '../../../../../utils/getFormattedDate'
import {getSumValuesByKeys} from "../../../../../utils/getSumValuesByKeys";
import Input from "../../../../../components/ui/Input";
import Flatpickr from "react-flatpickr";
import {Russian} from 'flatpickr/dist/l10n/ru';
import ArrowLeftIcon from '../../../../../assets/images/icons/16/chevron-left.svg'
import ArrowRightIcon from '../../../../../assets/images/icons/16/chevron-right.svg'
import {getNearestDateFromArray} from "../../../../../utils/getNearestDateFromArray";

const Sessions = (props) => {
    const fp = useRef()
    const [preloader, setPreloader] = useState(false)
    const [data, setData] = useState({
        data: {
            dates: [],
            sessions: []
        },
        meta: {
            date: null,
            from: null
        },
        timezone: null,
    })
    const options = {
        enableTime: false,
        noCalendar: false,
        dateFormat: "d.m.Y",
        altFormat: "d.m.Y",
        time_24hr: true,
        locale: Russian,
        monthSelectorType: "static",
    }

    /* Инициализация данных компонента */
    useEffect(() => {
        handleGetTimezone().then(timezone => {
            handleUpdateData(data.meta).then(res => {
                let tmp = {...data, ...res.data}
                tmp.meta.date = getNearestDateFromArray(tmp.data.dates, new Date())
                tmp.timezone = timezone
                setData(tmp)
            })
        })
    }, [])
    /* Поисковой запрос, принимает объект параметров поиска, вощвращает тело запроса */
    const handleUpdateData = async (options) => {
        let response
        response = await postData(null, API.getStatisticSessions.path(props.match.params.id, options), API.getStatisticSessions.method)
        return response
    }

    const handleGetTimezone = async () => {
        let response
        response = await postData(null, API.getEvents.path(props.match.params.id, options), API.getEvents.method)
        return response.data.data.attributes.timezone
    }

    /* Сборка объекта для поискового запроса */
    const handleConstructFilter = (instance) => {
        let date, month, year, timezone, reset, diff

        timezone = data.timezone.timezone
        date = instance.selectedDates[0]
        month = instance.currentMonth
        year = instance.currentYear
        reset = resetCurrentMonth(date, month, year)
        diff = getTimezoneOffset(timezone, Intl.DateTimeFormat().resolvedOptions().timeZone)

        return {
            date: convert(new Date(new Date(date).getTime() + diff), timezone),
            from: convert(new Date(new Date(reset).getTime() + diff), timezone),
        }
    }

    const getTimezoneOffset = (firstTimezone, secondTimezone) => {
        const firstDate = new Date().toLocaleString('en-US', {timeZone: firstTimezone});
        const secondDate = new Date().toLocaleString('en-US', {timeZone: secondTimezone});
        const difference = new Date(secondDate) - new Date(firstDate);
        return difference;
    }

    /* Смена месяца */
    const handleChangeMonth = async () => {
        let options, instance, response, dates, year

        //определяем переменные
        instance = fp.current?.flatpickr
        year = instance.currentYear
        options = handleConstructFilter(instance)

        //ждем, пока календарь полностью закроется
        await instance.close()

        //ждем ответа API
        setPreloader(true)
        response = await handleUpdateData(options)
        dates = response.data.data.dates
        setPreloader(false)

        //анализируем ответ API; затирается instance из-за ререндера <Preloader/>, потому заново определяем его
        instance = fp.current?.flatpickr
        instance.currentMonth = new Date(new Date(options.from).setMonth(new Date(options.from).getMonth() + 1)).getMonth()
        instance.currentYear = year
        instance.set('enable', dates.map(x => x.split('T')[0].split('-').reverse().join('-')))
        //открываем календарь с обновленными данными
        instance.open()
    }

    const handleAnalyzeDate = (direction, arr, date) => {
        let current_idx, result
        current_idx = arr.findIndex(x => x.split('T')[0] === date.split('T')[0])

        if (direction === 'forward') result = arr[current_idx + 1]
        if (direction === 'backward') result = arr[current_idx - 1]

        return result
    }

    const handleNavigateDate = async (direction, data) => {
        let options, instance, response, dates

        //определяем переменные
        instance = fp.current?.flatpickr
        dates = data.data.dates

        options = handleConstructFilter(instance)
        options.date = handleAnalyzeDate(direction, dates, options.date)
        if (options.date) {
            //ждем ответа API
            setPreloader(true)
            response = await handleUpdateData(options)
            data.data.dates = response.data.data.dates
            setData({...data, ...response.data})
            setPreloader(false)

            //анализируем ответ API; затирается instance из-за ререндера <Preloader/>, потому заново определяем его
            instance = fp.current?.flatpickr
            instance.set('enable', data.data.dates.map(x => x.split('T')[0].split('-').reverse().join('-')))
        } else {
            showTopMessage('error', 'small', 'Нет доступных дат')
        }
    }

    /* Смена даты */
    const handleChangeDate = async () => {
        let options, instance, response, dates

        // определяем переменные
        instance = fp.current?.flatpickr
        options = handleConstructFilter(instance)

        // ждем ответ API
        setPreloader(true)
        response = await handleUpdateData(options)
        dates = response.data.data.dates
        setPreloader(false)

        // затирается instance из-за ререндера <Preloader/>, потому заново определяем его, анализируем ответ API
        setData({...data, ...response.data})
        instance = fp.current?.flatpickr
        instance.set('enable', dates.map(x => x.split('T')[0].split('-').reverse().join('-')))
    }

    /* Функция смены месяца на указанный, принимает дату и требуемый месяц */
    const resetCurrentMonth = (dateToReset, month, year) => {
        let date = new Date(dateToReset)
        date.setFullYear(year)
        date.setMonth(month - 1)
        date.setDate(1)
        date.setHours(0, 0, 0, 0)
        return date
    }

    /* Проверка на бесконечность */
    const checkInfinityValue = (value) => {
        if (value === null || value === Infinity) value = '∞'
        return value
    }

    /* Рендер инпута */
    const renderInput = (ref) => <div className={'nh-cp__statistic-sessions__calendar'}>
        <Input
            calendar={1}
            type={"date"}
            id={"session_date"}
            size={"small"}
            disabled={preloader}
            className={'test'}
            inputRef={ref}
            handleChange={(e) => {
            }}
        />
        {preloader ? <Preloader/> : null}
    </div>

    /* Рендер календаря и его кнопок */
    const TableControls = () => {
        return (<div className={'nh-cp__statistic-sessions__header'}>
            <div className={'nh-cp__statistic-sessions__control'}>
                <Flatpickr
                    ref={fp}
                    value={data.meta.date ? data.meta.date.split('T')[0].split('-').reverse().join('-') : null}
                    options={options}
                    render={({value, ...props}, ref) => renderInput(ref)}
                    onReady={(selectedDates, dateStr, instance) => {
                        instance.set('enable', data.data.dates.map(x => x.split('T')[0].split('-').reverse().join('-')))
                    }}
                    onChange={handleChangeDate}
                    onMonthChange={handleChangeMonth}
                    onYearChange={handleChangeMonth}
                />
                <div className={'nh-cp__statistic-sessions__btn-group'}>
                    <button
                        disabled={preloader}
                        className={'cp-btn cp-btn_size_small cp-scheme_secondary-ghost'}
                        onClick={() => handleNavigateDate('backward', data)}>
                        <ArrowLeftIcon/>
                    </button>
                    <button
                        disabled={preloader}
                        className={'cp-btn cp-btn_size_small cp-scheme_secondary-ghost'}
                        onClick={() => handleNavigateDate('forward', data)}>
                        <ArrowRightIcon/>
                    </button>
                </div>
            </div>
            <div className={'nh-cp__statistic-sessions__timezone'}>
                Часовой пояс: {data.timezone.utc}<br/><span>,</span> {data.timezone.name}
            </div>
        </div>)
    }

    return (
        <div className={'nh-cp__statistic__sessions'}>
            {data.data.sessions.length && data.meta.from ? <table className={'nh-cp__statistic-sessions'}>
                <thead>
                <tr>
                    <th>
                        <TableControls/>
                    </th>
                    <th>Всего</th>
                    <th>Забронировано</th>
                    <th>Оплачено</th>
                    <th>Свободно</th>
                </tr>
                </thead>
                {data.data.sessions.length && data.data.sessions.map((session, session_idx) => {
                    return session.times.length ? <tbody key={session_idx}>
                    <tr>
                        <td>
                            <h6>
                                {session.name}
                            </h6>
                        </td>
                    </tr>
                    {session.times.map((time, time_idx) => <tr key={time_idx}>
                        <td>
                            <div>{getFormattedDate(time.time, 'HH:mm', data.timezone.timezone)}</div>
                        </td>
                        <td>
                            <div>{checkInfinityValue(time.total)}</div>
                        </td>
                        <td>
                            <div>{checkInfinityValue(time.reserved)}</div>
                        </td>
                        <td>
                            <div>{checkInfinityValue(time.used)}</div>
                        </td>
                        <td>
                            <div>{checkInfinityValue(time.free)}</div>
                        </td>
                    </tr>)}
                    </tbody> : null
                })}
                {data.data.sessions.reduce((sum, x) => sum + x.times.length, 0) === 0 ? <tbody>
                <tr>
                    <td>
                        <h6>Ничего не найдено</h6>
                    </td>
                </tr>
                </tbody> : null}
                {data.data.sessions.reduce((sum, x) => sum + x.times.length, 0) !== 0 ? <tfoot>
                <tr>
                    <td>
                        <div>
                            Общее число
                        </div>
                    </td>
                    <td>
                        <div>
                            {checkInfinityValue(getSumValuesByKeys(data.data.sessions, 'total', {infinity: true}))}
                        </div>
                    </td>
                    <td>
                        <div>
                            {getSumValuesByKeys(data.data.sessions, 'reserved')}
                        </div>
                    </td>
                    <td>
                        <div>
                            {getSumValuesByKeys(data.data.sessions, 'used')}
                        </div>
                    </td>
                    <td>
                        <div>
                            {checkInfinityValue(getSumValuesByKeys(data.data.sessions, 'free', {infinity: true}))}
                        </div>
                    </td>
                </tr>
                </tfoot> : null}
            </table> : <Preloader/>}
        </div>);
};

export default Sessions;