import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';
import Checkbox from '../../../../../UIKit/component/checkbox/Checkbox';
import Window from '../../../../../UIKit/component/window/window';
import Pushka from '../../../../../UIKit/component/window/template/pushka';
import {API} from '../../../../../api/API';
import './PaymentMethods.scss';
import {postData} from "../../../../../utils/sendData";
import Preloader from "../../../../../widget/components/preloader";
import Notification from "../../../../../UIKit/component/notification/notification";
import Toggletip from "../../../../../UIKit/component/toggletip/toggletip";
import QuestionIcon from "../../../../../assets/images/icons/16/question-circle.svg";

const PaymentMethods = () => {
    const links = {
        docs: {
            href: 'https://events.nethouse.ru/help/kak-nastroit-integraciyu-pushkinskoj-karty/',
            target: '_blank'
        },
    }

    const router = useRouteMatch()
    const pushka = useRef(null)
    const containerRef = useRef(null)
    const culture_bit = 8

    const admin = useSelector(state => state.admin)
    const [methods, setMethods] = useState([])
    const [preloader, setPreloader] = useState(false)
    const [status, setStatus] = useState(false)

    useEffect(() => {
        setPreloader(true)
    }, [])

    useEffect(() => {
        if (admin.constants['payment_types']) initPaymentTypesList(admin.constants['payment_types'])
    }, [admin.constants['payment_types']])

    /* Инициализация списка методов оплаты */
    const initPaymentTypesList = (data) => {
        let current = admin.event['payment_types']

        data = Object.entries(data).map(([key, name]) => ({key: Number(key), name, isChecked: Boolean(key & current)}))
        setMethods(data)
        setPreloader(false)
    }

    /* Смена значения метода оплаты */
    const handleCheckbox = async (boolean, idx) => {
        let data, updated, response, arr

        data = JSON.parse(JSON.stringify(methods))
        updated = data.map((method, method_idx) => method_idx === idx ? {...method, isChecked: boolean} : method)
        if (!updated.filter(x => x.isChecked).length) {
            showTopMessage('warning', 'small', 'Невозможно выполнить операцию', 'Как минимум один из способов оплаты должен быть выбран')
            return
        }

        setPreloader(true)
        arr = updated.map(x => x.isChecked ? x.key : null).filter(x => x ?? x)
        response = await requestPaymentMethods(null, arr, updated)

        /* Ответ не был получен */
        if (!response) {
            showTopMessage('error', 'small', 'Произошла ошибка', 'Сервер не отвечает')
            return
        }

        /* Метод оплаты Пушкинской картой успешно установился без вызова модального окна */
        if (data[idx].key & culture_bit && !data[idx].isChecked && response.success) {
            showTopMessage('success', 'large', 'Операция выполнена успешно', 'Оплата Пушкинской картой была успешно подключена')
        }

        /* Получена ошибка, необходимо заполнить поле cultureEventId */
        if (data[idx].key & culture_bit && !data[idx].isChecked && response.errors && !response.errors['paymentType.3']) {
            setStatus(true)
        }

        /* Получена ошибка, необходимо установить для события даты начала/окончания или добавить сеансы */
        if (data[idx].key & culture_bit && !data[idx].isChecked && response.errors && response.errors['paymentType.3']) {
            showTopMessage('warning', 'large', 'Невозможно выполнить операцию', response.errors['paymentType.3'])
        }

        if (!data[idx].isChecked && response.errors && Object.keys(response.errors).map(x => x.includes('paymentType.'))) {
            //..
        }

        if (response.success) {
            setMethods(updated)
        }

        setPreloader(false)
    }

    /* Запрос на изменение методов оплаты в API */
    const requestPaymentMethods = async (data, params, updated) => {
        let event_id, url, method, is_course, response

        is_course = router.params.type_event_id !== 'events'
        event_id = router.params.id
        params = params.map((type, index) => `paymentType[${index}]=${type}`).join('&')
        url = API.changePaymentMethod.path(is_course, event_id, params)
        method = API.changePaymentMethod.method

        try {
            response = await postData(data, url, method);
        } catch (e) {
            response = e.response || {}
        }

        return response.data
    }

    /* Проверка на деактивированное состояние чекбокса */
    // const checkDisabledCheckboxes = (method) => {
    //     let count = methods.map(x => x.isChecked ? x.key : null).filter(x => x ?? x).length
    //     return (count <= 1 && method.isChecked) || preloader
    // }

    /* Активировать метод "Пушкинская карта" */
    const handleSubmit = async (data) => {
        let copied, response, prepare

        copied = JSON.parse(JSON.stringify(methods))
        copied.find(x => x.key == culture_bit).isChecked = true
        prepare = {}
        Object.entries(data).map(([x, i]) => prepare[x] = i.value)
        response = await requestPaymentMethods(prepare, copied.map(x => x.isChecked ? x.key : null).filter(x => x ?? x), copied)
        return response
    }

    /* Закрыть модальное окно */
    const handleClose = (result) => {
        result === true ? setMethods(methods.filter(x => x.key == culture_bit ? x.isChecked = true : x)) : null
        pushka.current.handleClose()
    }

    /* Рендер модального окна подключения пушкинской карты */
    const renderPushkaModal = () => {
        return <Window
            status={status}
            setStatus={setStatus}
            ref={pushka}
            dismissible={true}
            esc={true}
            selector={'nh-window__pushka'}
            content={<Pushka
                links={links}
                onClose={handleClose}
                onSubmit={handleSubmit}
            />}
        />
    }

    const renderToggletipContent = () => {
        return <p>
            Для подключения оплаты при помощи пушкинской картой необходимо пройти модерацию на
            Культура.РФ,
            подробнее в <a href={links.docs.href} target={links.docs.target}>инструкции</a>.
        </p>
    }

    return (
        <div className={'ui-cp__container'} ref={containerRef}>
            {renderPushkaModal()}
            <div className="ui-cp__row ui-cp__row_md">
                <div className="ui-cp__payment-methods">
                    <h4 className={'settings-main-title'}>Способы оплаты</h4>
                    <div className={'ui-cp__payment-methods__grid'}>
                        {methods.length ? methods.map((method, method_idx) => <div
                            key={method_idx}
                            className={'ui-cp__payment-methods__checkbox'}>
                            <Checkbox
                                id={`payment-key__${method.key}`}
                                disabled={preloader}
                                checked={method.isChecked}
                                size={'medium'}
                                value={method.name}
                                onChange={(e) => handleCheckbox(e, method_idx)}
                            />
                            {method.key == 8 ? <Toggletip
                                parent={document.querySelector('main')}
                                content={renderToggletipContent()}>
                                <button className={'ui-cp__payment-methods__toggle'}><QuestionIcon/></button>
                            </Toggletip> : null}
                        </div>) : null}
                        {Object.values(admin.constants).length && Object.values(admin.constants?.payment_types)?.length === 0 ?
                            <Notification type={'warning'}
                                          value={'Для текущего события отсутствуют способы оплаты'}/> : null}
                        {!methods.length && preloader ? <Preloader/> : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentMethods;