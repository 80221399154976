import React, {useEffect, useState} from 'react';
import modal from '../Modal.module.scss'
import Input from "../../../../../../../components/ui/Input";
import testDataJson from './TestModalContent.data.json'

const TestModalContent = ({data, onCancel, onSubmit}) => {
    const [form, setForm] = useState({
        ...testDataJson, fields: {
            ...testDataJson.fields,
            email: {...testDataJson.fields.email, value: null},
        }
    })

    const handleChangeEmail = (value) => {
        setForm(prev => ({
            ...prev, fields: {
                ...prev.fields,
                email: {...prev.fields.email, value: value, error: null},
            }
        }))
    }

    const handleSubmit = async (event) => {
        let response

        event.preventDefault()
        response = await onSubmit(form)
        if (response) {
            if (response.status === 422) {
                handleValidate(response.data.errors)
            }
            if (response.status === 200 && response.data.success) {
                showTopMessage('success', 'small', response.data.message || 'Операция выполнена успешно')
            }
        }
    }

    const handleValidate = (errors) => {
        setForm(prev => ({
            ...prev, fields: {
                ...prev.fields,
                email: {...prev.fields.email, error: errors[0].detail},
            }
        }))
    }

    return (
        <form className={modal.container}>
            <div className={modal.header}>
                <h3>Тестовая рассылка</h3>
            </div>
            <div className={modal.body}>
                <Input
                    size={'medium'}
                    outside={1}
                    placeholder={'Введите Email получателя'}
                    label={'Email'}
                    value={form.fields.email.value}
                    error={form.fields.email.error}
                    disabled={form.fields.email.is_disabled}
                    handleChange={(e) => handleChangeEmail(e.target.value)}
                />
            </div>
            <div className={modal.footer}>
                <button
                    type={'reset'}
                    className={'a-btn a-btn_size_small a-btn_type_tertiary-ghost'}
                    onClick={onCancel}>
                    Отмена
                </button>
                <button
                    className={'a-btn a-btn_size_small a-btn_type_primary-default'}
                    type={'submit'}
                    onClick={handleSubmit}>
                    Отправить
                </button>
            </div>
        </form>
    );
};

export default TestModalContent;