import {Tooltip} from "../primitives/Tooltip";
import {graphicsTooltip, worldContainer} from "../utils/Const";

export const renderTooltipe = (tooltipContainer, container, show) => {
    if (show) {
        tooltipContainer = Tooltip({
            place: container.place,
            zIndex: worldContainer.children.length + 1
        });
        tooltipContainer.visible = true;
        worldContainer.addChild(tooltipContainer);

    } else {
        if (tooltipContainer) {
            graphicsTooltip.clear()
            worldContainer.removeChild(tooltipContainer);
            tooltipContainer.destroy();
            tooltipContainer = null;
        }
    }
    return tooltipContainer
}

export const clearToolTip = () => {
    // удаление контейнера tooltip
    let toolTip = worldContainer.getChildByName(`tooltip`)
    if (toolTip) {
        worldContainer.removeChild(toolTip)
    }
}
