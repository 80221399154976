import React, {useEffect, useState} from 'react';
import Input from "../ui/Input";

const TicketOptionEditor = props => {
    const [editorOption, setEditorOption] = useState(props.item || null)
    const [validator, setValidator] = useState({})

    const [countInf, setCountInf] = useState(props.item.unlimited || false)

    useEffect(() => {
        if (!props.item.unlimited) {
            if (editorOption.count == null) {
                setEditorOption(prev => ({
                    ...prev,
                    count: `0`
                }));
            }
        }
    }, [])

    const validation = () => {
        /*если count = 0 -> нет в наличии*/
        /*если count = null -> не ограничено*/

        let message = {
            name: null,
            price: null,
            count: null,
        }

        !editorOption.name ? message.name = 'Поле Опция билета обязательно для заполнения' : message.name = null;
        (editorOption.price == null) ? message.price = 'Поле Цена обязательно для заполнения' : message.price = null;
        editorOption.count === '' ? message.count = 'Поле Количество для заполнения' : message.count = null;

        setValidator(prev => ({
            ...prev,
            name: message.name,
            price: message.price,
            count: message.count
        }))

        return Boolean((Object.values(message).filter(x => x !== null).length == 0))
    }
    return (
        <div className="ui-cp__ticket-options-editor">
            <div className="ui-cp__ticket-editor_row">
                <Input label={'Опция билета'}
                       outside={1}
                       size={'small'}
                       value={editorOption.name || null}
                       error={validator.name !== null ? validator?.name : null}
                       handleChange={(e) => {
                           let value = e.target.value
                           setEditorOption(prev => ({
                               ...prev,
                               name: value
                           }));
                           let copy = validator
                           delete validator.name
                           setValidator(copy);
                       }}
                />
            </div>
            <div className="ui-cp__ticket-editor_row">
                <Input label={'Цена, ₽'}
                       outside={1}
                       size={'small'}
                       type={'text'}
                       maxLength={8}
                       value={String(editorOption.price !== null ? editorOption.price : '') || null}
                       error={validator?.price ? validator?.price : null}
                       handleChange={(e) => {
                           let value = e.target.value
                           if (e.target.value === '' || /^[\d\b]+$/.test(e.target.value)) {
                               setEditorOption(prev => ({
                                   ...prev,
                                   price: value
                               }));
                               let copy = validator
                               delete validator.price
                               setValidator(copy);
                           }
                       }}
                />
                <div style={{position: 'relative', width: '100%'}}>
                    {countInf ? <span>Не ограничено</span> : null}
                    <Input label={'Количество'}
                           outside={1}
                           size={'small'}
                           type={'number'}
                           value={`${editorOption?.count}`}
                           onKeyPress={e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                           error={validator?.count}
                           isInfinity={true}
                           min={0}
                           max={1000000}
                           onClick={(e) => {
                               setCountInf(false)
                               let value = e.target.value
                               setEditorOption(prev => ({
                                   ...prev,
                                   count: Number(value),
                                   unlimited: false
                               }))
                           }}
                           onPicClick={(e) => {
                               setCountInf(!countInf)
                               setEditorOption(prev => ({
                                   ...prev,
                                   count: !countInf == true ? null : ''
                               }))
                               setValidator(prev => ({
                                   ...prev,
                                   count: ''
                               }))
                           }}
                           handleChange={(e) => {
                               let value = e.target.value
                               if (Number(value) <= -1) {
                                   value = 0
                               }
                               setValidator(prev => ({
                                   ...prev,
                                   count: ''
                               }))
                               if (!countInf) {
                                   setEditorOption(prev => ({
                                       ...prev,
                                       count: Number(value),
                                       unlimited: countInf
                                   }))
                               }
                           }}
                    />
                </div>
            </div>
            <div className="ui-cp__ticket-options_actions">
                <button
                    className={'a-btn a-btn_size_small a-btn_type_tertiary-ghost'}
                    onClick={() => {
                        props.handlers.handleSwitchOptionEditor(null, null)
                        props.handlers.handleDisabledForm(false)
                    }}>
                    отменить
                </button>
                <button
                    className={'a-btn a-btn_size_small a-btn_type_secondary-default'}
                    onClick={() => {
                        if (validation()) {
                            props.handlers.handleSubmitOption({
                                data: editorOption,
                                action: props.action
                            }, props.idx)
                            props.handlers.handleDisabledForm(false)
                        }
                    }}>
                    сохранить опцию
                </button>
            </div>
        </div>
    );
};

export default TicketOptionEditor;