import React from 'react';
import '../../scss/components/checkbox/Checkbox.scss'
import ReactHtmlParser from "react-html-parser";
import Message from "../../../widget/components/Message";

const Checkbox = (props) => {
    const handleCheckbox = (event, boolean) => {
        return event.target.checked
    }

    return (
        <label htmlFor={props.id}
               style={props.style}
               className={`ui-widget__checkbox${props.size ? ' ui-widget__checkbox_size-' + props.size : ' ui-widget__checkbox_size-medium'}${props.checked ? ' ui-widget__checkbox_checked' : ' '}${props.disabled ? ' ui-widget__checkbox_disabled' : ' '}${props.error ? ' ui-widget__checkbox_error' : ' '}`}
        >
            <input
                type="checkbox"
                tabIndex={props.tabIndex}
                id={props.id}
                // defaultChecked={props.checked}
                checked={props.checked}
                onChange={(event) => {
                    props.onChange(handleCheckbox(event, props.checked))
                }}
            />
            <span className={'ui-widget__checkbox_checkmark'}></span>
            {ReactHtmlParser(props.value)}
            <span className={'ui-widget__checkbox_available'}>{ReactHtmlParser(props.available)}</span>
            {props.error && <Message value={props.error} type={'error'}/>}
        </label>
    )
};

export default Checkbox;
