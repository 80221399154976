// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Group-module_container_yLBRY{width:100%}.Group-module_add_W8nql{margin-left:auto}", "",{"version":3,"sources":["webpack://resources/js/react/src/views/Admin/Events/Notifications/Groups/Group/Group.module.scss"],"names":[],"mappings":"AAAA,8BACI,UAAA,CAGJ,wBACI,gBAAA","sourcesContent":[".container {\n    width: 100%;\n}\n\n.add {\n    margin-left: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Group-module_container_yLBRY",
	"add": "Group-module_add_W8nql"
};
export default ___CSS_LOADER_EXPORT___;
