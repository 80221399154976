import React from 'react';
import Loading from '../../assets/images/icons/load-s.svg'
import '../scss/components/preloader.scss'


const Preloader = () => {
    return (
        <div className={'ui-widget__preloader'}>
            <Loading />
        </div>
    );
};

export default Preloader;