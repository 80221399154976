import React, {useEffect, useState} from 'react';
import {Header} from "./Header";
import {Sidebar} from "./Sidebar";

export const Layout = () => {
    const [toggle, setToggle] = useState(!Number(localStorage.getItem('sidebarState')) && window.innerWidth > 1279)

    return (
        <div style={{position: 'absolute', top: 0, left: 0}}>
            <Header toggle={toggle} setToggle={setToggle}/>
            <Sidebar toggle={toggle} setToggle={setToggle}/>
        </div>
    );
};