import React, {useEffect, useState} from 'react';
import Button from "../../../../../components/ui/Button";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import ReactHtmlParser from "react-html-parser";

const BottomBarDesigner = (props) => {
    const {selectedNodes, disabledSave, handleSave, activeSquare, handCloseEditor} = props;
    const [help, setHelp] = useState(false)
    const [helpModalVisibility, setHelpModalVisibility] = useState(false)

    const countSelected = () => {
        if (selectedNodes.length !== 0) {
            return selectedNodes.length
        }
        if (activeSquare.length !== 0) {
            return activeSquare.length
        }
        return 0
    }

    const handleModal = () => {
        setHelp(true)
    }

    const handleModalLeave = (e) => {
        e.target.classList.forEach(e => {
            if (e === 'bottomBarLeft-modal') {
                setHelpModalVisibility(true)
                setTimeout(e => {
                    setHelp(false)
                    setHelpModalVisibility(false)
                },150)
            }
        })
    }

    const settings = {
        dots: true,
        infinite: false,
        speed: 250,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dotsClass: 'bottomBarLeft__carousel-dots',
        customPaging: (i) => {
            return (
                <button className={'bottomBarLeft__carousel-page'}>
                    <span className={'bottomBarLeft__carousel-circle'}></span>
                </button>
            );
        },
    };

    const content = [
        {
            title: 'Добавляем объекты на схему',
            description: 'Выберите область и добавьте места, зоны без нумерации (к примеру <i>Танцпол</i>), столы, сцену. Передвигайте, меняйте названия или удаляйте добавленные объекты',
            svg: <img src={'../../../../../../../assets/images/floors/help/slide1.svg'} />,
        },
        {
            title: 'Добавляем группы',
            description: 'Чтобы объединить часть объектов в одну группу (например, <i>Партер</i> или <i>Столы ряд 1</i>) добавляйте/редактируйте группы и применяйте их к выделенным объектам',
            svg: <img src={'../../../../../../../assets/images/floors/help/slide2.svg'} />,
        }
    ]


    return (
        <>
            <div className="bottomBarLeft">
                {help && <div onClick={(e) => {handleModalLeave(e)}} className={`bottomBarLeft-modal${helpModalVisibility ? ' bottomBarLeft-modal--leave' : ''}`}>
                    <div className="bottomBarLeft-modal__container">
                        <Slider {...settings}>
                            {content.map((item, idx) => <div key={idx} className={'bottomBarLeft-modal__slide'}>
                                <div className="bottomBarLeft-modal__header">
                                    <h1>{item.title}</h1>
                                    <p>{ReactHtmlParser(item.description)}</p>
                                </div>
                                <div className="bottomBarLeft-modal__body">
                                    {item.svg}
                                </div>
                            </div>)}
                        </Slider>
                        <div className="bottomBarLeft-modal__footer">
                            <button className={'cp-btn cp-btn_icon_left cp-btn_size_small cp-scheme_secondary-ghost'}
                                    onClick={() => {
                                        window.open('/help/kak-rabotat-so-skhemami-zalov/', '_blank')
                                    }}>
                                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_2274_11494)">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M1.5 1C0.947715 1 0.5 1.44772 0.5 2V12C0.5 12.5523 0.947715 13 1.5 13H6.5C6.76522 13 7.01957 13.1054 7.20711 13.2929C7.39464 13.4804 7.5 13.7348 7.5 14C7.5 14.5523 7.94772 15 8.5 15C9.05229 15 9.5 14.5523 9.5 14C9.5 13.7348 9.60536 13.4804 9.79289 13.2929C9.98043 13.1054 10.2348 13 10.5 13H15.5C16.0523 13 16.5 12.5523 16.5 12V2C16.5 1.44772 16.0523 1 15.5 1H11.1667C10.1942 1 9.26157 1.38631 8.57394 2.07394C8.54889 2.09899 8.52425 2.12437 8.5 2.15005C8.47576 2.12437 8.45111 2.09899 8.42606 2.07394C7.73843 1.38631 6.80579 1 5.83333 1H1.5ZM7.5 4.66667C7.5 4.22464 7.32441 3.80072 7.01185 3.48816C6.69928 3.17559 6.27536 3 5.83333 3H2.5V11H6.5C6.84394 11 7.1818 11.0591 7.5 11.1716V4.66667ZM9.5 11.1716C9.8182 11.0591 10.1561 11 10.5 11H14.5V3H11.1667C10.7246 3 10.3007 3.17559 9.98816 3.48816C9.67559 3.80072 9.5 4.22464 9.5 4.66667V11.1716Z" fill="#0067F2"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_2274_11494">
                                            <rect width="16" height="16" fill="white" transform="translate(0.5)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Полная инструкция
                            </button>
                            <button className={'cp-btn cp-btn_icon_left cp-btn_size_small cp-scheme_tertiary-outline'}
                                    onClick={() => {
                                        setHelpModalVisibility(true)
                                        setTimeout(e => {
                                            setHelp(false)
                                            setHelpModalVisibility(false)
                                        },150)
                                    }}>
                                Закрыть
                            </button>
                        </div>
                    </div>
                </div>}
                <div className="bottomBarLeft__help">
                    <button className={'cp-btn cp-btn_icon_left cp-btn_size_medium cp-scheme_secondary-ghost'}
                            onClick={() => {
                                handleModal()
                            }}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM9 10H7V9.5C7 8.28237 7.42356 7.68233 8.4 6.95C8.92356 6.55733 9 6.44904 9 6C9 5.44772 8.55229 5 8 5C7.44772 5 7 5.44772 7 6H5C5 4.34315 6.34315 3 8 3C9.65685 3 11 4.34315 11 6C11 7.21763 10.5764 7.81767 9.6 8.55C9.07644 8.94267 9 9.05096 9 9.5V10ZM8.00033 12.9983C7.44786 12.9983 7 12.5506 7 11.9983C7 11.4461 7.44786 10.9983 8.00033 10.9983C8.55279 10.9983 9.00066 11.4461 9.00066 11.9983C9.00066 12.5506 8.55279 12.9983 8.00033 12.9983Z"
                                  fill="#0067F2"/>
                        </svg>
                        <span>Помощь</span>
                    </button>
                </div>
                <div className="bottomBarLeft__control">
                    <button className={'a-btn a-btn_size_medium a-btn_type_tertiary-ghost'}
                            onClick={() => {
                                handCloseEditor()
                            }}>
                        Выйти
                    </button>
                    <button className={'a-btn a-btn_size_medium a-btn_type_secondary-default'}
                            disabled={disabledSave}
                            onClick={() => {
                                handleSave()
                            }}>
                        Сохранить
                    </button>
                </div>
            </div>
            <div className="bottomBarRight">
                <div className="col-1">
                    <div className="col-1_title">Выбрано</div>
                    <div className={`col-1_value ${activeSquare.length && 'col-1_blue'}`}>{countSelected()}</div>
                </div>
            </div>
        </>
    );
};

export default BottomBarDesigner;
