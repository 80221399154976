import React, {useEffect, useState} from 'react';
import TicketOptionEditor from "./TicketOptionEditor";
import {arrayMove, SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";
import TicketsEditor from "./TicketsEditor";
import Ticket from "./TicketSettings";
import IconDraw from "../../assets/images/icons/16/pencil.svg";
import IconTrash from "../../assets/images/icons/16/trash.svg";
import IconDrag from "../../assets/images/icons/16/arrows-v.svg";
import IconAdd from "../../assets/images/icons/16/plus_simple.svg";
import {API} from "../../api/API";
import axios from "axios";
import Checkbox from "../../UIKit/component/checkbox/Checkbox";
import Message from "../../widget/components/Message";
import DragIcon from "../../assets/images/icons/16/arrows-v.svg";
import Input from "@/components/ui/Input";
import {handleFormatNumber} from "@/utils/formateSum";

const TicketOption = props => {
    const [options, setOptions] = useState(props.items || null)
    const [edit, setEdit] = useState({id: null, state: null})
    const [emptyOption, setEmptyOption] = useState({
        available: false,
        count: null,
        id: null,
        name: null,
        ordered: null,
        price: null,
        reserved: null,
        ticketId: null,
        unlimited: true,
    })

    //компонент списка опций
    const List = SortableContainer(({items}) => <div>{items.map((item, index) => {
        return <Item key={`item-${index}`}
                     index={index}
                     idx={index}
                     item={item}/>
    })}</div>);

    const handleSwitchOptionEditor = (option_idx, state) => {
        setEdit({
            id: option_idx,
            state: state
        })
    }

    const OptionDragElement = SortableHandle(() => {
        return <button className={'a-btn a-btn_size_small a-btn_type_secondary-ghost'}
                       disabled={edit.state}>
                <span className={'ui-cp__tickets-actions-drag'}>
                    <DragIcon/>
                </span>
        </button>
    })

    const handleDeleteOption = (item, option_idx) => {
        let arr = []
        if (item.id) {
            arr = options.filter(x => x.id !== item.id)
            setOptions(arr)
            props.handlers.setEditor(prev => ({
                ...prev,
                options: arr
            }))
        } else {
            arr = options
            arr.splice(option_idx, 1)
            setOptions(arr)
            props.handlers.setEditor(prev => ({
                ...prev,
                options: arr
            }))
            showTopMessage('success', 'small', 'Успешно', 'Опция успешно удалена');
        }
    }

    const handleDisabledForm = (value) => {
        return props.handlers.setDisabledForm(value)
    }

    const Item = SortableElement(props => {
        //редактор опции
        const ItemEditor = () => {
            return <TicketOptionEditor handlers={{handleSwitchOptionEditor, handleSubmitOption, handleDisabledForm}}
                                       disabledForm={props.disabledForm}
                                       item={props.item}
                                       idx={props.idx}
                                       action={'edit'}/>
        }
        //опция
        const ItemStatic = () => {
            const formatter = (option) => {
                let result
                if (option.unlimited == true) {
                    result = 'Не ограничено'
                } else {
                    if (option.count == null) {
                        result = 'Не ограничено'
                    }
                    if (option.count == 0) {
                        result = 'Нет в наличии'
                    }
                    if (option.count > 0) {
                        result = Number(option.count)
                    }
                }
                return result
            }
            return <div className={'ui-cp__ticket-option'}>
                <div className="ui-cp__ticket-option-name">
                    {props.item.name}
                    {/*TODO:Протянуть сюда ошибку*/}
                    {/*{props?.validator?.hasOwnProperty(props.validator[`options_data.${props.idx}.price`]) ? <Message value={props.validator[`options_data.${props.idx}.price`]} type={'error'}/> : null}*/}
                </div>
                <div className="ui-cp__ticket-option-price">{handleFormatNumber(props.item.price)} ₽</div>
                <div className="ui-cp__ticket-option-count">Всего: <span>{formatter(props.item)}</span></div>
                <div className="ui-cp__ticket-option-actions">
                    <button
                        disabled={edit.state}
                        className={'a-btn a-btn_size_small a-btn_type_primary-ghost'}
                        onClick={() => {
                            handleSwitchOptionEditor(props.idx, true)
                        }}>
                        <IconDraw/>
                    </button>
                    <OptionDragElement/>
                    <button
                        disabled={edit.state}
                        className={'a-btn a-btn_size_small a-btn_type_tertiary-ghost'}
                        onClick={() => {
                            handleDeleteOption(props.item, props.idx)
                        }}>
                        <IconTrash/>
                    </button>
                </div>
            </div>
        }
        return <div>{edit.state === true && edit.id === props.idx ? ItemEditor() : ItemStatic()}</div>
    });

    const onSortEnd = ({oldIndex, newIndex}) => {
        let arr = arrayMove(options, oldIndex, newIndex)
        setOptions(arr)
        props.handlers.setEditor(prev => ({
            ...prev,
            options: arr
        }));
    }

    const handleSubmitOption = (data, index) => {
        let copy = null
        switch (data.action) {
            case 'edit':
                copy = JSON.parse(JSON.stringify(options))
                copy[index] = data.data
                setOptions(copy)
                break;
            case 'add':
                copy = JSON.parse(JSON.stringify(options))
                copy.push(data.data)
                setOptions(copy)
                break;
        }
        props.handlers.setEditor((prev) => ({
            ...prev,
            options: copy
        }))
        handleSwitchOptionEditor(null, null)
    }

    return <div
        className={`ui-cp__ticket-editor_item ui-cp__ticket-options${!options?.length && !edit.state ? ' ui-cp__ticket-options_empty' : ''}`}>
        <h1 className={'ui-cp__ticket-editor_title'}>Опции</h1>
        {options?.length ?
            <List transitionDuration={500} lockAxis={'y'} items={options} onSortEnd={onSortEnd} useDragHandle/> : null}
        {edit.id === null && edit.state === true ? <div className={'ui-cp__ticket-options-wrapper'}>
            <TicketOptionEditor handlers={{handleSwitchOptionEditor, handleSubmitOption, handleDisabledForm}}
                                disabledForm={props.disabledForm}
                                item={emptyOption}
                                idx={null}
                                action={'add'}/>
        </div> : null}
        {options.length > 0 ? <div className={'ui-cp__ticket-options-checkboxes'}>
            {options.length ? <Input
                type={"number"}
                id={"need_one_option"}
                label={'Укажите минимальное количество опций для покупки билета'}
                outside={1}
                size={'small'}
                value={props.editor?.needOneOptionParam}
                error={props.validator?.need_one_option}
                handleChange={({target}) => props.handlers.setEditor(prev => ({
                    ...prev,
                    needOneOptionParam: target.value
                }))}
            /> : null}
            {options.length && options.length > 1 ? <Input
                type={"number"}
                id={"onlyOneOptionParam"}
                label={'Укажите максимальное количество опций для покупки билета'}
                outside={1}
                size={'small'}
                value={props.editor?.onlyOneOptionParam}
                error={props.validator?.only_one_option}
                handleChange={({target}) => props.handlers.setEditor(prev => ({
                    ...prev,
                    onlyOneOptionParam: target.value
                }))}
            /> : null}
            {/*{options.length > 0 ? <Checkbox*/}
            {/*    checked={props.editor?.needOneOptionParam}*/}
            {/*    size={'small'}*/}
            {/*    id={'need_one_option'}*/}
            {/*    value={'Минимум одна опция обязательна'}*/}
            {/*    onChange={(e) => {*/}
            {/*        props.handlers.setEditor(prev => ({*/}
            {/*            ...prev,*/}
            {/*            needOneOptionParam: Number(e)*/}
            {/*        }))*/}
            {/*    }}/> : null}*/}
            {/*{options.length > 1 ? <Checkbox*/}
            {/*    checked={props.editor?.onlyOneOptionParam}*/}
            {/*    size={'small'}*/}
            {/*    id={'only_one_option'}*/}
            {/*    value={'Можно выбрать только 1 опцию'}*/}
            {/*    onChange={(e) => {*/}
            {/*        props.handlers.setEditor(prev => ({*/}
            {/*            ...prev,*/}
            {/*            onlyOneOptionParam: Number(e)*/}
            {/*        }))*/}
            {/*    }}/> : null}*/}
        </div> : null}
        {!edit.state ? <button
            className={'cp-btn cp-btn_size_small cp-scheme_secondary-default cp-btn_icon_left ui-cp__ticket-options-add'}
            disabled={props.dropdownGroups || props.dropdownSessions}
            onClick={() => {
                props.handlers.setDisabledForm(true)
                handleSwitchOptionEditor(null, true)
            }}>
            <IconAdd/>
            Добавить опцию
        </button> : null}
    </div>
};

export default TicketOption;
