import React, {useState} from 'react';
import modal from '../Modal.module.scss'
import styles from './Resend.module.scss'
import resendDataJson from './Resend.data.json'
import NotifyAt from "../Datetime/NotifyAt/NotifyAt";
import {useSelector} from "react-redux";

const Resend = ({data, onSubmit, onCancel}) => {
    const selector = useSelector(state => state)
    const event = selector.admin.event
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({
        ...resendDataJson, fields: {
            ...resendDataJson.fields,
            notify_at: {...resendDataJson.fields.notify_at, value: null},
        }
    })

    const handleSubmit = async (event) => {
        let response
        setLoading(true)
        event.preventDefault()
        response = await onSubmit({attributes: {notify_at: form.fields.notify_at.value, id: data.id}})
        if (response.status === 422) {
            handleValidate(response.data.errors)
            setLoading(false)
            return
        }
        if (response.status === 200) {
            showTopMessage('success', 'small', response.data?.message || 'Операция выполнена успешно')
        }
    }

    const handleValidate = (errors) => {
        setForm(prev => ({
            ...prev, fields: {
                ...prev.fields,
                notify_at: {...prev.fields.notify_at, error: errors[0].detail},
            }
        }))
    }

    return (
        <form className={modal.container} onSubmit={handleSubmit}>
            <div className={modal.header}>
                <h3>Повторная отправка</h3>
            </div>
            <div className={[modal.body, styles.body].filter(x => x ?? x).join(' ')}>
                <NotifyAt data={form} onChange={setForm} timezone={event.timezone.timezone}/>
            </div>
            <div className={modal.footer}>
                <button
                    className={'a-btn a-btn_size_small a-btn_type_tertiary-ghost'}
                    type={'reset'}
                    onClick={onCancel}>
                    Отмена
                </button>
                <button
                    className={'a-btn a-btn_size_small a-btn_type_primary-default'}
                    type={'submit'}
                    disabled={loading}
                    onClick={handleSubmit}>
                    Сохранить
                </button>
            </div>
        </form>
    );
};

export default Resend;