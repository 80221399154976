import React from 'react';
import '../../scss/components/radio/radio.scss'

const Radio = (props) => {
    return (
        <div className={`nh-radio nh-radio__${props.size || 'medium'}${props.disabled ? ' nh-radio__disabled' : ''}`}
             tabIndex={0}>
            <label className="nh-radio__container">
                <input
                    type="radio"
                    checked={props.checked}
                    onChange={props.onChange}
                />
                <span className="nh-radio__checkmark"></span>
                <span className="nh-radio__label">{props.label}</span>
            </label>
        </div>
    );
};

export default Radio;
