import $ from 'jquery'

export default class MailingApi {
    _baseMailingUrl = 'http://localhost:8081/api';
    _tokenLoading = false;

    constructor() {
        this._baseMailingUrl = $('#js-api_url').data('api-url');
        this._token = localStorage.getItem('keycloackToken');
    }

    async authorize() {
        const response = await this.getKeycloakToken();
        let result = await response.json();
        try {
            this.setToken(result.token);
            return true;
        } catch (e) {
            return false;
        }
    }
    async getKeycloakToken() {
        const response = await fetch('/admin/mailings/get-token', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });

        this._tokenLoading = false;
        return response;
    }
    async fetchData(method, url = '', data = null) {
        let config = {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this._token,
            }
        }
        if (data !== null && typeof data === "object"){
            config.body = JSON.stringify(data);
        }
        return await fetch(url, config);
    }
    setToken(token) {
        this._token = token;
        localStorage.setItem('keycloackToken', token);
    }
    getGroupsUrl(){
        return this._baseMailingUrl + '/groups';
    }

    getMailingsUrl(){
        return this._baseMailingUrl + '/mailings';
    }
    getSubscribersCountUrl(){
        return this._baseMailingUrl + '/subscribers/count-by-params';
    }
    async getGroups(e =null) {
        let url = this.getGroupsUrl();
        if (e !== null){
            url = $(e.target).attr('href');
        }
        return this.fetchData( 'get',url);
    }

    async getGroup(id) {
        let url = `${this.getGroupsUrl()}/${id}`;
        return this.fetchData( 'get', url);
    }

    async deleteGroup(id) {
        let url = `${this.getGroupsUrl()}/${id}`;
        return this.fetchData( 'delete', url);
    }

    async updateGroup(id, data) {
        let url = `${this.getGroupsUrl()}/${id}`;
        return this.fetchData( 'put', url, data);
    }

    async storeGroup(data) {
        return this.fetchData( 'post', this.getGroupsUrl(), data);
    }

    async getMailings(e =null) {
        let url = this.getMailingsUrl();
        if (e !== null){
            url = $(e.target).attr('href');
        }
        return this.fetchData( 'get',url);
    }
    async getMailing(id) {
        let url = `${this.getMailingsUrl()}/${id}`;
        return this.fetchData( 'get', url);
    }

    async deleteMailing(id) {
        let url = `${this.getMailingsUrl()}/${id}`;
        return this.fetchData( 'delete', url);
    }

    async updateMailing(id, data) {
        let url = `${this.getMailingsUrl()}/${id}`;
        return this.fetchData( 'put', url, data);
    }

    async storeMailing(data) {
        return this.fetchData( 'post', this.getMailingsUrl(), data);
    }

    async getSubscribersCountByParams(params) {
        return this.fetchData(
            'post',
            this.getSubscribersCountUrl(),
            {
                "params": params
            }
        );
    }
}
