import React, {useEffect, useState} from 'react';
import ArrowIcon from "../../../assets/images/icons/12/arrow.svg";
import DashboardIcon from '../../../assets/images/icons/24/dashboard.svg'
import EventIcon from '../../../assets/images/icons/24/calendar-dates.svg'
import CourseIcon from '../../../assets/images/icons/24/education.svg'
import FinanceIcon from '../../../assets/images/icons/24/money.svg'
import ServiceIcon from '../../../assets/images/icons/24/cash.svg'
import AppIcon from '../../../assets/images/icons/24/puzzle.svg'
import AccessIcon from '../../../assets/images/icons/24/peoples.svg'
import MailingIcon from '../../../assets/images/icons/24/mail.svg'
import PromoIcon from '../../../assets/images/icons/24/rocket.svg'
import PartnerIcon from '../../../assets/images/icons/24/partners.svg'
import FloorIcon from '../../../assets/images/icons/24/seats.svg'
import TaskIcon from '../../../assets/images/icons/24/book.svg'
import CertificateIcon from '../../../assets/images/icons/24/award.svg'
import {useRouteMatch} from "react-router-dom";

export const SidebarItem = ({item, toggle, activeSubitem}) => {
    const [open, setOpen] = useState(false)
    const router = useRouteMatch()

    useEffect(() => {
        if (item.type === activeSubitem) {
            setOpen(true)
        }
    }, [activeSubitem])

    /* Слушатель выпадающего подменю */
    const handleClickItem = () => {
        if (!item.href && toggle) {
            setOpen(!open)
        }
    }

    /* Возвращает необходимый класс в зависимости от состояния боковой панели, строка */
    const checkStatusSidebar = () => {
        let arr = ['nh-cp__sidebar__item']

        //проверки в зависимости от состояния боковой панели
        if (!toggle) {
            arr.push('nh-cp__sidebar__item-lite')
        } else {
            arr.push('nh-cp__sidebar__item-advanced')
            if (open) {
                arr.push('nh-cp__sidebar__item-active')
            }
        }

        return arr.filter(x => x ?? x).join(' ')
    }


    /* Проверка на активный элемент, строка/класс */
    const checkActiveItem = () => {
        let arr = [], url = new URL(window.origin + window.location.pathname).toString()
        arr.push(Boolean(url.includes(item.url)))
        arr.push(Boolean(item.menus && item.menus.find(x => url.includes(x.url))))
        arr.push(Boolean(item.or_urls && item.or_urls.find(x => url.includes(x))))
        arr = arr.filter(x => x ?? x)
        return arr.length ? 'nh-cp__sidebar__item-selected' : null
    }

    /* Проверка на активный подэлемент, строка/класс */
    const checkActiveSubitem = (subitem) => {
        if (router.url === subitem.url) {
            return 'nh-cp__sidebar__subitem-selected'
        }

        return ''
    }

    /* Проверка на необходимую иконку, строка/класс */
    const checkSvgItem = (item) => {
        let type = item?.type
        switch (type) {
            case 'dashboard':
                return <DashboardIcon/>;
            case 'event':
                return <EventIcon/>;
            case 'course':
                return <CourseIcon/>;
            case 'finance':
                return <FinanceIcon/>;
            case 'service':
                return <ServiceIcon/>;
            case 'app':
                return <AppIcon/>;
            case 'access':
                return <AccessIcon/>;
            case 'mailing':
                return <MailingIcon/>;
            case 'promo':
                return <PromoIcon/>;
            case 'partner':
                return <PartnerIcon/>;
            case 'floor':
                return <FloorIcon/>;
            case 'task':
                return <TaskIcon/>;
            case 'certificate':
                return <CertificateIcon/>;
        }
    }

    return (
        <li className={[checkStatusSidebar(), checkActiveItem(), checkSvgItem()].filter(x => x ?? x).join(' ')}>
            <div className={'nh-cp__sidebar__item-wrapper'}>
                <a href={item.url}
                   target={item.target}
                   onClick={handleClickItem}>
                    <div className={'nh-cp__sidebar__icon'}>
                        {checkSvgItem(item)}
                    </div>
                    <div className={'nh-cp__sidebar__content'}>
                        <h6>{item.name}</h6>
                        {item?.menus && item.menus.length ? <span className={'nh-cp__sidebar__arrow'}>
                            <ArrowIcon/>
                        </span> : null}
                    </div>
                </a>
                {item.menus && item.menus.length ?
                    <ul className={['nh-cp__sidebar__sublist'].filter(x => x ?? x).join(' ')}>
                        {item?.menus.map((subitem, subitem_idx) => <li key={subitem_idx}>
                            {(subitem.hasOwnProperty('if') ? subitem.if : true) ?
                                <a className={['nh-cp__sidebar__subitem', checkActiveSubitem(subitem)].filter(x => x ?? x).join(' ')}
                                   href={subitem.url}
                                   target={subitem.target}>
                                    {subitem.name}
                                </a> : null}
                        </li>)}
                    </ul> : null}
            </div>
        </li>
    );
};
