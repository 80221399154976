export const STAGESTEP = 36; // размер клетки холста

// группы для создания нового зала
export const DEFAULTGROUPSLIST = {
    '1': {id: -1, name: 'Группа 1', type: 1, color: '#C5F6FA'},
    '4': {id: -4, name: 'Зоны', type: 4, color: '#E5DBFF'},
    '3': {id: -3, name: 'Столы', type: 3, color: '#FFE066'}
};

export const DEFAULTSTAGE = {
    scale: 1,
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    offsetX: 0,
    offsetY: 0,
    backgroundX: 0,
    backgroundY: 0,
    minY: 0,
    maxY: 0,
    minX: 0,
    maxX: 0
}
