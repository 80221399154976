/* Component props:
 * id => Unique input identifier
 * size => Size of the component; values: xlarge/large/medium/small; default :large;
 * type => Type of input field; values: text/number/password; default: text;
 * value => Default value of input field. String or null
 * label => Label for the input. String or null
 * name => Input name. String.
 * inputRef => Link to input ref.
 * isClearable => Does component have clear icon and functional; values: true/false; default: false
 * isInfinity => Does component have infinity icon and functional; values: true/false; default: false
 * required => Does component have required attribute. Boolean
 * tip => Tip for the input field. String or null
 * error => Error message
 * readOnly => is input readonly
 */

import React from 'react';
import Tip from '../Toggletip';
import IconClear from '../../assets/images/icons/16/x.svg';
import IconInfinity from '../../assets/images/icons/16/Union.svg';
import IconQuestion from '../../assets/images/icons/16/question-circle.svg';
import IconCalendar from '../../assets/images/icons/16/calendar.svg'
import IconTime from '../../assets/images/icons/16/time 1.svg'
import Message from "../../widget/components/Message";

import '../../scss/ui/a-input.scss';
import ReactHtmlParser from "react-html-parser";
import Toggletip from "../Toggletip";
import ReactTooltip from "react-tooltip";

const Input = (props) => {
    return (
        <div className={`a-input a-input_size_${props.size || 'large'}${props.calendar || props.time ? ' a-input__calendar' : ''}`}>
            {props.calendar ? <i className={'a-input__icon-calendar'}><IconCalendar/></i> : null}
            {props.time ? <i className={'a-input__icon-calendar'}><IconTime/></i> : null}
            <input
                onKeyPress={props.onKeyPress}
                onKeyDown={props.onKeyDown}
                onClick={props.onClick}
                onBlur={props.onBlur}
                onFocus={props.onFocus}
                calendar={props.calendar}
                type={props.type || 'text'}
                className={'a-input__input ' + (props.error && !props.disabled ? ' a-input__input_error' : '') + (props.disabled ? ' a-input__input_disabled' : '')}
                disabled={props.disabled}
                name={props.name || ''}
                value={props.value || ''}
                ref={props.inputRef}
                placeholder={props.placeholder}
                onChange={props.handleChange}
                id={props.id}
                required={props.required || false}
                readOnly={props.readOnly}
                min={props.min}
                max={props.max}
                maxLength={props.maxLength}
                outside={`${props.outside}` || false}
                title={props.title}
                style={props.style}
            />

            {/* Если присутствует ошибка, значение в инпуте и лейбл сдвигается вверх, */}
            {/* то скрываем его, чтобы не пересекался с ошибкой */}

            {/* Если есть значение, то лейбл сдвигаем вверх. Если есть ошбка и нет значения */}
            {/* то сдвигаем лейбл вниз */}

            {!props.outside && props.label && !(props.error && props.value && props.value.length > 0) && (
                <label
                    className={
                        (props.outside ? ' a-input__label_outside' : 'a-input__label') +
                        (props.value //убрал дополнительные условия, чтобы лейбл не перекрывал инпут, если в нём число или дата
                            ? !props.outside ? ' a-input__label_fixed' : ''
                            : '') +
                        ((!props.value || props.value.length === 0) && props.error ? props.outside ? '' : ' a-input__label_bottom' : '')
                    }
                    htmlFor={props.name}
                >
                    {props.label}
                </label>
            )}
            {props.outside && props.label && (
                <label
                    className={
                        (props.outside ? ' a-input__label_outside' : 'a-input__label') +
                        (props.value //убрал дополнительные условия, чтобы лейбл не перекрывал инпут, если в нём число или дата
                            ? !props.outside ? ' a-input__label_fixed' : ''
                            : '') +
                        ((!props.value || props.value.length === 0) && props.error ? props.outside ? '' : ' a-input__label_bottom' : '')
                    }
                    htmlFor={props.name}
                >
                    {ReactHtmlParser(props.label)}
                </label>
            )}
            {props.isClearable && (
                <i className={'a-input__clear'} onClick={() => props.handleChange({target: {value: ''}})}>
                    <IconClear/>
                </i>
            )}
            {props.toggletipText && (
                <i data-tip="React-tooltip" data-for='toolTipInput' className={'a-input__question'}>
                    <Toggletip text={props.toggletipText} />
                    {/*<IconQuestion/>*/}
                    {/*<ReactTooltip id="toolTipInput" place="top" type="dark" effect="float" className="reactTooltip">*/}
                    {/*<span style={{ maxWidth: '100px'}}>*/}
                    {/* {props.toggletipText}*/}
                    {/*</span>*/}
                    {/*</ReactTooltip>*/}
                </i>
            )}
            {props.isInfinity && (
                <i className={'a-input__infinity'} onClick={props.onPicClick}>
                    <IconInfinity/>
                </i>
            )}
            {/*{props.error && <div className="a-input__warning">*/}
            {/*    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
            {/*        <path*/}
            {/*            d="M7.99998 6C8.2652 6 8.51954 6.10536 8.70708 6.29289C8.89461 6.48043 8.99998 6.73478 8.99998 7V9C8.99998 9.26522 8.89461 9.51957 8.70708 9.70711C8.51954 9.89464 8.2652 10 7.99998 10C7.73477 10 7.48043 9.89464 7.29289 9.70711C7.10536 9.51957 6.99998 9.26522 6.99998 9V7C6.99998 6.73478 7.10536 6.48043 7.29289 6.29289C7.48043 6.10536 7.73477 6 7.99998 6Z"*/}
            {/*            fill="#FB3640"/>*/}
            {/*        <path*/}
            {/*            d="M7.99998 11C8.2652 11 8.51954 11.1054 8.70708 11.2929C8.89461 11.4804 8.99998 11.7348 8.99998 12C8.99998 12.2652 8.89461 12.5196 8.70708 12.7071C8.51954 12.8946 8.2652 13 7.99998 13C7.73477 13 7.48043 12.8946 7.29289 12.7071C7.10536 12.5196 6.99998 12.2652 6.99998 12C6.99998 11.7348 7.10536 11.4804 7.29289 11.2929C7.48043 11.1054 7.73477 11 7.99998 11Z"*/}
            {/*            fill="#FB3640"/>*/}
            {/*        <path fillRule="evenodd" clipRule="evenodd"*/}
            {/*              d="M0.220151 13.0176L6.15737 1.13919C6.91657 -0.379728 9.08342 -0.379731 9.84263 1.13919L15.7798 13.0176C16.4647 14.3878 15.4687 16 13.9372 16H2.06279C0.531325 16 -0.464739 14.3878 0.220151 13.0176ZM1.99934 14L7.99734 2L13.9953 14H1.99934Z"*/}
            {/*              fill="#FB3640"/>*/}
            {/*    </svg>*/}
            {/*    {props.error}*/}
            {/*</div>}*/}
            {props.error && <Message type={"error"} value={props.error}/>}
        </div>
    );
};

export default Input;
