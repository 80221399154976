import React from 'react';
import styles from './Period.module.scss'
import Message from "../../../../../../../../widget/components/Message";

const Period = ({data, onChange}) => {
    const handleChange = (field, value) => {
        onChange(prev => ({
            ...prev, fields: {...prev.fields, [field]: {...prev.fields[field], value: value, error: null}}
        }))
    }

    const checkActiveItem = (field, value) => {
        return [
            styles.item, value === data.fields[field].value ? styles.active : null
        ].filter(x => x ?? x).join(' ')
    }

    return (
        <div className={styles.container}>
            {Object.entries(data.fields.period.variants).map(([key, value]) => <div
                className={checkActiveItem('period', key)}
                onClick={() => handleChange('period', key)}
                key={key}>
                {value}
            </div>)}
            {data.fields.period.error ? <div className={styles.error}>
                <Message type={'error'} value={data.fields.period.error}/>
            </div> : null}
        </div>
    );
};

export default Period;