import React from 'react';
import './Apps.scss'
import data from './Apps.data'
import ArrowIcon from '../../../assets/images/icons/16/arrow-up.svg'
import ReactHtmlParser from "react-html-parser";

const Apps = () => {
    return (
        <div className={'control-page__main cp-apps'}>
            <div className="control-page__heading">
                {currentMenu ? <div className={'service-page'}>
                    <h2 className="service-page__title">
                        {currentMenu?.name || 'Приложения'}
                    </h2>
                    {currentMenu.description ? <div className="service-page__desc">
                        {ReactHtmlParser(currentMenu.description)}
                    </div> : null}
                </div> : null}
            </div>
            {data.length ? <div className={'cp-apps__list'}>
                {data.map((app, app_idx) => <a
                    className={'cp-apps__item'}
                    href={app.path}
                    key={app_idx}>
                    <div className={'cp-apps__item-image'}>
                        <img src={app.img} alt=""/>
                    </div>
                    <div className={'cp-apps__item-bar'}>
                        <p>{app.description}</p>
                        <h6>
                            {app.name}
                            <span>
                                <ArrowIcon/>
                            </span>
                        </h6>
                    </div>
                </a>)}
            </div> : null}
        </div>
    );
};

export default Apps;
