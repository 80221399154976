/**
 * Прокручивает видимую область к указанному целевому элементу, с необязательным позиционированием и смещением.
 *
 * @param {HTMLElement|string} target - Целевой элемент, к которому нужно прокрутить: ref, DOM-элемент, селектор
 * @param {object} options - Объект с необязательными параметрами:
 *   @property {string} [position='top'] - Вертикальное положение целевого элемента для выравнивания. Может быть 'top', 'center' или 'bottom'.
 *   @property {number} [offset=0] - Смещение в пикселях для корректировки окончательной позиции прокрутки.
 * @throws {Error} Если не найден элемент для указанного селектора цели или если цель не является допустимым элементом DOM или строкой-селектором.
 */

const scrollToElement = (target, {position = 'top', offset = 0} = {}) => {
    let ref;
    if (typeof target === 'string') {
        ref = document.querySelector(target);
        if (!ref) {
            throw new Error(`scrollToElement: no element found for selector "${target}"`);
        }
    } else if (target instanceof HTMLElement) {
        ref = target;
    } else if (target?.current instanceof HTMLElement) {
        ref = target.current;
    }

    if (!ref) {
        throw new Error('scrollToElement: "target" must be a valid React ref, a DOM element, or a string selector');
    }

    const scrollOffset = ref.getBoundingClientRect().top + window.pageYOffset;
    const windowHeight = window.innerHeight;
    const maxScroll = document.body.scrollHeight - windowHeight;
    let targetOffset;

    switch (position) {
        case 'center':
            targetOffset = scrollOffset + offset - windowHeight / 2;
            break;
        case 'bottom':
            targetOffset = scrollOffset + offset - windowHeight;
            break;
        default:
            targetOffset = scrollOffset + offset;
            break;
    }

    const finalOffset = Math.min(Math.max(targetOffset, 0), maxScroll);
    window.scrollTo({top: finalOffset, behavior: 'smooth'});
};

export default scrollToElement;