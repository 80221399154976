import React, {forwardRef, useEffect, useImperativeHandle, useRef} from 'react';
import '../../scss/components/window/window.scss'
import CrossIcon from "../../../assets/images/icons/16/close_12.svg";
import Standard from './template/standard'
import Custom from './template/custom'

/**
 * status
 * require: обязательный параметр
 * typeof: boolean
 * default: undefined
 * description: статус окна, открыто оно или закрыто
 *
 * setStatus
 * require: обязательный параметр
 * typeof: handler
 * default: undefined
 * description: изменение статуса окна, открыть или закрыть
 *
 * content
 * require: обязательный параметр
 * typeof: JSX или объект типового окна
 * default: объект типового окна 'warning' (см конец)
 * description: принимает в себя JSX для рендера кастомного компонента, либо объект для рендера типового окна
 *
 * ref
 * require: обязательный параметр
 * typeof: link
 * default: undefined
 * description: Ref для вызова функций этого компонента из родительских
 *
 * dismissible
 * require: необязательный параметр;
 * typeof: boolean;
 * default: false
 * description: возможность закрытия окна путём нажатия на область вне его границ
 *
 * esc
 * require: необязательный параметр;
 * typeof: boolean;
 * default: false
 * description: возможность закрытия окна путём нажатия на клавишу Esc
 *
 * cross
 * require: необязательный параметр;
 * typeof: boolean;
 * default: true
 * description: возможность закрытия окна путём нажатия на крестик
 *
 * beforeClose
 * require: необязательный параметр
 * typeof: handler
 * default: undefined
 * description: Событие, выполняемое перед закрытием окна
 *
 * afterClose
 * require: необязательный параметр
 * typeof: handler
 * default: undefined
 * description: Событие, выполняемое после закрытия окна
 *
 * selector
 * require: необязательный параметр
 * typeof: class
 * default: undefined
 * description: Селектор для кастомизации css стилей
 *
 *
 *
 *
 *
 *
 * Объект типового окна:
 * {
 *     icon: <IconComponent/>
 *     type: 'info' / 'warning' / 'error' / 'success'
 *     title: 'string',
 *     message: 'string'
 *     cancelText: 'string',
 *     submitText: 'string',
 *     cancelScheme: 'tertiary-default' или другие, соответствует схемам кнопок,
 *     submitScheme: 'primary-ghost' или другие, соответствует схемам кнопок,
 *     handleCancel: func, функция для cancel,
 *     handleSubmit: func, функция для submit,
 * }
 *
 * **/

const Window = forwardRef((props, ref) => {
    const windowRef = useRef()

    /* Дескриптор для родительских компонентов */
    useImperativeHandle(ref, () => ({
        handleClose() {
            handleClose()
        }
    }));

    /* Автофокус на модальном окне */
    useEffect(() => {
        windowRef.current ? windowRef.current.focus() : null
    }, [windowRef.current])

    /* Закрытие по клику вне окна */
    const handleDismissible = (e) => e.target === windowRef.current && props?.dismissible && handleClose()

    /* Закрытие по нажатию на Esc */
    const handleEscPress = (e) => e.keyCode === 27 && props?.esc ? handleClose() : null

    /* Нажатие на крестик */
    const handleCross = () => props?.cross !== false ? <button
        type={'button'}
        className={'a-btn a-btn_size_small a-btn_type_tertiary-ghost nh-window__cross'}
        onClick={() => handleClose()}>
        <CrossIcon/>
    </button> : null

    /* Закрыть окно */
    const handleClose = () => {
        props.hasOwnProperty('beforeClose') && props.beforeClose()
        windowRef.current ? windowRef.current.style.animation = '.15s window-out forwards' : null
        setTimeout(() => {
            props.setStatus(false)
            props.hasOwnProperty('afterClose') && props.afterClose()
        }, 150)
    }

    /* Определение типа модального окна */
    const renderTemplate = () => {
        let type = Boolean(props.content?.$$typeof)
        return type ? <Custom content={props.content}/> : <Standard handleClose={handleClose} content={props.content}/>
    }

    return props.status ? <div
        className={`nh-window${props?.selector ? ' ' + props.selector : ''}`}
        tabIndex={-1}
        ref={windowRef}
        onKeyDown={(e) => handleEscPress(e)}
        onClick={(e) => handleDismissible(e)}>
        <div className={['nh-window__container', `nh-window__${props.size || 'small'}`].filter(x => x ?? x).join(' ')}>
            {handleCross()}
            <div className={'nh-window__body'}>
                {renderTemplate()}
            </div>
        </div>
    </div> : null
});

export default Window;
