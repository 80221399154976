import React, {useEffect, useRef, useState} from 'react';
import scrollToElement from "../../../../../../../utils/scrollToElement";
import editDataJson from './Edit.data.json'
import styles from "./Edit.module.scss";
import Checkbox from "../../../../../../../UIKit/component/checkbox/Checkbox";
import {useRouteMatch} from "react-router-dom";
import {methodGetConfirmed} from "../../Owners.methods";
import Suggest from "../../../../../../../UIKit/component/suggest/Suggest";
import Message from "../../../../../../../widget/components/Message";

const Edit = ({data, onSubmit, onCancel, orgTemplates}) => {
    const editorRef = useRef()
    const router = useRouteMatch()
    const {event_id, type} = router.params
    const [form, setForm] = useState({
        ...editDataJson, fields: {
            ...editDataJson.fields, email: {
                ...editDataJson.fields.email, value: data.additional_email.email,
            },
            types: {
                ...editDataJson.fields.types, value: data.types || 0,
                variants: orgTemplates.length ? orgTemplates.map(x => ({
                    ...x,
                    is_active: (BigInt(data.types || 0) & BigInt(x.email_type)) === BigInt(x.email_type)
                })) : [],
            }
        }
    })

    useEffect(() => {
        scrollToElement(editorRef, {position: 'top', offset: -70})
        handleGetConfirmedEmails()
    }, [])

    const handleGetConfirmedEmails = async () => {
        let response = await methodGetConfirmed(null, {})
        setForm(prev => ({
            ...prev, fields: {...prev.fields, email: {...prev.fields.email, variants: response}}
        }))
    }

    const transformArrayToObject = (array) => {
        const obj = {};

        Object.entries(array).map(([key, value]) => obj[value.id] = value.email);
        return Object.fromEntries(
            Object.entries(obj).filter(([key, value]) => {
                if (!form.fields.email.value) return true
                return value.includes(form.fields.email.value);
            })
        );
    }

    const handleSubmit = async (e) => {
        let response, type, isSubscribe
        e.preventDefault()
        setForm(prev => ({...prev, is_disabled: true}))
        type = calculationTypes(form.fields.types.variants)

        if (form.fields.email.variants.filter(x => x.email === form.fields.email.value).length) {
            isSubscribe = form.fields.email.variants.find(x => x.email === form.fields.email.value).id
        } else {
            isSubscribe = data.additional_email_id
        }

        response = await onSubmit({
            email: form.fields.email.value,
            types: type,
            additional_email_id: isSubscribe,
            id: data.id,
            event_id: event_id
        })

        if (response && response.status && response.status === 422) {
            handleValidateForm(response.data.errors)
            setForm(prev => ({...prev, is_disabled: false}))
            return
        }

        if (response && !response.id) {
            showTopMessage('error', 'small', 'Произошла ошибка. Повторите попытку позднее')
            setForm(prev => ({...prev, is_disabled: false}))
            return
        }

        if (response && response.status === 200) {
            showTopMessage('success', 'small', 'Операция выполнена успешно')
            return
        }
    }

    const handleValidateForm = (errors) => {
        let data = {}
        const pointer = (x) => x.source.pointer.replace('/data/attributes/', '')
        errors.map(x => {
            let field = pointer(x)
            field === 'additional_email_id' ? field = 'email' : null
            data[field] = {...form.fields[field], error: x.detail}
        })

        setForm(prev => ({...prev, fields: {...prev.fields, ...data}}))
    }

    const handleCancel = (e) => {
        e.preventDefault()
        onCancel()
    }

    const handleField = (field, value) => {
        setForm(prev => ({
            ...prev, fields: {
                ...prev.fields,
                [field]: {...prev.fields[field], value: value, error: null},
            }
        }))
    }

    const handleChangeTypes = (idx) => {
        let variants, value
        variants = form.fields.types.variants
        variants = variants.map((x, i) => i === idx ? {...x, is_active: !x.is_active} : {...x})
        value = calculationTypes(variants)
        setForm(prev => ({
            ...prev, fields: {
                ...prev.fields,
                types: {...prev.fields.types, value: value, error: null, variants: variants}
            }
        }))
    };

    const calculationTypes = (variants) => {
        let value = BigInt(0);
        variants.map((x, idx) => x.is_active ? value |= BigInt(variants[idx].email_type) : null);
        return Number(value.toString())
    }


    return (
        <form ref={editorRef} onSubmit={handleSubmit} className={styles.container}>
            <div className={styles.section}>
                <h6 className={styles.title}>Email</h6>
                <div className={styles.email}>
                    <Suggest
                        value={form.fields.email.value}
                        data={transformArrayToObject(form.fields.email.variants)}
                        size={'medium'}
                        placeholder={'Введите Email'}
                        outside={1}
                        visibilityItems={5}
                        error={form.fields.email.error}
                        disabled={form.fields.email.is_disabled || data.hash || form.is_disabled}
                        direction={['bottom']}
                        onInput={(e) => handleField('email', e.target.value)}
                        onChange={(e) => handleField('email', form.fields.email.variants.find(x => x.id == e).email)}
                    />
                </div>
            </div>
            <div className={styles.section}>
                <h6 className={styles.title}>Уведомления</h6>
                <div className={styles.checkbox}>
                    {form.fields.types.variants.length ? (
                        <div className={styles.templates}>
                            {form.fields.types.variants.map((x, i) => <Checkbox
                                key={i}
                                size={'small'}
                                disabled={form.is_disabled}
                                checked={x.is_active}
                                value={x.name}
                                onChange={() => handleChangeTypes(i)}
                            />)}
                        </div>
                    ) : null}
                    {form.fields.types.error ? <Message type={'error'} value={form.fields.types.error}/> : null}
                </div>
            </div>
            <div className={styles.footer}>
                <button
                    className={'a-btn a-btn_icon_left a-btn_size_small a-btn_type_tertiary-ghost'}
                    disabled={form.is_disabled}
                    type={'reset'} onClick={handleCancel}>
                    Отмена
                </button>
                <button
                    className={'a-btn a-btn_icon_left a-btn_size_small a-btn_type_primary-default'}
                    disabled={form.is_disabled}
                    type={'submit'} onClick={handleSubmit}>
                    Сохранить
                </button>
            </div>
        </form>
    );
};

export default Edit;