import React, {forwardRef} from 'react';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import styles from './Editor.module.scss'
import Message from "../../../widget/components/Message";

const Editor = forwardRef((
    {
        value,
        error,
        formats,
        modules,
        theme,
        onChange,
    }, ref) => {

    const handleChange = (newValue) => {
        onChange(newValue === '<p><br></p>' ? '' : newValue)
    }

    return (
        <div className={styles.container}>
            <ReactQuill
                ref={ref}
                className={[styles.quill, error ? styles.error : null].filter(x => x ?? x).join(' ')}
                theme={theme || "snow"}
                value={value}
                modules={modules}
                formats={formats}
                onChange={(newValue, delta, source) => source === 'user' ? handleChange(newValue) : null}
            />
            {error ? <Message type={'error'} value={error}/> : null}
        </div>
    );
});

export default Editor;
