import Paginator from "./paginator";
import MailingListItem from "./MailingListItem";

export default function MailingTable(props) {
    const mailings = props.mailings;
    let mailingCounter = 0;
    if (mailings !== null){
        mailingCounter = mailings.meta.total;
    }

    const emptyMailing = (
        <>
            { mailingCounter === 0 &&
            <div className="mailing-table__empty" id="mailing-empty_js-list">
                <div className="mailing-table__empty-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none">
                        <g fillRule="evenodd" clipPath="url(#clip0)" clipRule="evenodd">
                            <path fill="#9381FF"
                                  d="M11.616 12L24 20.669 36.384 12H11.616zm27.377 1.836c.005.054.007.109.007.164v5.378c1.064.274 2.072.69 3 1.227V14a5 5 0 0 0-5-5H11a5 5 0 0 0-5 5v20a5 5 0 0 0 5 5h16.056c-.797-.89-1.463-1.9-1.968-3H11a2 2 0 0 1-2-2V14c0-.055.002-.11.007-.164l13.56 9.491a2.5 2.5 0 0 0 2.867 0l13.56-9.491z"/>
                            <path fill="#000"
                                  d="M42 31a6 6 0 1 1-12 0 6 6 0 0 1 12 0zm-.785 7.336a9 9 0 1 1 2.121-2.121l4.453 4.452a1.5 1.5 0 1 1-2.122 2.122l-4.452-4.453z"/>
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <path fill="#fff" d="M0 0h48v48H0V0z"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <p className="mailing-table__empty-text">
                    У вас пока нет рассылок
                </p>
            </div>
            }
        </>
    );

    if (mailings === null) return emptyMailing;

    const mailingList = (
        <>
            { mailingCounter !== 0 &&
                mailings.data.map((item) =>
                    <MailingListItem key={item.id} mailing={item}/>
                )
            }
        </>
    )

    return (
        <div className="mailing-page__table-block is-hidden" id="mailing-table_js-mailing">
            {emptyMailing}
            { mailingCounter > 0 && <div className="mailing-page__table mailing-page__table_mailing cp-table cp-table_size_small">
                <div className="cp-table__thead">
                    <div className="cp-table__tr">
                        <div className="cp-table__th mailing-page__table-cell mailing-page__table-cell_mailing">Название
                            рассылки
                        </div>
                        <div className="cp-table__th mailing-page__table-cell mailing-page__table-cell_groups">Группы
                        </div>
                        <div className="cp-table__th mailing-page__table-cell mailing-page__table-cell_send">
                            Отправлено
                        </div>
                        <div className="cp-table__th mailing-page__table-cell mailing-page__table-cell_read">Прочитано
                        </div>
                        <div className="cp-table__th mailing-page__table-cell mailing-page__table-cell_error">Ошибка
                        </div>
                        <div className="cp-table__th mailing-page__table-cell mailing-page__table-cell_status">Статус
                        </div>
                        <div className="cp-table__th mailing-page__table-cell mailing-page__table-cell_actions"></div>
                    </div>
                </div>
                <div className="cp-table__tbody" id="mailing-table-body_js-mailing">
                    {mailingList}
                </div>
            </div>
            }
            <div id="js-mailing_paginator">
                { mailingCounter > 0 && <Paginator paginable={mailings}/> }
            </div>
        </div>
    );
}
