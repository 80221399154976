import React, {useEffect, useState} from 'react';
import {postData} from "../../../utils/sendData";
import {API} from "../../../api/API";
import {useHistory} from "react-router-dom";
import {handleErrors} from "../../../utils/handleErrors";
import {useDispatch, useSelector} from "react-redux";
import Editor from './Editor/Editor'
//import Editor from './Editor/Render'
import './Editor/style/Editor.scss'
import Preloader from "../../../components/Preloader";

const Floor = (props) => {
    const id = props.match.params.id;
    let config = {
        role: 'designer', grid: true
    }
    const history = useHistory();
    const [editor, setEditor] = useState(false);
    const [place, setPlace] = useState([]);
    const [floorsData, setFloorsData] = useState(null);
    const dispatch = useDispatch();
    const noticeData = useSelector(state => state.notice);
    const modalData = useSelector(state => state.modal);


    useEffect(() => {
        if (id !== 'new') {
            getFloors(id)
        } else {
            handeNewFloor()
        }
    }, []);

    const getFloors = async (id) => {
        return await postData(null, API.getFloor.path(id), API.getFloor.method).then((res) => {
            setFloorsData(res.data.data);
            handleSetMode(true)
            setEditor(true)
        });
    };

    const handleSetMode = (val) => {

        let display = val ? 'none' : 'block';
        let height = val ? '100vh' : 'calc(100vh - 72px)';
        document.querySelector('#Layout').style.display = display;
        document.getElementById('App').style.height = height;
        //
        if (!val) {
            history.push(`/admin/floors`)
        }
        // setEditor(val)
    }

    const setFloors = async (id, data, action, closeAfterSave, changeMode) => {
        // сохранение схемы зала
        console.log(closeAfterSave)
        return await postData(data,
            id != -1 ? API.setEditFloor.path(id) : API.setNewFloor.path(id),
            id != -1 ? API.setEditFloor.method : API.setNewFloor.method)
            .then((res) => {
                if (action) {
                    eval(action(false))
                    //history.push(`/admin/floors`)
                }
                if (res.data.success) {
                    //handleSetMode(false)
                    if (closeAfterSave) {
                        history.push(`/admin/floors`)
                    }
                    setFloorsData(res.data.data)
                    dispatch({
                        type: "SET_NOTICE",
                        payload: {...noticeData,open: true, type: 'success', message: 'Схема зала сохранена', closingTime: 5}
                    })
                    eval(changeMode(false))
                    handleSetMode(!closeAfterSave)
                    //getFloorList()
                } else {
                    console.log(res.data.message)
                    handleModalError(res.data, id, data, action, closeAfterSave)
                }
            }).catch(({response}) => {
                eval(action(false))
                if (response.status === 403) {
                    dispatch({
                        type: "SET_NOTICE",
                        payload: {
                            ...noticeData,
                            open: true,
                            type: 'error',
                            message: 'Зал другого пользователя доступен только для просмотра'
                        }
                    })
                } else {
                    handleErrors(response, 'uploadFile', dispatch, noticeData, noticeData, null);
                }
            });
    }

    const handeNewFloor = () => {
        // создание нового плана
        let data = {
            places: [],
            description: "Описание зала",
            groups: null,
            name: "Новый зал",
        }
        setFloorsData(data);
        setPlace([])
        setEditor(true)
        handleSetMode(true)
    }

    const handleModalError = (dataRes, id, data, action, closeAfterSave, changeMode) => {
        dispatch(({
            type: "SET_MODAL", payload: {
                ...modalData,
                open: true,
                title: 'Требуется подтверждение',
                content: dataRes.message,
                handler: null,
                courseId: null,
                recaptcha: null,
                buttons: [{
                    style: "", action: "handler", handler: () => {
                        console.log('cancel')
                    }, name: "Закрыть", redirect: ""
                }, {
                    style: "danger", action: "handler", handler: () => {
                        data['force_delete'] = true;
                        setFloors(id, data, action, closeAfterSave, changeMode)
                    }, name: "Подтвердить сохранение", redirect: ""
                }]
            }
        }))
    }
    const handleSavePlans = (place, groups, titleFloor, id, action, closeAfterSave, changeMode, setLoader) => {
        let data = {
            "id": floorsData.id ? floorsData.id : -1,
            "user_id": floorsData.user_id ? floorsData.user_id : -1,
            "name": titleFloor,
            "description": 'Описание зала',
            "is_active": floorsData.is_active ? floorsData.is_active : true,
            "is_public": floorsData.is_public ? floorsData.is_public : false,
            "groups": groups,
            "places": place
        }
        setFloors(data.id, data, action, closeAfterSave, changeMode, setLoader).then(() => {
            setLoader(false)
        }).catch(() => {
            setLoader(false)
        })
    }

    const handCloseEditor = (changeMade, action) => {
        // модалка закрытия плана для дизайнера
        if (!changeMade) {
            // нет изменений
            history.push(`/admin/floors`)
        } else {
            dispatch(({
                type: "SET_MODAL", payload: {
                    open: true,
                    title: 'Есть несохраненные изменения',
                    content: '',
                    handler: null,
                    courseId: null,
                    recaptcha: null,
                    buttons: [{
                        style: "",
                        action: "handler",
                        handler: () => {
                            handleSetMode(false)
                        },
                        name: "Выйти",
                        redirect: ""
                    }, {
                        style: "primary",
                        action: "handler",
                        handler: () => {
                            eval(action(true))
                            //handleSavePlans(place, groups, titleFloor, id, 'closed')
                        },
                        name: "Сохранить и выйти",
                        redirect: ""
                    }]
                }
            }))
        }
    }

    let nameFloors = () => {
        let months = [
            "января",
            "февраля",
            "марта",
            "апреля",
            "мая",
            "июня",
            "июля",
            "августа",
            "сентября",
            "октября",
            "ноября",
            "декабря",
        ];

        let date = new Date(),
            year = date.getFullYear(),
            day = date.getDate().toString().padStart(2, "0"),
            month = date.getMonth(),
            hours = date.getHours().toString().padStart(2, "0"),
            minutes = date.getMinutes().toString().padStart(2, "0");
        return `Новый зал  ${day} ${months[month]} ${year}, ${hours}:${minutes}`

    }

    return (
        <>
            {editor ?
            //     <Editor config={config} handleSetMode={handleSetMode} handleSavePlans={handleSavePlans}
            //                   titleFloor={nameFloors()}
            //                   place={place}
            //                   floorsData={floorsData}
            //                   handCloseEditor={handCloseEditor}
            // />
                <Editor config={config}
                        handleSetMode={handleSetMode}
                        handleSavePlans={handleSavePlans}
                        titleFloor={nameFloors()}
                        place={place}
                        floorsData={floorsData}
                        handCloseEditor={handCloseEditor}
                />
                : <Preloader/>}
        </>
    );
};

export default Floor;
