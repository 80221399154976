// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Modal-module_container_hg7sr{display:flex;flex-direction:column;padding:16px;gap:16px}@media(max-width: 576px){.Modal-module_container_hg7sr{gap:24px}}.Modal-module_header_IIWoQ h3{color:var(--registration-black, #000);font-size:16px;font-family:Inter,sans-serif;font-style:normal;font-weight:700;line-height:125%;letter-spacing:-0.208px;margin:4px 0}.Modal-module_footer_1vvrC{display:flex;justify-content:flex-end;gap:8px}", "",{"version":3,"sources":["webpack://resources/js/react/src/views/Admin/Events/Notifications/Notification/Modal/Modal.module.scss","webpack://resources/js/react/src/scss/breakpoints.scss"],"names":[],"mappings":"AAEA,8BACE,YAAA,CACA,qBAAA,CACA,YAAA,CACA,QAAA,CCmBE,yBDvBJ,8BAOI,QAAA,CAAA,CAQJ,8BACE,qCAAA,CACA,cAAA,CACA,4BAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CACA,uBAAA,CACA,YAAA,CAOF,2BACE,YAAA,CACA,wBAAA,CACA,OAAA","sourcesContent":["@import \"../../../../../../scss/breakpoints\";\n\n.container {\n  display: flex;\n  flex-direction: column;\n  padding: 16px;\n  gap: 16px;\n\n  @include _xs {\n    gap: 24px;\n  }\n}\n\n.header {\n\n}\n\n.header h3 {\n  color: var(--registration-black, #000);\n  font-size: 16px;\n  font-family: Inter, sans-serif;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 125%;\n  letter-spacing: -0.208px;\n  margin: 4px 0;\n}\n\n.body {\n\n}\n\n.footer {\n  display: flex;\n  justify-content: flex-end;\n  gap: 8px;\n}","@mixin _xl {\n    @media (max-width: 1599px) {\n        @content;\n    }\n}\n\n@mixin _lg {\n    @media (max-width: 1279px) {\n        @content;\n    }\n}\n\n@mixin _md {\n    @media (max-width: 1023px) {\n        @content;\n    }\n}\n\n@mixin _sm {\n    @media (max-width: 767px) {\n        @content;\n    }\n}\n\n@mixin _xs {\n    @media (max-width: 576px) {\n        @content;\n    }\n}\n\n@mixin _xxs {\n    @media (max-width: 376px) {\n        @content;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Modal-module_container_hg7sr",
	"header": "Modal-module_header_IIWoQ",
	"footer": "Modal-module_footer_1vvrC"
};
export default ___CSS_LOADER_EXPORT___;
