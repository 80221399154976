import MailingApi from "./MailingApi";

const API = new MailingApi();
export default function MailingListItem(props) {

    function formatDate(stringDate) {
        let date = new Date(Date.parse(stringDate));
        let dd = date.getDate();
        if (dd < 10) dd = '0' + dd;

        let mm = date.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;

        return dd + '.' + mm + '.' + date.getFullYear();
    }
    function formatTime(stringDate) {
        let date = new Date(Date.parse(stringDate));
        let mm = date.getMinutes();
        if (mm < 10) mm = '0' + mm;
        return date.getHours() + ':' + mm;
    }
    const mailing = props.mailing;

    function getStatusBadge(item) {
        let statusClass = "cp-status-bar_type_draft";
        let statusText = "Запланирована";
        let statusDate = '';
        if (item.send_at !== null) {
            statusDate = formatDate(item.send_at) + ', ' + formatTime(item.send_at);
        }

        if (item.is_sent) {
            statusClass = "cp-status-bar_type_published";
            statusText = "Отправлено";
            statusDate = formatDate(item.finished_at) + ', ' + formatTime(item.finished_at);
        }
        if (item.is_sending) {
            statusClass = "cp-status-bar_type_closed";
            statusText = "Отправляется";
            statusDate = formatDate(item.started_at) + ', ' + formatTime(item.started_at);
        }
        // if (item.is_draft) {
        //     statusClass = "cp-status-bar_type_archive";
        //     statusText = "Черновик";
        //     let statusDate = this.formatDate(item.send_at);
        // }
        return (
            <div className="cp-table__td mailing-page__table-cell mailing-page__table-cell_status">
                <div className={"mailing-page__table-status e-status " + statusClass}>{statusText}</div>
                <div className="mailing-page__table-period js-mailing-period">{statusDate}</div>
            </div>
        );
    }

    const editButton = (
      <>
          { mailing.started_at === null &&
          <>
              <span className="cp-tooltip">Редактировать</span>
              <a href={"/admin/mailings/mailing/" + mailing.id}
                 className="cp-action__btn cp-scheme_secondary-ghost_turn mailing-page__action-btn">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd"
                            d="M11 1C11.5288 1 11.9869 1.20949 12.3544 1.57991L14.4217 3.64718C14.7924 4.01786 15 4.47274 15 5C15 5.52385 14.794 6.06728 14.4217 6.43959L6.89021 13.9675C6.36661 14.5715 5.62438 14.9426 4.7564 15.0016H1V14.0016L1.00325 11.1647C1.06698 10.3758 1.43373 9.64229 1.98196 9.15991L9.56093 1.58101C9.93285 1.20718 10.4754 1 11 1ZM3.35157 10.6161C3.14601 10.7979 3.01885 11.0522 3 11.2452V13.0025L4.68578 13.004C4.95369 12.9851 5.20307 12.8604 5.42749 12.6054L9.80933 8.22354L7.77751 6.19172L3.35157 10.6161ZM9.19197 4.77776L11.2235 6.80933L12.9895 5.04339L10.9582 3.01212L9.19197 4.77776Z"
                            fill="currentColor"></path>
                  </svg>
              </a>
          </>
          }
      </>
    );

    function mailingDelete(mailingId, e) {
        let target = $(e.currentTarget).parents('.cp-table__tr');
        removalConfirmation(target, 'Удалить рассылку?');
        rippleBtn();

        let declineBtn = $('.control-panel__removal_decline'),
            confirmBtn = $('.control-panel__removal_remove');

        confirmBtn.click(() => removeMailing(mailingId, target))

        declineBtn.click(()=>{
            declineBtn.parents('.control-panel__removal').remove();
        })
    }

    async function removeMailing(mailingId, target) {
        let response = await API.deleteMailing(mailingId);
        switch (response.status) {
            case 200:
                target.slideUp(200,function() {
                    target.remove();
                });
                showTopMessage('success', 'small' , "Рассылка успешно удалена");
                break;
            case 401:
                API.authorize().then(result => {
                    removeMailing(mailingId, target);
                });
                break;
            case 500:
                showTopMessage('warning', 'small' , 'Что-то пошло не так. Попробуйте позже');
                break;
            default:
        }
    }

    return (
        <div className="cp-table__tr">
            <a className="cp-table__td mailing-page__table-cell mailing-page__table-cell_mailing"
               href={"/admin/mailings/mailing/"+ mailing.id+"/stat/"}>
                {mailing.name}
            </a>

            <div className="cp-table__td mailing-page__table-cell mailing-page__table-cell_groups"
                 data-label="Группы">
                {mailing.contact_group.name}
            </div>

            <div className="cp-table__td mailing-page__table-cell mailing-page__table-cell_send"
                 data-label="Отправлено">
                <div>{mailing.statistics_sent_count}
                    <b>({Math.floor(mailing.statistics_sent_count / mailing.statistics_count * 100)}%)</b>
                </div>
            </div>

            <div className="cp-table__td mailing-page__table-cell mailing-page__table-cell_read" data-label="Прочитано">
                <div>{mailing.statistics_read_count}
                    <b>({Math.floor(mailing.statistics_read_count / mailing.statistics_count * 100)}%)</b>
                </div>
            </div>

            <div className="cp-table__td mailing-page__table-cell mailing-page__table-cell_error" data-label="Ошибка">
                <div>{mailing.statistics_failed_count}
                    <b>({Math.floor(mailing.statistics_failed_count / mailing.statistics_count * 100)}%)</b>
                </div>
            </div>
            {getStatusBadge(mailing)}
            <div
                className="cp-table__td cp-table__td_nopadding_y mailing-page__table-cell mailing-page__table-cell_actions">
                <div className="cp-actions mailing-page__table-data mailing-page__table-data_actions"
                     data-label="">

                    <div className="cp-action cp-action_size_medium">
                        {editButton}
                    </div>
                    <div className="cp-action cp-action_size_medium">
                        <span className="cp-tooltip">Удалить</span>
                        <button
                            className="cp-action__btn cp-scheme_tertiary-ghost_turn mailing-page__action-btn"
                            onClick={(e) => mailingDelete(mailing.id, e)}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M15 3H11V2C11 1.46957 10.7893 0.960888 10.4142 0.585815C10.0391 0.210743 9.53043 0 9 0H7C6.46957 0 5.96086 0.210743 5.58578 0.585815C5.21071 0.960888 5 1.46957 5 2V3H1C0.734784 3 0.480429 3.10537 0.292892 3.29291C0.105356 3.48044 0 3.73478 0 4C0 4.26522 0.105356 4.51956 0.292892 4.70709C0.480429 4.89463 0.734784 5 1 5H2V14C2 14.5304 2.21071 15.0391 2.58578 15.4142C2.96086 15.7893 3.46957 16 4 16H12C12.5304 16 13.0391 15.7893 13.4142 15.4142C13.7893 15.0391 14 14.5304 14 14V5H15C15.2652 5 15.5196 4.89463 15.7071 4.70709C15.8946 4.51956 16 4.26522 16 4C16 3.73478 15.8946 3.48044 15.7071 3.29291C15.5196 3.10537 15.2652 3 15 3ZM7 2H9V3H7V2ZM12 14H4V5H12V14Z"
                                      fill="currentColor"></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
