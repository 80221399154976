import React, {useEffect, useRef, useState} from 'react';
import Input from "../../../../../components/ui/Input";
import {useRouteMatch} from "react-router-dom";
import mailingDataJson from './Mailing.data.json'
import styles from './Mailing.module.scss'
import page from "../../Events.module.scss";
import Select from "../../../../../UIKit/component/select/Select";
import {useDispatch, useSelector} from "react-redux";
import {methodGetAdditionalEmails, methodGetDomainEmails} from "../Notifications.methods";
import {methodUpdateMailing} from "./Mailing.methods";
import Preloader from "../../../../../widget/components/preloader";

const Mailing = () => {
    const router = useRouteMatch()
    const ref = useRef()
    const {event_id, type} = router.params
    const is_course = type === 'course'
    const [form, setForm] = useState({...mailingDataJson})
    const selector = useSelector(state => state)
    const admin = selector.admin
    const dispatch = useDispatch()

    useEffect(() => {
        handleGetEmails()
    }, [])

    const handleGetEmails = async () => {
        await Promise.all([await handleGetDomainEmails(), await handleGetAdditionalEmails()]);
        setForm(prev => ({...prev, is_loading: false}))
    }

    useEffect(() => {
        handleGetValues()
    }, [admin.event])

    const handleGetValues = () => {
        const {
            domain_email_id,
            domain_email_name,
            reply_to_additional_email_id,
            reply_to_additional_email_name
        } = admin.event

        setForm(prev => ({
            ...prev, fields: {
                ...prev.fields,
                email_domain: {...prev.fields.email_domain, value: transformValue(domain_email_id) || "0"},
                name_domain: {...prev.fields.name_domain, value: transformValue(domain_email_name)},
                email_reply: {...prev.fields.email_reply, value: transformValue(reply_to_additional_email_id) || "0"},
                name_reply: {...prev.fields.name_reply, value: transformValue(reply_to_additional_email_name) || "Nethouse.События"}
            }
        }))
    }

    const handleGetDomainEmails = async () => {
        let response = await methodGetDomainEmails(event_id, is_course ? 'courses' : 'events')
        setForm(prev => ({
            ...prev,
            fields: {...prev.fields, email_domain: {...prev.fields.email_domain, variants: response}}
        }))
    }

    const handleGetAdditionalEmails = async () => {
        let response = await methodGetAdditionalEmails(event_id, is_course ? 'courses' : 'events')
        response = [...response, {
            "email": "events@nethouse.ru",
            "owner_id": null,
            "is_confirmed": null,
            "is_main": null,
            "created": null,
            "id": "0"
        }]
        setForm(prev => ({
            ...prev,
            fields: {...prev.fields, email_reply: {...prev.fields.email_reply, variants: response}}
        }))
    }


    const handleChangeField = (field, value) => {
        setForm(prev => ({
            ...prev,
            fields: {
                ...prev.fields,
                [field]: {
                    ...prev.fields[field],
                    value: value !== null ? value : null,
                    error: null,
                },
            }
        }))
    }

    const handleChangeFieldApi = async (field, value) => {
        let body

        body = {
            "domain_email_id": field === 'email_domain' ? value : form.fields.email_domain.value,
            "domain_email_name": field === 'name_domain' ? value : form.fields.name_domain.value,
            "reply_to_additional_email_id": field === 'email_reply' ? Number(value) ? value : null : form.fields.email_reply.value,
            "reply_to_additional_email_name": field === 'name_reply' ? value : form.fields.name_reply.value,
        }
        await methodUpdateMailing(body, event_id, is_course)
        dispatch({type: "SET_ADMIN", payload: {...admin, event: {...admin.event, ...body}}})
    }

    const transformVariants = (array) => {
        let obj = {}
        Object.entries(array).map(([key, value]) => obj[value.id] = value.email || value.address)
        return obj
    }

    const transformValue = (value) => {
        return value ? String(value) : null
    }

    return (
        <div className={page.section}>
            <h3 className={page.title}>
                Письма участникам
            </h3>
            {form.is_loading ? <Preloader/> : <div className={styles.container} ref={ref}>
                {Object.keys(form.fields.email_domain.variants).length > 0 ? <div className={styles.item}>
                    <Select
                        value={form.fields.email_domain.value}
                        label={form.fields.email_domain.name}
                        size={'medium'}
                        data={transformVariants(form.fields.email_domain.variants)}
                        error={form.fields.email_domain.error}
                        direction={['bottom']}
                        placeholder={`Выберите Email`}
                        onChange={(value) => {
                            handleChangeField('email_domain', value)
                            handleChangeFieldApi('email_domain', value)
                        }}
                    />
                    <Input
                        size={'medium'}
                        outside={'true'}
                        label={form.fields.name_domain.name}
                        required={""}
                        value={form.fields.name_domain.value}
                        error={form.fields.name_domain.error}
                        placeholder={`Введите ${form.fields.name_domain.name}`}
                        disabled={form.fields.name_domain.is_disabled}
                        handleChange={(e) => handleChangeField('name_domain', e.target.value)}
                        onBlur={(e) => handleChangeFieldApi('name_domain', e.target.value)}
                    />
                </div> : null}
                <div className={styles.item}>
                    <Select
                        value={form.fields.email_reply.value}
                        label={form.fields.email_reply.name}
                        size={'medium'}
                        data={transformVariants(form.fields.email_reply.variants)}
                        error={form.fields.email_reply.error}
                        direction={['bottom']}
                        placeholder={`Выберите Email`}
                        onChange={(value) => {
                            handleChangeField('email_reply', value)
                            handleChangeFieldApi('email_reply', value)
                        }}
                    />
                    <Input
                        size={'medium'}
                        outside={'true'}
                        label={form.fields.name_reply.name}
                        value={form.fields.name_reply.value}
                        error={form.fields.name_reply.error}
                        placeholder={`Введите ${form.fields.name_reply.name}`}
                        disabled={form.fields.name_reply.is_disabled}
                        handleChange={(e) => handleChangeField('name_reply', e.target.value)}
                        onBlur={(e) => handleChangeFieldApi('name_reply', e.target.value)}
                    />
                </div>
            </div>}
        </div>
    );
};

export default Mailing;