// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Groups-module_notification_TU91w{width:fit-content}", "",{"version":3,"sources":["webpack://resources/js/react/src/views/Admin/Events/Notifications/Groups/Groups.module.scss"],"names":[],"mappings":"AAAA,kCACE,iBAAA","sourcesContent":[".notification {\n  width: fit-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notification": "Groups-module_notification_TU91w"
};
export default ___CSS_LOADER_EXPORT___;
