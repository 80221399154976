import React, {Component} from 'react'
import QuestionMarkIcon from '../assets/images/icons/16/qustionmark-circled.svg'
import CloseIcon from '../assets/images/icons/16/x.svg'

export default class Toggletip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            windowWidth: window.innerWidth,
            show: false
        }
    }

    handleResize = () => {
        this.setState({windowWidth: window.innerWidth});
    };

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnMount() {
        window.addEventListener("resize", this.handleResize);
    }

    handleShow = () => {
        this.setState({show: !this.state.show});
    }
    handleClose = () => {
        this.setState({show: false});
    }

    render() {
        const windowWidth = this.state.windowWidth;
        return (
            <div className={`toggletip-icon ${this.state.inInput ? 'toggletip-icon--inp ut ' : ''}`} onClick={this.handleShow}>
                <QuestionMarkIcon/>
                {this.state.show &&
                <div
                    className={`e-action__toggletip ${windowWidth < 768 ? 'e-action__toggletip_position_left' : 'e-action__toggletip_position_top'}`}>
                    {this.state.text}
                    <div className="e-action__toggletip-close" onClick={this.handleClose}>
                        <CloseIcon/>
                    </div>
                </div>
                }
            </div>
        )
    }
}
