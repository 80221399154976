import Paginator from "./paginator";
import GroupListItem from "./GroupListItem";

export default function GroupTable(props) {
    const groups = props.groups;
    let groupCounter = 0;
    if (groups !== null){
        groupCounter = groups.meta.total;
    }

    const emptyGroup = (
        <>
            { groupCounter === 0 &&
            <div className="mailing-table__empty" id="mailing-empty_js-groups">
                <div className="mailing-table__empty-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none">
                        <path fill="#B8B8FF"
                              d="M37 35.593c.23-.028.456-.058.678-.09 2.323-.33 4.234-.847 5.596-1.633.482-.278.763-.808.722-1.364-.156-2.114-.932-3.716-2.061-4.933-1.1-1.186-2.472-1.941-3.723-2.498-.623-.277-1.244-.517-1.8-.732l-.014-.005a27.284 27.284 0 0 1-1.496-.61c-.955-.439-1.113-.706-1.135-.773-.17-.52-.112-.783-.02-1 .134-.313.365-.605.807-1.165l.113-.143c.473-.6 1.06-1.377 1.514-2.431.458-1.066.755-2.354.755-3.99 0-3.256-1.36-5.389-3.121-6.659C32.133 6.354 30.194 6 29 6c-1.14 0-2.959.322-4.585 1.408.39.212.776.453 1.155.726.492.355.956.755 1.386 1.199.778-.318 1.543-.43 2.044-.43.735 0 2.038.241 3.116 1.019.999.72 1.916 1.974 1.916 4.304 0 1.267-.226 2.162-.519 2.843-.297.692-.688 1.225-1.125 1.78l-.14.175c-.382.48-.875 1.096-1.172 1.795-.386.908-.442 1.898-.069 3.04.447 1.363 1.728 2.069 2.684 2.507.518.238 1.09.46 1.637.67l.024.01c.57.22 1.129.436 1.68.681 1.107.493 2.066 1.056 2.774 1.82a4.694 4.694 0 0 1 1.154 2.213c-.884.342-2.098.642-3.692.87-.24.034-.487.066-.742.096.237.796.395 1.65.462 2.56.008.103.011.205.012.307z"/>
                        <path fill="#574AE2" fillRule="evenodd"
                              d="M14.185 10.567C15.867 9.354 17.806 9 19 9c1.194 0 3.133.354 4.815 1.567 1.76 1.27 3.12 3.403 3.12 6.659 0 1.636-.296 2.924-.754 3.99-.454 1.054-1.04 1.831-1.514 2.43l-.113.144c-.442.56-.673.852-.806 1.165-.093.217-.152.48.019 1 .022.068.18.334 1.135.773.43.197.921.388 1.496.61l.014.005c.556.215 1.178.455 1.8.732 1.25.557 2.623 1.312 3.723 2.498 1.13 1.217 1.906 2.819 2.061 4.933.041.556-.24 1.086-.722 1.364-1.362.786-3.273 1.303-5.597 1.634-2.351.335-5.251.496-8.677.496-3.426 0-6.326-.161-8.677-.496-2.324-.331-4.235-.848-5.597-1.634a1.452 1.452 0 0 1-.722-1.364c.156-2.114.932-3.716 2.061-4.933 1.1-1.186 2.472-1.941 3.723-2.498.623-.277 1.244-.517 1.8-.732l.014-.005a27.284 27.284 0 0 0 1.496-.61c.955-.439 1.113-.706 1.135-.773.17-.52.112-.783.02-1-.134-.313-.365-.605-.807-1.165l-.113-.143c-.473-.6-1.06-1.377-1.514-2.431-.458-1.066-.754-2.354-.754-3.99 0-3.256 1.36-5.389 3.12-6.659zM19 36.097c3.349 0 6.1-.159 8.268-.468 1.594-.227 2.808-.527 3.692-.869a4.694 4.694 0 0 0-1.154-2.213c-.709-.764-1.667-1.327-2.774-1.82-.551-.245-1.11-.461-1.68-.681l-.024-.01a29.381 29.381 0 0 1-1.637-.67c-.956-.438-2.237-1.144-2.683-2.508-.374-1.141-.318-2.131.068-3.04.297-.698.79-1.315 1.172-1.794l.14-.175c.437-.555.828-1.088 1.125-1.78.293-.68.52-1.576.52-2.843 0-2.33-.918-3.584-1.917-4.304-1.078-.778-2.38-1.019-3.116-1.019-.735 0-2.038.241-3.116 1.019-.999.72-1.916 1.974-1.916 4.304 0 1.267.226 2.162.519 2.843.297.692.688 1.225 1.125 1.78l.14.175c.382.48.875 1.096 1.172 1.795.386.908.442 1.898.069 3.04-.447 1.363-1.728 2.069-2.684 2.507-.518.238-1.09.46-1.637.67l-.024.01c-.57.22-1.129.436-1.68.681-1.107.493-2.065 1.056-2.774 1.82A4.694 4.694 0 0 0 7.04 34.76c.884.342 2.098.642 3.692.87 2.168.308 4.92.467 8.268.467z"
                              clipRule="evenodd"/>
                    </svg>
                </div>
                <p className="mailing-table__empty-text">
                    У вас пока нет списков контактов
                </p>
            </div>
            }
        </>
    );

    if (groups === null) return emptyGroup;

    const groupList = (
        <>
            { groupCounter !== 0 &&
                groups.data.map((item) =>
                    <GroupListItem key={item.id} group={item}/>
                )
            }
        </>
    )

    return (
        <div className="mailing-page__table-block" id="mailing-table_js-groups">
            {emptyGroup}
            { groupCounter > 0 &&
                <div className="mailing-page__table mailing-page__table_groups cp-table cp-table_size_small">
                <div className="cp-table__thead">
                    <div className="cp-table__tr">
                        <div className="cp-table__th mailing-page__table-cell mailing-page__table-cell_groups">Группы
                            контактов
                        </div>
                        <div className="cp-table__th mailing-page__table-cell mailing-page__table-cell_date">Дата
                            создания
                        </div>
                        <div className="cp-table__th mailing-page__table-cell mailing-page__table-cell_participate">
                            Участники
                        </div>
                        <div className="cp-table__th mailing-page__table-cell mailing-page__table-cell_actions"></div>
                    </div>
                </div>
                <div className="cp-table__tbody" id="mailing-table-body_js-groups">
                    {groupList}
                </div>
            </div>
            }
            <div id="js-group_paginator">
                { groupCounter > 0 && <Paginator paginable={groups}/> }
            </div>
        </div>
    );
}
