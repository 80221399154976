import React, {useEffect, useState} from 'react'

import IconCircle from '../../assets/images/icons/8/ellipse.svg'
import '../../scss/ui/a-btn.scss'

const Button = (props) => {

    const createRipple = (event) => {
        props.onClick ? props.onClick() : console.log('onClick undefined');
        const button = event.currentTarget;

        const circle = document.createElement("span");
        const diameter = Math.max(button.clientWidth, button.clientHeight);
        const radius = diameter / 2;

        circle.style.width = circle.style.height = `${diameter}px`;
        circle.style.left = `${event.clientX - button.getBoundingClientRect().left - radius}px`;
        circle.style.top = `${event.clientY - button.getBoundingClientRect().top - radius}px`;
        circle.classList.add("ripple");

        const ripple = button.getElementsByClassName("ripple")[0];

        if (ripple) {
            ripple.remove();
        }

        button.appendChild(circle);
    }

    return (
        <button
            className={`a-btn a-btn_size_${props.size || "medium"} a-btn_type_${props.scheme || "primary"}-${props.type || "default"}${props.disabled ? " a-btn_disabled" : ""}${props.loading ? " a-btn_loading" : ""}${props.nopadding ? " a-btn_nopadding" : ""}`}
            onClick={createRipple}
            // onClick={props.onClick}
        >
            <div className={`a-btn__content ${(props.icon && props.icon)}`}>
                {(props.icon && props.icon === 'left') &&
                props.svg
                }
                {props.value || ""}
                {(props.icon && props.icon === 'right') &&
                props.svg
                }
                {(props.icon && props.icon === 'no-margin') &&
                    props.svg
                }
            </div>
            {props.loading &&
            <div className={'a-btn__loader'}>
                <IconCircle/>
                <IconCircle/>
                <IconCircle/>
            </div>
            }

        </button>
    )
}

export default Button;

