import React, {useEffect, useRef, useState} from 'react';
import Input from "../ui/Input";
import Textarea from "../ui/Textarea";
import CustomCheckbox from "../CustomCheckbox";
import Flatpickr from "react-flatpickr";
import {Russian} from 'flatpickr/dist/l10n/ru';
import IconTrash from "../../assets/images/icons/16/trash.svg";
import axios from "axios";

import {API} from "../../api/API";
import TicketOption from "./TicketOption";
import Checkbox from "../../UIKit/component/checkbox/Checkbox";
import IconAdd from '../../assets/images/icons/16/plus_simple.svg'
import scrollToElement from "../../utils/scrollToElement";
import Select from "../../UIKit/component/select/Select";
import Toggletip from "../../UIKit/component/toggletip/toggletip";
import QuestionIcon from "../../assets/images/icons/16/question-circle.svg";

const TicketsEditor = (props) => {
    const ref = useRef(null)

    const id = props.id

    const [disabledForm, setDisabledForm] = useState(false)

    const [countInf, setCountInf] = useState(true)
    const [validator, setValidator] = useState({})

    const [notificationKeys, setNotificationKeys] = useState(false)

    const [editor, setEditor] = useState(props.item || null)

    const [accessKey, setAccessKey] = useState(props.item?.hasKeys || null)

    //TODO: это выносится в отдельный компонент
    const [salePeriod, setSalePeriod] = useState(false)

    //TODO: это выносится в отдельный компонент
    const [validPeriod, setValidPeriod] = useState({
        value: props.item.validPeriod,
        state: Boolean(props.item.validPeriod)
    })

    //TODO: это выносится в отдельный компонент
    const [sessions, setSessions] = useState(props.sessions || null)

    //TODO: это выносится в отдельный компонент
    const [floors, setFloors] = useState(props.floors || null)

    //TODO: это выносится в отдельный компонент
    const [dropdownSessions, setDropdownSessions] = useState(false)
    const [selectedSessions, setSelectedSessions] = useState(editor?.sessions || [])

    //TODO: это выносится в отдельный компонент
    const [dropdownGroups, setDropdownGroups] = useState(false)
    const [selectedGroups, setSelectedGroups] = useState(editor?.floorGroups || [])

    const constructValidPeriod = (value, type) => {
        if (value.length < 3) {
            if (value.length < 2) value = '0' + value
            if (value.length === 0) value = '00'
            switch (type) {
                case 'mon':
                    setValidPeriod(prev => ({
                        state: true,
                        value: `P${value}M${validPeriod.value.split('P')[1].split('M')[1].split('D')[0]}D`,
                    }))
                    break;
                case 'day':
                    setValidPeriod(prev => ({
                        state: true,
                        value: `P${validPeriod.value.split('P')[1].split('M')[0]}M${value}D`,
                    }))
                    break;
            }
            let copy = JSON.parse(JSON.stringify(editor))
            copy.validPeriod = validPeriod
        }
    }

    //TODO: избавиться от temp
    const [temp, setTemp] = useState()

    //дефолтные опции всех flatpickr в текущем компоненте
    const [options, setOptions] = useState({
        dateFormat: "Y-m-d",
        altFormat: "d.m.Y",
        disableMobile: "true",
        altInput: "true",
        locale: Russian,
        monthSelectorType: "static",
        minDate: 'today',
    })

    useEffect(() => {
        scrollToElement(ref, {position: 'top', offset: -100})
    }, [])

    useEffect(() => {
        Boolean(editor?.startsAt || editor?.endsAt) && setSalePeriod(true)
    }, [salePeriod])

    useEffect(() => {
        if (new Date(editor?.endsAt).getTime() < new Date(editor?.startsAt).getTime()) {
            setEditor(prev => ({
                ...prev,
                endsAt: null
            }))
        }
    }, [editor?.startsAt, editor?.endsAt])

    useEffect(() => {
        if (String(validPeriod.value) === 'P00M00D') {
            setEditor(prev => ({
                ...prev,
                validPeriod: null
            }))
        } else {
            setEditor(prev => ({
                ...prev,
                validPeriod: validPeriod.value
            }))
        }
    }, [validPeriod])

    useEffect(() => {
        if (temp) {
            let copy = JSON.parse(JSON.stringify(editor))
            copy.prices[temp?.id].date_start = new Date(temp?.value).setHours(0, 0, 0, 0)
            setEditor(copy)
        }
    }, [temp])

    //добавление поля цены
    const handleAddPrice = (item, idx) => {
        let copy = JSON.parse(JSON.stringify(editor))
        let date = new Date(new Date(new Date().setHours(0, 0, 0, 0)).getTime() + ((editor?.prices.length + 1) * 86400000))
        copy?.prices.push({
            id: 'tmp_' + (Math.floor(Math.random() * (99999 - 11111 + 1)) + 11111),
            ticket_id: editor?.ticket_id,
            date_start: date,
            price: null
        })
        setEditor(copy)
    }

    //запись/перезапись в state поля price
    const handlePriceInput = (value, idx) => {
        setTemp({
            value: value,
            id: idx,
        })
    }

    //сохранение результата редактора
    const handeSubmitEdit = (ticket_id, index) => {
        let data = {
            id: editor?.ticket_id,
            name: editor?.name,
            type: editor?.type,
            price: editor?.price,
            count: countInf ? 'Не ограничено' : editor?.count,
            description: editor?.description,
            has_dates: editor?.has_dates,
        }
        //повышение цены
        if (editor?.prices) {
            let arr = editor?.prices
            for (let i in arr) {
                data[`date_prices[${i}][id]`] = editor?.prices[i].id
                data[`date_prices[${i}][date]`] = new Date(editor?.prices[i].date_start).toLocaleDateString('ru-RU').split('.').reverse().join('-')
                data[`date_prices[${i}][value]`] = editor?.prices[i].price
            }
        }
        if (editor?.onlyOneOptionParam && editor?.options.length) {
            data.only_one_option = editor?.onlyOneOptionParam
        } else {
            delete data.only_one_option
        }
        if (editor?.needOneOptionParam && editor?.options.length) {
            data.need_one_option = editor?.needOneOptionParam
        } else {
            delete data.need_one_option
        }
        //опции
        if (editor?.options) {
            let arr = editor?.options
            for (let i in arr) {
                data[`options_data[${i}][id]`] = editor?.options[i]?.id || 0
                data[`options_data[${i}][name]`] = editor?.options[i].name
                data[`options_data[${i}][price]`] = editor?.options[i].price
                data[`options_data[${i}][count]`] = (editor?.options[i].count == null || editor?.options[i].unlimited) ? 'Не ограничено' : editor?.options[i].count
                data[`options_data[${i}][order]`] = [i]
            }
        }
        //промокод для сертификата
        if (editor?.type == 16) {
            data.promocode_id = editor?.promocodeId
        } else {
            delete data.promocode_id
        }

        //видеозапись
        if (editor?.type == 4 || editor?.type == 16) {
            data.video_link = editor?.videoLink
        } else {
            delete data.video_link
        }

        //период продажи
        if (editor?.startsAt && editor?.endsAt) {
            data.starts_at = editor?.startsAt
            data.ends_at = editor?.endsAt
        } else {
            data.starts_at = null
            data.ends_at = null
        }

        //срок действия
        if (validPeriod.state) {
            if (validPeriod.value === 'P00M00D') {
                data.valid_period = null
            } else {
                data.valid_period = validPeriod.value
            }
        } else {
            delete data.valid_period
        }

        if (accessKey) {
            data['accessKey'] = accessKey
        } else {
            data['accessKey'] = 0
        }

        //преобразование data в new FormData(), фильтр пустых полей
        let formData = new FormData()
        Object.entries(data).forEach(e => {
            if ((e[1] || e[1] == 0)) {
                formData.append(e[0], e[1])
            } else {
                if (e[0].includes('date_prices')) {
                    formData.append(e[0], e[1])
                }
            }
        })

        //группы
        if (editor?.floorGroups) {
            let arr = editor?.floorGroups
            for (let i in arr) {
                formData.append([`floor_groups[]`], editor?.floorGroups[i].floor_group_id)
            }
        }

        //сессии
        if (editor?.sessions) {
            let arr = editor?.sessions
            for (let i in arr) {
                formData.append([`schedules[]`], i)
            }
        }

        //сформированный запрос на сохранение билета
        let method, url
        switch (props.action) {
            case 'add':
                url = API.saveNewTicket.path(id)
                method = API.saveNewTicket.method
                break;
            case 'edit':
                url = API.saveEditedTicket.path(id)
                method = API.saveEditedTicket.method
                break;
        }
        axios({
            method: method,
            url: url,
            data: formData,
            config: {headers: {'Content-Type': 'multipart/form-data'}}
        }).then(e => {
            console.log(e)
            let form = JSON.parse(JSON.stringify(editor))
            if (form.options.length === 0) {
                form.needOneOptionParam = 0
                form.onlyOneOptionParam = 0
            }
            if (!editor.startsAt || !editor.endsAt) {
                form.startsAt = null
                form.endsAt = null
            }
            if (e.data.reason && e.data.reason !== null) {
                form.isSelling = false
                form.isNotSellingReason = e.data.reason
            } else {
                form.isSelling = true
                form.isNotSellingReason = ''
            }

            switch (props.action) {
                case 'add':
                    if (e.data.success === true) {
                        let copy = JSON.parse(JSON.stringify(form))
                        copy.ticket_id = e.data.id
                        let arr = copy.options
                        if (e.data?.options_ids?.length) {
                            for (let i in arr) {
                                if (!arr[i].id) {
                                    arr[i].id = Number(e.data.options_ids[i])
                                }
                            }
                        }
                        setEditor(copy);
                        props.handlers.handleSubmit({
                            data: copy,
                            action: props.action
                        })
                        showTopMessage('success', 'small', 'Успешно', `Билет «${data.name}» был успешно создан`);
                    } else {
                        showTopMessage('error', 'small', 'Ошибка', 'При сохранении билета произошла ошибка. Повторите попытку позднее')
                    }
                    break;
                case 'edit':
                    if (e.data.success === true) {
                        props.handlers.handleSubmit({
                            data: form,
                            action: props.action
                        })
                        showTopMessage('success', 'small', 'Успешно', `Билет «${data.name}» был успешно сохранен`);
                    } else {
                        showTopMessage('error', 'small', 'Ошибка', 'При сохранении билета произошла ошибка. Повторите попытку позднее')
                    }
                    break;
            }
        }).catch((e) => {
            setValidator(e.response.data.errors)
            if (e.response.data.errors.accessKey){
                showTopMessage('error', 'small', 'Ошибка', e.response.data.errors.accessKey)
                return
            }
            if (validator) {
                setTimeout(e => {
                    if (document.querySelector('.ui-widget__message-error')) document.querySelector('.ui-widget__message-error').scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    })
                }, 250)
            } else {
                showTopMessage('error', 'small', 'Ошибка', 'При сохранении билета произошла ошибка. Повторите попытку позднее')
            }
        })
    }

    const TicketGlobalNameEditor = () => {
        return <div className={'ui-cp__ticket-editor_row'}>
            <div className="ui-cp__ticket-editor_name">
                <Input label={'Наименование'}
                       outside={1}
                       size={'small'}
                       value={editor?.name || null}
                       error={validator?.name}
                       handleChange={(e) => {
                           let value = e.target.value
                           setEditor(prev => ({
                               ...prev,
                               name: value
                           }));
                           setValidator(prev => ({
                               ...prev,
                               name: '',
                           }))
                       }}
                />
            </div>
            <div className="ui-cp__ticket-editor_type">
                <Select
                    value={String(Object.entries(props.types).findIndex(x => x[0] == editor?.type))}
                    direction={['bottom', 'left']}
                    label={'Тип билета'}
                    size={'small'}
                    data={Object.values(props.types)}
                    error={validator?.type}
                    onChange={(e) => {
                        setEditor(prev => ({
                            ...prev,
                            type: Number(Object.keys(props.types)[e])
                        }));
                    }}
                />
            </div>
        </div>
    }

    const TicketGlobalDescriptionEditor = () => {
        return <div className={'ui-cp__ticket-editor_row'}>
            <div className={'ui-cp__ticket-editor_description'}>
                <Textarea
                    size={'small'}
                    outside={1}
                    label={'Описание'}
                    required={false}
                    handleChange={(e) => {
                        let value = e.target.value ? e.target.value : null
                        setEditor(prev => ({
                            ...prev,
                            description: value
                        }));
                        setValidator(prev => ({
                            ...prev,
                            description: ''
                        }));
                    }}
                    disabled={false}
                    value={editor?.description || null}
                    error={validator?.description}
                />
            </div>
        </div>
    }

    const TicketTermEditor = () => {
        return <div>
            <div className="ui-cp__ticket-editor_row">
                <div className="ui-cp__ticket-editor_switch"
                     style={{alignItems: salePeriod ? 'flex-end' : 'center'}}>
                    <div className={'ui-cp__ticket-editor_switch-title'}
                         style={{marginBottom: salePeriod ? '8px' : '0'}}>Период продажи
                    </div>
                    <div className={'ui-cp__ticket-editor_switch-checkbox'}
                         style={{marginBottom: salePeriod ? '4px' : '0'}}>
                        <CustomCheckbox
                            value={salePeriod}
                            onChange={(e) => {
                                if (e == false) {
                                    setEditor(prev => ({
                                        ...prev,
                                        startsAt: null,
                                        endsAt: null
                                    }));
                                }
                                setSalePeriod(e)
                            }}
                        />
                    </div>
                    <div className={'ui-cp__ticket-editor_switch-value'}>
                        {!salePeriod ? 'не ограничен' :
                            <div className={'ui-cp__ticket-editor_row'}>
                                <Flatpickr
                                    value={editor?.startsAt ? new Date(editor?.startsAt) : ''}
                                    options={{
                                        dateFormat: "Y-m-d",
                                        altFormat: "d.m.Y",
                                        disableMobile: "true",
                                        altInput: "true",
                                        locale: Russian,
                                        monthSelectorType: "static",
                                        // minDate: editor?.startsAt ? new Date(editor?.startsAt) : 'today',
                                    }}
                                    onChange={(selectedDates, dateStr, instance) => {
                                        let value = new Date(selectedDates[0].setHours(0, 0, 0, 0)).toISOString()
                                        setEditor({...editor, startsAt: value})
                                    }}
                                    render={({value}, ref) => {
                                        return (
                                            <Input
                                                calendar={1}
                                                type={"date"}
                                                id={"sale-period-starts-at"}
                                                label={'Начало'}
                                                inputRef={ref}
                                                outside={1}
                                                placeholder={'дд.мм.гггг.'}
                                                size={'small'}
                                                value={value}
                                                isClearable={editor?.startsAt}
                                                handleChange={(e) => {
                                                    setEditor({...editor, startsAt: null})
                                                }}
                                            />
                                        );
                                    }}
                                />
                                <Flatpickr
                                    value={editor?.endsAt ? new Date(editor?.endsAt) : ''}
                                    options={{
                                        dateFormat: "Y-m-d",
                                        altFormat: "d.m.Y",
                                        disableMobile: "true",
                                        altInput: "true",
                                        locale: Russian,
                                        monthSelectorType: "static",
                                        // minDate: editor?.startsAt ? new Date(editor?.startsAt) : 'today',
                                    }}
                                    onReady={(selectedDates, dateStr, instance) => {
                                        instance.jumpToDate(new Date(editor?.startsAt) || new Date())
                                    }}
                                    onChange={(selectedDates, dateStr, instance) => {
                                        let value = new Date(selectedDates[0].setHours(23, 59, 59, 59)).toISOString()
                                        setEditor(prev => ({
                                            ...prev,
                                            endsAt: value
                                        }));
                                    }}
                                    render={({value}, ref) => {
                                        return (
                                            <Input
                                                calendar={1}
                                                type={"date"}
                                                id={"sale-period-ends-at"}
                                                label={'Окончание'}
                                                inputRef={ref}
                                                outside={1}
                                                placeholder={'дд.мм.гггг.'}
                                                size={'small'}
                                                value={value}
                                                isClearable={editor?.endsAt}
                                                handleChange={(e) => {
                                                    setEditor(prev => ({
                                                        ...prev,
                                                        endsAt: null
                                                    }));
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>}
                    </div>
                </div>
            </div>
            <div className="ui-cp__ticket-editor_row ui-cp__ticket-editor_period">
                <div className="ui-cp__ticket-editor_switch"
                     style={{alignItems: validPeriod.state ? 'flex-end' : 'center'}}>
                    <div className={'ui-cp__ticket-editor_switch-title'}
                         style={{marginBottom: validPeriod.state ? '8px' : '0'}}>Срок действия
                    </div>
                    <div className={'ui-cp__ticket-editor_switch-checkbox'}
                         style={{marginBottom: validPeriod.state ? '4px' : '0'}}>
                        <CustomCheckbox
                            value={validPeriod.state}
                            onChange={(e) => {
                                if (e === true) {
                                    setValidPeriod({
                                        value: 'P00M00D',
                                        state: true
                                    })
                                } else {
                                    setValidPeriod({
                                        value: null,
                                        state: false
                                    })
                                }
                            }}
                        />
                    </div>
                    <div className={'ui-cp__ticket-editor_switch-value'}>
                        {!validPeriod.state ? 'не ограничен' :
                            <div className={'ui-cp__ticket-editor_row'}>
                                <Input
                                    id={"date"}
                                    label={'Месяцев'}
                                    type={'number'}
                                    outside={1}
                                    size={'small'}
                                    min={0}
                                    max={99}
                                    value={validPeriod.state && Number(validPeriod.value?.split('P')[1]?.split('M')[0]) !== 0 ? Number(validPeriod.value?.split('P')[1]?.split('M')[0]) : '0'}
                                    onKeyPress={e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                                    handleChange={(e) => {
                                        constructValidPeriod(e.target.value, 'mon')
                                    }}
                                />
                                <Input
                                    id={"date"}
                                    label={'Дней'}
                                    outside={1}
                                    type={'number'}
                                    size={'small'}
                                    min={0}
                                    max={99}
                                    value={validPeriod.state && Number(validPeriod.value?.split('P')[1]?.split('M')[1].split('D')[0]) !== 0 ? Number(validPeriod.value?.split('P')[1]?.split('M')[1].split('D')[0]) : '0'}
                                    onKeyPress={e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                                    handleChange={(e) => {
                                        constructValidPeriod(e.target.value, 'day')
                                    }}
                                />
                            </div>}
                    </div>
                </div>
            </div>
            {!Boolean(editor.type & 16) ? <div className="ui-cp__ticket-editor_row">
                <div className="ui-cp__ticket-editor_switch ui-cp__ticket-editor-multidates">
                    <div className={'ui-cp__ticket-editor_switch-title'}>Многодневный</div>
                    <div className={'ui-cp__ticket-editor_switch-checkbox'}>
                        <CustomCheckbox
                            value={editor?.has_dates === "0" || editor?.has_dates === 0}
                            onChange={() => setEditor(prev => ({
                                ...prev,
                                has_dates: (prev.has_dates === "0" || prev.has_dates === 0) ? "" : "0"
                            }))}
                        />
                    </div>
                    <Toggletip
                        parent={document.querySelector('main')}
                        content={<>
                            Разрешите участникам входить на мероприятие много раз. При включении этой опции, билет
                            сможет проходить проверку по уникальному qr-коду каждый день.
                        </>}>
                        <button type={'button'} className={'ui-cp__ticket-editor__toggle'}>
                            <QuestionIcon/>
                        </button>
                    </Toggletip>
                </div>
            </div> : null}
            {!(editor?.type & Number(Object.entries(props.types).find(x => x[1].toLowerCase() === 'сертификат') ? Object.entries(props.types).find(x => x[1].toLowerCase() === 'сертификат')[0] : false)) ?
                <div className="ui-cp__ticket-editor_row">
                    <div className="ui-cp__ticket-editor_switch ui-cp__ticket-editor-multidates">
                        <div className={'ui-cp__ticket-editor_switch-title'}>Ключи доступа</div>
                        <div className={'ui-cp__ticket-editor_switch-checkbox'}>
                            <CustomCheckbox
                                value={Boolean(accessKey)}
                                onChange={(value) => {
                                    if (value) {
                                        setNotificationKeys(true)
                                    } else {
                                        setNotificationKeys(false)
                                    }
                                    setAccessKey(value ? 1 : 0)
                                }}
                            />
                        </div>
                        <Toggletip
                            parent={document.querySelector('main')}
                            content={<>
                                Индивидуальный <a
                                href={'https://events.nethouse.ru/help/kak-nastroit-klyuchi-dostupa-dlya-kazhdogo-uchastnika/'}
                                target={'_blank'}>код-пароль</a> участника для просмотра вебинара
                            </>}>
                            <button type={'button'} className={'ui-cp__ticket-editor__toggle'}>
                                <QuestionIcon/>
                            </button>
                        </Toggletip>
                    </div>
                </div> : null}
        </div>
    }

    const TicketPriceEditor = () => {

        useEffect(() => {
            if (editor?.count) setCountInf(false)
        }, [countInf])

        return <div className={'ui-cp__ticket-editor_price-default'}>
            <div className="ui-cp__ticket-editor_row">
                <div className={'ui-cp__ticket-editor_count'}>
                    {countInf ? <span>Не ограничено</span> : null}
                    <Input label={'Количество'}
                           outside={1}
                           size={'small'}
                           type={'number'}
                           value={`${editor?.count}`}
                           onKeyPress={e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                           error={validator?.count}
                           isInfinity={true}
                           min={0}
                           max={1000000}
                           onClick={() => {
                               setCountInf(false)
                           }}
                           onPicClick={(e) => {
                               setEditor(prev => ({
                                   ...prev,
                                   count: ''
                               }))
                               setValidator(prev => ({
                                   ...prev,
                                   count: ''
                               }))
                               setCountInf(!countInf)
                           }}
                           handleChange={(e) => {
                               let value = e.target.value
                               if (Number(value) <= -1) {
                                   value = 0
                               }
                               setValidator(prev => ({
                                   ...prev,
                                   count: ''
                               }))
                               if (!countInf) {
                                   setEditor(prev => ({
                                       ...prev,
                                       count: value
                                   }))
                               }
                           }}
                    />
                </div>
                <div className={'ui-cp__ticket-editor_legend'}>
                    <div><strong>0</strong> — нет в наличии</div>
                    <div><strong>∞</strong> — неограниченное количество</div>
                </div>
            </div>
            {editor?.type == 4 || editor.type == 16 ? <div className="ui-cp__ticket-editor_row">
                <Input label={'Ссылка'}
                       outside={1}
                       size={'small'}
                       value={editor?.videoLink}
                       handleChange={(e) => {
                           let value = e.target.value
                           setEditor(prev => ({
                               ...prev,
                               videoLink: value
                           }));
                       }}
                />
            </div> : null}
            <div className="ui-cp__ticket-editor_row">
                <Input label={'Цена, ₽'}
                       outside={1}
                       size={'small'}
                       value={editor?.price == null ? '' : String(editor?.price)}
                       error={validator?.price}
                       handleChange={(e) => {
                           let value = e.target.value
                           if (e.target.value === '' || /^[\d\b]+$/.test(e.target.value)) {
                               setEditor(prev => ({
                                   ...prev,
                                   price: value
                               }));
                               setValidator(prev => ({
                                   ...prev,
                                   price: '',
                               }))
                           }
                       }}
                />
                <Input label={'Начало действия'}
                       disabled={true}
                       outside={1}
                       size={'small'}
                       value={'С момента публикации'}
                />
            </div>
            {editor?.prices && editor?.prices.map((price, index) => <div className="ui-cp__ticket-editor_row"
                                                                         key={index}>
                <Input label={'Цена, ₽'}
                       outside={1}
                       size={'small'}
                       value={price.price || ''}
                       error={validator[`date_prices.${index}.value`]}
                       handleChange={(e) => {
                           let value = e.target.value
                           let copy = JSON.parse(JSON.stringify(editor))
                           copy.prices[index].price = value
                           setEditor(copy)
                           let validatorCopy = JSON.parse(JSON.stringify(validator))
                           validatorCopy[`date_prices.${index}.value`] = ''
                           setValidator(validatorCopy)
                       }}
                />
                <Flatpickr
                    value={price.date_start}
                    options={{
                        dateFormat: "Y-m-d",
                        altFormat: "d.m.Y",
                        disableMobile: "true",
                        altInput: "true",
                        locale: Russian,
                        minDate: price.date_start || 'today',
                        monthSelectorType: "static",
                    }}
                    onChange={(selectedDates, dateStr, instance) => {
                        if (editor?.prices) handlePriceInput(new Date(selectedDates[0]).setHours(0, 0, 0, 0), index)
                    }}
                    render={({value}, ref) => {
                        return (
                            <div className={'ui-cp__ticket-editor_row'}>
                                <Input
                                    type={"date"}
                                    label={'Начало действия'}
                                    inputRef={ref}
                                    outside={1}
                                    calendar={1}
                                    placeholder={'дд.мм.гггг.'}
                                    size={'small'}
                                    isClearable={price.date_start}
                                    handleChange={() => {
                                        let copy = JSON.parse(JSON.stringify(editor))
                                        copy.prices.find(x => new Date(x.date_start).getTime() === new Date(price.date_start).getTime()).date_start = null
                                        setEditor(copy);
                                    }}
                                />
                                <button
                                    className={'cp-btn cp-btn_size_small cp-scheme_tertiary-ghost'}
                                    onClick={() => {
                                        let copy = JSON.parse(JSON.stringify(editor))
                                        setEditor(prev => ({
                                            ...prev,
                                            prices: copy?.prices.filter(x => x.id != editor?.prices[index].id)
                                        }));
                                    }}><IconTrash/></button>
                            </div>
                        );
                    }}
                />
            </div>)}
            <div className="ui-cp__ticket-editor_row">
                <button className={'cp-btn cp-btn_size_small cp-scheme_secondary-default cp-btn_icon_left'}
                        disabled={dropdownSessions || dropdownGroups}
                        onClick={() => {
                            handleAddPrice(props.item, props.idx)
                        }}>
                    <IconAdd/>
                    Добавить цену
                </button>
            </div>
        </div>
    }

    const handleSelectSession = (session, e) => {
        if (e) {
            setSelectedSessions(prev => ({
                ...prev,
                [`${session.id}`]: session.name
            }))
        } else {
            setSelectedSessions(Object.fromEntries(Object.entries(selectedSessions).filter(x => x[0] != session.id)))
        }
    }

    const TicketSessionEditor = () => {
        return <div className="ui-cp__ticket-editor_item ui-cp__ticket-sessions">
            <div className={'ui-cp__ticket-sessions-container'}>
                <h1 className="ui-cp__ticket-editor_title">Сеансы</h1>
                {sessions.length ? <ul className={'ui-cp__ticket-sessions-list'}>
                    {Object.entries(editor?.sessions).length ? Object.entries(editor?.sessions).map((session, session_idx) =>
                        <li className={'ui-cp__ticket-sessions-item'}
                            key={session_idx}>
                            <span>{session[1]}</span>
                        </li>
                    ) : <li className={'ui-cp__ticket-sessions-item'}><span>Все сеансы</span></li>}
                </ul> : <div className={'ui-cp__ticket-sessions_empty'}>
                    У вас пока нет сеансов. <a href={`/admin/events/${id}/schedule/`}>Перейти в
                    Расписание</a>
                </div>}
            </div>
            {sessions.length ?
                <button className="cp-btn cp-btn_size_small cp-scheme_secondary-default ui-cp__ticket-sessions-add"
                        disabled={dropdownGroups || disabledForm}
                        onFocus={(e) => {
                            setDropdownSessions(true)
                            e.target.blur()
                        }}>
                    выбрать сеансы
                    {dropdownSessions ? <div className={'ui-cp__ticket-sessions-dropdown'}>
                        <ul className={'ui-cp__ticket-sessions-dropdown_list'}>
                            {sessions.map((session, session_idx) => {
                                return <li key={session_idx}>
                                    <Checkbox
                                        checked={Boolean(Object.keys(selectedSessions).find(x => x == session.id))}
                                        size={'small'}
                                        id={session.id + session_idx}
                                        value={session.name}
                                        onChange={(e) => handleSelectSession(session, e)}/>
                                </li>
                            })}
                        </ul>
                        <div className="ui-cp__ticket-sessions-dropdown_actions">
                            <div className="cp-btn cp-btn_size_small cp-scheme_tertiary-ghost"
                                 onClick={() => {
                                     setDropdownSessions(false)
                                     setSelectedSessions([])
                                 }}>
                                Отмена
                            </div>
                            <div className="cp-btn cp-btn_size_small cp-scheme_secondary-default"
                                 onClick={() => {
                                     setEditor(prev => ({
                                         ...prev,
                                         sessions: selectedSessions
                                     }))
                                     setDropdownSessions(false)
                                 }}>
                                Сохранить
                            </div>
                        </div>
                    </div> : null}
                </button> : null}
        </div>
    }

    const handleSelectGroups = (group, e) => {
        if (e) {
            setSelectedGroups(selectedGroups.concat([group]))
        } else {
            setSelectedGroups(selectedGroups.filter(x => x.floor_group_id != group.floor_group_id))
        }
    }

    const TicketGroupEditor = () => {
        return <div className="ui-cp__ticket-editor_item ui-cp__ticket-groups">
            <div className={'ui-cp__ticket-groups-container'}>
                <h1 className="ui-cp__ticket-editor_title">Группы</h1>
                {floors.length ? <ul className={'ui-cp__ticket-groups-list'}>
                    {editor?.floorGroups.length ? editor?.floorGroups.map((group, group_idx) =>
                        <li className={'ui-cp__ticket-groups-item'}
                            key={group_idx}>
                            <span>{floors.find(x => x.id === group.floor_id).name}, {group.name}</span>
                        </li>
                    ) : <li className={'ui-cp__ticket-groups-item'}><span>Все группы</span></li>}
                </ul> : <div className={'ui-cp__ticket-groups_empty'}>
                    У вас пока нет залов. <a href={`/admin/events/${id}/floors/`}>Перейти в
                    Залы</a>
                </div>}
            </div>
            {floors.length ?
                <button className="cp-btn cp-btn_size_small cp-scheme_secondary-default ui-cp__ticket-groups-add"
                        disabled={dropdownSessions || disabledForm}
                        onFocus={(e) => {
                            setDropdownGroups(true)
                            e.target.blur()
                        }}>
                    выбрать группы
                    {dropdownGroups ? <div className={'ui-cp__ticket-groups-dropdown'}>
                        <div className="ui-cp__ticket-groups-dropdown__container">
                            {floors.map((floor, floor_idx) => <div key={floor_idx}
                                                                   className={'ui-cp__ticket-groups-dropdown__floor'}>
                                <div className="ui-cp__ticket-groups-dropdown__title">{floor.name}</div>
                                <ul className={'ui-cp__ticket-groups-dropdown_list'}>
                                    {floor.groups.map((group, group_index) => <li key={group_index + 1000}>
                                        <Checkbox
                                            checked={Boolean(selectedGroups.find(x => x.floor_group_id == group.id))}
                                            size={'small'}
                                            id={group.id + 10000}
                                            value={group.name}
                                            onChange={(e) => {
                                                handleSelectGroups({
                                                    floor_group_id: group.id,
                                                    floor_id: group.floor_id,
                                                    name: group.name
                                                }, e)
                                            }}/>
                                    </li>)}
                                </ul>
                            </div>)}
                        </div>
                        <div className="ui-cp__ticket-groups-dropdown_actions">
                            <div className="cp-btn cp-btn_size_small cp-scheme_tertiary-ghost"
                                 onClick={() => {
                                     setDropdownGroups(false)
                                     setSelectedGroups([])
                                 }}>
                                Отмена
                            </div>
                            <div className="cp-btn cp-btn_size_small cp-scheme_secondary-default"
                                 onClick={() => {
                                     setEditor(prev => ({
                                         ...prev,
                                         floorGroups: selectedGroups
                                     }))
                                     setDropdownGroups(false)
                                 }}>
                                Сохранить
                            </div>
                        </div>
                    </div> : null}
                </button> : null}
        </div>
    }

    const TicketCertEditor = () => {
        return <div className="ui-cp__ticket-editor_item ui-cp__ticket-editor_certificate">
            <div className="ui-cp__ticket-editor_row">
                {props.certs.length ? <div className={'ui-cp__ticket-sessions-container'}>
                    <Select
                        direction={['bottom', 'right']}
                        placeholder={'Не выбрано'}
                        value={String(props.certs.findIndex(x => JSON.stringify(x) === JSON.stringify(props.certs.find(x => x.promocode_id == editor?.promocodeId))))}
                        label={'Промокод для сертификата'}
                        error={validator?.promocode_id}
                        size={'small'}
                        data={props.certs.map(e => e.promocode).filter(promocode => promocode)}
                        onChange={(e) => {
                            setEditor(prev => ({
                                ...prev,
                                promocodeId: props.certs[e].promocode_id
                            }));
                        }}
                    />
                </div> : <div className={'ui-cp__ticket-sessions-container'}><h1
                    className="ui-cp__ticket-editor_title">Промокод для сертификата</h1>
                    <div className={'ui-cp__ticket-sessions_empty'}>
                        У вас пока нет промокодов. <a href={`/admin/events/${id}/promo/`}>Перейти в
                        Промокоды</a>
                    </div>
                </div>}
            </div>
        </div>
    }

    const TicketActionEditor = () => {
        return <div className="ui-cp__ticket-editor_item ui-cp__ticket-editor_actions">
            <button className={'cp-btn cp-btn_size_small cp-scheme_tertiary-ghost'}
                    disabled={dropdownSessions || dropdownGroups || disabledForm}
                    onClick={() => {
                        setNotificationKeys(false)
                        props.handlers.handleSwitchTicketsEditor(props.idx, false)
                    }}>
                отменить
            </button>
            <button className={'cp-btn cp-btn_size_small cp-scheme_primary-default'}
                    disabled={dropdownSessions || dropdownGroups || disabledForm}
                    onClick={() => {
                        setNotificationKeys(false)
                        handeSubmitEdit(props.item?.ticket_id, props?.idx)
                    }}>
                сохранить билет
            </button>
        </div>
    }

    return (
        <div className={'ui-cp__ticket-editor'} ref={ref}>
            <div className="ui-cp__ticket-editor_item ui-cp__ticket-editor_title">
                название билета, количество, цена
            </div>
            <div className="ui-cp__ticket-editor_item ui-cp__ticket-editor_info">
                {TicketGlobalNameEditor()}
                {TicketGlobalDescriptionEditor()}
                {TicketTermEditor()}
                {notificationKeys ? <div
                    className={'ui-cp__ticket-editor_row ui-cp__notification-bordered ui-cp__notification-bordered_info'}>
                    Добавление и настройка ключей станут доступны после сохранения билета
                </div> : null}
            </div>
            <div className="ui-cp__ticket-editor_item ui-cp__ticket-editor_price">
                {TicketPriceEditor()}
            </div>
            {editor?.type !== 16 ? <TicketOption
                items={editor.options}
                handlers={{setEditor, setDisabledForm}}
                editor={editor}
                disabledForm={disabledForm}
                dropdownSessions={dropdownSessions}
                dropdownGroups={dropdownGroups}
                validator={validator}/> : null}
            {editor?.type !== 16 ? TicketSessionEditor() : null}
            {editor?.type !== 16 ? TicketGroupEditor() : null}
            {editor?.type === 16 ? TicketCertEditor() : null}
            {TicketActionEditor()}
        </div>
    );
};

export default TicketsEditor;
