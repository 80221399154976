// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Events-module_section_-pDQz{display:flex;flex-direction:column;justify-content:center;align-items:center;gap:24px}.Events-module_section_-pDQz{margin:56px 0}.Events-module_section_-pDQz:first-child{margin-top:24px}.Events-module_section_-pDQz:last-child{margin-bottom:24px}.Events-module_title_zYo78{font-family:Inter,sans-serif;color:#000;text-align:center;font-size:14px;font-style:normal;font-weight:700;line-height:125%;letter-spacing:.28px;text-transform:uppercase;margin:0;padding:0}.Events-module_notifications_rnzYF{margin-top:32px;display:flex;flex-direction:column;gap:32px}", "",{"version":3,"sources":["webpack://resources/js/react/src/views/Admin/Events/Events.module.scss"],"names":[],"mappings":"AAAA,6BACI,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,QAAA,CAGJ,6BACI,aAAA,CAGJ,yCACI,eAAA,CAGJ,wCACI,kBAAA,CAGJ,2BACI,4BAAA,CACA,UAAA,CACA,iBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CACA,oBAAA,CACA,wBAAA,CACA,QAAA,CACA,SAAA,CAGJ,mCACI,eAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA","sourcesContent":[".section {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: 24px;\n}\n\n.section {\n    margin: 56px 0;\n}\n\n.section:first-child {\n    margin-top: 24px;\n}\n\n.section:last-child {\n    margin-bottom: 24px;\n}\n\n.title {\n    font-family: Inter, sans-serif;\n    color: #000000;\n    text-align: center;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 125%;\n    letter-spacing: 0.28px;\n    text-transform: uppercase;\n    margin: 0;\n    padding: 0;\n}\n\n.notifications {\n    margin-top: 32px;\n    display: flex;\n    flex-direction: column;\n    gap: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "Events-module_section_-pDQz",
	"title": "Events-module_title_zYo78",
	"notifications": "Events-module_notifications_rnzYF"
};
export default ___CSS_LOADER_EXPORT___;
