import {conf, graphicsHover, isSegments, lockStatus, pixiMP, stageMP} from "./Const";
import {drawSelect} from "./DrawSelect";
import {Floor} from "../App";
import {handleSetMiddleMouseDown} from "./MouseProcessor";
import {localUniformBitGl} from "pixi.js";

export const StageEvents = () => {
    const container = document.getElementsByClassName('pixiApp')[0]

    const stage = Floor.app.stage;
    let touches = {}
    // stage.on("touchstart", (event) => {
    //     // обработка нажатия мыши над канвасом
    //     touches[event.pointerId] = event
    //     console.log('touchstart - ', event.pointerId)
    // })
    // stage.on("touchend", (event) => {
    //     // обработка нажатия мыши над канвасом
    //     console.log('touchend - ', event.pointerId)
    // })
    // stage.on("touchmove", (event) => {
    //     // обработка нажатия мыши над канвасом
    //     console.log(touches)
    // })

    stage.on("pointerleave", (event) => {
        if (pixiMP.isMiddleMouseDown) {
            // handleSetMiddleMouseDown(false) когда в режиме навигации курсор покидает сцену выключаем навигацию
            if (isSegments) {
                Floor.reRenderPlace()
            } else {
                Floor.updatePlace()
            }
        }
    })

    stage.on("mousedown", (event) => {
        // обработка нажатия мыши над канвасом
        if (event.data.button === 0 && (conf.isDesigner || conf.isManager)) {
            drawSelect({action: "start", event: event})
        }
    })

    stage.on("mouseup", (event) => {
        // обработка клика по канвасу
        if (event.data.button === 0 && (conf.isDesigner || conf.isManager)) {
            drawSelect({action: "end", event: event})
        }
        if (event.target.label !== 'stage') {
            // клик по месту
            if (conf.isUser && Math.abs(pixiMP.lastMouseX - pixiMP.startMouseX) <= pixiMP.threshold &&
                Math.abs(pixiMP.lastMouseY - pixiMP.startMouseY) <= pixiMP.threshold) {
                if (event.target.place && event.target.place.type !== 'scene') {
                    if (event.data.button === 0) {
                        selectPlace(event.target.place)
                    }
                }
            }
        }
    })

    stage.on("mousemove", (event) => {
        if (conf.isDesigner || conf.isManager) {
            drawSelect({action: "update", event: event})
        }
    })

    stage.on("touchend", (event) => {
        // тап по канвасу
        if (event.target.label === 'stage') {
            // по пустоте
            clearSelectPlace()
        } else {
            // по месту
            if (pixiMP.isMiddleMouseDown) {
                // проверка на то что не производилась навигация
                if (Math.abs(pixiMP.lastMouseX - pixiMP.startMouseX) <= pixiMP.threshold &&
                    Math.abs(pixiMP.lastMouseY - pixiMP.startMouseY) <= pixiMP.threshold) {
                    if (event.data.button === 0) {
                        selectPlace(event.target.place)
                    }
                }
            }
        }
    })

    const selectPlace = (item) => {
        graphicsHover.clear()
        if (item.type !== 'scene') {
            if (conf.isDesigner || conf.isManager) {
                Floor.handlers.pixiMouseUp([item], [item.id], Floor.groupList)
                return
            }

            if (conf.isUser && item.status === 0) {
                Floor.handlers.pixiMouseUp([item], [item.id], Floor.groupList)
                return
            }
        } else {
            clearSelectPlace()
        }
    }


    const clearSelectPlace = () => {
        Floor.selectedNodes.length && Floor.handlers.pixiMouseUp(null, [], Floor.groupList)
    }
}
