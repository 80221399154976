import React, {useEffect, useState, useMemo, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Circle, Text, Layer, Rect, Stage, Tag, Image} from "react-konva";
import {Beforeunload, useBeforeunload} from 'react-beforeunload';

import ToolbarDesigner from './toolbar/ToolBarDesigner'
import ToolBarManager from './toolbar/ToolBarManager'
import BottomBarDesigner from "./toolbar/BottomBarDesigner";
import BottomBarManager from "./toolbar/BottomBarManager";
import ToolBarUser from "./toolbar/ToolBarUser";
import BottomBarUser from "./toolbar/BottomBarUser";

import './style/Editor.scss'
import Preloader from "../../../../widget/components/preloader";
import PlaceChair from "@/views/Admin/Floors/Editor/figure/PlaceChair";
import PlaceScene from "@/views/Admin/Floors/Editor/figure/PlaceScene";
import PlaceTable from "@/views/Admin/Floors/Editor/figure/PlaceTable";
import PlaceFreeZone from "@/views/Admin/Floors/Editor/figure/PlaceFreeZone";
import ActiveSquare from "@/views/Admin/Floors/Editor/figure/ActiveSquare";
import {STAGESTEP, DEFAULTGROUPSLIST, DEFAULTSTAGE} from "@/views/Admin/Floors/Editor/utils/constants";

import Render from "@/views/Admin/Floors/Editor/app/Index";
import {isWebGPUSupported, isWebGLSupported} from 'pixi.js';
import {
    handleBtnScale,
    handleSetMiddleMouseDown,
    setMiddleMouseDown
} from "@/views/Admin/Floors/Editor/app/utils/MouseProcessor";
import {Floor} from "@/views/Admin/Floors/Editor/app/App";

const stageWidth = window.innerWidth;
const stageHeight = window.innerHeight

const Editor = (props) => {
    let config = props.config;

    const [isNewRender, setIsNewRender] = useState(true);
    const [place, setPlace] = useState([]);
    const [selectedNodes, setSelectedNodes] = useState([]); // заселекченые координаты
    const [activeSquare, setActiveSquare] = useState([]); // координаты для построения
    const [groupList, setGroupList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [map, setMap] = useState([]);
    const [constEditableStatus, setConstEditableStatus] = useState([1, 4, 5]);
    const counterId = useRef(1); // счетчик id

    const [isResetCustomPlace, setIsResetCustomPlace] = useState(true);
    const [activeGroup, setActiveGroup] = useState(0);
    const formTemplate = useSelector(state => state.formTemplate);
    const formOrder = useSelector(state => state.formOrder);
    const offsetWidth = document.getElementsByClassName('floorEditor');
    const displayWidth = offsetWidth.length ? offsetWidth[0].offsetWidth : stageWidth
    const displayHeight = offsetWidth.length ? offsetWidth[0].offsetHeight : stageHeight

    const [stage, setStage] = useState({...DEFAULTSTAGE, width: displayWidth, height: displayHeight})

    const [titleFloor, setTitleFloor] = useState('Новый зал');
    const [titleEvent, setTitleEvent] = useState('');
    const [modeEditor, setModeEditor] = useState('none');
    const [disabledSave, setDisabledSave] = useState(false);
    const [changeMade, setChangeMade] = useState(false);
    const [isDragging, setIsDragging] = useState(false);

    const layerRef = React.useRef();
    const stageRef = React.useRef();
    const Konva = window.Konva;

    const selectionRectRef = React.useRef();
    const toolTipRef = React.useRef();
    const toolTipTextRef = React.useRef();
    const selection = React.useRef({
        visible: false, x1: 0, y1: 0, x2: 0, y2: 0
    });

    const dispatchNotification = useDispatch();
    const notification = useSelector(state => state.notification);
    const extremes = useRef({left: null, right: null, top: null, bottom: null}) // координаты крайних элементов
    const isWebGPUSupport = useRef(null) // поддержка WebGLS
    const [errorNumbering, setErrorNumbering] = useState(false)

    useEffect(() => {
        // isWebGPUSupported().then((res) => {
        //     isWebGPUSupport.current = res
        //     alert(`isWebGPUSupported - ${res}`)
        // })
        // let gls = isWebGLSupported()
        // alert(`isWebGLSupported - ${gls}`)
        if (config.role === 'manager') {
            setTitleEvent(props.titleEvent);
        }

        if (!props.floorsData === null) {
            setTitleFloor(props.titleFloor)
        }

    }, []);

    useEffect(() => {
        selectedNodes.forEach((item) => {
            if (item.type === 'place') {
                setExtremes(item)
            }
        })
    }, [selectedNodes])

    useEffect(() => {
        resetExtremes()
        prePareInitData()
    }, [props.floorsData])

    useEffect(() => {
        if (place.length && groupList.length) updateColorGroup()
    }, [groupList]);

    const updateColorGroup = () => {
        // обновление цветов после изменения группы
        let colorGroup = {}
        groupList.map((item) => {
            colorGroup[item.id] = item.color
        })
        setPlace(prev => prev.map(item => {
            item.group ? item.group.color = colorGroup[item.group.id] : ''
            return item
        }))
    }

    useEffect(() => {
        // пересчет мапы
        updateMap('newMap')
    }, [place])

    const resetExtremes = () => {
        extremes.current.left = null
        extremes.current.right = null
        extremes.current.top = null
        extremes.current.bottom = null
    }

    const setExtremes = (item, reset) => {
        // установка координаты самого левого и правого стульчика + верхнего ряда
        if (extremes.current.left > item.column || extremes.current.left === null) {
            extremes.current.left = item.column
        }
        if (extremes.current.right < item.column || extremes.current.right === null) {
            extremes.current.right = item.column
        }

        if (item.row < extremes.current.top || extremes.current.top === null) {
            extremes.current.top = item.row
        }
        if (item.row > extremes.current.bottom || extremes.current.bottom === null) {
            extremes.current.bottom = item.row
        }
    }

    const prePareInitData = () => {
        // инициализация данных
        let placeInOrder = [];

        if (formOrder?.data?.order_tickets?.length) {
            formOrder?.data?.order_tickets?.forEach((item) => {
                placeInOrder.push(item.floor_place.id)
            })
        }

        if (props.floorsData !== null) {
            if (props.floorsData.groups !== null) {
                if (config.role !== 'user') {
                    setGroupList(props.floorsData.groups)
                } else {
                    setGroupList(props.floorsData.groups.map((item, i) => {
                        item['price'] = (i + 1) * 240
                        item['tickets_id'] = i % 2 ? [595, 596] : [597]
                        return item
                    }))
                }
            }

            if (config.role === 'designer') {
                setTitleFloor(props.floorsData.name);
            }

            if (config.role === 'manager') {
                setTitleFloor(props.titleFloor);
            }
        }
        const mapGroup = getMapGroups(props.floorsData.groups)

        let arrPlaces = [];
        let minX = null, minY = null, maxX = null, maxY = null;
        if (props.floorsData.places.length) {
            minY = props.floorsData.places[0].map_y
            minX = props.floorsData.places[0].map_x
            maxX = props.floorsData.places[0].map_x
            maxY = props.floorsData.places[0].map_y
        }
        for (let i = 0; i < props.floorsData.places.length; i++) {
            if (props.floorsData.places[i].type === 1) {
                // place
                setExtremes(props.floorsData.places[i])

                minY = Math.min(minY, props.floorsData.places[i].map_y)
                minX = Math.min(minX, props.floorsData.places[i].map_x)
                maxY = Math.max(maxY, props.floorsData.places[i].map_y + 1)
                maxX = Math.max(maxX, props.floorsData.places[i].map_x + 1)

                arrPlaces.push({
                    "id": `${props.floorsData.places[i].id}`,
                    "status": props.floorsData.places[i].status,
                    "cart": placeInOrder.indexOf(Number(props.floorsData.places[i].id)) !== -1,
                    "isEdit": false,
                    "used": props.floorsData.places[i].used,
                    "posName": `place_${props.floorsData.places[i].row}_${props.floorsData.places[i].seat}`,
                    "type": "place",
                    "group": {
                        "id": props.floorsData.places[i].group_id,
                        "name": "",
                        "type": 1,
                        "color": mapGroup[props.floorsData.places[i].group_id].color
                    },
                    "selected": false,
                    "x": props.floorsData.places[i].map_x * STAGESTEP + 6,
                    "y": props.floorsData.places[i].map_y * STAGESTEP + 6,
                    "row": props.floorsData.places[i].map_y,
                    "column": props.floorsData.places[i].map_x,
                    "fill": "#000000",
                    "stroke": "",
                    "error": false,
                    "coords": null,
                    "custom": {
                        "row": props.floorsData.places[i].row !== "null" ? props.floorsData.places[i].row : null,
                        "column": props.floorsData.places[i].seat !== "null" ? props.floorsData.places[i].seat : null,
                        "name": props.floorsData.places[i].name
                    }
                })
                continue
            }
            if (props.floorsData.places[i].type === 2) {
                //scene
                minY = Math.min(minY, props.floorsData.places[i].map_y)
                minX = Math.min(minX, props.floorsData.places[i].map_x)
                maxY = Math.max(maxY, props.floorsData.places[i].map_y + props.floorsData.places[i].map_height)
                maxX = Math.max(maxX, props.floorsData.places[i].map_x + props.floorsData.places[i].map_width)

                arrPlaces.push({
                    "id": `${props.floorsData.places[i].id}`,
                    "type": "scene",
                    "group": null,
                    "selected": false,
                    "x": null,
                    "y": null,
                    "map_height": props.floorsData.places[i].map_height,
                    "map_width": props.floorsData.places[i].map_width,
                    "coords": {
                        "start": {
                            "column": props.floorsData.places[i].map_x,
                            "row": props.floorsData.places[i].map_y
                        },
                        "end": {
                            "column": Math.floor(props.floorsData.places[i].map_width + props.floorsData.places[i].map_x),
                            "row": Math.floor(props.floorsData.places[i].map_height + props.floorsData.places[i].map_y),
                        }
                    },
                    "row": null,
                    "column": null,
                    "fill": "#DDD",
                    "stroke": "",
                    "custom": {
                        "row": null,
                        "column": null,
                        "name": props.floorsData.places[i].name
                    }
                })
                continue
            }
            if (props.floorsData.places[i].type === 3 || props.floorsData.places[i].type === 4) {
                // table and free zone
                minY = Math.min(minY, props.floorsData.places[i].map_y)
                minX = Math.min(minX, props.floorsData.places[i].map_x)
                maxY = Math.max(maxY, props.floorsData.places[i].map_y + props.floorsData.places[i].map_height)
                maxX = Math.max(maxX, props.floorsData.places[i].map_x + props.floorsData.places[i].map_width)

                arrPlaces.push({
                    "id": `${props.floorsData.places[i].id}`,
                    "status": props.floorsData.places[i].status,
                    "isEdit": false,
                    "used": props.floorsData.places[i].used,
                    "type": props.floorsData.places[i].type === 3 ? "table" : "freeZone",
                    "group": {
                        "id": props.floorsData.places[i].group_id,
                        "name": "Столы",
                        "type": 3,
                        "color": mapGroup[props.floorsData.places[i].group_id].color
                    },
                    "selected": false,
                    "x": null,
                    "y": null,
                    "map_height": props.floorsData.places[i].map_height,
                    "map_width": props.floorsData.places[i].map_width,
                    "coords": {
                        "start": {
                            "column": props.floorsData.places[i].map_x,
                            "row": props.floorsData.places[i].map_y
                        },
                        "end": {
                            "column": Math.floor(props.floorsData.places[i].map_width + props.floorsData.places[i].map_x),
                            "row": Math.floor(props.floorsData.places[i].map_height + props.floorsData.places[i].map_y),
                        }
                    },
                    "row": null,
                    "column": null,
                    "fill": "#FFE066",
                    "stroke": "",
                    "custom": {
                        "row": props.floorsData.places[i].row,
                        "column": props.floorsData.places[i].seat,
                        "name": props.floorsData.places[i].name
                    }
                })
                continue
            }
        }
        extremumUser.current = {
            min: {x: minX * STAGESTEP, y: minY * STAGESTEP},
            max: {x: maxX * STAGESTEP, y: maxY * STAGESTEP}
        }
        if (!isNewRender) setUserOffset(minX, minY, maxX, maxY)
        if (arrPlaces.length) setPlace(arrPlaces)
    }

    const extremumUser = useRef(null)

    const getMapGroups = (group) => {
        let mapList = {}
        let list = group ? group : groupList
        list.forEach((item) => {
            mapList[item.id] = item;
        });
        return mapList
    }

    const setUserOffset = (minX, minY, maxX, maxY) => {
        if (minX === null || minY === null || maxX === null || maxY === null || config.role !== 'user') return 1

        const offsetWidth = document.getElementsByClassName('floorEditor');
        const displayHeight = offsetWidth.length ? offsetWidth[0].offsetHeight : stageHeight
        const displayWidth = offsetWidth.length ? offsetWidth[0].offsetWidth : stageWidth
        let canvasOffsetX = 0, canvasOffsetY = 0, canvasScale = 1, ratio = 1, step = 1;

        let schemeHeight = maxY - minY;
        let schemeWidth = maxX - minX;

        let schemeOffsetX = 0, schemeOffsetY = 0;

        schemeHeight = schemeHeight === 0 ? 2 * STAGESTEP : schemeHeight * STAGESTEP // высота схемы в пикселях
        schemeWidth = schemeWidth === 0 ? 2 * STAGESTEP : schemeWidth * STAGESTEP // ширина схемы в пикселях

        let ratioHeight = displayHeight / schemeHeight; // соотношение высоты канваса к схеме
        let ratioWidth = displayWidth / schemeWidth; // соотношение ширины канваса к схеме
        let verticalScale = ratioHeight <= ratioWidth ? true : false // true - вертикальная

        canvasScale = verticalScale ? ratioHeight : ratioWidth

        schemeOffsetX = minX * STAGESTEP;
        schemeOffsetY = minY * STAGESTEP;

        let sumSquareDisX = Math.round(displayWidth / STAGESTEP / canvasScale) // сколько квадратов влазит вертикально в экран
        let widthSchemeX = maxX - minX // сколько квадратов в картинке вертикаль
        let spaceSquareX = sumSquareDisX - widthSchemeX // пустых квадратов в экране
        let spaceSquareXPx = spaceSquareX * STAGESTEP / 2
        canvasOffsetX = schemeOffsetX - spaceSquareXPx

        let sumSquareDisY = Math.round(displayHeight / STAGESTEP / canvasScale) // сколько квадратов влазит вертикально в экран
        let heightSchemeY = maxY - minY // сколько квадратов в картинке вертикаль
        let spaceSquareY = sumSquareDisY - heightSchemeY // пустых квадратов в экране
        let spaceSquareYPx = spaceSquareY * STAGESTEP / 2
        canvasOffsetY = schemeOffsetY - spaceSquareYPx
        setStage(prevState => {
            let arr = Object.assign({}, prevState)
            arr.scale = canvasScale
            arr.height = displayHeight
            arr.width = displayWidth
            arr.offsetY = canvasOffsetY
            arr.offsetX = canvasOffsetX
            arr.minX = minX
            arr.minY = minY
            arr.maxX = maxX
            arr.maxY = maxY
            return arr
        })

    }

    const generateIdPolygon = (item, offsetX = 0, offsetY = 0) => {
        // генерируем ID для map по координатам полигона
        let id = [];
        if (item.start.column == item.end.column) {
            // вертикальная фигура в 1 колонку
            for (let i = item.start.row; i <= item.end.row; i++) {
                id.push(`place_${i + offsetY}_${item.start.column + offsetX}`)
            }
            return id
        }
        if (item.start.row == item.end.row) {
            // горизонтальная фигура в 1 строку
            for (let k = item.start.column; k <= item.end.column; k++) {
                id.push(`place_${item.end.row + offsetY}_${k + offsetX}`)
            }
            return id
        }

        if (item.start.row == item.end.row && item.start.column == item.end.column) {
            // фигура размером в 1 клетку
            id.push(`place_${item.start.row + offsetY}_${item.start.column + offsetX}`)
        }

        for (let i = item.start.row; i <= item.end.row - 1; i++) {
            for (let k = item.start.column; k <= item.end.column - 1; k++) {
                id.push(`place_${i + offsetY}_${k + offsetX}`)
            }
        }

        return id
    }

    const updateMap = (action, item, type) => {
        // обновление мапы
        let id = [];
        if (action === 'creation') {
            // добавить в мапу новое место
            if (type === 'place') {
                setMap(prev => prev.concat(item))
                return
            }
            if (type === 'scene' || type === 'table' || type === 'freeZone') {
                id = generateIdPolygon(item);
                setMap(prev => prev.concat(id));
                return
            }
        }
        if (action === 'newMap') {
            // Построить новую мапу по всем местам
            place.forEach((item) => {
                if (item.type === 'place') {
                    id.push(`place_${item.row}_${item.column}`)
                } else if (item.type === 'scene' || item.type === 'table' || item.type === 'freeZone') {
                    id = id.concat(generateIdPolygon(item.coords));
                }
            })
            setMap(id)
        }

        if (action === 'delete') {
            // удалить из мапы все заселекченые места
            selectedNodes.forEach((item) => {
                if (item.type === 'place') {
                    id.push(`place_${item.row}_${item.column}`)
                } else if (item.type === 'scene' || item.type === 'table' || item.type === 'freeZone') {
                    id = id.concat(generateIdPolygon(item.coords));
                }
            })
            setMap(map.filter((item, index) => id.indexOf(item) === -1))
        }
    }

    const handleDelete = () => {
        let used = false;
        for (let i = 0; i < selectedNodes.length; i++) {
            if (typeof selectedNodes[i]['used'] !== "undefined") {
                if (selectedNodes[i].used === null) {
                    continue
                }
                if (selectedNodes[i].used.length > 0) {
                    // место занято
                    used = true
                    continue
                }
            }
        }
        if (!used) {
            setChangeMade(true)
            updateMap('delete', null, null);
            let result = place.filter((item, index) => {
                return !placeIncludedSelect(selectedNodes, item) ? true : false
            })

            resetExtremes()

            result.forEach((item) => {
                if (item.type === 'place') {
                    setExtremes(item)
                    // обнуляем крайние координаты
                }
            })
            setPlace(result)
            setSelectedNodes([]);
        } else {
            dispatchNotification({
                type: "SET_NOTIFICATION",
                payload: {
                    ...notification,
                    open: true,
                    type: 'error',
                    title: 'Удаление невозможно',
                    message: 'На выбранные места уже проданы билеты'
                }
            })
        }
    }

    const updateSelectionRect = () => {
        const node = selectionRectRef.current;
        node.setAttrs({
            visible: selection.current.visible,
            x: Math.min(selection.current.x1, selection.current.x2),
            y: Math.min(selection.current.y1, selection.current.y2),
            width: Math.abs(selection.current.x1 - selection.current.x2),
            height: Math.abs(selection.current.y1 - selection.current.y2),
            fill: "rgba(0, 161, 255, 0.3)"
        });
    };

    const getRelativePointerPosition = (node) => {
        // функция вернет позицию указателя относительно переданного узла
        const transform = node.getAbsoluteTransform().copy();
        // чтобы определить относительное положение, нам нужно инвертировать преобразование
        transform.invert();
        // получить положение указателя (скажем, мыши или касания)
        const pos = node.getStage().getPointerPosition();
        // относительная точка
        return transform.point(pos);
    }

    const onMouseDown = (e) => {
        const isElement = e.target.findAncestor(".elements-container");
        const isTransformer = e.target.findAncestor("Transformer");
        if (isElement || isTransformer) {
            return;
        }

        const point = getRelativePointerPosition(e.target.getStage());
        selection.current.visible = true;
        selection.current.x1 = point.x;
        selection.current.y1 = point.y;
        selection.current.x2 = point.x;
        selection.current.y2 = point.y;
        updateSelectionRect();
    };

    const onMouseMove = (e) => {
        if (!selection.current.visible) {
            return;
        }
        const point = getRelativePointerPosition(e.target.getStage());
        selection.current.x2 = point.x;
        selection.current.y2 = point.y;
        updateSelectionRect();
    };

    const createCoordsClickToGrid = (x, y) => {
        // создание координат по клику на сетке
        let coords = {x: null, y: null, row: null, column: null};
        coords.column = coords.column = Math.floor(x / STAGESTEP);
        coords.row = coords.row = Math.floor(y / STAGESTEP);
        coords.y = coords.row * STAGESTEP;
        coords.x = coords.column * STAGESTEP;
        return coords
    }

    const createCoordsRegion = (e, selectionBounds, pixiMap) => {
        // создание координат пустой клетки по выделению

        resetExtremes();
        handleDeselected();
        let active = [];
        let x1, x2, y1, y2;

        if (selectionBounds) {
            x1 = Math.min(selectionBounds.x1, selectionBounds.x2);
            x2 = Math.max(selectionBounds.x1, selectionBounds.x2);
            y1 = Math.min(selectionBounds.y1, selectionBounds.y2);
            y2 = Math.max(selectionBounds.y1, selectionBounds.y2);
        } else {
            x1 = Math.min(selection.current.x1, selection.current.x2);
            x2 = Math.max(selection.current.x1, selection.current.x2);
            y1 = Math.min(selection.current.y1, selection.current.y2);
            y2 = Math.max(selection.current.y1, selection.current.y2);
        }


        let coords = {rowStart: null, rowEnd: null, columnStart: null, columnEnd: null};

        coords.rowStart = Math.round(x1 / STAGESTEP);
        coords.columnStart = Math.round(y1 / STAGESTEP);
        coords.rowEnd = Math.round(x2 / STAGESTEP);
        coords.columnEnd = Math.round(y2 / STAGESTEP);

        for (let i = coords.rowStart; i < coords.rowEnd; i++) {
            for (let k = coords.columnStart; k < coords.columnEnd; k++) {
                let block = {x: null, y: null, row: null, column: null}
                block.y = k * STAGESTEP;
                block.x = i * STAGESTEP;
                block.row = k;
                block.column = i;
                active.push(block);
                setExtremes(block)
            }
        }
        if (config.role === 'designer') {
            if (active.length) {
                // создали пустые клетки
                setActiveSquare(active);
            } else {
                // создаем клеки по клику
                onClickTap(x1, y1, pixiMap);
            }
        }
    }


    const findIntersectingElements = (selector, selectedNode, selBox) => {
        layerRef.current.find(selector).forEach((elementNode) => {
            const elBox = elementNode.getClientRect();
            if (Konva.Util.haveIntersection(selBox, elBox)) {
                selectedNode.push(elementNode.attrs);
            }
        });
    };

    const onMouseUp = (e) => {

        const selectedNode = [];
        const selBox = selectionRectRef.current.getClientRect();
        const selectors = [".Place", ".scene", ".Text", ".Table", ".freeZone"];

        selectors.forEach((selector) => {
            findIntersectingElements(selector, selectedNode, selBox);
        });

        if (selectedNode.length != 0) {
            // если в выделение что-то попало
            selection.current.visible = false;
            // отключить событие клика
            Konva.listenClickTap = false;
            updateSelectionRect();
            setActiveSquare([]);
            setPlace(prev => prev.map((item) => {
                if (e.evt.ctrlKey) {
                    placeIncludedSelect(selectedNode, item) ? item.selected = true : '';
                } else {
                    if (selectedNodes.length != 0) item.selected = false
                    placeIncludedSelect(selectedNode, item) ? item.selected = true : false;
                }

                return item
            }))

            // применение группы выбранного места к выбору группы
            let singleType = [];
            let activeIdGroup = 1;

            for (let i = 0; i < selectedNode.length; i++) {
                singleType.push(selectedNode[i].type)
                if (selectedNode[i].type === 'scene') continue
                if (i !== 0) {
                    // if (selectedNode[i - 1].type !== 'scene'){
                    //     // здесь кроется ошибка, т.к. в предыдущей ноде может быть сцена у которой нет группы
                    // if (selectedNode[i].group.id !== selectedNode[i - 1].group.id) {
                    //     singleIdGroup = false;
                    //     break
                    // }}
                } else {
                    activeIdGroup = selectedNode[i].group.id
                }
            }
            singleType = singleType.filter((el, id) => singleType.indexOf(el) === id)
            if (singleType.length === 1) {
                for (let i = 0; i < groupList.length; i++) {
                    if (groupList[i].id === activeIdGroup) {
                        setActiveGroup(i)
                        break
                    }
                }
            } else {
                setActiveGroup(null)
            }

            if (e.evt.ctrlKey) {
                let oldSelected = [], selectedNoDuplicates = [];
                selectedNodes.forEach((item) => {
                    oldSelected.push(item.posName)
                })
                selectedNode.forEach((item) => {
                    oldSelected.push(item.posName)
                })

                const arrPlaceWithoutDuplicates = [...new Set(oldSelected)];
                let duplicates = [...oldSelected];
                arrPlaceWithoutDuplicates.forEach((item) => {
                    const i = duplicates.indexOf(item)
                    duplicates = duplicates
                        .slice(0, i)
                        .concat(duplicates.slice(i + 1, duplicates.length))
                })
                selectedNode.forEach((item) => {
                    if (duplicates.indexOf(item.posName) == -1) {
                        selectedNoDuplicates.push(item)
                    }
                    oldSelected.push(item.posName)
                })
                setSelectedNodes(selectedNodes.concat(selectedNoDuplicates));
            } else {
                setSelectedNodes(selectedNode);
            }

            return
        } else {
            // если выделение пустое создаем пустые клетки
            setActiveSquare([]);
            createCoordsRegion(e)
        }
        activeGroup === null ? setActiveGroup(0) : ''
        selection.current.visible = false;
        // отключить событие клика
        Konva.listenClickTap = false;
        updateSelectionRect();
    };

    const onMouseUpUser = (e) => {
        const selBox = selectionRectRef.current.getClientRect();
        const selectedNode = [];
        layerRef.current.find(".Place").forEach((elementNode) => {
            // поиск мест в области выделения
            const elBox = elementNode.getClientRect();
            if (Konva.Util.haveIntersection(selBox, elBox)) {
                // activePlaceArr.push(elementNode.attrs.id);
                selectedNode.push(elementNode.attrs);
            }
        });

        layerRef.current.find(".Text").forEach((elementNode) => {
            // поиск текста в области выделения
            const elBox = elementNode.getClientRect();
            if (Konva.Util.haveIntersection(selBox, elBox)) {
                selectedNode.push(elementNode.attrs);
            }
        });

        layerRef.current.find(".Table").forEach((elementNode) => {
            // поиск мест в области выделения
            const elBox = elementNode.getClientRect();
            if (Konva.Util.haveIntersection(selBox, elBox)) {
                // activePlaceArr.push(elementNode.attrs.id);
                selectedNode.push(elementNode.attrs);
            }
        });

        layerRef.current.find(".freeZone").forEach((elementNode) => {
            // поиск мест в области выделения
            const elBox = elementNode.getClientRect();
            if (Konva.Util.haveIntersection(selBox, elBox)) {
                // activePlaceArr.push(elementNode.attrs.id);
                selectedNode.push(elementNode.attrs);
            }
        });


        if (selectedNode.length != 0) {
            // если в выделение что-то попало
            selection.current.visible = false;
            // отключить событие клика
            Konva.listenClickTap = false;
            updateSelectionRect();
            // setActiveSquare([]);

            setPlace(prev => prev.map((item) => {
                placeIncludedSelect(selectedNode, item) ? item.selected = true : item.selected = false;
                //if (e.evt.shiftKey) placeIncludedSelect(selectedNodes.concat(selectedNode), item) ? item.selected = true : item.selected = false; //  шифт
                return item
            }))

            if (config.role === 'user') {
                if (selectedNode[0].status == 0) props.handleSelectPlace(selectedNode[0], groupList)
                // if (selectedNode[0].status != 3) props.handleSelectPlace(selectedNode[0], groupList)
            }

            // применение группы выбранного места к выбору группы
            let singleIdGroup = true;
            let singleType = [];
            let activeIdGroup = 1;

            for (let i = 0; i < selectedNode.length; i++) {
                singleType.push(selectedNode[i].type)
                if (selectedNode[i].type === 'scene') continue
                if (i !== 0) {
                    // if (selectedNode[i - 1].type !== 'scene'){
                    //     // здесь кроется ошибка, т.к. в предыдущей ноде может быть сцена у которой нет группы
                    // if (selectedNode[i].group.id !== selectedNode[i - 1].group.id) {
                    //     singleIdGroup = false;
                    //     break
                    // }}
                } else {
                    activeIdGroup = selectedNode[i].group.id
                }
            }
            singleType = singleType.filter((el, id) => singleType.indexOf(el) === id)
            if (singleType.length === 1) {
                for (let i = 0; i < groupList.length; i++) {
                    if (groupList[i].id === activeIdGroup) {
                        setActiveGroup(i)
                        break
                    }
                }
            } else {
                setActiveGroup(null)
            }

            setSelectedNodes(selectedNode);
            return
        }

        selection.current.visible = false;
        // отключить событие клика
        Konva.listenClickTap = false;
    };

    const placeIncludedSelect = (selectedNode, item) => {
        // Проверка места по координатам на вхождение в выбранные элементы selectedNodes
        for (let i = 0; i < selectedNode.length; i++) {
            if (item.type !== selectedNode[i].type) continue
            if (item.type === 'place') {
                if (selectedNode[i].column === item.column && selectedNode[i].row === item.row) {
                    return true
                    break
                }
            }
            if (item.type === 'scene' || item.type === 'table' || item.type === 'freeZone') {
                if (selectedNode[i].coords.start.column === item.coords.start.column && selectedNode[i].coords.start.row === item.coords.start.row) {
                    return true
                    break
                }
            }

        }
        return false
    }

    const placeIncludedSelectMove = (item) => {
        // Проверка места по координатам на вхождение в выбранные элементы selectedNodes
        for (let i = 0; i < selectedNodes.length; i++) {
            if (item.type !== selectedNodes[i].type) continue
            if (item.type === 'place') {
                if (selectedNodes[i].column === item.column && selectedNodes[i].row === item.row) {
                    return true
                    break
                }
            }
            if (item.type === 'scene' || item.type === 'table' || item.type === 'freeZone') {
                if (selectedNodes[i].coords.start.column === item.coords.start.column && selectedNodes[i].coords.start.row === item.coords.start.row) {
                    return true
                    break
                }
            }

        }
        return false
    }

    const onClickTap = (x1, y1, pixiMap) => {
        let coordsClick = createCoordsClickToGrid(x1, y1);
        let newElem = `place_${coordsClick.row}_${coordsClick.column}`
        let isBusy;
        // проверка на незанятость ячейки
        if (isNewRender) {
            isBusy = pixiMap.some(item => item === newElem);
        } else {
            isBusy = map.some(item => item === newElem);
        }

        if (!isBusy) setActiveSquare([coordsClick]);
        return;
    };

    const handleWheel = (e) => {
        //скролл
        e.evt.preventDefault();
        e.evt.deltaY < 0 ? handleScale('+', e) : handleScale('-', e);
    };

    const handleScale = (type) => {
        isNewRender ? handleBtnScale(type) : handleZoom(type)
    }

    const handleZoom = (type, e) => {
        let newScale = type === '+' ? stage.scale + 0.1 : stage.scale - 0.1;
        newScale <= 0.2 ? newScale = 0.2 : '';
        let step = STAGESTEP * newScale;
        let x = Math.round(stage.x % step);
        let y = Math.round(stage.y % step);
        let offsetX = stage.offsetX * newScale
        let offsetY = stage.offsetY * newScale

        let schemeOffsetX = stage.minX * STAGESTEP;
        let schemeOffsetY = stage.minY * STAGESTEP;

        let canvasOffsetX = 0, canvasOffsetY = 0;
        let sumSquareDisX = stage.width / STAGESTEP / newScale // сколько квадратов влазит вертикально в экран
        let widthSchemeX = stage.maxX - stage.minX // сколько квадратов в картинке вертикаль
        let spaceSquareX = sumSquareDisX - widthSchemeX // пустых квадратов в экране
        let spaceSquareXPx = spaceSquareX * STAGESTEP / 2
        canvasOffsetX = schemeOffsetX - spaceSquareXPx

        let sumSquareDisY = displayHeight / STAGESTEP / newScale // сколько квадратов влазит вертикально в экран
        let heightSchemeY = stage.maxY - stage.minY // сколько квадратов в картинке вертикаль
        let spaceSquareY = sumSquareDisY - heightSchemeY // пустых квадратов в экране
        let spaceSquareYPx = spaceSquareY * STAGESTEP / 2
        canvasOffsetY = schemeOffsetY - spaceSquareYPx

        if (config.role !== 'user') {
            canvasOffsetX = offsetX
            canvasOffsetY = offsetY
        }

        setStage({
            ...stage,
            offsetX: canvasOffsetX,
            offsetY: canvasOffsetY,
            backgroundX: x,
            backgroundY: y,
            scale: newScale,
        });
    }

    const handleSave = (closeAfterSave) => {
        setLoader(true)
        setDisabledSave(true)
        let arrPlace = [], emptyNameArr = [];
        const mapGroup = getMapGroups()

        for (let i = 0; i < place.length; i++) {
            if (place[i].type === 'place') {
                if (place[i].custom.column === null || place[i].custom.row === null || place[i].custom.column === '' || place[i].custom.row === '') {
                    emptyNameArr.push(`${place[i].row}_${place[i].column}`)
                }
                arrPlace.push(`${place[i].custom.row}_${place[i].custom.column}_${mapGroup[place[i].group.id].name}`)
            }
        }

        const arrPlaceWithoutDuplicates = [...new Set(arrPlace)];
        let duplicates = [...arrPlace];
        arrPlaceWithoutDuplicates.forEach((item) => {
            const i = duplicates.indexOf(item)
            duplicates = duplicates
                .slice(0, i)
                .concat(duplicates.slice(i + 1, duplicates.length))
        })

        if (duplicates.length === 0 && emptyNameArr.length === 0) {
            // если нет ошибок сохраняем
            parseDataSave(closeAfterSave)
        } else {
            // подсвечиваем ошибки
            markErrorPlace(duplicates, emptyNameArr)
        }
    }

    const markErrorPlace = (duplicates, emptyNameArr) => {
        setDisabledSave(false)
        setLoader(false)
        let txtError = []
        if (duplicates.length) {
            txtError.push('исправьте совпадающие номера')
        }

        if (emptyNameArr.length) {
            setErrorNumbering(true)
            txtError.push('задайте номера')
        }

        dispatchNotification({
            type: "SET_NOTIFICATION",
            payload: {
                ...notification,
                open: true,
                type: 'error',
                title: 'Сохранение не удалось',
                message: txtError.join(' и ') + ' мест'
            }
        })
        if (duplicates.length || emptyNameArr.length) {
            setPlace(prev => prev.map(item => {
                if (item.type === 'place') setErrorPlace({item, duplicates}, {item, emptyNameArr})
                return item
            }))
        }


    }
    const parseDataSave = (closeAfterSave) => {
        let arrSavePlace = []
        for (let i = 0; i < place.length; i++) {
            if (place[i].type === 'place') {
                arrSavePlace.push({
                    "id": Number(place[i].id),
                    "floor_id": 1,
                    "group_id": place[i].group.id,
                    "type": 1,
                    "map_x": place[i].column,
                    "map_y": place[i].row,
                    "map_angle": 0,
                    "map_width": 1,
                    "map_height": 1,
                    "map_shift": 0,
                    "row": `${place[i].custom.row ? place[i].custom.row : place[i].row}`, // TODO place[i].row нужно заменить на null после добавления новыъх полей в API идея в том что бы сюда записывать только кастомные поля
                    "seat": `${place[i].custom.column ? place[i].custom.column : place[i].column}`, // TODO place[i].column нужно заменить на null после добавления новыъх полей в API
                    "name": place[i].custom.name,
                    "created_at": "2022-03-18T08:47:00.000000Z",
                    "updated_at": "2022-03-18T08:47:00.000000Z",
                })
                continue
            }
            if (place[i].type === 'scene') {
                arrSavePlace.push({
                    "id": Number(place[i].id),
                    "floor_id": 1,
                    "group_id": null,
                    "type": 2,
                    "map_x": place[i].coords.start.column,
                    "map_y": place[i].coords.start.row,
                    "map_angle": 0,
                    "map_width": Math.floor(place[i].coords.end.column - place[i].coords.start.column),
                    "map_height": Math.floor(place[i].coords.end.row - place[i].coords.start.row),
                    "map_shift": 0,
                    "row": '0',
                    "seat": '0',
                    "name": `${place[i].custom.name}`,
                    "created_at": "2022-03-18T08:47:00.000000Z",
                    "updated_at": "2022-03-18T08:47:00.000000Z",
                })
                continue
            }
            if (place[i].type === 'table' || place[i].type === 'freeZone') {
                arrSavePlace.push({
                    "id": Number(place[i].id),
                    "floor_id": 1,
                    "group_id": place[i].group.id,
                    "type": place[i].type === 'table' ? 3 : 4,
                    "map_x": place[i].coords.start.column,
                    "map_y": place[i].coords.start.row,
                    "map_angle": 0,
                    "map_width": Math.floor(place[i].coords.end.column - place[i].coords.start.column),
                    "map_height": Math.floor(place[i].coords.end.row - place[i].coords.start.row),
                    "map_shift": 0,
                    "row": '0',
                    "seat": '0',
                    "name": place[i].custom.name,
                    "created_at": "2022-03-18T08:47:00.000000Z",
                    "updated_at": "2022-03-18T08:47:00.000000Z",
                })
                continue
            }
        }
        props.handleSavePlans(arrSavePlace, groupList, titleFloor, props.floorsData !== null ? props.floorsData.id : -1, setDisabledSave, closeAfterSave, setChangeMade, setLoader)
    }

    const setErrorPlace = (duplicates, empty) => {
        const mapGroup = getMapGroups()

        if (duplicates.duplicates.indexOf(`${duplicates.item.custom.row}_${duplicates.item.custom.column}_${mapGroup[duplicates.item.group.id].name}`) !== -1 || empty.emptyNameArr.indexOf(`${empty.item.row}_${empty.item.column}`) !== -1) {
            duplicates.item.error = true;
        } else {
            duplicates.item.error = false;
        }
    }


    const validateGroup = (type) => {
        let availability = false;
        groupList.forEach((item) => {
            if (item.type === type) availability = true
        })
        if (availability) {
            // если группа создана
            if (activeGroup && groupList[activeGroup]) {
                if (type === groupList[activeGroup].type) {
                    return groupList[activeGroup]
                }
            }

            for (let i = 0; i < groupList.length; i++) {
                if (groupList[i].type === type) {
                    return groupList[i]
                }
            }
        } else {
            // создаем новую группу
            let newGroup = DEFAULTGROUPSLIST[type]
            let list = JSON.parse(JSON.stringify(groupList))

            setGroupList(list.concat(newGroup))
            return newGroup
        }

        return {id: null, color: "red"};
    }

    const validateGroup_OLD = (type) => {
        let availability = false;
        groupList.forEach((item) => {
            if (item.type === type) availability = true
        })
        if (availability) {
            // если группа создана
            if (type === groupList[activeGroup].type) {
                return groupList[activeGroup]
            } else {
                for (let i = 0; i < groupList.length; i++) {
                    if (groupList[i].type === type) {
                        return groupList[i]
                    }
                }
            }
        } else {
            // создаем новую группу
            let newGroup = DEFAULTGROUPSLIST[type]
            let list = JSON.parse(JSON.stringify(groupList))

            setGroupList(list.concat(newGroup))
            return newGroup
        }
        return {id: null, color: "red"};
    }

    const checkNegativePos = () => {
        // поиск смещения по X Y
        let minX = 1, minY = 1;
        activeSquare.forEach((item) => {
            if (item.column < minX) {
                minX = item.column
            }
            if (item.row < minY) {
                minY = item.row
            }
        })
        if (minX < 0) minX = minX - 1
        if (minY < 0) minY = minY - 1

        if (minX === 0) minX = -1
        if (minY === 0) minY = -1

        if (minX === 1) minX = 0
        if (minY === 1) minY = 0

        return {x: Math.abs(minX), y: Math.abs(minY)}
    }

    const handleNumbering = (param) => {
        autoNumbering(param)
    }


    const autoNumbering = (param) => {
        // автоматическая нумерация мест
        let allPlace = selectedNodes.length ? JSON.parse(JSON.stringify(selectedNodes)) : JSON.parse(JSON.stringify(place))
        let arrPlace = []
        let otherPlace = []
        let newPlaces = []
        let editId = []
        resetExtremes()
        allPlace.forEach((item) => {
            if (item.type === 'place') {
                setExtremes(item)
            }
        })
        allPlace.forEach((place) => {
            // делим на два массива все места на схеме
            editId.push(place.id)
            if (place.type === 'place') {
                place.custom.column = getColumn(place.column, param)
                place.custom.row = getRow(place.row, param)
                place.error = false;
                arrPlace.push(place)
            } else {
                otherPlace.push(place)
            }
        })

        if (selectedNodes.length) {
            let placeState = place.filter(x => !editId.includes(x.id))
            newPlaces = arrPlace.concat(otherPlace)
            setSelectedNodes(newPlaces)
            newPlaces = placeState.concat(newPlaces)
        } else {
            newPlaces = arrPlace.concat(otherPlace)
        }

        setPlace(newPlaces) // собираем все типы мест воедино
    }

    const getColumn = (col, param) => {
        // возвращает номер клетки с учетом зеркалирования
        if (param.placeLeft) {
            col = col - extremes.current.left
        } else {
            col = extremes.current.right - col
        }
        return col + param.placeNumber
    }

    const getRow = (row, param) => {
        if (param.rowTop) {
            row = row - extremes.current.top
        } else {
            row = extremes.current.bottom - row
        }

        return row + param.rowNumber
    }

    const generateId = () => {
        return `-${counterId.current++}`;
    }

    const createNewPlace = () => {
        // создаем новые стульчики на выбранных квадратах
        setChangeMade(true)
        let group = validateGroup(1);
        let active = [];
        if (activeSquare.length) {
            let offset = checkNegativePos();

            let newPlaces = activeSquare.map((item, i) => {
                setExtremes(item)
                //active.push(`place_${item.row + offset.x}_${item.column + offset.y}`);
                active.push(`place_${item.row}_${item.column}`);
                let x = item.x + 6;
                let y = item.y + 6;
                let row = item.row;
                let column = item.column;

                // Вычисляем уникальный id на основе начальной длины массива place и текущего индекса i

                return {
                    id: generateId(),
                    posName: `place_${item.row}_${item.column}`,
                    type: 'place',
                    group: group,
                    selected: true,
                    x: x,
                    y: y,
                    column: column,
                    row: row,
                    fill: group.color,
                    stroke: "",
                    error: false,
                    custom: {
                        row: null, column: null, name: null
                    }
                };
            });

            groupList.forEach((item, i) => {
                // выбор группы в тулбаре
                if (item.id == group.id) {
                    setActiveGroup(i);
                }
            })

            setPlace(prev => prev.concat(newPlaces))
            setSelectedNodes(newPlaces)
            setActiveSquare([]);
        }
    }

    const createNewScene = () => {
        // создаем новую сцену на выбранных квадратах
        setChangeMade(true)
        let start = {column: null, row: null};
        let end = {column: null, row: null};
        if (activeSquare.length) {
            start = {column: activeSquare[0].column, row: activeSquare[0].row};
            end = {column: activeSquare[0].column, row: activeSquare[0].row};
            activeSquare.forEach((item) => {
                if (item.row < start.row) start.row = item.row;
                if (item.column < start.column) start.column = item.column;
                if (item.row > end.row) end.row = item.row;
                if (item.column > end.column) end.column = item.column;
            })

            let newPlace = [{
                id: generateId(),
                posName: `place_${start.column}_${start.row}`,
                type: 'scene',
                group: null,
                selected: true,
                x: null,
                y: null,
                map_height: ++end.row - start.row,
                map_width: ++end.column - start.column,
                coords: {start, end},
                row: null,
                column: null,
                fill: "#FFA94D",
                stroke: "",
                custom: {
                    row: null, column: null, name: null
                }
            }];

            updateMap('creation', {start, end}, 'scene')
            setPlace(prev => prev.concat(newPlace))
            setSelectedNodes(newPlace)
            setActiveSquare([]);
        }
    }
    const createNewTable = () => {
        // создаем стол
        setChangeMade(true)
        let group = validateGroup(3);
        let start = {column: null, row: null};
        let end = {column: null, row: null};
        if (activeSquare.length) {
            start = {column: activeSquare[0].column, row: activeSquare[0].row};
            end = {column: activeSquare[0].column, row: activeSquare[0].row};
            activeSquare.forEach((item) => {
                if (item.row < start.row) start.row = item.row;
                if (item.column < start.column) start.column = item.column;
                if (item.row > end.row) end.row = item.row;
                if (item.column > end.column) end.column = item.column;
            })

            let newPlace = [{
                id: generateId(),
                posName: `place_${start.column}_${start.row}`,
                type: 'table',
                group: group,
                selected: true,
                x: null,
                y: null,
                map_height: ++end.row - start.row,
                map_width: ++end.column - start.column,
                coords: {start, end},
                row: null,
                column: null,
                fill: group.color,
                stroke: "",
                custom: {
                    row: null, column: null, name: 'Стол'
                }
            }];

            groupList.forEach((item, i) => {
                // выбор группы в тулбаре
                if (item.id == group.id) {
                    setActiveGroup(i);
                }
            })

            updateMap('creation', {start, end}, 'table')
            setPlace(prev => prev.concat(newPlace))
            setSelectedNodes(newPlace)
            setActiveSquare([]);
        }
    }

    const handleModeEditor = () => {
        if (modeEditor !== 'hande') {
            document.body.style.cursor = "grab";
            isNewRender && setMiddleMouseDown(true)
            setModeEditor('hande')
        } else {
            document.body.style.cursor = "default";
            isNewRender && setMiddleMouseDown(false)
            setModeEditor('none')
        }
    }

    const handleOnMouseDown = (e) => {
        if (modeEditor !== 'hande') {
            onMouseDown(e)
        } else {
            // const pos = e.target.getStage().getPointerPosition();
        }
    }

    const handleDeselected = () => {
        // снятие выделений со всех мест
        setSelectedNodes([]);
        setPlace(prev => prev.map((item) => {
            item.selected = false;
            return item
        }))
    }

    const setPositionPlaceMove = (item, pos) => {
        // перемещение выбранных объектов в зависимости от типа
        item.selected = true
        if (item.type === 'place') {
            item.x = item.x + pos.x;
            item.y = item.y + pos.y;
            item.row = item.row + pos.row;
            item.column = item.column + pos.column;
            item.posName = 'place_' + item.row + '_' + item.column;
            return
        }
        if (item.type === 'scene' || item.type === 'table' || item.type === 'freeZone') {
            item.coords.start.row = item.coords.start.row + pos.row;
            item.coords.start.column = item.coords.start.column + pos.column;
            item.coords.end.row = item.coords.end.row + pos.row;
            item.coords.end.column = item.coords.end.column + pos.column;
            item.posName = 'place_' + item.coords.start.row + '_' + item.coords.start.column;
            return
        }
    }

    const checkOccupancy = ({x, y, row, column}) => {
        // проверка на занятость ячейки
        let newSelMap = []; // новая мапа для выделенных мест
        let selMap = []; //  мапа выделенных мест
        let noSelMap = []; // текущая мапа без выделенных мест

        selectedNodes.forEach((item) => {
            if (item.type === 'place') {
                selMap.push(`place_${item.row}_${item.column}`)
                newSelMap.push(`place_${item.row + row}_${item.column + column}`)
            } else if (item.type === 'scene' || item.type === 'table' || item.type === 'freeZone') {
                selMap = selMap.concat(generateIdPolygon(item.coords));
                newSelMap = newSelMap.concat(generateIdPolygon(item.coords, column, row));
            }
        })

        map.forEach((item) => {
            if (selMap.indexOf(item) === -1) {
                noSelMap.push(item)
            }
        })

        for (let i = 0; i < newSelMap.length; i++) {
            // поиск пересечений
            if (noSelMap.indexOf(newSelMap[i]) !== -1) {
                return false
            }
        }
        return true
    }

    const handleMoveTo = (target) => {
        let pos = {x: 0, y: 0, row: 0, column: 0}
        setChangeMade(true)
        switch (target) {
            case 'up':
                pos.y = -STAGESTEP;
                pos.row = -1;
                break
            case 'down':
                pos.y = STAGESTEP;
                pos.row = +1;
                break
            case 'left':
                pos.x = -STAGESTEP;
                pos.column = -1;
                break
            case 'right':
                pos.x = STAGESTEP;
                pos.column = 1;
                break
            default:
                console.log(target)
        }

        let occupancy = checkOccupancy(pos);
        if (occupancy && config.role === 'designer') {
            setPlace(prev => prev.map((item) => {
                placeIncludedSelectMove(item) ? setPositionPlaceMove(item, pos) : '';
                return item
            }))

            // setSelectedNodes(selectedNodes.map((item) => {
            //    setPositionPlaceMove(item, pos);
            //     return item
            // }))

            updateMap('newMap'); // обновляем мапу
        } else {
            console.log('Занято')
        }
    }

    const renderPlace = (item, i) => {
        // рендер места по типу
        if (item.type === 'place') {
            return <PlaceChair
                role={config.role}
                key={i}
                getKey={i}
                param={item}
                groupList={groupList}
                toolTipRef={toolTipRef}
                toolTipTextRef={toolTipTextRef}
                constEditableStatus={constEditableStatus}
            />
        }
        if (item.type === 'scene') {
            return <PlaceScene
                key={i}
                getKey={i}
                param={item}
                groupList={groupList}
            />
        }

        if (item.type === 'table') {
            return <PlaceTable
                role={config.role}
                key={i}
                getKey={i}
                param={item}
                groupList={groupList}
                constEditableStatus={constEditableStatus}
            />
        }

        if (item.type === 'freeZone') {
            return <PlaceFreeZone
                role={config.role}
                key={i}
                getKey={i}
                param={item}
                groupList={groupList}
                constEditableStatus={constEditableStatus}
            />
        }
    }

    const createNewFreeZone = () => {
        setChangeMade(true)
        let group = validateGroup(4);
        let start = {column: null, row: null};
        let end = {column: null, row: null};
        if (activeSquare.length) {
            start = {column: activeSquare[0].column, row: activeSquare[0].row};
            end = {column: activeSquare[0].column, row: activeSquare[0].row};
            activeSquare.forEach((item) => {
                if (item.row < start.row) start.row = item.row;
                if (item.column < start.column) start.column = item.column;
                if (item.row > end.row) end.row = item.row;
                if (item.column > end.column) end.column = item.column;
            })

            let newPlace = [{
                id: generateId(),
                posName: `place_${start.column}_${start.row}`,
                type: 'freeZone',
                group: group,
                selected: true,
                x: null,
                y: null,
                map_height: ++end.row - start.row,
                map_width: ++end.column - start.column,
                coords: {start, end},
                row: null,
                column: null,
                fill: group.color,
                stroke: "",
                custom: {
                    row: null, column: null, name: 'Танцпол'
                }
            }];

            groupList.forEach((item, i) => {
                // выбор группы в тулбаре
                if (item.id == group.id) {
                    setActiveGroup(i);
                }
            })
            updateMap('creation', {start, end}, 'freeZone')
            setPlace(prev => prev.concat(newPlace))
            setSelectedNodes(newPlace)
            setActiveSquare([]);
        }

    }

    const stageOnMouseDown = (e) => {
        if (e.evt.button == 1) {
            stageRef.current.draggable(true)
        }
        if (config.role === 'designer' || config.role === 'manager') {
            return handleOnMouseDown(e)
        }
        if (config.role === 'user') {
            return handleOnMouseDown(e)
        }
        return console.log(config.role)
    }

    const stageOnTouchStart = (e) => {
        if (config.role === 'designer' || config.role === 'manager') {
            return handleOnMouseDown(e)
        }
        if (config.role === 'user') {
            return handleOnMouseDown(e)
        }
        return console.log(config.role)
    }

    const stageOnMouseUp = (e) => {
        if (e.evt.button == 1) {
            stageRef.current.draggable(false)
            selection.current.visible = false;
            return
        }
        if (config.role === 'designer' || config.role === 'manager') {
            return modeEditor !== 'hande' ? onMouseUp(e) : null
        }
        if (config.role === 'user') {
            return onMouseUpUser(e)
        }

        return console.log(config.role)
    }
    const stageOnTap = (e) => {

        if (config.role === 'designer' || config.role === 'manager') {
            return modeEditor !== 'hande' ? onMouseUp(e) : null
        }
        if (config.role === 'user') {
            return onMouseUpUser(e)
        }
        return console.log(config.role)
    }

    const stageOnMouseMove = (e) => {
        if (config.role === 'designer' || config.role === 'manager') {
            return modeEditor !== 'hande' ? onMouseMove(e) : null
        }
        return null
    }

    const stageOnTouchMove = (e) => {
        if (config.role === 'designer' || config.role === 'manager') {
            return modeEditor !== 'hande' ? onMouseMove(e) : null
        }
        return console.log(config.role)
    }

    const stageDraggable = (e) => {
        if (config.role === 'designer' || config.role === 'manager') {
            return modeEditor === 'hande' ? true : false
        }
        return true
    }

    const stageOnDragStart = (e) => {
        setIsDragging(true)
        if (config.role === 'designer') {
            return null
        }
        return null
    }

    const stageOnDragEnd = (e) => {
        setIsDragging(false)

        if (config.role === 'designer' || config.role === 'manager' || config.role === 'user') {
            let step = STAGESTEP * stage.scale
            let x = Math.round(e.currentTarget.attrs.x % step)
            let y = Math.round(e.currentTarget.attrs.y % step)

            setStage(prevState => {
                let arr = Object.assign({}, prevState)
                arr.backgroundX = x
                arr.backgroundY = y
                arr.x = e.currentTarget.attrs.x
                arr.y = e.currentTarget.attrs.y
                return arr
            })
        }
    }

    const handleSaveManager = (val, action) => {
        props.handleSaveManager(val, place, action)
    }

    const handCloseEditorDesigner = () => {
        // выход из редактора менеджера
        props.handCloseEditor(changeMade, handleSave)
    }
    const styleGrid = {
        'backgroundImage': `-webkit-repeating-radial-gradient(center center, rgb(255 255 255 / 17%), rgba(0, 0, 0, .2) ${2 * stage.scale}px, transparent ${2 * stage.scale}px, transparent 100%)`,
        'WebkitBackgroundSize': `${3 / stage.scale}px ${3 / stage.scale}px`,
        'MozBackgroundSize': `${3 / stage.scale}px ${3 / stage.scale}px`,
        'backgroundPosition': `left ${stage.backgroundX}px top ${stage.backgroundY}px`,
        'backgroundSize': `${STAGESTEP * stage.scale}px ${STAGESTEP * stage.scale}px`,
    };

    const handKeyDown = (e) => {
        switch (e.keyCode) {
            case 32:
                if (modeEditor !== 'hande') handleModeEditor()
                break
            case 107:
            case 187:
                handleScale('+')
                break
            case 189:
            case 109:
                handleScale('-')
                break
            case 40:
                handleMoveTo('down')
                break
            case 37:
                handleMoveTo('left')
                break
            case 39:
                handleMoveTo('right')
                break
            case 38:
                handleMoveTo('up')
                break
            case 46:
                handleDelete()
                break;
            // case 69:
            //     handleSetNewRender()
            //     break;

            default:
                console.log(e.keyCode)
        }

    }

    const handOnKeyUp = (e) => {
        switch (e.keyCode) {
            case 32:
                if (modeEditor !== 'none') handleModeEditor()
                break
            default:
                break

        }
    }

    const handleSetNewRender = () => {
        setIsNewRender(!isNewRender)
    }

    const pixiMouseUp = (selectNode, arrId, groupListPixi) => {
        // клик по месту дизайнера и менеджера
        setPlace(prev => prev.map((item) => {
            item.selected = arrId.includes(item.id);
            return item;
        }));

        selectNode ? setSelectedNodes(selectNode) : setSelectedNodes([])

        if (config.role === 'user') {
            selectNode && props.handleSelectPlace(selectNode[0], groupList)
        }

        if (selectNode.length) {
            // установка выбранной группы в тулбаре
            let singleType = new Set();
            let activeIdGroup = null;

            for (let i = 0; i < selectNode.length; i++) {
                singleType.add(selectNode[i].type);
                if (selectNode[i].type !== 'scene' && activeIdGroup === null) {
                    activeIdGroup = selectNode[i].group.id;
                }
            }

            if (singleType.size === 1) {
                for (let i = 0; i < groupListPixi.length; i++) {
                    if (groupListPixi[i].id === activeIdGroup) {
                        setActiveGroup(i);
                        break;
                    }
                }
            } else {
                setActiveGroup(null);
            }
        }
    }

    // useEffect(() => {
    //     console.log(place)
    //     console.log(selectedNodes)
    // }, [place, selectedNodes]);

    const handlers = {
        setPlace: setPlace,
        setSelectedNodes: (place) => {
            place ? setSelectedNodes([place]) : setSelectedNodes([])
        },
        pixiMouseUp: (selectNode, arrId, groupListPixi) => pixiMouseUp(selectNode, arrId, groupListPixi),
        createCoordsRegion: (e, selectionBounds, pixiMap) => createCoordsRegion(e, selectionBounds, pixiMap),
        setActiveSquare: (active) => {
            active ? setActiveSquare(active) : setActiveSquare([])
        },
        onMouseDown: (e) => stageOnMouseDown({evt: e}),
        onMouseUp: (e) => stageOnMouseUp({evt: e}),
        onTouchStart: (e) => stageOnTouchStart({evt: e}),
        onTap: (e) => stageOnTap({evt: e}),
        onMouseMove: (e) => stageOnMouseMove({evt: e}),
        onTouchMove: (e) => stageOnTouchMove({evt: e})
    }

    const editorData = {
        groupList: groupList,
        selectedNodes: selectedNodes,
        place: place,
        activeSquare: activeSquare,
        role: config.role,
        extremumUser: extremumUser.current,
        map: map
    }

    return (<>
        {changeMade && <Beforeunload onBeforeunload={() => 'You’ll lose your data!'}/>}

        <div className={'floorEditor'}
             onKeyDown={handKeyDown}
             onKeyUp={handOnKeyUp}
        >
            {config.role === 'designer' && <ToolbarDesigner
                errorNumbering={errorNumbering}
                setErrorNumbering={setErrorNumbering}
                handleNumbering={handleNumbering}
                handleSetNewRender={handleSetNewRender}
                setChangeMade={setChangeMade}
                selectedNodes={selectedNodes}
                setSelectedNodes={setSelectedNodes}
                titleFloor={titleFloor}
                setTitleFloor={setTitleFloor}
                modeEditor={modeEditor}
                createNewPlace={createNewPlace}
                createNewScene={createNewScene}
                createNewTable={createNewTable}
                createNewFreeZone={createNewFreeZone}
                handleMoveTo={handleMoveTo}
                handleDelete={handleDelete}
                handleModeEditor={handleModeEditor}
                handleScale={handleScale}
                groupList={groupList}
                setGroupList={setGroupList}
                activeGroup={activeGroup}
                setActiveGroup={setActiveGroup}
                setPlace={setPlace}
                place={place}
                isResetCustomPlace={isResetCustomPlace}
                setIsResetCustomPlace={setIsResetCustomPlace}
            />}
            {config.role === 'manager' && <ToolBarManager
                selectedNodes={selectedNodes}
                statuses={props.floorsData.statuses}
                editableStatuses={props.floorsData.editableStatuses}
                groups={props.floorsData.groups}
                setSelectedNodes={setSelectedNodes}
                modeEditor={modeEditor}
                handleModeEditor={handleModeEditor}
                handleScale={handleScale}
                setPlace={setPlace}
                place={place}
                changeMade={changeMade}
                setChangeMade={setChangeMade}
            />}

            <div className={`${config.role !== 'user' ? 'stage' : 'stageUser'}`}>
                {loader && <Preloader/>}
                <div style={{outline: 'none'}} tabIndex={1}
                     className={`ui-nh__floor-wrapper ${loader ? ' loader' : ''}`}>
                    {isNewRender && <Render data={editorData} handlers={handlers} config={config}/>}
                    {!isNewRender && <Stage style={isDragging ? {} : styleGrid} ref={stageRef}
                                            width={stage.width}
                                            height={stage.height}
                                            scaleX={stage.scale}
                                            scaleY={stage.scale}
                                            x={0}
                                            y={0}
                                            offsetX={stage.offsetX}
                                            offsetY={stage.offsetY}
                                            onMouseDown={stageOnMouseDown}
                                            onMouseUp={stageOnMouseUp}

                                            onTouchStart={stageOnTouchStart}
                                            onTap={stageOnTap}

                                            onMouseMove={stageOnMouseMove}
                                            onTouchMove={stageOnTouchMove}

                                            draggable={stageDraggable()}

                                            onDragStart={(e) => {
                                                stageOnDragStart(e)
                                            }}
                                            onDragEnd={(e) => {
                                                stageOnDragEnd(e)
                                            }}
                                            onWheel={handleWheel}

                    >
                        <Layer ref={layerRef}>
                            {activeSquare.map((item, i) => {
                                return <ActiveSquare key={`active_${i}`} pos={item}/>
                            })}
                            {place.map((item, i) => {
                                return renderPlace(item, i)
                            })}
                            <Rect fill="rgba(0,0,255,0.5)" ref={selectionRectRef}/>
                            <Tag visible={false} pointerDirection='down' pointerWidth={11} pointerHeight={7}
                                 fill="rgba(0,0,0,0.8)"
                                 ref={toolTipRef}/>
                            <Text fontFamily={"Inter, sans-serif"}
                                  fontStyle={'bold'}
                                  ref={toolTipTextRef}/>
                        </Layer>
                    </Stage>}


                </div>
            </div>
            {config.role === 'designer' && <BottomBarDesigner
                selectedNodes={selectedNodes}
                activeSquare={activeSquare}
                handleDeselected={handleDeselected}
                handleSetMode={props.handleSetMode}
                setChangeMade={setChangeMade}
                handleSave={handleSave}
                handCloseEditor={handCloseEditorDesigner}
                disabledSave={disabledSave}
                map={map}
            />}
            {config.role === 'manager' && <BottomBarManager
                data={props.floorsData}
                titleEvent={titleEvent}
                titleFloor={titleFloor}
                floorsData={props.floorsData}
                schedule={props.floorsData.schedule}
                handleUpdateStatus={props.handleUpdateStatus}
                handleSaveManager={handleSaveManager}
                setFloorsData={props.setFloorsData}
                changeMade={changeMade}
                setChangeMade={setChangeMade}
            />}
            {config.role === 'user' &&
                <> <ToolBarUser isNewRender={isNewRender} handleBackPage={props.handleBackPage}
                                selectedNodes={selectedNodes}
                                handleScale={handleScale}
                />
                    <BottomBarUser groups={groupList} handleSelectPlace={props.handleSelectPlace}/>
                </>
            }

        </div>
    </>)
};

export default Editor;
