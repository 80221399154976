import React from 'react';
import '../../scss/components/switcher/Switcher.scss'
import Message from "../../../widget/components/Message";
import Toggletip from "../toggletip/toggletip";

const Switcher = (props) => {
    const handleSwitcher = (event) => {
        return event.target.checked
    }

    return (
        <div
            style={props.style}
            className={['ui-widget__switcher', `ui-widget__switcher_size-${props.size || 'medium'}`, props.checked ? 'ui-widget__switcher_checked' : null, props.disabled ? 'ui-widget__switcher_disabled' : null, props.error ? 'ui-widget__switcher_error' : null].filter(x => x ?? x).join(' ')}
        >
            <input
                type="checkbox"
                tabIndex={props.tabIndex}
                id={props.id}
                // defaultChecked={props.checked}
                checked={props.checked}
                onChange={(event) => {
                    props.onChange(handleSwitcher(event))
                }}
            />
            {props.value ? <span className={'ui-widget__switcher_content'}>{props.value}</span> : null}
            {props.icon ? <Toggletip
                parent={document.querySelector('main')}
                content={props.placeholder}>
                <button>{props.icon}</button>
            </Toggletip> : null}
            <label htmlFor={props.id} className={'ui-widget__switcher_checkmark'}>
                {props.label ? props.label : null}
            </label>
            {props.error && <Message value={props.error} type={'error'}/>}
        </div>
    )
};

export default Switcher;