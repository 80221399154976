import React, {useEffect, useRef, useState} from 'react';
import Button from "../../../../../components/ui/Button";

import ChevronIcon from '../../../../../assets/images/icons/16/chevron-down.svg'
import CloseIcon from '../../../../../assets/images/icons/16/close_12.svg'
import {useDispatch, useSelector} from "react-redux";
import SessionFilter from "../../../../../components/sessionFilter/SessionFilter";

const BottomBarManager = (
    {
        data,
        titleEvent,
        titleFloor,
        handleUpdateStatus,
        handleSaveManager,
        setFloorsData,
        changeMade,
        setChangeMade,
    }) => {

    const [preloader, setPreloader] = useState(false)
    const [active, setActive] = useState(window.innerWidth > 768 ? true : false)
    const dispatch = useDispatch();
    const selector = useSelector(state => state)

    const handleActive = () => {
        setActive(!active)
    }

    const handleClose = () => {
        changeMade ? checkForChanges('close') : handleSaveManager(false, 'closed')
    }

    /* Поисковой запрос, принимает объект параметров поиска, возвращает тело запроса */
    const updateData = async (options, onlyFrom, schedule) => {
        let response, url
        url = Object.entries(options).map(x => `${x[0]}=${encodeURIComponent(x[1])}`).join('&')
        setPreloader(true)
        response = await handleUpdateStatus(data.floorId, data.eventId, url, onlyFrom, schedule)
        setPreloader(false)
        return response
    }

    const handleChangeSession = (idx) => {
        checkForChanges() // проверка на несохраненные изменения статусов зала
    }

    const checkForChanges = (who) => {
        if (changeMade) {
            dispatch(({
                type: "SET_MODAL",
                payload: {
                    open: true,
                    title: 'Есть несохраненные изменения',
                    content: '',
                    handler: null,
                    courseId: null,
                    recaptcha: null,
                    buttons: [
                        {
                            style: "",
                            action: "handler",
                            handler: async () => {
                                await setChangeMade(false)
                                who ? handleSaveManager(false, setChangeMade) : ''
                            },
                            name: who ? 'Закрыть' : 'Продолжить',
                            redirect: ""
                        }, {
                            style: "primary",
                            action: "handler",
                            handler: () => {
                                handleSaveManager(true, setChangeMade)
                            },
                            name: "Сохранить",
                            redirect: ""
                        }
                    ]
                }
            }))
        }
        return !changeMade
    }

    const actions = {
        sessions: {
            checkForChanges: () => checkForChanges(),
            setSession: (idx) => handleChangeSession(idx),
            handleUpdateStatus: (options, onlyFrom, schedule) => updateData(options, onlyFrom, schedule),
            setFloorsData: (data) => setFloorsData(data)
        }
    }

    return (
        <div className={`bottom-bar${active ? ' bottom-bar--overlay' : ''}`}>
            {active && <div className="bottom-bar__container">
                <div className="bottom-bar__wrapper-titles">
                    <div className="bottom-bar__section bottom-bar__floor">
                        <div className="bottom-bar__section-label">
                            Зал
                        </div>
                        <div className="bottom-bar__section-value">
                            {titleFloor}
                        </div>
                    </div>
                    <div className="bottom-bar__section bottom-bar__seminar">
                        <div className="bottom-bar__section-label">
                            {selector?.currentEvent?.content_type_text}
                        </div>
                        <div className="bottom-bar__section-value">
                            {titleEvent}
                        </div>
                    </div>
                </div>
                {Object.keys(data.allSession).length ?
                    <SessionFilter preloader={preloader} data={data} actions={actions}/> : ''}
            </div>}
            <div className="bottom-bar__section bottom-bar__control">
                {active && <div className={'bottom-bar__action--open'}>
                    <Button
                        value={<span><CloseIcon/></span>}
                        scheme={'tertiary'}
                        type={'default'}
                        onClick={handleActive}
                    />
                </div>}
                {!active && <div className={'bottom-bar__action--open'}>
                    <Button
                        value={<span><ChevronIcon/></span>}
                        scheme={'secondary'}
                        type={'ghost'}
                        onClick={handleActive}
                    />
                </div>}
                <button
                    className={'a-btn a-btn_size_small a-btn_type_tertiary-ghost-default'}
                    onClick={handleClose}>
                    Закрыть
                </button>
                <button
                    className={'a-btn a-btn_size_small a-btn_type_secondary-default'}
                    disabled={!changeMade}
                    onClick={() => handleSaveManager(true, setChangeMade)}>
                    Сохранить
                </button>
            </div>
        </div>
    );
};

export default BottomBarManager;
