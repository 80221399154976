import React from 'react';
import Select from "../select/Select";
import Input from "../../../components/ui/Input";

const Suggest = (
    {
        direction,
        visibilityItems,
        size,
        value,
        onInput,
        data,
        onDropShow,
        onDropHide,
        disabled,
        parentRef,
        title,
        outside,
        label,
        error,
        onClick,
        placeholder,
        maxLength,
        onChange,
    }) => {

    return (
        <Select
            direction={direction}
            visibilityItems={visibilityItems}
            size={size}
            data={data}
            onDropShow={onDropShow}
            onDropHide={onDropHide}
            parentRef={parentRef}
            onChange={onChange}
        >
            <Input
                title={title}
                size={size}
                outside={outside}
                label={label}
                handleChange={onInput}
                value={value}
                error={error}
                onClick={onClick}
                placeholder={placeholder}
                disabled={disabled}
                maxLength={maxLength}
            />
        </Select>
    );
};

export default Suggest;
