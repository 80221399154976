import React, {useState, useEffect} from 'react'
import Toggletip from "./Toggletip";

export default function CustomCheckbox(props) {

    const [value, setValue] = useState(props.value)

    const changeValue = (value) => {
        setValue(value);
        props.onChange(value)
    }

    useEffect(() => {
        if (value !== props.value) {
            setValue(props.value)
        }
    }, [props.value])

    if (props.checkboxType === 'checkbox') {
        return (
            <div className={'default-checkbox'}>
                <div className={`widget-form__checkbox widget-checkbox widget-checkbox_size_${props.size}`}>
                    <input
                        id={props.id}
                        type="checkbox"
                        value={value}
                        onChange={() => changeValue(!value)}
                        className="widget-checkbox__input"
                        checked={props.checked}
                    />
                    <label htmlFor={props.id} className="widget-checkbox__label">
                        <span className="widget-checkbox__text">{props.toggletipText}</span>
                    </label>
                </div>
            </div>
        )
    } else {
        return (
            <div className={'custom-checkbox'}>
                {props.title &&
                <p>{props.title}</p>
                }
                {props.toggletipText && props.toggletipLeft &&
                <Toggletip text={props.toggletipText}/>
                }
                <label className={`e-switch ${props.className} ${props.toggletipText && props.toggletipLeft ? '' : ''} ${props.toggletipText && props.toggletipRight ? '' : ''}`}>
                    <input className="e-switch__switch"
                           type="checkbox"
                           checked={value}
                           onChange={() => changeValue(!value)}
                    />
                    <span className="e-switch__slider"></span>
                </label>
                {props.toggletipText && props.toggletipRight &&
                <Toggletip text={props.toggletipText}/>
                }
            </div>
        )
    }

}
