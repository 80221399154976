import React, { FC } from 'react';
import Flatpickr from "react-flatpickr";
import {Russian} from 'flatpickr/dist/l10n/ru';
import styles from './ChartsDataPicker.module.scss';
import Input from '../../../../../../../components/ui/Input';
import {convert} from '../../../../../../../utils/nhApi';
import { useRouteMatch } from 'react-router';
import {ChartDataPickerInterface} from './ChartsDataPicker.interface';

const ChartsDataPicker:FC<ChartDataPickerInterface> = ({data, onChange, onFilter}) => {
    const {params} = useRouteMatch<{eventId: string}>();

    const dataOptions = {
        altFormat: "Y-m-d",
        disableMobile: "true",
        altInput: "true",
        locale: Russian,
        monthSelectorType: "static",
    }

    const handleFilterDate = async ():Promise<void> => {
        let query

        query = {
            from: encodeURIComponent(data.startDate),
            till: encodeURIComponent(data.endDate),
        }

        await onFilter(data.type.visitorsType, params.eventId, query);
        await onFilter(data.type.salesType, params.eventId, query);
    }

    const handleChangeDate = (selectedDates, dateStr, instance, key) => {
        let selected

        if(key === 'endDate') {
            selected = convert(new Date(`${dateStr} 23:59`), 'Europe/Moscow');
        } else {
            selected = convert(new Date(dateStr), 'Europe/Moscow');
        }

        onChange(prev => ({...prev, [key]: selected}));
    }


    return (
        <div className={styles.container}>
            <h6 className={styles.label}>ДАТА С</h6>
                <div className={styles.picker}>
                    <Flatpickr
                        value={data.startDate}
                        options={dataOptions}
                        onChange={(selectedDates, dateStr, instance) => handleChangeDate(selectedDates, dateStr, instance, 'startDate')}
                        render={({value, ...props}, ref) => {
                            return (
                                <div className={styles.input}>
                                    <Input
                                        handleChange={(e) => {
                                            console.log(e.target.value)
                                        }}
                                        label={"C"}
                                        size={"medium"}
                                        required={true}
                                        inputRef={ref}
                                        value={value}
                                    />
                                </div>
                            );
                        }}
                    />
                </div>
                <h6 className={styles.label}>ПО</h6>
                <div className={styles.picker}>
                    <Flatpickr
                        value={data.endDate}
                        options={dataOptions}
                        onChange={(selectedDates, dateStr, instance) => handleChangeDate(selectedDates, dateStr, instance, 'endDate')}
                        render={({value, ...props}, ref) => {
                            return (
                                <div className={styles.input}>
                                    <Input
                                        handleChange={(e) => {
                                            console.log(e.target.value)
                                        }}
                                        label={"По"}
                                        size={"medium"}
                                        required={true}
                                        inputRef={ref}
                                        value={value}
                                    />
                                </div>
                            );
                        }}
                    />
                </div>
                <button
                        className={'cp-btn cp-btn_size_medium cp-scheme_secondary-default'}
                        onClick={handleFilterDate}
                    >
                        Применить
                </button>
        </div>
    );
}

export default ChartsDataPicker;
