import React, {useState} from 'react';
import modal from '../Modal.module.scss'
import styles from './Datetime.module.scss'
import Radio from "../../../../../../../UIKit/component/radio/radio";
import dateTimeDataJson from './Datetime.data.json'
import NotifyAt from "./NotifyAt/NotifyAt";
import Period from "./Period/Period";
import {useSelector} from "react-redux";

const Datetime = ({data, periods, onSubmit, onCancel}) => {
    const selector = useSelector(state => state)
    const event = selector.admin.event

    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({
        ...dateTimeDataJson,
        tab: data.schedule.datetime && data.attributes.notify_at ? 1 : 0,
        fields: {
            ...dateTimeDataJson.fields,
            period: {...dateTimeDataJson.fields.period, value: data.attributes.period || null, variants: periods},
            notify_at: {...dateTimeDataJson.fields.notify_at, value: data.attributes.notify_at || null},
        }
    })

    const handleSubmit = async () => {
        let response

        setLoading(true)
        response = await onSubmit({
            ...data,
            attributes: {
                ...data.attributes,
                period: form.tab === 0 ? form.fields.period.value : null,
                notify_at: form.tab === 1 ? form.fields.notify_at.value : null
            }
        })

        if (response?.status === 422) {
            handleValidateForm(response.data.errors)
            return
        }

        if (response?.id) {
            showTopMessage('success', 'small', 'Операция выполнена успешно')
        }
    }

    const handleValidateForm = (errors) => {
        let data = {}

        const pointer = (x) => x.source.pointer.replace('/data/attributes/', '')
        errors.map(x => data[pointer(x)] = {...form.fields[pointer(x)], error: x.detail})

        setLoading(false)
        setForm(prev => ({...prev, fields: {...prev.fields, ...data}}))
    }

    return (
        <div className={modal.container}>
            <div className={[modal.header, styles.tabs].filter(x => x ?? x).join(' ')}>
                {data.schedule.periods ? <div>
                    <Radio
                        size={'medium'}
                        label={'Время по умолчанию'}
                        checked={form.tab === 0}
                        required={false}
                        disabled={!data.schedule.periods}
                        onChange={() => setForm(prev => ({...prev, tab: 0}))}
                    />
                </div> : null}
                {data.schedule.datetime ? <div>
                    <Radio
                        size={'medium'}
                        label={'Выбрать в календаре'}
                        checked={form.tab === 1}
                        required={false}
                        disabled={!data.schedule.datetime}
                        onChange={() => setForm(prev => ({...prev, tab: 1}))}
                    />
                </div> : null}
            </div>
            <div className={modal.body}>
                {data.schedule.periods && form.tab === 0 ? <Period
                    data={form} onChange={setForm}
                /> : null}
                {data.schedule.datetime && form.tab === 1 ? <NotifyAt
                    data={form} onChange={setForm} timezone={event.timezone.timezone}
                /> : null}
            </div>
            <div className={modal.footer}>
                <button
                    className={'a-btn a-btn_size_small a-btn_type_tertiary-ghost'}
                    onClick={onCancel}>
                    Отмена
                </button>
                <button
                    className={'a-btn a-btn_size_small a-btn_type_primary-default'}
                    disabled={loading}
                    onClick={handleSubmit}>
                    Сохранить
                </button>
            </div>
        </div>
    );
};

export default Datetime;