import React, {useState, useEffect} from "react";
import "../style/Editor.scss";
import IconEdit from "../../../../../assets/images/icons/16/pencil-blue.svg";
import IconSave from "../../../../../assets/images/icons/16/icon_check.svg";
import IconClose from "../../../../../assets/images/icons/16/x.svg";
import IconSeat from "../../../../../assets/images/icons/16/seatBtn.svg";
import IconPlace from "../../../../../assets/images/icons/16/place.svg";
import IconTable from "../../../../../assets/images/icons/16/table.svg";
import IconPeople from "../../../../../assets/images/icons/16/people.svg";
import IconScene from "../../../../../assets/images/icons/16/scene.svg";
import IconArrowLeft from "../../../../../assets/images/icons/16/arrow-left.svg";
import IconArrowRight from "../../../../../assets/images/icons/16/arrow-right.svg";
import IconArrowUp from "../../../../../assets/images/icons/16/arrow-up.svg";
import IconArrowDown from "../../../../../assets/images/icons/16/arrow-down.svg";
import IconTrash from "../../../../../assets/images/icons/16/trashGray.svg";
import IconHande from "../../../../../assets/images/icons/16/hande.svg";
import IconZoomPlus from "../../../../../assets/images/icons/16/zoomP.svg";
import IconZoomMinus from "../../../../../assets/images/icons/16/zoomM.svg";
import IconMirror from "../../../../../assets/images/icons/16/options.svg";
import SwapRender from "../../../../../assets/images/icons/16/rocket.svg";
import Button from "../../../../../components/ui/Button";
import DesignerSelectGroup from "./components/DesignerSelectGroup";
import ReactTooltip from "react-tooltip";
import {useDispatch, useSelector} from "react-redux";
import RadioButton from "@/UIKit/component/radio/radio";
import Checkbox from "@/UIKit/component/checkbox/Checkbox";
import Input from "@/components/ui/Input";

const ToolbarDesigner = (props) => {
        const {
            titleFloor,
            setTitleFloor,
            modeEditor,
            selectedNodes,
            setSelectedNodes,
            createNewPlace,
            createNewScene,
            createNewTable,
            createNewFreeZone,
            handleMoveTo,
            handleDelete,
            handleModeEditor,
            handleScale,
            groupList,
            setGroupList,
            activeGroup,
            setActiveGroup,
            setPlace,
            place,
            setChangeMade,
            handleNumbering,
            handleSetNewRender,
            errorNumbering,
            setErrorNumbering
        } = props;

        const [editTitleFloor, setEditTitleFloor] = useState({
            display: false,
            val: "",
        });
        const [mirrorModal, setMirrorModal] = useState(false);
        const [node, setNode] = useState(null);
        const dispatch = useDispatch();
        const noticeData = useSelector((state) => state.notice);

        const [numberingRules, setNumberingRules] = useState({
            placeLeft: true,
            placeNumber: 1,
            rowTop: true,
            rowNumber: 1,
        })

        const [rowError, setRowError] = useState('')
        const [numberError, setNumberError] = useState('')

        useEffect(() => {
            if (selectedNodes.length === 1) {
                setNode(selectedNodes[0]);
            }
        }, [selectedNodes]);

        const handleSaveTitle = () => {
            if (
                editTitleFloor.val.length &&
                editTitleFloor.val.replace(/ /g, "").length === 0
            ) {
                dispatch({
                    type: "SET_NOTICE",
                    payload: {
                        ...noticeData,
                        open: true,
                        type: "error",
                        message: "Название зала обязательно для заполнения",
                    },
                });
            } else {
                setEditTitleFloor({display: false, val: ""});
                setTitleFloor(editTitleFloor.val);
            }
        };

        const getNameInput = (type) => {
            // название фигуры
            if (selectedNodes.length === 1) {
                // если выбрана 1 свободная зона
                if (
                    selectedNodes[0].type === "freeZone" ||
                    selectedNodes[0].type === "table"
                ) {
                    return selectedNodes[0].custom.name !== null
                        ? selectedNodes[0].custom.name
                        : selectedNodes[0].group.name;
                }
            }

            return "-";
        };


        const getValueRow = () => {
            let value = ''
            let isPlace = true
            let arrValue = []
            if (selectedNodes.length === 1 && selectedNodes[0].type === 'place') {
                if (selectedNodes[0].custom.row) return selectedNodes[0].custom.row
            }

            selectedNodes.forEach((item) => {
                if (item.type !== "place") {
                    isPlace = false;
                }
                if (item.custom.row) {
                    arrValue.push(item.custom.row)
                }
            });

            let uniqueArr = Array.from(new Set(arrValue));
            if (uniqueArr.length === 1) {
                value = uniqueArr[0]
            }
            return value
        }

        const getValueColumn = () => {
            let value = ''
            let isPlace = true
            let arrValue = []
            if (selectedNodes.length === 1 && selectedNodes[0].type === 'place') {
                if (selectedNodes[0].custom.column) return selectedNodes[0].custom.column
            }

            selectedNodes.forEach((item) => {
                if (item.type !== "place") {
                    isPlace = false;
                }
                if (item.custom.row) {
                    arrValue.push(item.custom.column)
                }
            });

            let uniqueArr = Array.from(new Set(arrValue));
            if (uniqueArr.length === 1) {
                value = uniqueArr[0]
            }
            return value
        }

        const thisNodePlace = (item) => {
            // поиск места сохранения
            let result = false;
            for (let i = 0; i < selectedNodes.length; i++) {
                if (
                    selectedNodes[i].row === item.row &&
                    selectedNodes[i].column === item.column
                ) {
                    result = true;
                    continue;
                }
            }
            return result;
        };

        const thisNodePolygon = (item) => {
            // поиск зоны сохранения
            let result = false;
            for (let i = 0; i < selectedNodes.length; i++) {
                if (
                    selectedNodes[i].type === "freeZone" ||
                    selectedNodes[i].type === "table"
                ) {
                    if (
                        selectedNodes[i].coords.start.row === item.coords.start.row &&
                        selectedNodes[i].coords.start.column === item.coords.start.column
                    ) {
                        result = true;
                        continue;
                    }
                }
            }
            return result;
        };

        const handleSetCustomRow = (value) => {
            // сохранение ряда
            let isValid = value >= 1 || value === ''
            if (!isValid) {
                return
            }
            setChangeMade(true);
            setSelectedNodes(
                selectedNodes.map((item) => {
                    item.custom.row = value;
                    return item;
                })
            );

            setPlace(
                place.map((item) => {
                    if (thisNodePlace(item)) setDataPlace(item, "row", value);
                    return item;
                })
            );
        };

        const handleSetCustomColumn = (value) => {
            // сохранение номеров мест
            let isValid = value >= 1 || value === ''
            if (!isValid) {
                return
            }
            setChangeMade(true);
            setSelectedNodes(
                selectedNodes.map((item) => {
                    item.custom.column = value.slice(0, 5);
                    return item;
                })
            );

            setPlace(
                place.map((item) => {
                    if (thisNodePlace(item))
                        setDataPlace(item, "column", value.slice(0, 5));
                    return item;
                })
            );
        };

        const setDataPlace = (item, type, value) => {
            if (type === "row") {
                item.custom.row = value;
                item.error = false;
            }
            if (type === "column") {
                item.custom.column = value;
                item.error = false;
            }
        };

        const handleSetCustomName = (value) => {
            // Название
            setChangeMade(true);
            if (value.length >= 64) {
                return;
            }
            let row = selectedNodes[0].coords.start.row,
                column = selectedNodes[0].coords.start.column;
            setSelectedNodes(
                selectedNodes.map((item) => {
                    item.custom = {row: null, column: null, name: value};
                    return item;
                })
            );

            setPlace(
                place.map((item) => {
                    item.coords &&
                    item.coords.start.row === row &&
                    item.coords.start.column === column
                        ? (item.custom = {
                            row: null,
                            column: null,
                            name: value,
                        })
                        : "";
                    return item;
                })
            );
        };

        const preTypePlace = () => {
            for (let i = 0; i < selectedNodes.length; i++) {
                if (selectedNodes[i].type !== "place") return false;
            }
            return true;
        };

        const handTitle = (val) => {
            let name = val;
            if (name.length && name.replace(/ /g, "").length === 0) {
                //  setNameError('Название зала обязательно для заполнения')
            }
            if (name.length >= 255) {
                //setNameError('Длина названия не должна превышать 255 символов')
                name.slice(0, 255);
            } else {
                setEditTitleFloor({
                    ...editTitleFloor,
                    val: name,
                });
            }
        };

        const handleModalMirror = () => {
            setErrorNumbering(false)
            setMirrorModal(!mirrorModal);
        };


        const handleSaveNumbering = () => {
            let err = false;

            if (!isValidNumber(numberingRules.placeNumber)) {
                setNumberError('Ошибка')
                err = true
            }

            if (!isValidNumber(numberingRules.rowNumber)) {
                setRowError('Ошибка')
                err = true
            }
            if (err) return
            setMirrorModal(!mirrorModal);
            handleNumbering(numberingRules);
        };

        const isValidNumber = (number) => {
            return number >= 1 && !isNaN(number);
        };


        const renderModalTitle = () => {
            if (selectedNodes.length) {
                return `Нумерация выбранных мест ${selectedNodes.length}`
            } else {
                return 'Нумерация всех мест'
            }
        }

        const handleRules = (field, value) => {
            if (field === 'rowNumber') setRowError('')
            if (field === 'placeNumber') setNumberError('')
            setNumberingRules({...numberingRules, [field]: value})
        }

        return (
            <>
                <div
                    style={{
                        width: "100%",
                        background: "#ffffff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "0px 2px 8px rgba(114, 128, 170, 0.2)",
                        position: "fixed",
                        zIndex: 1,
                    }}
                >
                    <div className={"toolbar"}>
                        <div className={"toolbar__item toolbar__title"}>
                            {editTitleFloor.display && (
                                <>
                                    <input
                                        value={editTitleFloor.val}
                                        error={"dsfdsfds"}
                                        onChange={(e) => handTitle(e.target.value)}
                                    />
                                    <button
                                        className={
                                            "a-btn a-btn_size_small a-btn_type_tertiary-default"
                                        }
                                        onClick={() => setEditTitleFloor({display: false, val: ""})}
                                    >
                                        <IconClose/>
                                    </button>
                                    <button
                                        className={
                                            "a-btn a-btn_size_small a-btn_type_primary-default"
                                        }
                                        onClick={() => handleSaveTitle()}
                                    >
                                        <IconSave/>
                                    </button>
                                </>
                            )}
                            {!editTitleFloor.display && (
                                <>
                                    <div className="box">
                  <span>
                    {titleFloor.length > 45
                        ? `${titleFloor.slice(0, 45)}...`
                        : titleFloor}
                  </span>
                                    </div>
                                    <button
                                        data-tip="React-tooltip"
                                        data-for="toolTipTitle"
                                        className="a-btn_type_secondary-ghost a-btn_size_small"
                                        onClick={() =>
                                            setEditTitleFloor({display: true, val: titleFloor})
                                        }
                                    >
                                        <IconEdit/>
                                    </button>
                                    <ReactTooltip
                                        id="toolTipTitle"
                                        place="top"
                                        type="dark"
                                        effect="float"
                                        className="reactTooltip"
                                    >
                                        <span style={{color: "#fff"}}>Изменить название схемы</span>
                                    </ReactTooltip>
                                </>
                            )}
                        </div>
                        <div className="toolbar__item toolbar__group">
                            <DesignerSelectGroup
                                groupList={groupList}
                                selectedNodes={selectedNodes}
                                setSelectedNodes={setSelectedNodes}
                                setGroupList={setGroupList}
                                activeGroup={activeGroup}
                                setActiveGroup={setActiveGroup}
                                setPlace={setPlace}
                                place={place}
                                thisNodePlace={thisNodePlace}
                                thisNodePolygon={thisNodePolygon}
                                setChangeMade={setChangeMade}
                            />
                            <ReactTooltip
                                id="toolTipSelectGroup"
                                place="top"
                                type="dark"
                                effect="float"
                                className="reactTooltip"
                            >
                                <span>Выбрать группу</span>
                            </ReactTooltip>
                        </div>
                        <div className={"toolbar__item toolbar__row"}>
                            <div className="toolbar__action">
                                <button
                                    data-tip="React-tooltip"
                                    data-for="toolTipPlace"
                                    className="a-btn a-btn_size_small a-btn_type_secondary-ghost"
                                    onClick={() => createNewPlace()}
                                >
                                    <IconSeat/>
                                </button>
                                <ReactTooltip
                                    place="top"
                                    type="dark"
                                    id="toolTipPlace"
                                    effect="float"
                                    className="reactTooltip"
                                >
                                    <span>Добавить места</span>
                                </ReactTooltip>
                            </div>
                            <div className="toolbar__action">
                                <button
                                    data-tip="React-tooltip"
                                    data-for="toolTipFreeZone"
                                    className="a-btn a-btn_size_small a-btn_type_secondary-ghost"
                                    onClick={() => createNewFreeZone()}
                                >
                                    <IconPeople/>
                                </button>
                                <ReactTooltip
                                    id="toolTipFreeZone"
                                    place="top"
                                    type="dark"
                                    effect="float"
                                    className="reactTooltip"
                                >
                                    <span>Добавить зону без нумерации</span>
                                </ReactTooltip>
                            </div>
                            <div className="toolbar__action">
                                <button
                                    data-tip="React-tooltip"
                                    data-for="toolTipTable"
                                    className="a-btn a-btn_size_small a-btn_type_secondary-ghost"
                                    onClick={() => createNewTable()}
                                >
                                    <IconTable/>
                                </button>
                                <ReactTooltip
                                    id="toolTipTable"
                                    place="top"
                                    type="dark"
                                    effect="float"
                                    className="reactTooltip"
                                >
                                    <span>Добавить стол</span>
                                </ReactTooltip>
                            </div>
                            <div className="toolbar__action">
                                <button
                                    data-tip="React-tooltip"
                                    data-for="toolTipScene"
                                    className="a-btn a-btn_size_small a-btn_type_secondary-ghost"
                                    onClick={() => createNewScene()}
                                >
                                    <IconScene/>
                                </button>
                                <ReactTooltip
                                    id="toolTipScene"
                                    place="top"
                                    type="dark"
                                    effect="float"
                                    className="reactTooltip"
                                >
                                    <span>Добавить сцену</span>
                                </ReactTooltip>
                            </div>
                            <div className="toolbar__action">
                                <button
                                    data-tip="React-tooltip"
                                    data-for="toolTipMirror"
                                    className="a-btn a-btn_size_small a-btn_type_primary-outline"
                                    onClick={() => handleModalMirror()}
                                >
                                    <IconMirror/>
                                </button>
                                <ReactTooltip
                                    id="toolTipMirror"
                                    place="top"
                                    type="dark"
                                    effect="float"
                                    className="reactTooltip"
                                >
                                    <span>Нумерация мест</span>
                                </ReactTooltip>
                                {errorNumbering && <div className={"cusotom_toggletip__container"}>
                                    <div
                                        className="e-action__toggletip e-action__toggletip_position_bottom cusotom_toggletip__message">
                                        Требуется нумерация мест
                                    </div>
                                </div>}
                            </div>
                        </div>
                        <div className={"toolbar__item toolbar__row"}>
                            <div className="toolbar__action">
                                <button
                                    data-tip="React-tooltip"
                                    data-for="toolTipLeft"
                                    className="a-btn a-btn_size_small a-btn_type_secondary-ghost"
                                    onClick={() => handleMoveTo("left")}
                                >
                                    <IconArrowLeft/>
                                </button>
                                <ReactTooltip
                                    id="toolTipLeft"
                                    place="top"
                                    type="dark"
                                    effect="float"
                                    className="reactTooltip"
                                >
                                    <span>Переместить влево</span>
                                </ReactTooltip>
                            </div>
                            <div className="toolbar__action">
                                <button
                                    data-tip="React-tooltip"
                                    data-for="toolTipRight"
                                    className="a-btn a-btn_size_small a-btn_type_secondary-ghost"
                                    onClick={() => handleMoveTo("right")}
                                >
                                    <IconArrowRight/>
                                </button>
                                <ReactTooltip
                                    id="toolTipRight"
                                    place="top"
                                    type="dark"
                                    effect="float"
                                    className="reactTooltip"
                                >
                                    <span>Переместить вправо</span>
                                </ReactTooltip>
                            </div>
                            <div className="toolbar__action">
                                <button
                                    data-tip="React-tooltip"
                                    data-for="toolTipUp"
                                    className="a-btn a-btn_size_small a-btn_type_secondary-ghost"
                                    onClick={() => handleMoveTo("up")}
                                >
                                    <IconArrowUp/>
                                </button>
                                <ReactTooltip
                                    id="toolTipUp"
                                    place="top"
                                    type="dark"
                                    effect="float"
                                    className="reactTooltip"
                                >
                                    <span>Переместить вверх</span>
                                </ReactTooltip>
                            </div>
                            <div className="toolbar__action">
                                <button
                                    data-tip="React-tooltip"
                                    data-for="toolTipDown"
                                    className="a-btn a-btn_size_small a-btn_type_secondary-ghost"
                                    onClick={() => handleMoveTo("down")}
                                >
                                    <IconArrowDown/>
                                </button>
                                <ReactTooltip
                                    id="toolTipDown"
                                    place="top"
                                    type="dark"
                                    effect="float"
                                    className="reactTooltip"
                                >
                                    <span>Переместить вниз</span>
                                </ReactTooltip>
                            </div>
                            <div className="toolbar__action">
                                <button
                                    data-tip="React-tooltip"
                                    data-for="toolTipDelete"
                                    className="a-btn a-btn_size_small a-btn_type_secondary-ghost"
                                    onClick={() => handleDelete()}
                                >
                                    <IconTrash/>
                                </button>
                                <ReactTooltip
                                    id="toolTipDelete"
                                    place="top"
                                    type="dark"
                                    effect="float"
                                    className="reactTooltip"
                                >
                                    <span>Удалить</span>
                                </ReactTooltip>
                            </div>
                        </div>
                        <div className={"toolbar__item toolbar__row"}>
                            <div className="toolbar__action">
                                <button
                                    data-tip="React-tooltip"
                                    data-for="toolTipHande"
                                    className={
                                        modeEditor === "hande"
                                            ? "a-btn a-btn_size_small a-btn_type_primary-default"
                                            : "a-btn a-btn_size_small a-btn_type_secondary-ghost"
                                    }
                                    onClick={() => handleModeEditor("hande")}
                                >
                                    <IconHande/>
                                </button>
                                <ReactTooltip
                                    id="toolTipHande"
                                    place="top"
                                    type="dark"
                                    effect="float"
                                    className="reactTooltip"
                                >
                                    <span>Включить прокрутку</span>
                                </ReactTooltip>
                            </div>
                            <div className="toolbar__action">
                                <button
                                    data-tip="React-tooltip"
                                    data-for="toolTipZoomIn"
                                    className="a-btn a-btn_size_small a-btn_type_secondary-ghost"
                                    onClick={() => handleScale("+")}
                                >
                                    <IconZoomPlus/>
                                </button>
                                <ReactTooltip
                                    id="toolTipZoomIn"
                                    place="top"
                                    type="dark"
                                    effect="float"
                                    className="reactTooltip"
                                >
                                    <span>Увеличить масштаб</span>
                                </ReactTooltip>
                            </div>
                            <div className="toolbar__action">
                                <button
                                    data-tip="React-tooltip"
                                    data-for="toolTipZoomOut"
                                    className="a-btn a-btn_size_small a-btn_type_secondary-ghost"
                                    onClick={() => handleScale("-")}
                                >
                                    <IconZoomMinus/>
                                </button>
                                <ReactTooltip
                                    id="toolTipZoomOut"
                                    place="top"
                                    type="dark"
                                    effect="float"
                                    className="reactTooltip"
                                >
                                    <span>Уменьшить масштаб</span>
                                </ReactTooltip>
                            </div>
                            {/*<div className="toolbar__action">*/}
                            {/*    <button*/}
                            {/*        data-tip="React-tooltip"*/}
                            {/*        data-for="toolTipRender"*/}
                            {/*        className="a-btn a-btn_size_small a-btn_type_secondary-ghost"*/}
                            {/*        onClick={() => handleSetNewRender()}*/}
                            {/*    >*/}
                            {/*        <SwapRender/>*/}
                            {/*    </button>*/}
                            {/*    <ReactTooltip*/}
                            {/*        id="toolTipRender"*/}
                            {/*        place="top"*/}
                            {/*        type="dark"*/}
                            {/*        effect="float"*/}
                            {/*        className="reactTooltip"*/}
                            {/*    >*/}
                            {/*        <span>Смена рендера</span>*/}
                            {/*    </ReactTooltip>*/}
                            {/*</div>*/}
                        </div>
                        <div className={"toolbar__visible"}>
                            {selectedNodes.length > 0 && preTypePlace() && (
                                <div className={"toolbar__item toolbar__row toolbar__seats"}>
                                    <div className={"toolbar__input-control"}>
                                        Ряд{" "}
                                        <input
                                            onChange={(e) => handleSetCustomRow(e.target.value)}
                                            value={getValueRow()}
                                        />
                                    </div>
                                    <div className={"toolbar__input-control"}>
                                        Место
                                        <input
                                            onChange={(e) => handleSetCustomColumn(e.target.value)}
                                            value={getValueColumn()}
                                        />
                                    </div>
                                </div>
                            )}
                            {selectedNodes.length > 0 && selectedNodes[0].type === "freeZone" && (
                                <div className={"toolbar__item toolbar__row toolbar__seats"}>
                                    <div
                                        className={
                                            "toolbar__input-control toolbar__input-control-name"
                                        }
                                    >
                                        Название{" "}
                                        <input
                                            disabled={selectedNodes.length !== 1}
                                            onChange={(e) => handleSetCustomName(e.target.value)}
                                            value={getNameInput()}
                                        />
                                    </div>
                                </div>
                            )}
                            {selectedNodes.length > 0 && selectedNodes[0].type === "table" && (
                                <div className={"toolbar__item toolbar__row toolbar__seats"}>
                                    <div
                                        className={
                                            "toolbar__input-control toolbar__input-control-name"
                                        }
                                    >
                                        Название{" "}
                                        <input
                                            disabled={selectedNodes.length !== 1}
                                            onChange={(e) => handleSetCustomName(e.target.value)}
                                            value={getNameInput()}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {mirrorModal && (
                    <div className="modal-react">
                        <div className={`modal-react__window`}>
                            <div className={"modal-react__container"}>
                                <div className={"modal-react__container__head"}>
                                    <div className={"modal-react__container__icon"}>
                                        <IconPlace/>
                                    </div>
                                    <div className={"modal-react__container__title"}>
                                        {renderModalTitle()}
                                    </div>
                                    <div
                                        className={"modal-react__container__close"}
                                        onClick={() => {
                                            handleModalMirror();
                                        }}
                                    >
                                        <IconClose/>
                                    </div>
                                </div>
                                <div className={"modal-react__container__settings"}>
                                    <div className="radio-list-column">
                                        <h2>Места</h2>
                                        <div className={"radio-list-row"}>
                                            <RadioButton
                                                key={'1'}
                                                id={'placeLeft'}
                                                size={"medium"}
                                                disabled={false}
                                                label={"Слева направо"}
                                                checked={numberingRules.placeLeft}
                                                onChange={() => handleRules('placeLeft', true)}
                                            />
                                            <RadioButton
                                                key={'2'}
                                                id={'!placeLeft'}
                                                size={"medium"}
                                                disabled={false}
                                                label={"Справа налево"}
                                                checked={!numberingRules.placeLeft}
                                                onChange={() => handleRules('placeLeft', false)}/>
                                        </div>
                                        <div className={'row-number-input'}>
                                            Начать нумерацию с места
                                            <Input
                                                type={"number"}
                                                label={''}
                                                error={numberError}
                                                outside={1}
                                                size={'small'}
                                                value={numberingRules.placeNumber}
                                                handleChange={({target}) => handleRules('placeNumber', Number(target.value))}
                                            />
                                        </div>
                                        <h2>Ряды</h2>
                                        <div className={"radio-list-row"}>
                                            <RadioButton
                                                key={'3'}
                                                id={'rowTop'}
                                                size={"medium"}
                                                disabled={false}
                                                label={"Сверху вниз"}
                                                checked={numberingRules.rowTop}
                                                onChange={() => handleRules('rowTop', true)}
                                            />
                                            <RadioButton
                                                key={'4'}
                                                id={'rowTop'}
                                                size={"medium"}
                                                disabled={false}
                                                label={"Снизу вверх"}
                                                checked={!numberingRules.rowTop}
                                                onChange={() => handleRules('rowTop', false)}
                                            />
                                        </div>
                                        <div className={'row-number-input'}>Начать нумерацию с ряда
                                            <Input
                                                type={"number"}
                                                error={rowError}
                                                outside={1}
                                                size={'small'}
                                                value={numberingRules.rowNumber}
                                                handleChange={({target}) => handleRules('rowNumber', Number(target.value))}

                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={"modal-react__container__bottom"}>
                                    <div className="actions">
                                        <Button
                                            onClick={() => {
                                                handleModalMirror();
                                            }}
                                            value="Отмена"
                                            size={"small"}
                                            scheme={"tertiary-ghost"}
                                        />

                                        <Button
                                            onClick={() => handleSaveNumbering()}
                                            value="Пронумеровать"
                                            size={"small"}
                                            scheme={"primary"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
;

export default ToolbarDesigner;
