import React, {useEffect, useRef} from 'react';
import CalendarIcon from "../../assets/images/icons/16/calendar.svg";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import {replaceTimezoneInIso, toIsoString} from "../../utils/toIsoString";

const SessionDate = (props) => {
    const flatpickr = useRef()

    useEffect(() => {
        let result = [], match = false
        for (let i in props.dates) {
            result.push(new Date(Date.parse(props.dates[i].value)))

            let currDate = new Date(Date.parse(props.dates[i].value)),
                fromDate = new Date(Date.parse(props.from))

            match = match || (props.dates[i].value === props.selected && currDate.getMonth() === fromDate.getMonth())
        }
        dataCalendar.current.instance.set('enable', result)
        if (match) {
            dataCalendar.current.instance.setDate(props.selected)
            dataCalendar.current.instance.jumpToDate(props.selected)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dates]);

    const handleChangeDate = (selectedDates) => {
        let value = replaceTimezoneInIso(toIsoString(selectedDates[0]))
        props.onChange(value)
    }
    const handleChangeFrom = (selectedDates, dateStr, instance) => {
        let date = new Date()
        date.setFullYear(instance.currentYear, instance.currentMonth, 1)
        date.setHours(0, 0, 0)

        let value = replaceTimezoneInIso(toIsoString(date))
        props.onChangeFrom(value)
    }

    const dataCalendar = useRef({
        // date: selector.form.data.schedules.selectedDates,
        year: null,
        month: null,
        from: null,
        instance: {},
        init: false,
        schedulesStore: {
            dates: [],
            sessions: [],
            times: []
        },
        options:
            {
                enableTime: false,
                dateFormat: 'Y-m-d',
                altFormat: "d.m.Y",
                locale: Russian,
                monthSelectorType: "static",
                enable: [],
            }
    })

    const calendarInit = (selectedDates, dateStr, instance) => {
        dataCalendar.current.instance = instance
    }

    const calendarOpen = () => {
    }

    return (
        <div>
            <div className={'bottom-bar__section-calendar--icon'}>
                <CalendarIcon/>
            </div>
            <div style={{width: '100%'}}>
                <Flatpickr
                    ref={flatpickr}
                    onReady={calendarInit}
                    onOpen={calendarOpen}
                    options={dataCalendar.current.options}
                    onChange={handleChangeDate}
                    onYearChange={handleChangeFrom}
                    onMonthChange={handleChangeFrom}
                />
            </div>
        </div>
    );
};

export default SessionDate;