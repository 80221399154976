import {API} from "../../../../api/API";
import {postData} from "../../../../utils/sendData";
import JSONAPIDeserializer from "jsonapi-serializer/lib/deserializer";

export const methodGetDomainEmails = async (event_id, is_course) => {
    let response, url, method, deserialized

    url = API.getDomainEmails.path(event_id, is_course)
    method = API.getDomainEmails.method

    response = await postData({}, url, method)
    deserialized = await deserializedData(response.data)

    return deserialized
}

export const methodGetAdditionalEmails = async (event_id, is_course) => {
    let response, url, method, deserialized

    url = API.getAdditionalEmails.path(event_id, is_course)
    method = API.getAdditionalEmails.method

    response = await postData({}, url, method)
    deserialized = await deserializedData(response.data)

    return deserialized
}

export const methodGetNotificationPage = async (event_id, is_course) => {
    let response, url, method

    url = API.getNotificationList.path(event_id)
    method = API.getNotificationList.method

    response = await postData({}, url, method)

    return response
}

const deserializedData = async (data) => new JSONAPIDeserializer({
    keyForAttribute: 'underscore_case',
}).deserialize(JSON.parse(JSON.stringify(data)), (err, deserializeData) => deserializeData);
