import {API} from "../../../../../api/API";
import {postData} from "../../../../../utils/sendData";
import JSONAPIDeserializer from "jsonapi-serializer/lib/deserializer";
import JSONAPISerializer from "jsonapi-serializer/lib/serializer";

export const methodGetConfirmed = async (additional_email_id, query) => {
    let response, url, method, deserialized

    url = API.createOwnerNotification.path(additional_email_id, query)
    method = 'GET'

    response = await postData(null, url, method)
    deserialized = await deserializedData(response.data)

    return deserialized
}

export const methodGetSubscribe = async (event_id, type) => {
    let response, url, method, deserialized

    url = API.getOwnersNotification.path(event_id, type === 'events' ? 'events' : 'courses')
    method = API.getOwnersNotification.method

    response = await postData(null, url, method)
    deserialized = await deserializedData(response.data)

    return deserialized
}

export const methodCreateEmail = async (params, event_id, is_course) => {
    let response, url, method, serialized, deserialized, config, body

    config = {
        keyForAttribute: 'underscore_case',
        typeForAttribute: (attribute, data) => data.customType,
        event: {ref: 'id', included: false},
        attributes: ['event', 'email'],
    }
    body = {...params, event: {id: event_id, customType: is_course ? 'courses' : 'events'}};
    serialized = new JSONAPISerializer('additional-emails', config).serialize(body);

    url = API.createAdditionalEmails.path()
    method = API.createAdditionalEmails.method

    response = await postData(serialized, url, method)
    deserialized = await deserializedData(response.data)

    return deserialized
}

export const methodCreateSubscribe = async (params, event_id, is_course) => {
    let response, url, method, serialized, deserialized, config, body

    config = {
        keyForAttribute: 'underscore_case',
        typeForAttribute: (attribute, data) => data.customType,
        event: {ref: 'id', included: false},
        attributes: ['event_id', 'additional_email_id', 'types'],
    }
    body = {...params, event: {id: event_id, customType: is_course ? 'courses' : 'events'}};
    serialized = new JSONAPISerializer('additional-email-events', config).serialize(body);

    url = API.subscribeOwnerNotification.path('')
    method = API.subscribeOwnerNotification.method

    response = await postData(serialized, url, method)
    deserialized = await deserializedData(response.data)

    return deserialized
}

export const methodUpdateSubscribe = async (params, event_id, is_course) => {
    let response, url, method, serialized, deserialized, config, body

    config = {
        keyForAttribute: 'underscore_case',
        typeForAttribute: (attribute, data) => data.customType,
        resource: {ref: 'id', included: false},
        attributes: ['resource', 'event_id', 'additional_email_id', 'types'],
    }
    body = {...params};
    serialized = new JSONAPISerializer('additional-email-events', config).serialize(body);

    url = API.updateSubscribeOwnerNotification.path(params.id)
    method = API.updateSubscribeOwnerNotification.method

    response = await postData(serialized, url, method)
    deserialized = await deserializedData(response.data)

    return deserialized
}

export const methodRemoveSubscribe = async (params) => {
    let response, url, method

    url = API.updateSubscribeOwnerNotification.path(params.id)
    method = 'DELETE'
    response = await postData({}, url, method)

    return response
}

export const methodRemoveEmail = async (params) => {
    let response, url, method

    url = API.createOwnerNotification.path(params.id)
    method = 'DELETE'
    response = await postData({}, url, method)

    return response
}

const deserializedData = async (data) => new JSONAPIDeserializer({
    keyForAttribute: 'underscore_case',
}).deserialize(JSON.parse(JSON.stringify(data)), (err, deserializeData) => deserializeData);
