import React, {FC, useState} from 'react';
import styles from './LineChart.module.scss';
import {Line} from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {LineChartPropsInterface} from './LineChart.interface';
import {Collapse} from 'react-collapse';
import {lineChartData} from "./LineChart.schema";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const LineChart: FC<LineChartPropsInterface> = ({data, type}) => {
    const [chartData, setChartData] = useState(lineChartData);

    let legendItem = [];
    let keyValues = {};

    let dateOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timezone: 'UTC+3'
    };

    const handleTransformDates = (dates: Object) => {
        let obj = {};
        if (type === 'visitors' || type === 'sales') {
            Object.entries(dates).map(([key, value], index) => {
                key = new Date(key).toLocaleString("ru", dateOptions as any);
                obj[key] = value;
                keyValues[index] = key;
            })
        } else {
            Object.entries(dates).map(([key, value], index) => {
                const labelName = `${index + 1} урок`;
                legendItem.push(({'name': labelName, 'value': key}));
                obj[labelName] = value;
            })
        }
        return obj;
    }

    const handleRenderLabels = (value: number, index: number) => {
        let dateLength = Object.keys(keyValues).length;
        if (dateLength <= 25) {
            return keyValues[value];
        }
        if (dateLength <= 63) {
            return ((index) % 3 === 0) ?  keyValues[value] :  '';
        }
        if (dateLength <= 300) {
            return ((index) % 9 === 0) ?  keyValues[value] :  '';
        }
        return ((index) % 12 === 0) ?  keyValues[value] :  '';
    };

    const chartDataset = data.charts.map((item) => ({
        label: item.name,
        data: handleTransformDates(item.values),
        borderColor: item.color,
        backgroundColor: 'transparent',
    }))

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
        },
        scales: {
            x: {
                beginAtZero: true,
                ticks: {
                    font: {
                        size: 10,
                    },
                    callback: (value: number, index: number) => {
                        if(type === 'visitors' || type === 'sales') {
                            return handleRenderLabels(value, index)
                        } else {
                            return `${value + 1} урок`
                        }
                    }
                }
            },
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 1,
                },
            },
        },
    }

    const chartDataScheme = {
        datasets: chartDataset,
    };

    return (
        <div className={styles.container}>
            <Line options={options} data={chartDataScheme} width={1120} height={250}/>
            {
                (type === 'time' || type === 'progress') &&
                <div className={styles.info}>
                    <button
                        className={styles.btn}
                        onClick={() => setChartData(prev => ({...prev, isOpen: !chartData.isOpen}))}
                    >
                        Посмотреть легенду
                    </button>
                    {
                        legendItem.map((item, index) => (
                            <Collapse isOpened={chartData.isOpen} key={index} theme={{content: styles.collapse}}>
                                <p className={styles.legends}>{`${item.name} : ${item.value}`}</p>
                            </Collapse>
                        ))
                    }
                </div>
            }
        </div>
    );
}
export default LineChart;
