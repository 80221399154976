import React, {useEffect, useState} from 'react';
//В компоненте - новые инпуты и кнопка
import {useDispatch, useSelector} from "react-redux";
import '../scss/ui/a-input.scss';
import '../scss/registration.scss';
import '../scss/colors.scss';
import '../scss/order-settings.scss';
// components
import Input from './ui/Input';
import Button from './ui/Button';
import {postData} from "../utils/sendData";
import {API} from "../api/API";
import {useRouteMatch} from "react-router-dom";
import Select from "@/UIKit/component/select/Select";
import Toggletip from "@/UIKit/component/toggletip/toggletip";

import QuestionIcon from '../assets/images/icons/16/question-circle.svg';

export default function RegistrationSettings(props) {
    const settingsStore = useSelector(state => state.admin.event);
    const releasePeriodConst = useSelector(state => state.admin.constants['release_period']);
    const [settings, setSettings] = useState({});
    const errorValidation = useSelector(state => state.errorValidation);
    const errorFields = useSelector(state => state.errorFields);
    const noticeData = useSelector(state => state.notice);
    const selector = useSelector(state => state);

    const dispatch = useDispatch();
    const router = useRouteMatch()

    useEffect(() => {
        if (settingsStore.id) {
            prePareData()
        } else {
            getData()
        }
    }, [])

    useEffect(() => {
        prePareData()
    }, [settingsStore])


    useEffect(() => {
        if (errorValidation) {
            let errors = errorValidation.response.data.errors;
            let fields = {};
            for (let i = 0; i < errors.length; i++) {
                let field = errors[i].source.pointer.split('/')
                let fieldName = field[field.length - 1];
                let detail = errors[i].detail;
                fields[fieldName] = detail;
            }
            dispatch({type: "SET_ERROR_FIELDS", payload: fields})
            dispatch({type: "SET_ERROR_VALIDATION", payload: null})
        }

    }, [errorValidation]);

    const prePareData = () => {
        const copy = JSON.parse(JSON.stringify(settingsStore));
        if (copy.id) {
            let data = {
                bill_payment_reserve_time: copy.bill_payment_reserve_time,
                free_tickets_max_count: copy.free_tickets_max_count,
                online_payment_reserve_time: copy.online_payment_reserve_time,
                paid_tickets_max_count: copy.paid_tickets_max_count,
                text_reg_form: copy.text_reg_form,
                timer_consult: copy.timer_consult,
                release_period_label: copy.release_period_label,
                release_period: copy.release_period,
                url_success_bill: copy.url_success_bill,
                url_success_free: copy.url_success_free,
                url_success_moder: copy.url_success_moder,
                url_success_paid: copy.url_success_paid
            }
            let newData = {}
            for (let item in data) {
                newData[item] = data[item]
                //data[item] === null || data[item] === 0 ? (newData[item] = '') : newData[item] = data[item];
            }
            setSettings(newData);
        }
    }

    const getData = () => {
        let isCourse = router.url.includes('courses') ? true : false
        postData(null, API.changeCourseInfo.path(router.params.id, isCourse), 'GET')
            .then((r) => {
                setSettings(r.data.data.attributes)
                dispatch({
                    type: "SET_ADMIN",
                    payload: {...selector.admin, event: {...selector.admin.event, ...r.data.data.attributes}}
                })
            })
            .catch(({response}) => {
                console.log(response)
            });
    }

    const setData = (name, val) => {
        setSettings((settings) => ({...settings, [name]: val}));
    };

    const handleSubmit = (data) => {
        changeRegistrationSettings(data);
    };

    const changeRegistrationSettings = (settingsData) => {
        let isCourse = router.url.includes('courses') ? true : false
        let data = {
            data: {
                type: router.url.includes('courses') ? 'courses' : 'events',
                id: router.params.id,
                attributes: {},
            },
        };
        data.data.attributes = settingsData;
        postData(data, API.changeCourseInfo.path(router.params.id, isCourse), API.changeCourseInfo.method)
            .then((r) => {
                dispatch({type: "SET_ERROR_FIELDS", payload: null})
                dispatch({type: "SET_ERROR_VALIDATION", payload: null})

                isCourse ? dispatch({
                        type: "SET_NOTICE",
                        payload: {
                            ...noticeData,
                            open: true,
                            type: 'success',
                            message: 'Данные успешно обновлены'
                        }
                    }) :
                    showTopMessage('success', 'small', 'Данные успешно обновлены');
            })
            .catch(({response}) => {
                console.log(response)
                dispatch({type: "SET_ERROR_VALIDATION", payload: {response}})
            });
    };

    useEffect(() => {
        if (errorValidation) {
            let errors = errorValidation.response.data.errors;
            let fields = {};
            for (let i = 0; i < errors.length; i++) {
                let field = errors[i].source.pointer.split('/')
                let fieldName = field[field.length - 1];
                let detail = errors[i].detail;
                fields[fieldName] = detail;
            }
            dispatch({type: "SET_ERROR_FIELDS", payload: fields})
            dispatch({type: "SET_ERROR_VALIDATION", payload: null})

        }

    }, [errorValidation]);

    const errorFieldsHandler = (key, fieldInput) => {
        if (errorFields) {
            if (errorFields[key]) {
                return fieldInput ? errorFields[key] : <ErrorComponent text={errorFields[key]}/>
            }
        }
        return ''
    }

    const ErrorComponent = ({text}) => {
        return (<React.Fragment>
            <svg width="16" height="16" fill="none">
                <path d="M8 6a1 1 0 011 1v2a1 1 0 01-2 0V7a1 1 0 011-1zM8 11a1 1 0 110 2 1 1 0 010-2z"
                      fill="currentColor"></path>
                <path fill-rule="evenodd"
                      d="M.22 13.018L6.157 1.139c.76-1.519 2.926-1.519 3.686 0l5.937 11.879c.685 1.37-.311 2.982-1.843 2.982H2.063C.53 16-.465 14.388.22 13.018zM2 14L7.996 2l5.998 12H2z"
                      fill="currentColor"></path>
            </svg>
            {text} </React.Fragment>)
    }

    return (
        <React.Fragment>
            <div className={"order-settings__row"}>
                <span>Время резервирования билета для оплаты онлайн (в минутах)</span>
                <Input
                    name={"online_payment_reserve_time"}
                    type={"number"}
                    value={settings.online_payment_reserve_time}
                    size={"small"}
                    handleChange={(val) => {
                        setData(val.target.name, val.target.value);
                    }}
                />
            </div>
            <div
                className={'ui-widget__message ui-widget__message-error'}>{errorFieldsHandler('online_payment_reserve_time')}</div>

            <div className={"order-settings__row"}>
        <span>
          Время резервирования билета для оплаты безналичным расчётом (в сутках)
        </span>
                <Input
                    name={"bill_payment_reserve_time"}
                    type={"number"}
                    value={settings.bill_payment_reserve_time}
                    size={"small"}
                    handleChange={(val) => {
                        setData(val.target.name, val.target.value);
                    }}
                />
            </div>
            <div
                className={'ui-widget__message ui-widget__message-error'}>{errorFieldsHandler('bill_payment_reserve_time')}</div>

            <div className={"order-settings__row"}>
                <span>Максимальное число платных билетов в 1 заказе</span>
                <Input
                    name={"paid_tickets_max_count"}
                    type={settings.paid_tickets_max_count === 0 ? 'text' : "number"}
                    value={settings.paid_tickets_max_count === 0 ? 'Не ограничено' : settings.paid_tickets_max_count}
                    size={"small"}
                    isInfinity={true}
                    onKeyDown={() => {
                        if (settings.paid_tickets_max_count === 0) setData('paid_tickets_max_count', null)
                    }}
                    onPicClick={(val) => {
                        setData('paid_tickets_max_count', settings.paid_tickets_max_count === 0 ? 10 : 0)
                    }}
                    handleChange={(val) => {
                        setData(val.target.name, Number(val.target.value));
                    }}
                />
            </div>
            <div
                className={'ui-widget__message ui-widget__message-error'}>{errorFieldsHandler('paid_tickets_max_count')}</div>

            <div className={"order-settings__row"}>
                <span>Максимальное число бесплатных регистраций в 1 заказе</span>
                <Input
                    name={"free_tickets_max_count"}
                    type={settings.free_tickets_max_count === 0 ? 'text' : "number"}
                    value={settings.free_tickets_max_count === 0 ? 'Не ограничено' : settings.free_tickets_max_count}
                    size={"small"}
                    isInfinity={true}
                    onKeyDown={() => {
                        if (settings.free_tickets_max_count === 0) setData('free_tickets_max_count', null)
                    }}
                    onPicClick={(val) => {
                        setData('free_tickets_max_count', settings.free_tickets_max_count === 0 ? 1 : 0)
                    }}
                    handleChange={(val) => {
                        setData(val.target.name, Number(val.target.value));
                    }}
                />
            </div>
            <div
                className={'ui-widget__message ui-widget__message-error'}>{errorFieldsHandler('free_tickets_max_count')}</div>

            <div className={"order-settings__row"}>
                <span>Таймер обратного отсчёта для оплаты заказа со скидкой (минут)</span>
                <Input
                    name={"timer_consult"}
                    type={"number"}
                    value={settings.timer_consult}
                    size={"small"}
                    handleChange={(val) => {
                        setData(val.target.name, val.target.value);
                    }}
                />
            </div>
            <div className={'ui-widget__message ui-widget__message-error'}>{errorFieldsHandler('timer_consult')}</div>

            <div className={"order-settings__row"}>
                <span style={{display: "flex"}}>
                    Период оказания услуги участнику {settings['release_period_label'] && `, ${settings['release_period_label'].toLowerCase()}`}
                    <Toggletip
                        parent={document.querySelector('main')}
                        content={'Укажите период, в который услуга для покупателей будет считаться полностью оказанной. Влияет на возможность возврата средств и на выплату партнёрского вознаграждения'}>
                        <button className={'ui-cp__payment-methods__toggle'}><QuestionIcon/></button>
                    </Toggletip>
                </span>
                <small>
                    <Select
                        data={releasePeriodConst}
                        value={settings['release_period']}
                        size={'small'}
                        direction={['bottom']}
                        onChange={(val) => {
                            setData('release_period', val);
                        }}
                    />
                </small>
            </div>
            <div className={'ui-widget__message ui-widget__message-error'}>{errorFieldsHandler('release_period_label')}</div>

            <div style={{marginBottom: '30px', marginTop: '30px'}}>
                <span className={"order-settings__title_input"}>Адрес страницы успешной регистрации</span>
                <Input
                    name={"url_success_free"}
                    autoComplete="off"
                    placeholder={"Ссылка на страницу"}
                    size={"small"}
                    type={"url"}
                    error={errorFieldsHandler('url_success_free', true)}
                    value={settings.url_success_free != 0 ? settings.url_success_free : ''}
                    handleChange={(val) => {
                        setData(val.target.name, val.target.value);
                    }}
                />
            </div>
            <div style={{marginBottom: '30px'}}>
                <span className={"order-settings__title_input"}>Адрес страницы успешной оплаты</span>
                <Input
                    name={"url_success_paid"}
                    autoComplete="off"
                    placeholder={"Ссылка на страницу"}
                    size={"small"}
                    type={"url"}
                    error={errorFieldsHandler('url_success_paid', true)}
                    value={settings.url_success_paid != 0 ? settings.url_success_paid : ''}
                    handleChange={(val) => {
                        setData(val.target.name, val.target.value);
                    }}
                />
            </div>
            <div style={{marginBottom: '30px'}}>
                <span
                    className={"order-settings__title_input"}>Адрес страницы успешной отправки заявки на модерацию</span>
                <Input
                    name={"url_success_moder"}
                    autoComplete="off"
                    placeholder={"Ссылка на страницу"}
                    size={"small"}
                    type={"url"}
                    error={errorFieldsHandler('url_success_moder', true)}
                    value={settings.url_success_moder != 0 ? settings.url_success_moder : ''}
                    handleChange={(val) => {
                        setData(val.target.name, val.target.value);
                    }}
                />
            </div>
            <div style={{marginBottom: '30px'}}>
                <span
                    className={"order-settings__title_input"}>Адрес страницы успешного выставления счёта</span>
                <Input
                    name={"url_success_bill"}
                    autoComplete="off"
                    placeholder={"Ссылка на страницу"}
                    size={"small"}
                    type={"url"}
                    error={errorFieldsHandler('url_success_bill', true)}
                    value={settings.url_success_bill != 0 ? settings.url_success_bill : ''}
                    handleChange={(val) => {
                        setData(val.target.name, val.target.value);
                    }}
                />
            </div>

            <div className="settings-btn__inner">
                <Button
                    onClick={() => handleSubmit(settings)}
                    className={"e-btn settings-btn settings-btn_primary"}
                    value={"Сохранить"}
                    size="small"
                />
            </div>
        </React.Fragment>
    );
}
