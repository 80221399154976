import React, {useEffect, useState} from "react";
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";
import DragIcon from "../../../../../../assets/images/icons/16/arrows-v.svg";
import TrashIcon from "../../../../../../assets/images/icons/16/trash.svg";
import EditIcon from "../../../../../../assets/images/icons/16/pencil.svg";
import RequiredIcon from "../../../../../../assets/images/icons/16/required.svg";
import AddIcon from "../../../../../../assets/images/icons/16/plus_simple.svg";
import './Fields.scss'
import {API} from "@/api/API";
import {postData} from "@/utils/sendData";
import template from './Edit/Edit.data.json'
import Edit from "./Edit/Edit";
import {getSortedArray} from "@/utils/getSortedArray";
import Confirm from "../../../../../../UIKit/component/confirm/confirm";
import JSONAPISerializer from "jsonapi-serializer/lib/serializer";
import {deserializedData} from "@/utils/deserializedData";
import ReactHtmlParser from "react-html-parser";
import {useSelector} from "react-redux";

const Fields = ({data, is_course, event_id}) => {
    const selector = useSelector(state => state)
    const types = selector.admin.constants.forms
    const [fields, setFields] = useState([...data]);

    /* Сохранить поле */
    const handleSubmit = async (data) => {
        let response, path, method, updated, serialized, deserialized
        path = API.controlFieldsForm.path(data.id)
        method = data.id ? 'PATCH' : 'POST'
        serialized = serializeData(data)

        try {
            response = await postData(serialized, path, method)
            deserialized = await deserializedData(response.data)
            updated = fields.map(x => x.id === data.id ? {...x, ...deserialized, is_edit: false} : x);
            showTopMessage('success', 'small', 'Операция выполнена успешно')
            setFields(updated)
        } catch (e) {
            if (e.response && e.response.status === 422) {
                return formatValidatorErrors(e.response.data.errors)
            } else {
                showTopMessage('error', 'small', 'Произошла ошибка, повторите попытку позднее')
            }
        }
    }

    /* Удаление поля */
    const handleRemove = async (field) => {
        let response, path, method, data

        path = API.controlFieldsForm.path(field.id)
        method = 'DELETE'
        data = {id: field.id}
        response = await postData(data, path, method)

        if (response.status >= 200 && response.status < 300) {
            showTopMessage('success', 'small', 'Операция выполнена успешно')
            setFields([...fields.filter(x => x.id !== field.id)])
        } else {
            showTopMessage('error', 'small', 'Произошла ошибка, повторите попытку позднее')
        }
    }

    /* Открыть редактор редактирования поля */
    const handleEdit = (field) => {
        setFields(fields.map(x => x.id === field.id ? {...x, is_edit: true} : x))
    }

    /* Открыть редактор создания поля */
    const handleCreate = () => {
        setFields([...fields, {...template, is_edit: true, deletable: true}])
    }

    /* Вызов подтверждения действия при удалении поля */
    const handleConfirm = (field, value) => {
        setFields(fields.map(x => x.id == field.id ? {...x, is_confirm: value} : x))
    }

    /* Отменить редактирование/создание */
    const handleCancel = (field) => {
        setFields(fields.map(x => x.id === field.id ? {...x, is_edit: false} : x).filter(x => x.id))
    }

    /* Сортировка */
    const onSortEnd = async ({oldIndex, newIndex}) => {
        let data, updated

        updated = getSortedArray(fields, oldIndex, newIndex)
        data = updated.map((x, i) => ({id: x.id, position: i + 1}));
        setFields(updated);

        await postData({list: data}, API.changeFieldPosition.path(event_id), API.changeFieldPosition.method)
    };

    /* Сериализация данных в принимаемый формат API */
    const serializeData = (data) => {
        let type, config, result
        type = 'registration-forms';
        config = {
            keyForAttribute: 'underscore_case',
            attributes: ['type_new', 'name', 'position', 'required', 'variants', 'course', 'is_custom', 'min_length', 'max_length'],
            typeForAttribute: (attribute, data) => data.customType,
            course: {
                ref: 'id',
                included: false
            },
        }
        data = {
            ...data,
            id: data.id,
            course: {
                id: event_id,
                customType: is_course ? 'courses' : 'events'
            },
        };
        data.required === null ? data.required = false : data.required
        data.variants === null ? data.variants = [] : data.variants
        data.position === null ? data.position = fields.length : data.position
        result = new JSONAPISerializer(type, config).serialize(data);

        return result
    };

    /* Форматирование ошибок с API в общепринятый формат валидаторов на фронте */
    const formatValidatorErrors = (errors) => {
        errors.map(x => x.pointer)

        const check = (str) => {
            const match = str.match(/variants\/(\d+)/);
            return match ? `variant_${match[1]}` : str.split('/')[str.split('/').length - 1];
        }
        return errors.map(x => ({key: check(x.source.pointer), message: x.detail}))
    }

    /* Триггер сортировки поля */
    const DragHandle = SortableHandle(() => {
        return (
            <button
                className={'cp-btn cp-btn_size_small cp-scheme_secondary-ghost'}
                disabled={fields.find(x => x.is_edit)}>
                <span className={'ui-cp__drag-trigger'}>
                    <DragIcon/>
                </span>
            </button>
        );
    });

    /* Компонент одного поля */
    const Item = SortableElement(({field}) => (
        <div className="ui-cp__form-fields__item">
            {field.is_edit ? <Edit
                data={field}
                onSubmit={handleSubmit}
                onCancel={() => handleCancel(field)}
            /> : <>
                <div className="ui-cp__form-fields__label">
                    {field.name ? <h6>{ReactHtmlParser(field.name_html)}</h6> : null}
                    {field.variants && field.variants.length ? <div className="ui-cp__tickets-item__options">
                        {field.variants.map((variant, variant_idx) => <li key={variant_idx}>
                            <span className="ui-cp__tickets-item__options-container">
                                <span className="ui-cp__tickets-item__options-name">{variant}</span>
                            </span>
                        </li>)}
                    </div> : null}
                </div>
                <div className={'ui-cp__form-fields__type'}>
                <span>
                    {field.type_new ? types[field.type_new] : null}
                </span>
                </div>
                <div className={'ui-cp__form-fields__actions'}>
                    <button
                        className={'ui-cp__form-fields__required cp-btn cp-btn_size_small'}
                        disabled={!field.required}>
                        <RequiredIcon/>
                    </button>
                    <button
                        className={'cp-btn cp-btn_size_small cp-scheme_secondary-ghost'}
                        onClick={() => handleEdit(field)}
                        disabled={fields.find(x => x.is_edit)}>
                        <EditIcon/>
                    </button>
                    <DragHandle/>
                    <button
                        className={'cp-btn cp-btn_size_small cp-scheme_tertiary-ghost'}
                        onClick={() => handleConfirm(field, true)}
                        disabled={!field.deletable || fields.find(x => x.is_edit)}>
                        <TrashIcon/>
                    </button>
                </div>
                {field.is_confirm ? <Confirm
                    type={'danger'}
                    message={`Удалить поле?`}
                    onCancelText={'Отмена'}
                    onSubmitText={'Удалить'}
                    direction={'row'}
                    onCancel={() => handleConfirm(field, false)}
                    onSubmit={() => handleRemove(field)}
                /> : null}
            </>}
        </div>
    ));

    /* Компонент списка полей */
    const List = SortableContainer(({fields}) => {
        return (
            <div className="ui-cp__form-fields">
                {fields.map((field, field_idx) => <Item
                    key={`field-${field_idx}`}
                    index={field_idx}
                    field={field}
                />)}
            </div>
        );
    });

    return (
        <>
            <List
                fields={fields}
                onSortEnd={onSortEnd}
                transitionDuration={500}
                lockAxis={'y'}
                useDragHandle>
                {fields.map((field, field_idx) => <Item
                    key={`field-${field_idx}`}
                    index={field_idx}
                    field={field}
                />)}
            </List>
            <div className="ui-cp__tickets-actions">
                <button
                    className="cp-btn cp-btn_size_small cp-scheme_secondary-default cp-btn_icon_left"
                    disabled={fields.find(x => x.is_edit)}
                    onClick={handleCreate}>
                    <AddIcon/>
                    Добавить
                </button>
            </div>
        </>
    );
};

export default Fields;
