import React, {useEffect, useState} from 'react';
import UploadIcon from '../../../../../assets/images/icons/16/upload.svg'
import DeleteIcon from '../../../../../assets/images/icons/16/trash.svg';
import './SettingsPdfTicket.scss'
import RadioButton from "../../../../../UIKit/component/radio/radio";
import {postData, postFile} from "@/utils/sendData";
import {API} from "@/api/API";
import PdfViewer from "@/components/pdfViewer/PdfViewer";
import DownloadIcon from "@/assets/images/icons/16/download.svg";

const SettingsPdfTicket = () => {
    const [data, setData] = useState(VIEW)
    const event_id = eventIdSettings
    const [disabledDeleteLogo, setDisabledDeleteLogo] = useState(!VIEW.isTicketLogoChanged)
    const [disabledDeleteImage, setDisabledDeleteImage] = useState(!VIEW.isTicketImageChanged)
    const [disabledDeleteTemplate, setDisabledDeleteTemplate] = useState(null)
    const [qrCode, setQrCode] = useState(null)
    const [pdfTemplateHtml, setPdfTemplateHtml] = useState(null)

    const [pdfViewer, setPdfViewer] = useState(false)


    useEffect(() => {
        getTemplateTicket()
        getQrCode()
    }, []);

    const getTemplateTicket = () => {
        postData(data, API.getPdfTemplateTicket.path(event_id), API.getPdfTemplateTicket.method).then((response) => {
            if (response.data.success) {
                setPdfTemplateHtml(response.data.pdf_template)
                setDisabledDeleteTemplate(!response.data.is_custom)
            } else {
                console.log(`response.data.success = ${response.data.success}`)
            }

        }).catch(({response}) => {
            showTopMessage('error', 'small', response.data?.message || 'Произошла ошибка. Попробуйте позже');
        })
    }

    const getQrCode = () => {
        postData(data, API.getQrCodeTicket.path(event_id), API.getQrCodeTicket.method).then((response) => {
            setQrCode(response.data.qrcode)
        }).catch(({response}) => {
        })
    }



    const handleUploadLogo = (e) => {
        let file = e.target.files[0];
        e.target.value = null;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            let data = {file: e.target.result}
            postData(data, API.uploadLogoTicket.path(event_id), API.uploadLogoTicket.method).then((response) => {
                setData(prev => ({...prev, ticketLogo: response.data.src}))
                setDisabledDeleteLogo(false)
                showTopMessage('success', 'small', response.data?.message || 'Логотип успешно загружен');
            }).catch(({response}) => {
                showTopMessage('error', 'small', response.data?.message || 'Произошла ошибка. Попробуйте позже');
            })
        }
    }

    const handleDeleteLogo = () => {
        postData(null, API.deleteLogoTicket.path(event_id), API.deleteLogoTicket.method).then((response) => {
            setData(prev => ({...prev, ticketLogo: response.data.src}))
            setDisabledDeleteLogo(true)
            showTopMessage('success', 'small', response.data?.message || 'Логотип успешно удален');
        })
    }

    const handleUploadImage = (e) => {
        let file = e.target.files[0];
        e.target.value = null;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            let data = {file: e.target.result}
            postData(data, API.uploadImageTicket.path(event_id), API.uploadImageTicket.method).then((response) => {
                if (response.data.success) {
                    setData(prev => ({...prev, ticketImage: response.data.src}))
                    setDisabledDeleteImage(false)
                    showTopMessage('success', 'small', response.data?.message || 'Контент успешно загружен');
                } else {
                    showTopMessage('error', 'small', response.data?.message || 'Произошла ошибка. Попробуйте позже');
                }
            }).catch(({response}) => {
                console.log(response)
                showTopMessage('error', 'small', response.data?.message || 'Произошла ошибка. Попробуйте позже');
            })
        }
    }

    const handleDeleteImage = () => {
        postData(null, API.deleteImageTicket.path(event_id), API.deleteImageTicket.method).then((response) => {
            setData(prev => ({...prev, ticketImage: response.data.src}))
            setDisabledDeleteImage(true)
            showTopMessage('success', 'small', response.data?.message || 'Контент успешно удален');
        }).catch(({response}) => {
            console.log(response)
            showTopMessage('error', 'small', response.data?.message || 'Произошла ошибка. Попробуйте позже');
        })
    }

    const handleDeleteTemplate = () => {
        postData(null, API.deleteTemplateTicket.path(event_id), API.deleteTemplateTicket.method).then((response) => {
            showTopMessage('success', 'small', response.data?.message || 'Шаблон успешно удален');
            setDisabledDeleteTemplate(true)
        }).catch(({response}) => {
            console.log(response)
            showTopMessage('error', 'small', response.data?.message || 'Произошла ошибка. Попробуйте позже');
        })
    }

    const handleUploadTemplate = (e) => {
        let file = e.target.files[0]
        let formData = new FormData()
        formData.append(`file`, file);
        e.target.value = null;
        postFile(formData, API.uploadTemplateTicket.path(event_id), API.uploadTemplateTicket.method, () => {
        }).then((response) => {
            if (response.data.success) {
                //TODO добавить в стейт ресурс на шаблон
                setDisabledDeleteTemplate(false)
                showTopMessage('success', 'small', response.data?.message || 'Шаблон успешно загружен');
            } else {
                showTopMessage('error', 'small', response.data?.message || 'Произошла ошибка. Попробуйте позже');
            }
        }).catch(({response}) => {
            console.log(response)
            showTopMessage('error', 'small', response.data?.message || 'Произошла ошибка. Попробуйте позже');
        })
    }

    const handleDownloadTemplate = () => {
        const blob = new Blob([pdfTemplateHtml], { type: 'text/html' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        let date = new Date();
        let year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().length == 1 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
        let day = date.getDate().toString().length == 1 ? '0' + date.getDate() : date.getDate();
        let hours = date.getHours().toString().length == 1 ? '0' + date.getHours() : date.getHours();
        let minutes = date.getMinutes().toString().length == 1 ? '0' + date.getMinutes() : date.getMinutes();

        link.download = `Шаблон билета ${day}.${month}.${year} ${hours}-${minutes}.html`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }

    const handleSetQrCode = (val) => {
        let data = {
            'qrcode': val,
        }
        postData(data, API.postQrCodeTicket.path(event_id), API.postQrCodeTicket.method).then((response) => {
            setQrCode(data.qrcode)
            showTopMessage('success', 'small', response.data?.message || 'QR код установлен');
        }).catch(({response}) => {
            showTopMessage('error', 'small', response.data?.message || 'Произошла ошибка. Попробуйте позже');
        })
    }

    return (
        <div>
            <div className="ticket-logo-title-settings"> Настройка PDF-БИЛЕТА</div>
            <div className="ticket-logo">
                <div className="ticket-logo__container">
                    <div className="ticket-logo__title">Логотип</div>
                    <div className="ticket-logo__preview">
                        <div>
                            <img src={data.ticketLogo} data-changed={data.isTicketLogoChanged}
                                 id="ticket-logo-preview" alt=""/>
                        </div>
                        <div className="ticket-logo__buttons">
                            <label className="cp-btn_icon_left cp-btn_size_small cp-scheme_secondary-outline e-btn">
                                <UploadIcon/>
                                <input onChange={(e) => handleUploadLogo(e)} type="file"
                                       accept="image/x-png,image/gif,image/jpeg"/>
                                Загрузить
                            </label>
                            {/*<form style={{display: "inline"}} target="_blank"*/}
                            {/*      action={`/admin/settings/${event_id}/form/ticket/test/`} method="get">*/}
                            <button type="submit"
                                    onClick={() => {
                                        setPdfViewer(`/admin/settings/${event_id}/form/ticket/test/`)
                                    }}
                                    className="cp-btn_size_small cp-btn_icon_left cp-scheme_secondary-ghost e-btn">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M2 0L15 8L2 16V0ZM3.88767 12.6466L11.4384 8L3.88767 3.35343V12.6466Z"
                                          fill="#0067F2"/>
                                </svg>
                                Просмотр
                            </button>
                            {/*</form>*/}
                            <button onClick={handleDeleteLogo} disabled={disabledDeleteLogo}
                                    className="ticket-logo__delete e-btn">
                                <DeleteIcon/>
                            </button>
                        </div>
                    </div>
                    <div className="ticket-logo__description">
                        После оплаты услуги "Скрытие рекламы", вы можете загрузить свой
                        логотип/логотип партнера. Офлайн-билеты формируются с ваши логотипом, без упоминания
                        сервиса Nethouse.События. Требования к изображению: формат — png/gif/jpeg, ориентация —
                        горизонтальная.
                    </div>
                </div>
            </div>

            <div className="ticket-logo">

                <div className="ticket-logo__container">
                    <div className="ticket-logo__title">Изображение в нижней части</div>
                    <div className="ticket-logo__preview">
                        <div>
                            <img src={data.ticketImage} data-changed={data.isTicketImageChanged}
                                 id="ticket-image-preview" alt=""/>
                        </div>
                        <div className="ticket-logo__buttons">
                            <label className="cp-btn_icon_left cp-btn_size_small cp-scheme_secondary-outline e-btn">
                                <UploadIcon/>
                                <input onChange={(e) => handleUploadImage(e)} type="file"
                                       accept="image/x-png,image/gif,image/jpeg"/>
                                Загрузить
                            </label>
                            {/*<form style={{display: "inline"}} target="_blank"*/}
                            {/*      action={`/admin/settings/${event_id}/form/ticket/test/`} method="get">*/}
                            <button
                                onClick={() => {
                                    setPdfViewer(`/admin/settings/${event_id}/form/ticket/test/`)
                                }}
                                type="submit" id="downloadPreviewTicket"
                                className="cp-btn_size_small cp-btn_icon_left cp-scheme_secondary-ghost e-btn">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M2 0L15 8L2 16V0ZM3.88767 12.6466L11.4384 8L3.88767 3.35343V12.6466Z"
                                          fill="#0067F2"/>
                                </svg>
                                Просмотр
                            </button>
                            {/*</form>*/}
                            <button onClick={handleDeleteImage} disabled={disabledDeleteImage}
                                    className="ticket-logo__delete e-btn">
                                <DeleteIcon/>
                            </button>
                        </div>
                    </div>
                    <div className="ticket-logo__description">
                        После оплаты услуги "Скрытие рекламы" вы сможете загрузить свой баннер, информацию о партнерах,
                        карту места проведения события и многое другое. Билеты сформируются с добавленным контентом.
                        Требования к изображению: формат — png/gif/jpeg, ширина: 890px, высота от 50px до 244px
                    </div>
                </div>
            </div>
            <div className="ticket-logo">
                <div className="ticket-logo__container">
                    <div className="ticket-logo__preview">
                        <div className="ticket-logo__title">Свой шаблон билета</div>
                        <div className="ticket-logo__buttons">
                            <button onClick={(e) => handleDownloadTemplate()}
                                    className="cp-btn_icon_left cp-btn_size_small cp-scheme_secondary-outline e-btn">
                                <DownloadIcon/>
                                Скачать HTML
                            </button>

                            <label className="cp-btn_icon_left cp-btn_size_small cp-scheme_secondary-outline e-btn">
                                <UploadIcon/>
                                <input data-is-course="{{ $event->isCourse ? 'true' : 'false' }}"
                                       onChange={(e) => handleUploadTemplate(e)} accept="text/html"
                                       type="file"/>
                                Загрузить
                            </label>
                            {/*<form style={{display: "inline"}} target="_blank"*/}
                            {/*      action={`/api/admin/${isCourse ? 'courses' : 'events'}/${event_id}/tickets_pdf_template/test/`}*/}
                            {/*      method="get">*/}
                            <button
                                onClick={() => {
                                    // setPdfViewer(`html`)
                                    setPdfViewer(`/admin/settings/${event_id}/form/ticket/test/`)
                                }}
                                id="downloadPreviewTemplate"
                                className="cp-btn_size_small cp-btn_icon_left cp-scheme_secondary-ghost e-btn">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M2 0L15 8L2 16V0ZM3.88767 12.6466L11.4384 8L3.88767 3.35343V12.6466Z"
                                          fill="#0067F2"/>
                                </svg>
                                Просмотр
                            </button>
                            {/*</form>*/}
                            <button onClick={handleDeleteTemplate}
                                    disabled={disabledDeleteTemplate}
                                    data-is-course="{{ $event->isCourse ? 'true' : 'false' }}"
                                    className="ticket-logo__delete e-btn">
                                <DeleteIcon/>
                            </button>
                        </div>
                    </div>

                    <div className="ticket-logo__description">
                        Загрузите свой шаблон билета <a target={'_blank'}
                                                        href={'https://events.nethouse.ru/help/kak-izmenit-shablon-bileta '}>(ссылка
                        на инструкию) </a>
                    </div>
                </div>
            </div>
            <div className="ticket-logo">
                <div className="ticket-logo__container">
                    <div className="ticket-logo__preview">
                        <div className="ticket-logo__title">Содержимое QR-кода</div>
                    </div>

                    <div className="ticket-logo__radio-button">
                        <RadioButton
                            size={'small'}
                            disabled={false}
                            label={'Ссылка на страницу проверки'}
                            checked={qrCode === 1}
                            onChange={() => handleSetQrCode(1)}
                        />
                        <RadioButton
                            size={'small'}
                            disabled={false}
                            label={'QR-код с ключом доступа'}
                            checked={qrCode === 2}
                            onChange={() => handleSetQrCode(2)}
                        />
                        <RadioButton
                            size={'small'}
                            disabled={false}
                            label={'Штрихкод с ключом доступа'}
                            checked={qrCode === 3}
                            onChange={() => handleSetQrCode(3)}
                        />
                    </div>
                    <div className="ticket-logo__description">
                        Воспользуйтесь стандартной проверкой билета по <a target={'_blank'}
                                                                          href={'https://events.nethouse.ru/help/kak-proverit-bilet-s-pomoshchyu-qr-koda'}>QR-коду</a>, добавьте в QR-код <a target={'_blank'}
                                             href={'https://events.nethouse.ru/help/kak-nastroit-klyuchi-dostupa-dlya-kazhdogo-uchastnika/'}>уникальные
                        ключи </a> или используйте <a target={'_blank'}
                                                      href={'https://events.nethouse.ru/help/kak-dobavit-barcode-v-bilet/'}> штрихкоды </a>
                        с ключами для каждого участника.
                    </div>
                </div>
            </div>
            {pdfViewer && <PdfViewer url={pdfViewer} html={pdfViewer === 'html' ? pdfTemplateHtml : null} close={() => {
                setPdfViewer(false)
            }}/>}
        </div>
    );
};

export default SettingsPdfTicket;
