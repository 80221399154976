import MailingApi from "./MailingApi.js";
import GroupTable from "./GroupTable";
import MailingTable from "./MailingTable";
import React from 'react'
import ReactDOM from "react-dom";
import {useEffect, useState} from "react";

const API = new MailingApi();

function Mailing(props) {
    const [groups, setGroups] = useState(null);
    const [mailings, setMailings] = useState(null);
    const [groupCounter, setGroupCounter] = useState(0);
    const [mailingCounter, setMailingCounter] = useState(0);

    async function loadMailings(e = null) {
        if (e !== null) {
            e.preventDefault();
        }
        let response = await API.getMailings(e);
        let result = await response.json();
        switch (response.status) {
            case 200:
                setMailings(result);
                setMailingCounter(result.meta.total);
                break;
            case 401:
                API.authorize().then(result => {
                    loadMailings(e);
                });
                break;
            default:
        }
    }

    async function loadGroups(e = null) {
        if (e !== null) {
            e.preventDefault();
        }
        let response = await API.getGroups(e);
        let result = await response.json();
        switch (response.status) {
            case 200:
                setGroups(result);
                setGroupCounter(result.meta.total);
                break;
            case 401:

                API.authorize().then(result => {
                    loadGroups(e);
                });
                break;
            default:
        }
    }

    function getCounter(count) {
        if (count > 0) {
            return <span className="mailing-page__tab-tag">{count}</span>
        }
    }

    useEffect(() => {
        loadGroups();
        loadMailings();
    }, []);

    function toggleTab(tabSelector, e) {
        $(e.currentTarget).siblings().removeClass('e-nav-tab__label_active');
        $(e.currentTarget).addClass('e-nav-tab__label_active');
        $('.mailing-page__table-block').addClass('is-hidden');
        $(tabSelector).removeClass('is-hidden');
    }

    return (
        <>
            {console.log('render')}
            <ul className="mailing-page__tabs e-nav-tab e-nav-tab_size_variable">
                <li className="mailing-page__tab e-nav-tab__label e-nav-tab__label_active" id="mailing-tabs_js-groups"
                    onClick={(e) => toggleTab('#mailing-table_js-groups', e)}>
                    <div className="e-nav-tab__text">
                        Группы
                        {getCounter(groupCounter)}
                    </div>

                </li>
                <li className="mailing-page__tab e-nav-tab__label" id="mailing-tabs_js-mailing"
                    onClick={(e) => toggleTab('#mailing-table_js-mailing', e)}>
                    <span className="e-nav-tab__text">Список рассылок
                        {getCounter(mailingCounter)}
                    </span>

                </li>
            </ul>
            <GroupTable groups={groups}/>
            <MailingTable mailings={mailings}/>
        </>
    );
}

let domContainer = document.querySelector('#mailing-index');
domContainer ? ReactDOM.render(<Mailing/>, domContainer) : null;
