import React, {useEffect, useState, useRef} from 'react'
import '../../../../../../scss/custom-select.scss'
import IconTriangle from '../../../../../../assets/images/icons/triangle.svg'
import {useDispatch} from "react-redux";
import useOnClickOutside from '../../../../../../hooks/useOnClickOutside';


const ManagerPlaceStatus = (props) => {
    const [isOpen, setIsOpen] = useState(false)
    const [selected, setSelected] = useState(props.initialValue)
    const dispatch = useDispatch();
    const ref = useRef();

    useOnClickOutside(ref, () => setIsOpen(false));

    useEffect(() => {
        setSelected(props.initialValue)
    }, [props.initialValue])

    const closeSelect = () => {
        setIsOpen(false)
    }

    const toggleSelect = (e) => {
        if (props.changeMade && props.canStatus) {
            props.disableChangeMade()
        } else {
            if (props.disable !== true) {
                if (!e.target.matches('.button__close')) setIsOpen(!isOpen)
            }
        }
    }

    const onClickedOption = (index) => {
        setSelected(index)
        props.onChange(index)
    }

    const prePareCategories = (list) => {
        let arr = []
        for (let key in list) {
            arr.push({id: key, val: list[key]})
        }
        return arr.map((item, index) => {
            return (
                <li key={index}
                    className={(selected.length ? selected.includes(item.id) : false) || item.id === selected ? 'selected' : ''}
                    onClick={() => onClickedOption(item.id)}>{item.val}</li>
            )

        })
    }

    const style = {
        height: props.height
    }


    const getValueSelect = (statuses) => {
        if (props.disable) {
            if (selected) {
                return statuses[selected]
            } else {
                return '-'
            }
        }
        return props.list[selected]
    }

    return (
        <div
            className={`custom-select ${props.height > 40 ? 'custom-select--big' : ''} ${isOpen ? 'custom-select--opened' : 'custom-select--closed'} ${props.multiselect ? 'custom-select--multiselect' : ''} ${props.download ? 'custom-select--download' : ''}`}
            tabIndex="0"
            ref={ref}
            // onBlur={() => closeSelect()}
        >

            <div onClick={toggleSelect}
                 className={`custom-select__wrap`}
                 style={style}
            >
                <div className="custom-select__value">
                    {props.hasOwnProperty('icon') ? <div className="custom-select__icon">{props.icon}</div> : ''}
                    <span
                        className={`label ${!props.hasOwnProperty('icon') ? 'label__default' : ''}`}>
                        {getValueSelect(props.statuses)}
                    </span>

                    <button className={`button ${isOpen ? 'active' : ''}`}>
                        <IconTriangle/>
                    </button>
                </div>
                {
                    isOpen &&
                    <div className={`custom-select__items ${props.up ? 'custom-select__up' : 'custom-select__down'}`}>
                        <div className="custom-select__scroll" style={props.style}>
                            <ul style={{maxHeight: props.height * (props.maxItems || 4)}}>
                                {prePareCategories(props.list)}
                            </ul>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}


export default ManagerPlaceStatus
