import {Container, Graphics} from "pixi.js";
import CustomContainer from "../components/CustomContainer";

export const dotGraphic = new Graphics({label: "grid"}); // сетка из точек
export const worldContainer = new Container({label: 'worldContainer'}); // внешний контейнер
export const graphicsPlace = new Graphics({label: 'renderPlace'}); // графика мест
export const graphicsExtremum = new Graphics({label: 'extremum'}); // квадрат зоны видимости
export const graphicsSquare = new Graphics({label: 'renderSquare'}); // квадраты выделенной области
export const tooltipContainer = new CustomContainer({label: 'Tooltip'}); // подсказка места/ряда
export const graphicsTooltip = new Graphics({label: 'tooltip'}); // подсказка места/ряда
export const graphicsSelect = new Graphics({label: 'Select'}); // рамка для выбранных мест
export const graphicsDrawSelect = new Graphics({label: 'drawSelect'}); // рамка выделенной области
export const graphicsHover = new Graphics({label: 'graphicsHover'}); // рамка над местом
export const offsetContainer = 0
export let containerCanvas = {top: null, left: null, height: null, width: null} // отступы у контейнера canvas
export let editorFloor = <any>{e: null}
export const screenParam = {
    scaledWidth: null,
    scaledHeight: null,
}
export const conf = {
    isUser: false,
    isDesigner: false,
    isManager: false
};

export const pixiMP = {
    lastPositionWorld: {x: null, y: null},
    startMouseX: 0,
    startMouseY: 0,
    lastMouseX: 0,
    lastMouseY: 0,
    threshold: 5, // константа смещения для корректного определения тап"а
    isMiddleMouseDown: false, // включен режим навигации
    accumulatedDeltaX: 0,
    accumulatedDeltaY: 0,
    maxScale: 10,
    minScale: 0.01,
    grab: false
}

export const stageMP = {
    startX: 0,
    startY: 0,
    lastX: 0,
    lastY: 0,
    threshold: 5, // константа смещения
    isMiddleMouseDown: false, // включен режим навигации
    accumulatedDeltaX: 0,
    accumulatedDeltaY: 0,
    maxScale: 10,
    minScale: 0.01,
    width: 0,
    height: 0,
    isDrawing: null
}

export let segments = {
    type: 1, // 0 по координатам, 1 по сегментам экрана, 2 по секторам
    view: [],
    place: {},
    horizontal: 10,
    vertical: 10
}

export const isSegments = false // включаем сегментацию
export const lockStatus = [1, 4, 5]
export let sprites = {
    alert: null,
    booked: null,
    lock: null,
    error: null
};

export let extremumUser = { // экстремумы фигур для вычисления области навигации пользователя
    min: null,
    max: null
}


