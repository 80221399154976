import React, {useEffect, useState} from 'react';
import styles from './Owners.module.scss'
import page from "../../Events.module.scss";
import ownersDataJson from './Owners.data.json'
import ownerDataJson from './Owner/Owner.data.json'
import Owner from "./Owner/Owner";
import AddIcon from "../../../../../assets/images/icons/16/plus_simple.svg";
import Notification from "../../../../../UIKit/component/notification/notification";
import Preloader from "../../../../../widget/components/preloader";
import {methodGetSubscribe} from "./Owners.methods";
import {useRouteMatch} from "react-router-dom";
import {generateHash} from "../../../../../utils/generateHash";

const Owners = ({data, onChange}) => {
    const router = useRouteMatch()
    const {event_id, type} = router.params
    const [owners, setOwners] = useState({...ownersDataJson})

    useEffect(() => {
        data.is_loading === 1 ? handleGetSubscribes() : null
    }, [data.is_loading])

    const handleGetSubscribes = async () => {
        try {
            await methodGetSubscribe(event_id, type).then(e => {
                setOwners(prev => ({
                    ...prev,
                    is_loading: 1,
                    list: e.map(x => ({...ownerDataJson, ...x, hash: generateHash()})),
                    orgTemplates: data.orgTemplates || []
                }))
            })
        } catch (e) {
            setOwners(prev => ({...prev, is_loading: 0}))
        }
    }

    const handleCreate = () => {
        setOwners(prev => ({
            ...prev,
            list: [...prev.list, {...ownerDataJson, is_edit: !ownerDataJson.is_edit}].map(x => ({
                ...x,
                is_disabled: !x.is_disabled
            }))
        }))
    }

    return (
        <div className={page.section}>
            <h3 className={page.title}>
                Письма организаторам
            </h3>

            {owners.is_loading === null ? <Preloader/> : null}

            {owners.is_loading === 1 ? <>
                {owners.list.length ? <div className={styles.container}>
                    {owners.list.map((owner, owner_idx) => <Owner
                        key={owner_idx}
                        data={owner}
                        orgTemplates={data.orgTemplates}
                        onChange={setOwners}
                        onUpdate={handleGetSubscribes}
                    />)}
                </div> : <Notification
                    type={'info'}
                    value={<><p>Письма отсутствуют.</p><p>Для создания нового письма нажмите кнопку "Добавить письмо" и
                        заполните форму.</p></>}
                />}
                <div className={styles.add}>
                    <button
                        className={'a-btn a-btn_size_small a-btn_icon_left a-btn_type_secondary-default'}
                        disabled={owners.list.find(x => (x.hash === null || x.is_edit))}
                        onClick={handleCreate}>
                        <AddIcon/>
                        Добавить письмо
                    </button>
                </div>
            </> : null}


            {owners.is_loading === 0 ? <Notification
                value={'Произошла ошибка загрузки модуля писем организаторам, повторите попытку позднее'}
                type={'error'}
            /> : null}
        </div>
    );
};

export default Owners;