import React, {useRef, useState} from 'react';

import IconKey from "../../assets/images/icons/16/key.svg";
import IconDraw from "../../assets/images/icons/16/pencil.svg";
import IconTicket from "../../assets/images/icons/16/ticket.svg";
import IconOnline from "../../assets/images/icons/16/monitor.svg";
import IconVideo from "../../assets/images/icons/16/video.svg";
import IconCertificate from "../../assets/images/icons/16/award.svg";
import {declension} from '../../utils/nhApi'
import Confirm from "../../UIKit/component/confirm/confirm";
import IconDots from "../../assets/images/icons/16/more-vertical.svg";
import ArrowUpIcon from '../../assets/images/icons/16/arrow-up.svg'
import VisibleIcon from "../../assets/images/icons/16/eye_1.svg";
import NoVisibleIcon from "../../assets/images/icons/16/eye_2_no.svg";
import Select from "../../UIKit/component/select/Select";
import {handleFormatNumber} from "@/utils/formateSum";

const TicketSettings = (props) => {
    const [ticket, setTicket] = useState(props.item)
    const [tickets, setTickets] = useState(props.tickets)
    const id = props.id
    const ticketListRef = useRef(document.querySelector('.ui-cp__tickets > div'))
    const [askRemove, setAskRemove] = useState(false)
    const [askActive, setAskActive] = useState(false)
    const iconPlace = (type) => {
        let result
        switch (type) {
            case 1:
                result = <span className={'ui-cp__tickets-item__icon'}>
                    <IconTicket/>
                </span>
                break;
            case 2:
                result = <span className={'ui-cp__tickets-item__icon'}>
                    <IconOnline/>
                </span>
                break;
            case 3:
                result = <span className={'ui-cp__tickets-item__icon-wrapper'}>
                            <span className={'ui-cp__tickets-item__icon'}>
                                <IconTicket/>
                            </span>
                            <span className={'ui-cp__tickets-item__icon'} style={{marginTop: '2px'}}>
                                <IconOnline/>
                            </span>
                        </span>
                break;
            case 4:
                result = <div className={'ui-cp__tickets-item__icon'}><IconVideo/></div>
                break;
            case 16:
                result = <span className={'ui-cp__tickets-item__icon-wrapper'}>
                            <span className={'ui-cp__tickets-item__icon'}>
                                <IconCertificate/>
                            </span>
                            <span className={'ui-cp__tickets-item__promocode'}>
                                {props.certs.find(x => x.promocode_id == ticket.promocodeId)?.promocode}
                            </span>
                        </span>
                break;
        }
        return result
    }

    const Global = () => {
        const Name = (props) => <h6 className={'ui-cp__tickets-item__title-h14'}>{props.value}</h6>
        const Description = (props) => <p className={'ui-cp__tickets-item__title-h12'}>{props.value}</p>
        const TicketType = (props) => {
            const url = `/admin/keys/${id}/ticket/${ticket.ticket_id}/`
            return <div className={'ui-cp__tickets-row'}>
                {props.type ? iconPlace(ticket.type) : null}
                {props.keys ? <a target={'_blank'}
                                 className={`cp-tag cp-tag_icon_left cp-tag_size_small cp-tag_type_square cp-scheme_secondary-default`}
                                 href={`${url}`}><IconKey/>{props.value}
                </a> : null}
                {!ticket.isSelling && <div className={'cp-scheme_yellow-default'}>{ticket.isNotSellingReason}</div>}
            </div>
        }
        const Price = (props) => <div className="ui-cp__tickets-item__price">
            <h6 className={'ui-cp__tickets-item__title-h14'}>{handleFormatNumber(props.value)} ₽</h6>
        </div>
        const Count = (props) => {
            let count
            if (ticket?.count == 0) {
                count = 'Нет в наличии'
            } else if (ticket?.count == null || ticket?.count.length === 0 || ticket?.count === 'Не ограничено') {
                count = 'Не ограничено'
            } else {
                count = ticket.count
            }
            return <div className="ui-cp__tickets-item__count">
                <p>Всего: <span>{String(count)}</span></p>
            </div>
        }
        return <div className="ui-cp__tickets-item__general">
            <div className="ui-cp__tickets-item__title">
                {ticket?.name ? <Name value={ticket.name}/> : null}
                {ticket?.description ? <Description value={ticket.description}/> : null}
                {ticket.type || ticket.hasKeys ? <TicketType
                    id={ticket.ticket_id}
                    type={ticket.type}
                    keys={ticket.hasKeys}
                    count={ticket.countTicketKeys}
                    ticket={ticket}
                    value={'Ключи доступа'}
                /> : null}
            </div>
            <Price value={ticket.price}/>
            <Count value={ticket.count}/>
        </div>
    }

    const format = (value) => {
        return new Date(value).toLocaleDateString('ru-RU')
    }

    const outputValidPeriod = (value) => {
        if (value) {
            let parent = value.split('P')[1],
                months = Number(parent.split('M')[0]),
                days = Number(parent.split('M')[1].split('D')[0]),
                arr = []

            months ? arr.push(months + ' ' + declension(months, ['месяц', 'месяца', 'месяцев'])) : null
            days ? arr.push(days + ' ' + declension(days, ['день', 'дня', 'дней'])) : null

            return arr.join(' ')
        }
    }

    const Terms = () => {
        const construct = () => {
            return format(ticket.startsAt) + ' - ' + format(ticket.endsAt)
        }
        return <div className="ui-cp__tickets-item__term">
            <div className={'ui-cp__tickets-item__term-date'}>
                <strong>В продаже: </strong>
                {ticket.startsAt || ticket.endsAt ? construct() : 'Не ограничено'}
            </div>
            <div className={'ui-cp__tickets-item__term-date'}>
                <strong>Действителен: </strong>
                {ticket.validPeriod ? outputValidPeriod(ticket.validPeriod) : 'Не ограничено'}
            </div>
            {ticket.has_dates === "0" || ticket.has_dates === 0 ? <div className={'ui-cp__tickets-item__term-date'}>
                <strong>Многодневный</strong>
            </div> : null}
        </div>
    }

    const Price = () => {
        let arr = Object.entries(props.item.prices)
        return <div className="ui-cp__tickets-item__prices">
            <ul>
                {arr.map((price, price_idx) => <li key={price_idx}>
                    <p className={'ui-cp__tickets-item__date'}>с {format(price[1].date_start)}</p>
                    <span></span>
                    <p className={'ui-cp__tickets-item__cost'}>{price[1].price} ₽</p>
                </li>)}
            </ul>
        </div>
    }

    const Options = () => {
        const OptionsList = () => {
            return props.item.options.map((option, option_idx) => <OptionsItem key={option_idx} item={option}/>)
        }
        const formatter = (option) => {
            let result
            if (option.unlimited == true) {
                result = 'Не ограничено'
            } else {
                if (option.count == null) {
                    result = 'Не ограничено'
                }
                if (option.count == 0) {
                    result = 'Нет в наличии'
                }
                if (option.count > 0) {
                    result = Number(option.count)
                }
            }
            return result
        }

        const OptionsItem = props => {
            return <li>
                <span className={'ui-cp__tickets-item__options-container'}>
                    <div className={'ui-cp__tickets-item__options-row'}>
                        <div className="ui-cp__tickets-item__options-name">{props.item.name}</div>
                        <div className="ui-cp__tickets-item__options-price">{handleFormatNumber(props.item.price)} ₽</div>
                        <div
                            className="ui-cp__tickets-item__options-count">Всего: <div>{formatter(props.item)}</div></div>
                    </div>
                </span>
            </li>
        }
        return <div className="ui-cp__tickets-item__options">
            <strong>Опции:</strong>
            <OptionsList/>
        </div>
    }

    const Sessions = () => {
        let arr = Object.entries(ticket.sessions)
        return <div className="ui-cp__tickets-item__sessions">
            <strong>Сеансы:</strong>
            <ul>
                {arr.map((session, session_idx) => <li key={session_idx}>
                    <span>{session[1]}</span>
                </li>)}
            </ul>
        </div>
    }

    const Groups = () => {
        return <div className="ui-cp__tickets-item__groups">
            <strong>Группы:</strong>
            <ul>
                {props.floors.length ? ticket.floorGroups.map((group, group_idx) => {
                    return <li key={group_idx}>
                        <span>{props.floors.find(x => x.id === group.floor_id).name}, {group.name}</span>
                    </li>
                }) : null}
            </ul>
        </div>
    }

    const handlerActiveTicket = (ticket) => {
        setAskActive(true)
    }

    const Actions = () => {
        return <div className="ui-cp__tickets-item__actions">
            <button
                onClick={() => handlerActiveTicket()}
                className={"cp-btn cp-btn_size_small cp-scheme_secondary-ghost"}
                disabled={false}
            >
                {props.item.is_active ? <VisibleIcon/> : <NoVisibleIcon/>}
            </button>
            <button
                disabled={props.edit.state}
                className={'cp-btn cp-btn_size_small cp-scheme_secondary-ghost'}
                onClick={() => {
                    props.handlers.handleSwitchTicketsEditor(props.index, true)
                }}>
                <IconDraw/>
            </button>
            <button
                disabled={props.edit.state || props.index === 0}
                className={'cp-btn cp-btn_size_small cp-scheme_secondary-ghost ui-cp__tickets__draggable-up'}
                onClick={() => props.handlers.onSortEnd({oldIndex: props.index, newIndex: props.index - 1})}>
                <ArrowUpIcon/>
            </button>
            <button
                disabled={props.edit.state || props.index === props.tickets.length - 1}
                className={'cp-btn cp-btn_size_small cp-scheme_secondary-ghost ui-cp__tickets__draggable-down'}
                onClick={() => props.handlers.onSortEnd({oldIndex: props.index, newIndex: props.index + 1})}>
                <ArrowUpIcon/>
            </button>
            {tickets.length > 1 ? props.dragHandle : null}
            <Select
                direction={['bottom', 'left']}
                visibleItems={5}
                size={'small'}
                data={['Копировать', tickets.length > 1 ? 'Удалить' : null].filter(x => x ?? x)}
                disabled={props.edit.state}
                onChange={(idx) => {
                    idx == 0 ? handleCopy() : null
                    idx == 1 ? setAskRemove(true) : null
                }}
            >
                <div
                    className={'a-btn a-btn_size_small a-btn_type_secondary-ghost'}>
                    <IconDots/>
                </div>
            </Select>
        </div>
    }

    const handleCopy = () => {
        props.handlers.handleCopyTicket(props.item.ticket_id)
    }

    return (
        <div className={'ui-cp__tickets-item'}>
            {askRemove ? <Confirm
                type={'danger'}
                message={`Удалить билет?`}
                onCancelText={'Отмена'}
                onSubmitText={'Удалить'}
                direction={'row'}
                onCancel={() => setAskRemove(false)}
                onSubmit={() => props.handlers.handleRemove(ticket.ticket_id)}
            /> : null}
            {askActive ? <Confirm
                type={'danger'}
                message={`${ticket.is_active ? 'Скрыть билет в форме ?' : 'Показать билет в форме ?'}`}
                onCancelText={'Нет'}
                onSubmitText={'Да'}
                direction={'row'}
                onCancel={() => setAskActive(false)}
                onSubmit={() => props.handlers.handleActiveTicket(ticket.ticket_id, ()=>setAskActive(false))}
            /> : null}
            <div className="ui-cp__tickets-item__column">
                <Global/>
                <Terms/>
                {ticket.prices.length ? <Price/> : null}
                {ticket.options.length && ticket.type != 16 ? <Options/> : null}
                {Object.entries(ticket.sessions).length && ticket.type != 16 ? <Sessions/> : null}
                {ticket.floorGroups.length && ticket.type != 16 ? <Groups/> : null}
                {(ticket.needOneOptionParam || ticket.onlyOneOptionParam) ?
                    <div className={'ui-cp__tickets-item__checkboxes'}>
                        {ticket.needOneOptionParam ? <p><strong>Минимальное количество опций для покупки билета: {ticket.needOneOptionParam}</strong></p> : null}
                        {ticket.onlyOneOptionParam ? <p><strong>Максимальное количество опций для покупки билета: {ticket.onlyOneOptionParam}</strong></p> : null}
                    </div> : null}
            </div>
            <Actions/>
        </div>
    );
};

export default TicketSettings;
