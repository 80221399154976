// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MemberCharts-module_charts_aPNHv{padding-top:16px;display:flex;flex-direction:column}.MemberCharts-module_title_d9Zet{font-family:\"Inter\",sans-serif;font-style:normal;font-weight:600;font-size:12px;line-height:15px;letter-spacing:.04px}.MemberCharts-module_statistic_UJkeh{font-family:\"Inter\",sans-serif;font-style:normal;font-weight:700;font-size:12px;line-height:15px;letter-spacing:.04px;color:#5e6b92;padding-left:8px}", "",{"version":3,"sources":["webpack://resources/js/react/src/views/Admin/Courses/Course/Lessons/Statistic/Charts/MemberCharts.module.scss"],"names":[],"mappings":"AAIA,kCACI,gBAAA,CACA,YAAA,CACA,qBAAA,CAGJ,iCACI,8BAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,oBAAA,CAGJ,qCACI,8BAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,oBAAA,CACA,aAAA,CACA,gBAAA","sourcesContent":[".container {\n\n}\n\n.charts {\n    padding-top: 16px;\n    display: flex;\n    flex-direction: column;\n}\n\n.title {\n    font-family: 'Inter', sans-serif;\n    font-style: normal;\n    font-weight: 600;\n    font-size: 12px;\n    line-height: 15px;\n    letter-spacing: 0.04px;\n}\n\n.statistic {\n    font-family: 'Inter', sans-serif;\n    font-style: normal;\n    font-weight: 700;\n    font-size: 12px;\n    line-height: 15px;\n    letter-spacing: 0.04px;\n    color: #5E6B92;\n    padding-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"charts": "MemberCharts-module_charts_aPNHv",
	"title": "MemberCharts-module_title_d9Zet",
	"statistic": "MemberCharts-module_statistic_UJkeh"
};
export default ___CSS_LOADER_EXPORT___;
