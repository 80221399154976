// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Datetime-module_tabs_VpIzh{display:grid;grid-template-columns:repeat(2, 1fr);gap:16px}@media(max-width: 576px){.Datetime-module_tabs_VpIzh{grid-template-columns:repeat(1, 1fr);gap:12px}}", "",{"version":3,"sources":["webpack://resources/js/react/src/views/Admin/Events/Notifications/Notification/Modal/Datetime/Datetime.module.scss","webpack://resources/js/react/src/scss/breakpoints.scss"],"names":[],"mappings":"AAEA,4BACE,YAAA,CACA,oCAAA,CACA,QAAA,CCoBE,yBDvBJ,4BAKI,oCAAA,CACA,QAAA,CAAA","sourcesContent":["@import \"../../../../../../../scss/breakpoints\";\n\n.tabs {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  gap: 16px;\n  @include _xs {\n    grid-template-columns: repeat(1, 1fr);\n    gap: 12px;\n  }\n}","@mixin _xl {\n    @media (max-width: 1599px) {\n        @content;\n    }\n}\n\n@mixin _lg {\n    @media (max-width: 1279px) {\n        @content;\n    }\n}\n\n@mixin _md {\n    @media (max-width: 1023px) {\n        @content;\n    }\n}\n\n@mixin _sm {\n    @media (max-width: 767px) {\n        @content;\n    }\n}\n\n@mixin _xs {\n    @media (max-width: 576px) {\n        @content;\n    }\n}\n\n@mixin _xxs {\n    @media (max-width: 376px) {\n        @content;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": "Datetime-module_tabs_VpIzh"
};
export default ___CSS_LOADER_EXPORT___;
