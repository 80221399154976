// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Mailing-module_container_R64A2{display:flex;gap:32px;justify-content:center;width:100%;max-width:640px;margin:0 auto}@media(max-width: 767px){.Mailing-module_container_R64A2{flex-direction:column;gap:16px}}.Mailing-module_item_jf5qm{display:flex;flex-direction:column;gap:16px;width:100%;max-width:304px}@media(max-width: 767px){.Mailing-module_item_jf5qm{max-width:100%}}", "",{"version":3,"sources":["webpack://resources/js/react/src/views/Admin/Events/Notifications/Mailing/Mailing.module.scss","webpack://resources/js/react/src/scss/breakpoints.scss"],"names":[],"mappings":"AAEA,gCACE,YAAA,CACA,QAAA,CACA,sBAAA,CACA,UAAA,CACA,eAAA,CACA,aAAA,CCWE,yBDjBJ,gCASI,qBAAA,CACA,QAAA,CAAA,CAIJ,2BACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,UAAA,CACA,eAAA,CCFE,yBDHJ,2BAQI,cAAA,CAAA","sourcesContent":["@import \"../../../../../scss/breakpoints\";\n\n.container {\n  display: flex;\n  gap: 32px;\n  justify-content: center;\n  width: 100%;\n  max-width: 640px;\n  margin: 0 auto;\n\n  @include _sm {\n    flex-direction: column;\n    gap: 16px;\n  }\n}\n\n.item {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  width: 100%;\n  max-width: 304px;\n\n  @include _sm {\n    max-width: 100%;\n  }\n}","@mixin _xl {\n    @media (max-width: 1599px) {\n        @content;\n    }\n}\n\n@mixin _lg {\n    @media (max-width: 1279px) {\n        @content;\n    }\n}\n\n@mixin _md {\n    @media (max-width: 1023px) {\n        @content;\n    }\n}\n\n@mixin _sm {\n    @media (max-width: 767px) {\n        @content;\n    }\n}\n\n@mixin _xs {\n    @media (max-width: 576px) {\n        @content;\n    }\n}\n\n@mixin _xxs {\n    @media (max-width: 376px) {\n        @content;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Mailing-module_container_R64A2",
	"item": "Mailing-module_item_jf5qm"
};
export default ___CSS_LOADER_EXPORT___;
