import React from 'react';
import styles from './Alert.module.scss'
import ReactHtmlParser from "react-html-parser";

const Alert = ({data}) => {
    const checkButtonType = () => {
        if (data.style === 'info') {
            return 'primary'
        }

        return data.style
    }

    return (
        <div className={styles.container}>
            <p className={styles[data.style]}>
                {ReactHtmlParser(data.content.text || data.content.body)}
            </p>
            {data.content.button ? <a
                href={data.content.button.link} target={'_blank'}
                className={`a-btn a-btn_size_small a-btn_type_${checkButtonType()}-default`}>
                {data.content.button.name}
            </a> : null}
        </div>
    );
};

export default Alert;