import React, {useEffect, useState} from 'react';
import Mailing from "./Mailing/Mailing";
import System from "./System/System";
import Owners from "./Owners/Owners";
import Groups from "./Groups/Groups";
import {API} from "../../../../api/API";
import {postData} from "../../../../utils/sendData";
import {useRouteMatch} from "react-router-dom";
import JSONAPIDeserializer from "jsonapi-serializer/lib/deserializer";
import JSONAPISerializer from "jsonapi-serializer/lib/serializer";
import {useDispatch, useSelector} from "react-redux";
import Notification from "../../../../UIKit/component/notification/notification";
import Alert from "./Alert/Alert";
import page from "../Events.module.scss";
import groupsDataJson from "./Groups/Groups.data.json";
import groupDataJson from "./Groups/Group/Group.data.json";
import notificationDataJson from "./Notification/Notification.data.json";
import {generateHash} from "../../../../utils/generateHash";
import {methodGetNotificationPage} from "./Notifications.methods";

const Notifications = () => {
    const router = useRouteMatch()
    const {event_id, type} = router.params
    const is_course = type === 'course'
    const dispatch = useDispatch()
    const selector = useSelector(state => state)
    const [data, setData] = useState({...groupsDataJson})

    useEffect(() => {
        initAdminSelector()
        handleGetData()
    }, [])

    const handleGetData = async () => {
        try {
            await methodGetNotificationPage(event_id).then(e => handleInitData(e))
        } catch (e) {
            setData(prev => ({...prev, is_loading: 0}))
            return
        }
    }

    const handleInitData = (response) => {
        let groups, variables, periods, alerts, addNotificationsLimit, orgTemplates

        if (!response.data.groups) return
        groups = prepareGroupsData(response.data.groups)
        variables = prepareVariablesData(response.data.variables)
        periods = preparePeriodsData(response.data.periods)
        alerts = prepareAlertsData(response.data.alerts)
        addNotificationsLimit = prepareAddNotificationsLimit(response.data.addNotificationsLimit)
        orgTemplates = prepareOrgTemplatesData(response.data.orgTemplates)

        setData({
            ...groups, ...variables, ...periods, ...alerts, ...addNotificationsLimit, ...orgTemplates,
            is_loading: 1
        })
    }

    const initAdminSelector = async () => {
        let constants, event

        constants = await handleGetConstants()
        event = await handleGetEvent()

        dispatch({type: "SET_ADMIN", payload: {...selector.admin, event: event, constants: constants}})
    }

    const handleGetConstants = async () => {
        let response, data

        response = await postData(null, API.getCourseCreationParams.path, API.getCourseCreationParams.method)
        data = response.data.data

        return data
    }

    const handleGetEvent = async () => {
        let response, url, serialized, deserialized

        serialized = serializeData({})
        url = API.changeCourseInfo.path(event_id, is_course)
        response = await postData(serialized, url)
        deserialized = await deserializedData(response.data)

        return deserialized
    }

    const prepareGroupsData = (data) => ({
        groups: [
            ...groupsDataJson.groups,
            ...data.map((g, g_idx) => ({
                ...groupDataJson,
                ...g,
                id: g_idx,
                list: g.list.map((n, i) => ({
                    ...notificationDataJson,
                    ...n,
                    attributes: {
                        ...notificationDataJson.attributes,
                        ...n.attributes,
                    },
                    hash: generateHash(),
                    variables: g.variables,
                }))
            }))
        ]
    })

    const prepareVariablesData = (data) => ({
        variables: {...groupsDataJson.variables, ...data}
    })

    const preparePeriodsData = (data) => ({
        periods: {...groupsDataJson.variables, ...data}
    })

    const prepareAlertsData = (data) => ({
        alerts: [...groupsDataJson.alerts, ...data]
    })

    const prepareOrgTemplatesData = (data) => ({
        orgTemplates: [...groupsDataJson.orgTemplates, ...data]
    })

    const prepareAddNotificationsLimit = (data) => ({
        addNotificationsLimit: data
    })

    const deserializedData = async (data) => new JSONAPIDeserializer({
        keyForAttribute: 'underscore_case',
    }).deserialize(JSON.parse(JSON.stringify(data)), (err, deserializeData) => deserializeData);

    /* Сериализация данных в принимаемый формат API */
    const serializeData = (params) => {
        let config, result, body, allowed

        allowed = ['is_send_owners', 'is_send_users']

        config = {
            keyForAttribute: 'underscore_case',
            attributes: allowed,
            typeForAttribute: (attribute, data) => data.customType,
            event: {
                ref: 'id',
                included: false
            },
        }

        body = {
            ...params,
            id: event_id,
        };

        result = new JSONAPISerializer(type, config).serialize(body);
        return result
    }

    return (
        <>
            {data.alerts.length ? <div className={page.notifications}>
                {data.alerts.filter(x => x.place === 'top').map((alert, alert_idx) => <Notification
                    key={alert_idx}
                    icon={false}
                    value={<Alert data={alert}/>}
                    type={alert.style}
                />)}
            </div> : null}
            <Mailing/>
            <Groups data={data} onChange={setData} onReset={handleGetData}/>
            <Owners data={data} onChange={setData}/>
            <System/>
        </>
    );
};

export default Notifications;
