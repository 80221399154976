import React, {useState, useEffect} from 'react';
import '../../style/SelectGroup.scss'
import Button from "../../../../../../components/ui/Button";
import IconEdit from "../../../../../../assets/images/icons/16/pencil-blue.svg";
import {HexColorPicker} from "react-colorful";
import IconMessageDots from "../../../../../../components/modal/icon/messageDots.svg";
import IconClose from "../../../../../../assets/images/icons/16/x.svg";
import Input from "../../../../../../components/ui/Input";
import {useDispatch, useSelector} from "react-redux";

const DesignerSelectGroup = ({
                                 groupList,
                                 setGroupList,
                                 activeGroup,
                                 setActiveGroup,
                                 selectedNodes,
                                 setSelectedNodes,
                                 place,
                                 setPlace,
                                 thisNodePlace,
                                 thisNodePolygon,
                                 setChangeMade
                             }) => {

        const [display, setDisplay] = useState(false);
        const [modalEditor, setModalEditor] = useState({display: false, title: ''});
        const [color, setColor] = useState("#aabbcc");
        const [colorEdit, setColorEdit] = useState(false);
        const [colorPicker, setColorPicker] = useState(false);
        const [name, setName] = useState('')
        const [nameError, setNameError] = useState('')
        const [typeEdit, setTypeEdit] = useState(null);
        const [idEdit, setIdEdit] = useState(null);
        const colorArr = ["#ffe3e3", "#ffc9c9", "#ffa8a8", "#ff8787", "#ffdeeb", "#fcc2d7", "#faa2c1", "#f783ac", "#f3d9fa", "#eebefa", "#e599f7", "#da77f2", "#e5dbff", "#d0bfff", "#b197fc", "#9775fa", "#dbe4ff", "#bac8ff", "#91a7ff", "#748ffc", "#d0ebff", "#a5d8ff", "#74c0fc", "#4dabf7", "#c5f6fa", "#99e9f2", "#66d9e8", "#3bc9db", "#c3fae8", "#96f2d7", "#63e6be", "#38d9a9", "#d3f9d8", "#b2f2bb", "#8ce99a", "#69db7c", "#e9fac8", "#d8f5a2", "#c0eb75", "#a9e34b", "#fff3bf", "#ffec99", "#FFE066", "#FFD43B", "#FFE8CC", "#FFD8A8", "#FFC078", "#FFA94D",]
        const dispatch = useDispatch();
        const [fadeOut, setFadeOut] = useState(false);
        const noticeData = useSelector(state => state.notice);

        const handleColor = (item) => {
            setColor(item);
        }

        const handleName = (item, type) => {
            setNameError('');
            if (item.length && item.replace(/ /g, '').length === 0 && type !== 'rename_group') {
                setNameError('Название зала обязательно для заполнения')
            }
            if (item.length >= 255) {
                setNameError('Длина названия не должна превышать 255 символов')
            }
            setName(item);
        }

        const colorScheme = () => {
            return colorArr.map((item, i) => {
                return <div key={i} onClick={() => {
                    handleColor(item)
                }}
                            style={{background: item}}
                            className={`col${color === item ? ' pressed' : ''}`}></div>
            })
        }

        const handleSaveGroup = () => {
            // сохранение группы
            if (name.trim().length === 0) {
                setNameError('Название группы обязательно для заполнения')
                return
            } else if (name.length >= 255) {
                setNameError('Длина названия не должна превышать 255 символов')
                return
            }

            if (idEdit === null) {
                let id = Number(`-${groupList.length + 5}`)
                setGroupList(groupList.concat([{id: id, name: name, type: typeEdit, color: color}]))
            } else {
                setGroupList(groupList.map(item => {
                    return item.id == idEdit ? {id: idEdit, name: name, type: typeEdit, color: color} : item
                }))
            }
            setChangeMade(true)
            setModalEditor({...modalEditor, display: false})
            setDisplay(false);
        }

        const handleDeleteGroup = () => {
            // удаление группы
            let error = false;
            let errorMsg = '';

            !error && place.forEach(item => {
                if (typeof item['group'] !== undefined) {
                    if (item['group'] !== null) {
                        if (item.group.id == idEdit) {
                            error = true
                            errorMsg = 'Чтобы удалить группу, открепите от неё места'
                        }
                    }
                }
            })

            if (!error) {
                setGroupList(groupList.filter((item) => item.id != idEdit))
                setActiveGroup(0)
                setChangeMade(true)
                setModalEditor({...modalEditor, display: false})
                setDisplay(false);
            } else {
                dispatch({
                    type: "SET_NOTICE",
                    payload: {
                        ...noticeData,
                        type: "error",
                        open: true,
                        message: errorMsg
                    }
                })
            }
        }

        const newGroup = (item, type) => {
            setColorEdit(false);
            if (item !== null) {
                setName(item.name)
                setColor(item.color)
                setTypeEdit(item.type)
                setIdEdit(item.id);
            } else {
                setIdEdit(null);
                setName('');
                setColor('#aabbcc');
                setTypeEdit(type);
            }
            let nameModal = function () {
                let thisType = item !== null ? item.type : type;
                if (thisType === 1) return 'Группа мест'
                if (thisType === 4) return 'Зона без нумерации'
                if (thisType === 3) return 'Столы'
            }()
            setDisplay(!display);
            setModalEditor({...modalEditor, display: true, title: nameModal});
        }

        const handleSelectItem = (index) => {
            setActiveGroup(index);
            setDisplay(false);
            setChangeMade(true)
            setPlaceGroup(index);
        }

        const setPlaceGroup = (indexActiveGroup) => {
            // применение новой группы к выбранным местам
            let arrType = [];
            let activeGroupType = '';
            if (groupList[indexActiveGroup].type === 1) activeGroupType = 'place';
            if (groupList[indexActiveGroup].type === 4) activeGroupType = 'freeZone';
            if (groupList[indexActiveGroup].type === 3) activeGroupType = 'table';

            selectedNodes.forEach(item => {
                if (item.type !== 'scene') arrType.push(item.group.type)
            })

            arrType = arrType.filter((el, id) => arrType.indexOf(el) === id)
            if (arrType.length === 1) {
                setPlace(place.map(item => {
                    if (item.type === 'place' && activeGroupType === 'place') if (thisNodePlace(item)) item.group = groupList[indexActiveGroup]
                    if (item.type === 'table' && activeGroupType === 'table') if (thisNodePolygon(item)) item.group = groupList[indexActiveGroup]
                    if (item.type === 'freeZone' && activeGroupType === 'freeZone') if (thisNodePolygon(item)) item.group = groupList[indexActiveGroup]
                    return item
                }))
            }
        }

        const ItemGroup = ({item, index}) => {
            return <div onClick={() => {
                handleSelectItem(index)
            }} className={activeGroup === index ? "item item__active" : "item"}>
                <div className={activeGroup !== index ? "item__color" : "item__color item__color_active"}>
                    <span style={{background: item.color}}></span>
                </div>
                <div className={"item__name"}>{item.name}</div>
                <div>
                    <button className={'a-btn a-btn_size_small a-btn_type_secondary-ghost'} onClick={() => {
                        newGroup(item)
                    }}><IconEdit/></button>
                </div>
            </div>
        }

        const closeModal = () => {
            setFadeOut(true)
            setTimeout(e => {
                // setDisplay(true)
                setModalEditor({...modalEditor, display: false})
                setFadeOut(false)
            }, 150)
        }

        const getName = () => {
            let name = '-'
            if (groupList.length && activeGroup !== null) {
                if (groupList[activeGroup]?.name) {
                    name = groupList[activeGroup]?.name
                }
            }
            return name
        }

        return (<div className={`selectGroup${display ? ' selectGroup__display' : ''}`}>
            <div data-tip="React-tooltip" data-for='toolTipSelectGroup' onClick={() => {
                activeGroup !== null ? setDisplay(!display) : ''
            }}
                 className="select">{getName(groupList, activeGroup)}</div>
            {display && <div className="content">
                <div className="row">
                    <div className="row__head">
                        <div className="row__head_title">Группы мест</div>
                        <Button
                            type={'outline'}
                            scheme={'secondary'}
                            size={"xsmall"}
                            value={"Создать"}
                            onClick={() => {
                                newGroup(null, 1)
                            }}
                        />
                    </div>
                    <div className={"row__list"}>
                        {groupList
                            .map((item, i) => {
                                return item.type === 1 ? <ItemGroup key={i} index={i} item={item}/> : ''
                            })}
                    </div>
                </div>

                <div className={"row"}>
                    <div className="row__head">
                        <div className="row__head_title">Зоны без нумерации</div>
                        <Button
                            type={'outline'}
                            scheme={'secondary'}
                            size={"xsmall"}
                            value={"Создать"}
                            onClick={() => {
                                newGroup(null, 4)
                            }}
                        />
                    </div>
                    <div className={"row__list"}>
                        {groupList
                            .map((item, i) => {
                                return item.type === 4 ? <ItemGroup key={i} index={i} item={item}/> : ''
                            })}
                    </div>
                </div>

                <div className={"row"}>
                    <div className="row__head">
                        <div className="row__head_title">Столы</div>
                        <Button
                            type={'outline'}
                            scheme={'secondary'}
                            size={"xsmall"}
                            value={"Создать"}
                            onClick={() => {
                                newGroup(null, 3)
                            }}
                        />
                    </div>
                    <div className={"row__list"}>
                        {groupList
                            .map((item, i) => {
                                return item.type === 3 ? <ItemGroup key={i} index={i} item={item}/> : ''
                            })}
                    </div>
                </div>
            </div>}
            {modalEditor.display &&
                <div className="modal-react">
                    <div className={`modal-react__window${fadeOut ? ' ui-nh__fadeout' : ''}`}>
                        <div className={"modal-react__container"}>
                            <div className={"modal-react__container__head"}>
                                <div className={"modal-react__container__icon"}><IconMessageDots/></div>
                                <div className={"modal-react__container__title"}>{modalEditor.title}</div>
                                <div className={"modal-react__container__close"}
                                     onClick={() => {
                                         closeModal()
                                     }}><IconClose/>
                                </div>
                            </div>
                            <div className={"modal-react__container__content"}>
                                {!colorEdit && <div className="modalEditorGroup">
                                    <div className="col1">
                                        {/*<div className="title">Название(Партер, Амфитеатр, ...)</div>*/}
                                        <div>
                                            <Input
                                                size={'medium'}
                                                outside={true}
                                                label={'Название группы (Партер, Амфитеатр, ...)'}
                                                // required={item.required}
                                                value={name}
                                                error={nameError}
                                                handleChange={(e) => {
                                                    handleName(e.currentTarget.value, 'rename_group')
                                                }}
                                            />
                                            {/*<input onChange={(e) => handleName(e.target.value)}*/}
                                            {/*        value={name}/>*/}

                                        </div>
                                    </div>
                                    <div className="col2">
                                        <div className="title">Цвет</div>
                                        <div style={{background: color, border: "1px solid #A7B0CA"}} onClick={() => {
                                            setColorEdit(!colorEdit)
                                        }} className="color"></div>
                                    </div>
                                </div>}
                                {colorEdit && <div className="modalEditorGroup">
                                    <div className="colorScheme">
                                        <div className="title"> Цвет</div>
                                        <div className="row">
                                            {colorScheme()}
                                        </div>
                                        <div className="colorSetup">
                                            <div style={{background: color}} className="colorSelect"
                                                 onClick={() => setColorPicker(!colorPicker)}></div>
                                            <input className="inputColor" onChange={(e) => handleColor(e.target.value)}
                                                   value={color}/>
                                            <Button
                                                type={'outline'}
                                                scheme={'secondary'}
                                                size={"small"}
                                                value={"Готово"}
                                                onClick={() => {
                                                    setColorEdit(!colorEdit)
                                                }}
                                            />
                                        </div>
                                        {colorPicker &&
                                            <div className="HexColorPicker"><HexColorPicker color={color}
                                                                                            onChange={handleColor}/>
                                            </div>}
                                    </div>
                                </div>}
                            </div>

                            <div className={"modal-react__container__bottom"}>
                                {idEdit && <Button
                                    onClick={() => {
                                        handleDeleteGroup()
                                    }
                                    }
                                    value="Удалить группу"
                                    size={"small"}
                                    scheme={"tertiary-ghost"}
                                />}
                                <div className="actions">
                                    <Button
                                        onClick={() => {
                                            closeModal()
                                        }}
                                        value="Отмена"
                                        size={"small"}
                                        scheme={"tertiary-ghost"}
                                    />

                                    <Button
                                        onClick={() => handleSaveGroup()}
                                        value='сохранить'
                                        size={"small"}
                                        scheme={"primary"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </div>);
    }
;

export default DesignerSelectGroup;
