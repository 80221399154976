import React, {useState} from 'react';
import Switcher from "../../../../../../UIKit/component/switcher/Switcher";
import InfoIcon from '../../../../../../assets/images/icons/16/info_cr.svg'
import {postData} from "../../../../../../utils/sendData";
import {API} from "../../../../../../api/API";
import "./Switchers.scss";

const Switchers = ({data, event_id, is_course}) => {
    const [preloader, setPreloader] = useState(false)
    const [fields, setFields] = useState(data)

    /* Изменить состояние Switcher */
    const handleChange = async (field, boolean) => {
        setPreloader(true);
        const data = {
            data: {
                type: is_course ? 'courses' : 'events',
                id: event_id,
                attributes: {[field.name]: boolean}
            }
        };
        await postData(data, API.changeCourseInfo.path(event_id, is_course), API.changeCourseInfo.method);
        setPreloader(false);
        setFields(fields.map(x => x.name === field.name ? {...x, value: boolean} : x));
    }

    return (
        <div className={'ui-cp__form-switchers'}>
            {fields.map((field, field_idx) => <div key={field_idx}>
                <Switcher
                    id={`form-switcher__${field_idx}`}
                    size={'medium'}
                    icon={field.toggletip ? <InfoIcon/> : null}
                    placeholder={field.toggletip}
                    parent={document.querySelector('main')}
                    value={field.title}
                    checked={field.value}
                    disabled={preloader}
                    onChange={(boolean) => handleChange(field, boolean)}
                    error={false}
                />
            </div>)}
        </div>
    );
};

export default Switchers;