import {API} from "../../../../../api/API";
import {postData} from "../../../../../utils/sendData";
import JSONAPIDeserializer from "jsonapi-serializer/lib/deserializer";
import JSONAPISerializer from "jsonapi-serializer/lib/serializer";

export const methodUpdateMailing = async (params, event_id, is_course) => {
    let response, url, method, serialized, deserialized, config, body

    config = {
        keyForAttribute: 'underscore_case',
        attributes: [
            'event',
            "domain_email_id",
            "domain_email_name",
            "reply_to_additional_email_id",
            "reply_to_additional_email_name"
        ],
        typeForAttribute: (attribute, data) => data.customType,
    }
    params = {...params, id: event_id}
    serialized = new JSONAPISerializer(is_course ? 'courses' : 'events', config).serialize(params);

    url = API.changeCourseInfo.path(params.id, is_course)
    method = API.changeCourseInfo.method

    response = await postData(serialized, url, method)
    deserialized = await deserializedData(response.data)

    return deserialized
}

const deserializedData = async (data) => new JSONAPIDeserializer({
    keyForAttribute: 'underscore_case',
}).deserialize(JSON.parse(JSON.stringify(data)), (err, deserializeData) => deserializeData);
