import React from 'react';
import {apiForm} from '../api/PREFIX'

const axios = require('axios');

const postData = async (data, url, method, baseURL) => {
    let options = {
        method: method,
        url: `${url}`,
        headers: {
            Accept: 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        },
    }
    if (baseURL) options['baseURL'] = baseURL
    data ? options.data = JSON.stringify(data) : null
    return axios(options);
};

const postDataAutch = async (data, url, method, token) => {
    return axios({
        method: method,
        url: `${url}`,
        data: JSON.stringify(data),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    });
};

const putAuth = async (url, method, token) => {
    return axios({
        method: method,
        url: `${url}`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    });
};

const getOrderData = async (orderID, token) => {
    return axios({
        method: 'GET',
        url: `${apiForm}/order/${orderID}`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    });
};


const postHeadAutch = async (url, method, token) => {
    return axios({
        method: method,
        url: `${url}`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    });
};

const postDataOldWay = async (data, url, method) => {
    return axios({
        method: method,
        url: `${url}`,
        data: data,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
    });
};

const postFormData = async (data, url, method) => {
    return axios({
        method: method,
        url: `${url}`,
        data: data,
        headers: {
            Accept: '*/*',
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
    });
};

const postFile = async (data, url, method, handleProgress) => {
    return axios({
        method: method,
        url: `${url}`,
        data: data,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'form/multipart; charset=UTF-8',
        },
        onUploadProgress: function (progressEvent) {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            //percentCompleted === 100 ? percentCompleted = 0 : null;
            handleProgress(percentCompleted)
        }
    })
};

const putFile = async (url, data, handleProgress) => {
    return axios({
        method: 'PUT',
        url: `${url}`,
        data: data,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'form/multipart; charset=UTF-8',
            'x-amz-acl': 'public-read',
        },
        onUploadProgress: function (progressEvent) {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            //percentCompleted === 100 ? percentCompleted = 0 : null;
            handleProgress ? handleProgress(percentCompleted) : console.log(percentCompleted)
        }
    })
};

export {postData, postDataOldWay, postFile, postFormData, putFile, postDataAutch, postHeadAutch, putAuth, getOrderData};
