import React from 'react';
import styles from './Group.module.scss'
import page from "../../../Events.module.scss";
import Notification from "../../Notification/Notification";
import Alert from "../../Alert/Alert";
import NotificationUI from '../../../../../../UIKit/component/notification/notification'
import AddIcon from '../../../../../../assets/images/icons/16/plus_simple.svg'
import notificationDataJson from '../../Notification/Notification.data.json'

const Member = ({data, variables, periods, alerts, addNotificationsLimit, onChange, onReset}) => {
    const handleCreateNotification = () => {
        onChange(prev => ({
            ...prev,
            groups: prev.groups.map((group, group_idx) => group.variables === 'member' && group.id === data.id ? {
                ...group,
                list: [...group.list, {
                    ...notificationDataJson,
                    attributes: {...notificationDataJson.attributes, is_edit: true}
                }]
            } : {...group, list: group.list.map(n => ({...n, attributes: {...n.attributes, is_disabled: true}}))})
        }))
    }

    return (
        <div className={page.section}>
            {data.name ? <h3 className={page.title}>
                {data.name}
            </h3> : null}
            <div className={styles.container}>
                {data.list.map((notification, notification_idx) => <Notification
                    key={notification_idx}
                    data={notification}
                    variables={variables}
                    periods={periods}
                    onChange={onChange}
                    onReset={onReset}
                />)}
            </div>
            {data.variables === 'member' && data.id === 0 ? <>
                {alerts.length ? alerts.filter(x => x.place === 'group').map((alert, alert_idx) => <NotificationUI
                    key={alert_idx}
                    icon={false}
                    value={<Alert data={alert}/>}
                    type={alert.style}
                />) : null}
                {addNotificationsLimit > 0 ? <div className={styles.add}>
                    <button
                        className={'a-btn a-btn_size_small a-btn_icon_left a-btn_type_secondary-default'}
                        disabled={data.list.find(x => (x.hash === null || x.attributes.is_edit))}
                        onClick={handleCreateNotification}>
                        <AddIcon/>
                        Добавить письмо
                    </button>
                </div> : null}
            </> : null}

        </div>
    );
};

export default Member;