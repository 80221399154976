// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Editor-module_quill_qgvZ\\+{transition:border .15s ease-in-out,box-shadow .15s ease-in-out;background:#fff}.Editor-module_error_w7KJt{-webkit-box-shadow:0 0 0 2px #f1cbce;-moz-box-shadow:0 0 0 2px #f1cbce;box-shadow:0 0 0 2px #f1cbce}.Editor-module_error_w7KJt .ql-toolbar,.Editor-module_error_w7KJt .ql-container{border:1px solid #fb3640}", "",{"version":3,"sources":["webpack://resources/js/react/src/UIKit/component/editor/Editor.module.scss"],"names":[],"mappings":"AAIA,4BACI,8DAAA,CACA,eAAA,CAGJ,2BACI,oCAAA,CACA,iCAAA,CACA,4BAAA,CAGJ,gFAEI,wBAAA","sourcesContent":[".container {\n\n}\n\n.quill {\n    transition: border .15s ease-in-out, box-shadow .15s ease-in-out;\n    background: #ffffff;\n}\n\n.error {\n    -webkit-box-shadow: 0 0 0 2px #f1cbce;\n    -moz-box-shadow: 0 0 0 2px #f1cbce;\n    box-shadow: 0 0 0 2px #f1cbce;\n}\n\n.error :global(.ql-toolbar),\n.error :global(.ql-container) {\n    border: 1px solid #fb3640;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"quill": "Editor-module_quill_qgvZ+",
	"error": "Editor-module_error_w7KJt",
	"qlToolbar": "ql-toolbar",
	"qlContainer": "ql-container"
};
export default ___CSS_LOADER_EXPORT___;
