import {MemberDataInterface} from './MemberCharts.interface';

export const memberChartsData: MemberDataInterface = {
    visitors: null,
    sales: null,
    time: null,
    progress: null,
    type: {
        visitorsType: 'visitors',
        salesType: 'sales',
        timeType: 'time',
        progressType: 'progress',
    },
    startDate: '',
    endDate: '',
    filter: false,
};
