import React, {useEffect, useRef, useState} from 'react';
import CalendarIcon from "../../assets/images/icons/16/calendar.svg";
import Flatpickr from "react-flatpickr";
import {convert} from "../../utils/nhApi";
import {Russian} from 'flatpickr/dist/l10n/ru';
import Select from "../../UIKit/component/select/Select";


const SessionFilter = ({data, actions, preloader}) => {
        const flatpickr = useRef()

        const filter = useRef({
            date: false,
            session: false,
            time: false,
            from: '',
            valid: true,
            changedData: false // false - не изменяли значения фильтра
        })

        useEffect(() => {
            if (data.schedule.selectedDate) filter.current.date = data.schedule.selectedDate
            filter.current.session = data.schedule.nearestSessionId
            filter.current.time = data.schedule.nearestTime

            dataCalendar.current.schedulesStore = data.schedule

        }, [])

        useEffect(() => {
            let arr = dataCalendar.current.schedulesStore.dates.concat(data.schedule.dates)

            arr = [...new Set(arr)]
            dataCalendar.current.schedulesStore.dates = arr

            if (!filter.current.valid) {
                if (data.schedule.nearestDate) filter.current.date = data.schedule.nearestDate
                if (data.schedule.nearestSessionId) filter.current.session = data.schedule.nearestSessionId
                if (data.schedule.nearestTime) filter.current.time = data.schedule.nearestTime
                filter.current.valid = true
            }

            initCalendar()
        }, [data.schedule])

        const dataCalendar = useRef({
            // date: selector.form.data.schedules.selectedDates,
            year: null,
            month: null,
            from: null,
            instance: {},
            init: false,
            schedulesStore: {
                dates: [],
                sessions: [],
                times: []
            },
            options:
                {
                    enableTime: false,
                    dateFormat: "Y-m-d",
                    altFormat: "d.m.Y",
                    locale: Russian,
                    monthSelectorType: "static",
                    enable: [],
                }
        })

        const initCalendar = () => {
            let enableDates = []
            dataCalendar.current.schedulesStore.dates.forEach((item) => {
                    enableDates.push(item)
                }
            )
            let thisDate = new Date().setHours(0, 0, 0, 0)
            if (enableDates.length) {
                dataCalendar.current.instance.set('enable', enableDates.map((item) => {
                    if (thisDate < new Date(item).setHours(0, 1, 0, 0)) {
                        return item
                    }
                }))

                let val = data.schedule.selectedDate || data.schedule.nearestDate
                if (!dataCalendar.current.init) {
                    dataCalendar.current.instance.setDate(val)
                    dataCalendar.current.instance.jumpToDate(val)
                    dataCalendar.current.init = true
                }
            }
        }

        const handleConstructFilter = async (options, initData) => {
            let date, time, session, from, timezone
            timezone = data.timezone
            if (!options) options = {}
            session = options.session || filter.current.session
            date = options.date || filter.current.date
            time = options.time || filter.current.time
            from = options.from || convert(handleConstructFromField(flatpickr.current?.flatpickr), timezone)
            let newDate = await actions.sessions.handleUpdateStatus({
                session: session,
                date: date && convert(new Date(date), timezone),
                time: time && convert(new Date(time), timezone),
                from: from,
            }, false, initData)
        }

        /* Смена даты */
        const handleChangeDate = (selectedDates, dateStr, instance) => {
            let date, from
            date = flatpickr.current?.flatpickr.selectedDates[0]
            from = convert(handleConstructFromField(flatpickr.current?.flatpickr), data.timezone)
            let selectDate, fromDate = from.split('T')[0].slice(0, -3);
            if (typeof (filter.current.date) === 'string') {
                selectDate = filter.current.date.split('T')[0].slice(0, -3)
            } else {
                selectDate = `${filter.current.date.getFullYear()}-${filter.current.date.getMonth().toString().padStart(2, '0')}`
            }

            if (fromDate !== selectDate) {
                filter.current.session = null
                filter.current.time = null
                filter.current.valid = false
            }

            actions.sessions.checkForChanges()
            filter.current.date = date
            filter.current.changedData = true
            handleConstructFilter({date: dateStr})
        }

        /* Смена месяца */
        const handleChangeMonth = async (year, month, instance) => {
            let from;

            dataCalendar.current.year = year;
            dataCalendar.current.month = month;

            from = convert(handleConstructFromField(flatpickr.current.flatpickr), data.timezone)
            filter.current.from = from
            actions.sessions.checkForChanges()

            await actions.sessions.handleUpdateStatus({
                from: from,
            }, true, data.schedule)
        }

        const selectedTime = () => {
            let result = null
            if (data.schedule.selectedTime !== null) {
                result = String(data.schedule.times.indexOf(data.schedule.selectedTime)).toString()
            }
            return result
        }

        const selectedSession = () => {
            let result = null
            if (data.schedule.selectedSessionId !== null) {
                result = data.schedule.sessions.findIndex(x => x.id == data.schedule.selectedSessionId).toString()
            }
            return result
        }

        const handleConstructFromField = (instance) => {
            let date = new Date()

            date.setHours(0, 0, 0, 0)
            date.setDate(1)
            date.setFullYear(instance.currentYear)
            date.setMonth(instance.currentMonth)

            return date
        }

        const handleSession = (idx) => {
            let available = actions.sessions.checkForChanges()
            if (available) {
                filter.current.session = data.schedule.sessions[idx].id
                filter.current.changedData = true
                let options = handleConstructFilter({session: filter.current.session})
                //actions.sessions.handleUpdateStatus(options)
            }
        }

        const handleTime = (idx) => {
            let available = actions.sessions.checkForChanges()
            if (available) {
                filter.current.times = data.schedule.times[idx]
                filter.current.changedData = true
                let options = handleConstructFilter({time: filter.current.times})
            }
        }

        /* Форматирование времени (hh:mm) */
        const FormatTime = (time) => {
            return time && time.split('T')[1].split(':').splice(0, 2).join(':')
        }


        return (
            <div>
                {data.schedule && <div className="bottom-bar__wrapper-control">
                    <div className="bottom-bar__section bottom-bar__session">
                        <div className="bottom-bar__section bottom-bar__date">
                            <div className="bottom-bar__section-label">
                                Дата
                            </div>
                            <div className="bottom-bar__section-calendar">
                                <div className={'bottom-bar__section-calendar--icon'}>
                                    <CalendarIcon/>
                                </div>
                                <div style={{width: '100%'}} onClick={() => actions.sessions.checkForChanges()}>
                                    <Flatpickr
                                        ref={flatpickr}
                                        onReady={(selectedDates, dateStr, instance) => {
                                            dataCalendar.current.instance = instance
                                            initCalendar()
                                            instance.prevMonthNav.innerHTML = '<svg width="7" height="12" viewBox="0 0 7 12" fill="#currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clipRule="evenodd" d="M6.70711 0.292893C6.31658 -0.0976311 5.68342 -0.0976311 5.29289 0.292893L0.292893 5.29289C-0.0976311 5.68342 -0.0976311 6.31658 0.292893 6.70711L5.29289 11.7071C5.68342 12.0976 6.31658 12.0976 6.70711 11.7071C7.09763 11.3166 7.09763 10.6834 6.70711 10.2929L2.41421 6L6.70711 1.70711C7.09763 1.31658 7.09763 0.683417 6.70711 0.292893Z" fill="#currentColor"/></svg>'
                                            instance.nextMonthNav.innerHTML = '<svg width="7" height="12" viewBox="0 0 7 12" fill="#currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clipRule="evenodd" d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683417 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z" fill="#currentColor"/></svg>'
                                        }}
                                        onOpen={(selectedDates, dateStr, instance) => {
                                            //    handleChangeMonth(instance.currentYear, instance.currentMonth, instance)
                                            instance.jumpToDate(new Date(new Date(filter.current.date).setHours(0, 0, 0, 0)))

                                        }}
                                        options={dataCalendar.current.options}
                                        onChange={(selectedDates, dateStr, instance) => handleChangeDate(selectedDates, dateStr, instance)}
                                        onYearChange={(selectedDates, dateStr, instance) => handleChangeMonth(instance.currentYear, instance.currentMonth, instance)}
                                        onMonthChange={(selectedDates, dateStr, instance) => handleChangeMonth(instance.currentYear, instance.currentMonth, instance)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-bar__wrapper-date">
                        <div className="bottom-bar__section bottom-bar__time">
                            <div className="bottom-bar__section-label">
                                Сеанс
                            </div>
                            <div className="bottom-bar__section-select">
                                {!preloader ? <Select
                                    direction={['top']}
                                    data={data.schedule.sessions.map(x => x.name)}
                                    value={selectedSession()}
                                    size={'small'}
                                    error={false}
                                    disabled={preloader}
                                    onChange={(idx) => handleSession(idx)}
                                /> : null}
                            </div>
                        </div>

                        <div className="bottom-bar__section bottom-bar__time">
                            <div className="bottom-bar__section-label">
                                Время
                            </div>
                            <div className="bottom-bar__section-select">
                                {!preloader ? <Select
                                    direction={['top']}
                                    value={selectedTime()}
                                    disabled={preloader}
                                    size={'small'}
                                    data={data.schedule.times.map(time => FormatTime(time))}
                                    error={false}
                                    onChange={(idx) => handleTime(idx)}
                                /> : null}
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        );
    }
;

export default SessionFilter;
