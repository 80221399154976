import React from "react";
import "../scss/preloader.scss";
import PreloaderAnimation from "../assets/images/icons/preloader.svg";

export default function Preloader() {
    return (
        <div className="preloader">
            <div className="preloader__container">
                <PreloaderAnimation />
            </div>
        </div>
    );
}
