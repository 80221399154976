import React, {useState} from 'react';
import Notification from "../../notification/notification";
import '../../../scss/components/window/template/pushka.scss'
import Input from "../../../../components/ui/Input";

const Pushka = ({onSubmit, onClose, links}) => {
    const [fields, setFields] = useState({
        cultureEventId: {
            value: null,
            error: null
        },
    })

    const [preloader, setPreloader] = useState(false)

    /* Компонент инлайн-уведомления в модальном окне */
    const NotificationModalContent = () => {
        return <div>
            <strong>Для подключения приёма платежей, в форму покупки билета будут внесены изменения:</strong>
            <p>
                1. Поле "Фамилия Имя Отчество" будет разделено на три отдельных поля "Фамилия", "Имя" и "Отчество",
                идущих друг за другом. Фамилия и Имя будут обязательными к заполнению.
            </p>
            <p>
                2. Поле "Телефон" будет добавлено после поля "Отчество" и будет обязательным к заполнению. Подробнее о
                правилах приёма платежей по пушкинской карте можно узнать в <a href={links.docs.href} target={links.docs.target}>инструкции</a>.
            </p>
        </div>
    }

    const handleChangeField = (name, value) => {
        let form = JSON.parse(JSON.stringify(fields))

        if (name === 'cultureEventId' && (/^[0-9\b]+$/.test(value) || !value)) {
            form.cultureEventId = {value: value, error: null}
        }

        setFields(form)
    }

    const handleSubmit = async () => {
        let response, form

        form = JSON.parse(JSON.stringify(fields))

        setPreloader(true)
        response = await onSubmit(fields)
        setPreloader(false)

        if (response.errors && Object.keys(response.errors).length) {
            Object.entries(includedValidation(response.errors)).map(([name, error]) => form[name].error = error[0])
            setFields(form)
        }

        if (response.success) {
            showTopMessage('success', 'large', 'Операция выполнена успешно', 'Оплата Пушкинской картой была успешно подключена')
            onClose(true)
        }

        if (response.success === false) {
            showTopMessage('error', 'small', 'Произошла ошибка', response.message || 'Повторите попытку позднее')
        }
    }

    const includedValidation = (data) => {
        delete data.dates
        return data
    }

    return (
        <form onSubmit={e => e.preventDefault()}>
            <div className="nh-window__pushka__header">
                <h6>Подключения приёма платежей по Пушкинской карте</h6>
            </div>
            <div className="nh-window__pushka__body">
                <p>Укажите идентификатор события на Культура.РФ (<a href={links.docs.href} target={links.docs.target}>инструкция</a>)</p>
                <Input
                    maxLength={7}
                    size={'medium'}
                    outside={1}
                    id={'cultureEventId'}
                    value={fields.cultureEventId.value}
                    placeholder={'Идентификатор события'}
                    handleChange={(e) => handleChangeField('cultureEventId', e.target.value)}
                    error={fields.cultureEventId.error}
                    disabled={preloader}
                />
                <Notification type={'warning'} value={NotificationModalContent()}/>
            </div>
            <div className="nh-window__pushka__footer">
                <button
                    type={'reset'}
                    className={`a-btn a-btn_size_small a-btn_type_tertiary-ghost`}
                    disabled={preloader}
                    onClick={onClose}>
                    Отменить
                </button>
                <button
                    type={'submit'}
                    className={`a-btn a-btn_size_small a-btn_type_primary-default`}
                    disabled={preloader}
                    onClick={handleSubmit}>
                    Отправить
                </button>
            </div>
        </form>
    );
};

export default Pushka;
