import React from 'react';
import Confirm from "../../../../../../UIKit/component/confirm/confirm";
import styles from './Owner.module.scss'
import Edit from "./Edit/Edit";
import EditIcon from "../../../../../../assets/images/icons/16/pencil.svg";
import TrashIcon from "../../../../../../assets/images/icons/16/trash.svg";
import {
    methodCreateEmail,
    methodCreateSubscribe,
    methodRemoveSubscribe,
    methodUpdateSubscribe,
} from "../Owners.methods";
import {useRouteMatch} from "react-router-dom";

const Owner = ({data, onChange, orgTemplates, onUpdate}) => {
    const router = useRouteMatch()
    const {event_id, type} = router.params
    const is_course = type === 'course'
    const selectedOrgTemplates = orgTemplates.filter(x => (BigInt(data.types || 0) & BigInt(x.email_type)) === BigInt(x.email_type))
    const handleEdit = () => {
        onChange(prev => ({
            ...prev, list: prev.list.map(x => x.hash === data.hash ? {...x, is_edit: !x.is_edit} : {
                ...x,
                is_disabled: !x.is_disabled
            }).filter(x => x.hash)
        }))
    }

    const handleConfirm = () => {
        onChange(prev => ({
            ...prev, list: prev.list.map(x => x.hash === data.hash ? {
                ...x,
                is_confirm: !x.is_confirm,
                is_disabled: !x.is_disabled
            } : {
                ...x,
                is_disabled: !x.is_disabled
            })
        }))
    }

    const handleRemove = async () => {
        try {
            await methodRemoveSubscribe({id: data.id})
        } catch (e) {
            showTopMessage('error', 'small', 'Произошла ошибка. Повторите попытку позднее')
            return
        }

        showTopMessage('success', 'small', 'Операция выполнена успешно')
        onChange(prev => ({
            ...prev,
            list: prev.list.filter(x => x.hash !== data.hash).map(x => ({...x, is_disabled: null}))
        }))
    }

    const handleSave = async (params) => {
        try {
            data.hash ? await handleUpdateSubscribe(params) : await handleCreateSubscribe(params)
            await onUpdate()
        } catch (e) {
            return e.response
        }
    }

    const handleUpdateSubscribe = async (params) => {
        return await methodUpdateSubscribe(params, event_id, is_course)
    }

    const handleCreateSubscribe = async (params) => {
        if (params.additional_email_id) {
            await methodCreateSubscribe(params, event_id, is_course)
        } else {
            const {id} = await methodCreateEmail(params, event_id, is_course)
            await methodCreateSubscribe({
                additional_email_id: id,
                event_id: event_id,
                types: params.types,
            }, event_id, is_course)
        }
    }

    const handleCancel = () => {
        handleEdit()
    }

    return (
        <div className={styles.container}>
            {data.is_confirm ? <Confirm
                type={'danger'}
                message={`Удалить письмо?`}
                onCancelText={'Отмена'}
                onSubmitText={'Удалить'}
                direction={'row'}
                onCancel={handleConfirm}
                onSubmit={handleRemove}
            /> : null}
            {data.is_edit ? <Edit
                data={data}
                orgTemplates={orgTemplates}
                onSubmit={handleSave}
                onCancel={handleCancel}
            /> : <div className={styles.item}>
                <h6>{data.additional_email.email}</h6>
                <div className={styles.actions}>
                    <button
                        className={'a-btn a-btn_size_small a-btn_quare a-btn_type_secondary-ghost'}
                        disabled={data.is_disabled}
                        onClick={handleEdit}>
                        <EditIcon/>
                    </button>
                    <button
                        className={'a-btn a-btn_size_small a-btn_quare a-btn_type_tertiary-ghost'}
                        disabled={data.is_disabled}
                        onClick={handleConfirm}>
                        <TrashIcon/>
                    </button>
                </div>
                {selectedOrgTemplates.length ? <div className={styles.types}>
                    {selectedOrgTemplates.map((x, i) => <div key={i}>
                        {x.name}
                    </div>)}
                </div> : null}
            </div>}
        </div>
    );
};

export default Owner;