import {containerCanvas, graphicsDrawSelect, pixiMP, stageMP, worldContainer} from "./Const";
import {getPlaceFromSelect} from "./Common";

export const drawSelect = ({action, event}) => {
    // создание области выделения
    if (pixiMP.grab) return
    if (action === 'draw') {
        // нарисовать
        let x = Math.min(stageMP.startX, stageMP.lastX);
        let y = Math.min(stageMP.startY, stageMP.lastY);
        stageMP.width = Math.abs(stageMP.lastX - stageMP.startX);
        stageMP.height = Math.abs(stageMP.lastY - stageMP.startY);
        graphicsDrawSelect.clear()
        graphicsDrawSelect.rect(x, y, stageMP.width, stageMP.height).fill({color: "#0067F2", alpha: 0.5}).stroke({
            width: 1,
            color: "#0067F2"
        })
        return;
    }

    if (action === 'start') {
        stageMP.startX = (event.clientX - containerCanvas.left - worldContainer.x) / worldContainer.scale.x
        stageMP.startY = (event.clientY - containerCanvas.top - worldContainer.y) / worldContainer.scale.y
        stageMP.lastX = (event.clientX - containerCanvas.left - worldContainer.x) / worldContainer.scale.x
        stageMP.lastY = (event.clientY - containerCanvas.top - worldContainer.y) / worldContainer.scale.y

        stageMP.width = 0
        stageMP.height = 0
        stageMP.isDrawing = true
        return;
    }

    if (action === 'update') {
        if (!stageMP.isDrawing) return;  // активен ли режим
        document.body.style.cursor = "crosshair";
        // Обновляем координаты и размеры квадрата
        stageMP.lastX = (event.clientX - containerCanvas.left - worldContainer.x) / worldContainer.scale.x;
        stageMP.lastY = (event.clientY - containerCanvas.top - worldContainer.y) / worldContainer.scale.y;

        drawSelect({action: "draw", event: event})
        return;
    }
    if (action === 'end') {
        document.body.style.cursor = "default";
        stageMP.isDrawing = false
        graphicsDrawSelect.clear()
        getPlaceFromSelect()
        return;
    }
}

export const getSelectionBounds = () => {
    return {
        "x1": Math.min(stageMP.startX, stageMP.lastX),
        "y1": Math.min(stageMP.startY, stageMP.lastY),
        "x2": Math.max(stageMP.startX, stageMP.lastX),
        "y2": Math.max(stageMP.startY, stageMP.lastY),
        "width": stageMP.width,
        "height": stageMP.height
    };
};
