import React, {useEffect, useRef, useState} from 'react';
import Flatpickr from "react-flatpickr";
import Message from "../../../../../../../../widget/components/Message";
import {Russian} from "flatpickr/dist/l10n/ru";
import styles from './NotifyAt.module.scss'
import Input from "../../../../../../../../components/ui/Input";
import CalendarIcon from '../../../../../../../../assets/images/icons/16/calendar.svg'
import ClockIcon from '../../../../../../../../assets/images/icons/16/clock.svg'
import CloseIcon from '../../../../../../../../assets/images/icons/16/close_12.svg'
import ArrowIcon from '../../../../../../../../assets/images/icons/16/arrow.svg'
import notifyAtDataJson from './NotifyAt.data.json'
import {convert} from "../../../../../../../../utils/nhApi";
import {useSelector} from "react-redux";

const NotifyAt = ({data, onChange, timezone}) => {
    const selector = useSelector(state => state)
    const diff = calcTimeDiff(new Date(), timeZoneConverter(timezone))
    const [form, setForm] = useState({
        ...notifyAtDataJson, ...data, fields: {
            ...notifyAtDataJson.fields, ...data.fields,
            date: {
                ...notifyAtDataJson.fields.date, ...data.fields.date,
                value: data.fields.notify_at.value ? convert(new Date(data.fields.notify_at.value), timezone) : null,
            },
            time: {
                ...notifyAtDataJson.fields.time, ...data.fields.time,
                value: data.fields.notify_at.value ? convert(new Date(data.fields.notify_at.value), timezone) : null,
            },
        }
    })
    const flatpickr = useRef()
    const optionsGlobal = {
        locale: Russian,
    }
    const optionsDate = {
        ...optionsGlobal,
        inline: true,
        monthSelectorType: "static",
        enableTime: false,
        altInput: "true",
    }
    const optionsTime = {
        ...optionsGlobal,
        dateFormat: 'H:i',
        enableTime: true,
        noCalendar: true,
        time_24hr: true,
    }

    useEffect(() => {
        subscribeNotifyAtField()
    }, [form.fields.date.value, form.fields.time.value])

    useEffect(() => {
        subscribeChangeFormHandler(form)
    }, [form])

    const handleChange = (field, value) => {
        setForm(prev => ({
            ...prev,
            fields: {
                ...prev.fields,
                [field]: {
                    ...prev.fields[field],
                    value: value ? convert(value, timezone) : null,
                    error: null,
                },
                notify_at: field === 'notify_at' ? {
                    value: value ? convert(value, timezone) : null,
                    error: null,
                } : {
                    value: null,
                    error: null,
                }
            },
        }));
    };

    const subscribeChangeFormHandler = () => {
        onChange(form)
    }

    const subscribeNotifyAtField = () => {
        let {date, time} = form.fields

        date = date.value
        time = time.value

        date && time ? handleChange('notify_at', generateNotifyAtField()) : null
    }

    const generateFormattedTime = (value) => {
        return value ? new Date(value).toLocaleTimeString('ru-RU', {
            hour: '2-digit',
            minute: '2-digit'
        }) : null
    }

    const generateFormattedDate = (value) => {
        return value ? new Date(value).toLocaleDateString('ru-RU') : null
    }

    const generateNotifyAtField = () => {
        let date, time, result

        result = new Date()
        date = new Date(form.fields.date.value)
        time = new Date(form.fields.time.value)
        result.setDate(date.getDate())
        result.setMonth(date.getMonth())
        result.setFullYear(date.getFullYear())
        result.setHours(time.getHours())
        result.setMinutes(time.getMinutes())
        result.setSeconds(0)
        result.setMilliseconds(0)

        return result
    }

    const renderTimeField = (ref) => {
        return <div className={styles.time}>
            <div className={styles.icon}>
                <ClockIcon/>
            </div>
            <Input
                type={"time"}
                id={"notify_at-time"}
                inputRef={ref}
                placeholder={'ЧЧ:СС'}
                size={'medium'}
                handleChange={() => null}
                value={'asd'}
            />
            {form.fields.time.value ? <div
                className={[styles.icon, styles.close].filter(x => x ?? x).join(' ')}
                onClick={() => handleChange('time', null)}>
                <CloseIcon/>
            </div> : <div
                className={[styles.icon, styles.arrow].filter(x => x ?? x).join(' ')}>
                <ArrowIcon/>
            </div>}
            {form.fields.notify_at.error ? <Message type={'error'} value={form.fields.notify_at.error}/> : null}
        </div>
    }

    const renderDateField = () => {
        return <div className={styles.date}>
            <div className={styles.icon}>
                <CalendarIcon/>
            </div>
            <Input
                type={"text"}
                id={"notify_at-date"}
                placeholder={'ДД.ММ.ГГ'}
                size={'medium'}
                handleChange={() => null}
                value={generateFormattedDate(form.fields.date.value)}
            />
            {form.fields.date.value ? <div
                className={[styles.icon, styles.close].filter(x => x ?? x).join(' ')}
                onClick={() => handleChange('date', null)}>
                <CloseIcon/>
            </div> : null}
        </div>
    }

    return (
        <div className={styles.container}>
            <Flatpickr
                ref={flatpickr}
                value={form.fields.date.value ? new Date(form.fields.date.value) : null}
                options={optionsDate}
                onChange={(e) => handleChange('date', e[0])}
            />
            <div className={styles.column}>
                {renderDateField()}
                <Flatpickr
                    options={optionsTime}
                    value={form.fields.time.value ? new Date(new Date(form.fields.time.value).getTime() + diff) : null}
                    render={({value}, ref) => renderTimeField(ref)}
                    onChange={(e) => handleChange('time', new Date(new Date(e[0]).getTime() - diff))}
                />
                <div className={styles.timezoneName}>{selector.admin.event.timezone.name}</div>
                {data.fields.notify_at.error ? <div className={styles.error}>
                    <Message type={'error'} value={data.fields.notify_at.error}/>
                </div> : null}
            </div>
        </div>
    );
};

export default NotifyAt;