import JSONAPIDeserializer from "jsonapi-serializer/lib/deserializer";

/* Прослойка для API уроков академии, сохраняет старую структуру данных */
export const lessonsApiMiddleware = async (jsonapi) => {
    let jsonapiObject = JSON.parse(JSON.stringify(jsonapi))
    /* Шаблон десериализации */
    const deserializedData = async (data) => new JSONAPIDeserializer({
        keyForAttribute: 'underscore_case',
    }).deserialize(JSON.parse(JSON.stringify(data)), (err, deserializeData) => deserializeData);

    /* Десериализованный JSONAPI объект */
    let lessonsParamsSerialized = await deserializedData(jsonapiObject)

    /* Структурирование урока */
    const syncSchemeLesson = (data) => {
        return {
            type: "lessons",
            id: data.id,
            attributes: {
                course_id: data.course_id,
                is_free: data.is_free,
                title: data.title,
                description: data.description,
                order: data.order,
                access: data.access,
                access_type: data.access_type,
                access_interval: data.access_interval,
                access_time: data.access_time,
                created_at: data.created_at,
                updated_at: data.updated_at,
                is_lesson_available_for_user: data.is_lesson_available_for_user,
                reason_lesson: data.reason_lesson,
                can_buy: data.can_buy,
                label_can_buy: data.label_can_buy,
                url_buy: data.url_buy,
                back_lesson_id: data.back_lesson_id,
                next_lesson_id: data.next_lesson_id,
                progress_all_course: data.progress_all_course,
                progress_the_lesson: data.progress_the_lesson,
                user_progress: data.user_progress,
                blocks: [],
            },
        }
    }

    /* Структурирование блока урока */
    const syncSchemeLessonBlock = (data) => {
        let result = {
            type: "lesson-blocks",
            id: data.id,
            attributes: {
                lesson_id: data.lesson_id,
                course_id: data.course_id,
                block_id: data.block_id,
                order: data.order,
                created_at: data.created_at,
                updated_at: data.updated_at,
                current_block_status: data.current_block_status,
                back_lesson_block_id: data.back_lesson_block_id,
                next_lesson_block_id: data.next_lesson_block_id,
                completed_block: data.completed_block,
                user_progress: data.user_progress,
                status_result_label: data.status_result_label,
                id: data.id,
                content: {
                    type: data.block_type,
                    id: data.block_id,
                    attributes: data.content,
                },
                included: null
            },
        }
        if (data.content.file || data.content.video) {
            result.attributes.included = []
            if (data.content.file) result.attributes.included.push({
                type: 'files',
                attributes: data.content.file,
                id: data.content.file.id,
            })
            if (data.content.video) result.attributes.included.push({
                type: 'videos',
                attributes: data.content.video,
                id: data.content.video.id,
            })
        }
        return result
    }

    /* Наполняем уроки новыми данными, сохраняя старую структуру */
    jsonapiObject.data = jsonapiObject.data.map(lesson => {
        let lessonSerialized = lessonsParamsSerialized.find(x => x.id == lesson.id)
        lesson = syncSchemeLesson(lessonSerialized)

        lesson.attributes.blocks = lessonSerialized?.lesson_blocks ? lessonSerialized.lesson_blocks.map(block => {
            block.course_id = lesson.attributes.course_id
            block = syncSchemeLessonBlock(block)
            return block
        }) : []
        lesson.attributes.blocks = lesson.attributes.blocks.sort((a, b) => {
            return a.attributes.order - b.attributes.order;
        });
        return lesson
    })

    jsonapiObject.data = jsonapiObject.data.sort((a, b) => {
        return a.attributes.order - b.attributes.order;
    });

    /* Возвращаем отформатированный jsonapi объект */
    return jsonapiObject
}


export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};


/* Скролл до элемента $el, timeout задан намеренно*/
export const scrollToElement = ($el) => {
    setTimeout(e => {
        if (document.querySelector($el)) document.querySelector($el).scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        })
    }, 250)
}

/* Скролл до элемента */
/**
 * element - цель
 * parent - контейнер со скроллом
 * offset - смещение (в px или позиция center, start, end, nearest
 * TODO: Избавиться от scrollToElement
 * **/
export const scrollToTarget = async (element, parent, offset) => {
    if (!element) return;
    if (!parent) return;
    if (!offset) return;
    await new Promise(resolve => setTimeout(() => {
        switch (offset) {
            case 'center':
            case 'start':
            case 'end':
            case 'nearest':
                element.scrollIntoView({behavior: 'smooth', block: offset})
                resolve()
                break;
            default:
                parent.scrollTo({top: element.offsetTop - offset, behavior: "smooth"});
                resolve()
                break
        }
    }, 250));
}

export const fpLimitDatesControl = (object) => {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    let limit = [],
        ctx = this,
        arr = [],
        sorted,
        weekdays = object.weekdays;
    if (limit.length) {
        object.instance.set('enable', limit)
        object.instance.set('disable', limit)
    }
    if (object.dates.length === 0 && object.isAllDates === false) {
        limit.push(function (date) {
            return (
                (weekdays.includes(7) ? '' : date.getDay() === 0)
                ||
                (weekdays.includes(1) ? '' : date.getDay() === 1)
                ||
                (weekdays.includes(2) ? '' : date.getDay() === 2)
                ||
                (weekdays.includes(3) ? '' : date.getDay() === 3)
                ||
                (weekdays.includes(4) ? '' : date.getDay() === 4)
                ||
                (weekdays.includes(5) ? '' : date.getDay() === 5)
                ||
                (weekdays.includes(6) ? '' : date.getDay() === 6)
            )
        })
        if (object.datesExcluded.length) {
            object.datesExcluded.forEach(function (e) {
                limit.push(new Date(e))
            })
        }
        object.instance.set('disable', limit)
    } else {
        if (object.isAllDates) {
            limit.push(function (date) {
                return (
                    (weekdays.includes(7) ? '' : date.getDay() === 0)
                    ||
                    (weekdays.includes(1) ? '' : date.getDay() === 1)
                    ||
                    (weekdays.includes(2) ? '' : date.getDay() === 2)
                    ||
                    (weekdays.includes(3) ? '' : date.getDay() === 3)
                    ||
                    (weekdays.includes(4) ? '' : date.getDay() === 4)
                    ||
                    (weekdays.includes(5) ? '' : date.getDay() === 5)
                    ||
                    (weekdays.includes(6) ? '' : date.getDay() === 6)
                )
            })
            if (object.datesExcluded.length) {
                object.datesExcluded.forEach(function (e) {
                    limit.push(new Date(e))
                })
            }

            object.instance.set('disable', limit)
        } else {
            if (object.dates.length > 0) {
                object.dates.forEach(function (e) {
                    limit.push(new Date(e))
                })
                limit.push(function (date) {
                    return (
                        (weekdays.includes(1) ? date.getDay() === 1 : '')
                        ||
                        (weekdays.includes(2) ? date.getDay() === 2 : '')
                        ||
                        (weekdays.includes(3) ? date.getDay() === 3 : '')
                        ||
                        (weekdays.includes(4) ? date.getDay() === 4 : '')
                        ||
                        (weekdays.includes(5) ? date.getDay() === 5 : '')
                        ||
                        (weekdays.includes(6) ? date.getDay() === 6 : '')
                        ||
                        (weekdays.includes(7) ? date.getDay() === 0 : '')
                    )
                })
                let arr = []
                limit.forEach(function (date) {
                    if (typeof date === 'object') {
                        if (calcTimeDiff(new Date(), timeZoneConverter('Europe/Moscow')) * (-1) >= 0) {
                            date = new Date(new Date(date).getTime())
                        } else {
                            date = new Date(new Date(date).getTime() + 86400000)
                        }
                        let input = convert(new Date(date), timezone)
                        arr.push(new Date(convert(new Date(input), 'Europe/Moscow')))
                    }
                })
                object.instance.set('enable', arr)
            }
        }
    }

    const fpConvert = (date) => {
        let input = convert(new Date(date), 'Europe/Moscow')
        let diff = calcTimeDiff(new Date(), timeZoneConverter('Europe/Moscow')) * (-1)
        let reset = convert(new Date(new Date(new Date(input).setHours(0, 0, 0, 0) + diff)), 'Europe/Moscow')
        let output = convert(new Date(reset), object.timezone)
        return output
    }

    let inputMin = typeof object['dateMin'] !== "undefined" ? convert(new Date(object.dateMin), 'Europe/Moscow') : null
    let inputMax = typeof object['dateMax'] !== "undefined" ? convert(new Date(object.dateMax), 'Europe/Moscow') : null
    let localMin = inputMin ? convert(new Date(inputMin), timezone) : null
    let localMax = inputMin ? convert(new Date(inputMin), timezone) : null

    object.dateMin ? object.instance.set('minDate', new Date(localMin)) : ''
    object.dateMax ? object.instance.set('maxDate', new Date(inputMax)) : ''
    object.dates.forEach(function (e) {
        arr.push(e.value)
    })
    sorted = arr.slice().sort(function (a, b) {
        return new Date(a) - new Date(b)
    });
    // object.instance.jumpToDate(new Date(object.dateMin));
    // object.dates.forEach(function (e) {
    //     if (e.value > object.dateMin) {
    //         object.instance.jumpToDate(new Date(sorted.pop()));
    //     }
    // })
}


export const dateTime = (time) => {
    let date = new Date(time),
        year = date.getFullYear(),
        month = (date.getMonth() + 1).toString().padStart(2, "0"),
        day = date.getDate().toString().padStart(2, "0"),
        hours = date.getHours().toString().padStart(2, "0"),
        minutes = date.getMinutes().toString().padStart(2, "0");
    return `${day}.${month}.${year}`
}

export const timeZoneConverter = (timeZone) => {
    const formatter = new Intl.DateTimeFormat('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
        timeZone
    })
    return {
        convert(date) {
            return new Date(formatter.format(date).replace(/\//g, '-').replace(' ', 'T').trim())
        }
    }
}

export const convert = (date, timezone) => {
    let toLocal = date.toLocaleString('ru-RU', {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        timeZoneName: 'short',
    }), toSet = date.toLocaleString('ru-RU', {
        timeZone: timezone,
        timeZoneName: 'short',
    }), output = {
        toLocal: {
            date: date.toLocaleString('ru-RU', {
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            }),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
        toSet: {
            date: date.toLocaleString('ru-RU', {
                timeZone: timezone,
            }),
            timezone: timezone
        }
    }
    let ts = null
    let GMT = toSet.split('GMT')[1]
    if (toSet.split('GMT')[1].includes(':')) {
        ts = [GMT.split(':')[0].toString().padStart(2, '0'), date.split('GMT')[1].split(':')[1].toString().padStart(2, '0')].join(':')
    } else {
        if (GMT.includes('-')) {
            GMT = `-${GMT.split('-')[1].toString().padStart(2, '0')}`
        } else if (GMT.includes('+')) {
            GMT = `+${GMT.split('+')[1].toString().padStart(2, '0')}`
        } else {
            GMT = `+00`
        }
        ts = [GMT, '00'].join(':')
    }
    return `${output.toSet.date.split(', ')[0].split('.').reverse().join('-')}T${output.toSet.date.split(', ')[1]}${ts}`
}

export const calcTimeDiff = (date, converter) => {
    const secDate = date - date.getMilliseconds()
    return converter.convert(new Date(secDate), 'Moscow/Europe') - secDate
}

export const toIso8601WithTimezone = (date, different) => {
    let tzSign = date.getTimezoneOffset() > 0 ? '-' : '+',
        tzHours = (Math.abs(date.getTimezoneOffset() / 60)).toString().padStart(2, '0'),
        tzMin = (Math.abs(date.getTimezoneOffset() % 60)).toString().padStart(2, '0'),
        timezone = tzSign + tzHours + ':' + tzMin;
    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        timezone;
}

export const pad = (number) => {
    if (number < 10) {
        return '0' + number;
    }
    return number;
}

export const toNewDateInTimeZone = (date, timezone) => {
    // console.log(date, timezone)
    // возвращаем дату с учетом полученного часового пояса
    let str = new Date(date).toLocaleString('ru', {timeZone: timezone})
    if (str !== 'Invalid Date') {
        let strDate = str.split(',')[0];
        let strTime = str.split(',')[1];

        let day = strDate.split('.')[0];
        let month = Number(strDate.split('.')[1]) - 1;
        let year = strDate.split('.')[2];

        let seconds = strTime.split(':')[2];
        let minutes = strTime.split(':')[1];
        let hour = strTime.split(':')[0];

        let result = new Date();
        result.setFullYear(year);
        result.setMonth(month);
        result.setDate(day);
        result.setHours(hour);
        result.setMinutes(minutes);
        result.setSeconds(seconds);

        return result.toISOString()
    }
}

export const declension = (number, words) => {
    return words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? Math.abs(number) % 10 : 5]];
}

export const humanizeList = (list, options) => {
    if (!Array.isArray(list)) {
        throw new TypeError('humanize-list expected an array')
    }

    options = options || {}
    options.conjunction = options.conjunction || 'и'

    let listLength = list.length;

    if (listLength === 1) {
        return list[0]
    }

    if (options.skipConjunction) {
        return list.join(', ')
    }

    let humanizedList = ''
    for (let i = 0; i < listLength; i++) {
        if (i === listLength - 1) {
            if (options.oxfordComma) {
                humanizedList += ','
            }

            humanizedList += ' ' + options.conjunction + ' '
        } else if (i !== 0) {
            humanizedList += ', '
        }

        humanizedList += list[i]
    }

    return humanizedList
}