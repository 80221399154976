import React, { FC, useEffect, useState } from 'react';
import styles from './MemberCharts.module.scss';
import ChartsDataPicker from '../ChartsDataPicker/ChartsDataPicker';
import LineChart from '../LineChart/LineChart';
import {getChartData} from './MemberCharts.methods';
import { useRouteMatch } from 'react-router-dom';
import {memberChartsData} from './MemberCharts.schema';
import {MemberDataInterface} from './MemberCharts.interface';



const MemberCharts:FC = () => {
    const [data, setData] = useState<MemberDataInterface>(memberChartsData);
    const {params} = useRouteMatch<{eventId: string}>()

    useEffect(() => {
        handleGetData(memberChartsData.type.visitorsType, params.eventId, {})
        handleGetData(memberChartsData.type.progressType, params.eventId, {})
        handleGetData(memberChartsData.type.salesType, params.eventId, {})
        handleGetData(memberChartsData.type.timeType, params.eventId, {})
    }, []);

    const handleGetData = async (type: string, id: string, query: Object) => {
        let res
        try {
            res = await getChartData(type, id, query);

            if (res) {
                setData(prev => ({...prev, [type]: res.data.data}))
            }
        } catch (err) {
            throw Error(err)
        }
    }

    return (
        <div className={styles.container}>
            <section className={styles.form}>
                <ChartsDataPicker
                    data={data}
                    onChange={setData}
                    onFilter={handleGetData}
                />
            </section>
            <section className={styles.charts}>
                <p className={styles.title}>
                    ПОСЕТИТЕЛИ
                </p>
                {data.visitors && <LineChart
                    data={data.visitors}
                    type={data.type.visitorsType}
                />}
                <p className={styles.title}>ПРОДАЖИ</p>
                {data.sales && <LineChart
                    data={data.sales}
                    type={data.type.salesType}
                />}
                {data.progress &&
                    <div>
                        <p className={styles.title}>
                            СРЕДНИЙ ПРОГРЕСС ПРОХОЖДЕНИЯ:
                            <span className={styles.statistic}>{data.progress.average !== null ? data.progress.average : `0`}</span>
                        </p>
                        <LineChart
                            data={data.progress}
                            type={data.type.progressType}
                        />
                    </div>
                }
                {data.time &&
                    <div>
                        <p className={styles.title}>
                            СРЕДНЕЕ ВРЕМЯ ПРОХОЖДЕНИЯ КУРСА:
                            <span className={styles.statistic}>{data.time.average !== null ? data.time.average : '0'}</span></p>
                        <LineChart data={data.time} type={data.type.timeType}/>
                    </div>
                }
            </section>
        </div>
    );
}

export default MemberCharts;
