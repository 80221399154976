import React, {useEffect} from 'react';
import '../../scss/components/notification/notification.scss'
import InfoIcon from '../../../assets/images/icons/24/info.svg'
import SuccessIcon from '../../../assets/images/icons/24/check.svg'
import WarningIcon from '../../../assets/images/icons/24/warning.svg'
import ErrorIcon from '../../../assets/images/icons/24/danger.svg'
import EventsIcon from '../../../assets/images/icons/24/rocket.svg'

const Notification = (props) => {
    useEffect(() => {
        if (props.type == 'error') document.querySelector('.ui-widget__notification-error').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        })
    }, [])

    const renderIcon = () => {
        switch (props.type) {
            case 'info':
                return <InfoIcon/>
                break
            case 'success':
                return <SuccessIcon/>
                break
            case 'warning':
                return <WarningIcon/>
                break
            case 'error':
                return <ErrorIcon/>
                break
            case 'events':
                return <EventsIcon/>
                break
            default:
                break;
        }
    }
    return (
        <div className={`ui-widget__notification ui-widget__notification-${props.type}`}>
            {props.icon !== false ? <div className={'ui-widget__notification-icon'}>{renderIcon()}</div> : null}
            {props.svg ? <div className={'ui-widget__notification-icon'}>{props.svg}</div> : null}
            <div className={'ui-widget__notification-content'}>{props.value}</div>
        </div>
    );
};

export default Notification;
