import React from 'react';
import styles from './Groups.module.scss'
import page from "../../Events.module.scss";
import Notification from '../../../../../UIKit/component/notification/notification'
import Preloader from "../../../../../widget/components/preloader";
import Group from "./Group/Group";

const Groups = ({data, onChange, onReset}) => {
    return (
        <>
            {data.is_loading === null ? <div className={page.section}>
                <h3 className={page.title}>
                    Уведомления
                </h3>
                <Preloader/>
            </div> : null}
            {data.is_loading === 1 ? data.groups.map((group, group_idx) => <Group
                key={group_idx}
                data={group}
                variables={data.variables[group.variables]}
                periods={data.periods}
                alerts={data.alerts}
                addNotificationsLimit={data.addNotificationsLimit}
                onChange={onChange}
                onReset={onReset}
            />) : null}

            {data.is_loading === 0 ? <div className={page.section}>
                <h3 className={page.title}>
                    Уведомления
                </h3>
                <div className={styles.notification}>
                    <Notification
                        value={'Произошла ошибка загрузки модуля уведомлений, повторите попытку позднее'}
                        type={'error'}
                    />
                </div>
            </div> : null}
        </>
    );
};

export default Groups;