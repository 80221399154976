import React from 'react';
import Warning from '../../assets/images/icons/16/warning.svg'
import '../scss/components/Message.scss'
import ReactHtmlParser from "react-html-parser";

const Message = (props) => {
    return (
        props.type === 'error' && <div className={'ui-widget__message ui-widget__message-error'}>
            <Warning/>
            {ReactHtmlParser(props.value)}
        </div>
    );
};

export default Message;