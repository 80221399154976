import React, {useEffect, useRef, useState} from 'react';
import {Rectangle, Sprite} from "pixi.js";
import {TextPixi} from "./primitives/TextPixi";
import {areaOfVisibility, isDisabledPlace} from "./utils/Common";
import {MouseProcessor} from "./utils/MouseProcessor";
import {getFill, getStroke, getTextColor, spriteError, spriteStatus} from "./utils/Graphics";
import {calculatePositionAndSize, getValueText} from "../utils/common";
import {clearToolTip, renderTooltipe} from "./components/Tooltipe";

import CustomContainer from "./components/CustomContainer";
import {
    containerCanvas, dotGraphic, extremumUser,
    graphicsDrawSelect, graphicsExtremum, graphicsHover,
    graphicsPlace,
    graphicsSquare,
    isSegments,
    segments,
    worldContainer,
    conf
} from "./utils/Const";

import {StageEvents} from "./utils/StageEvents";
import {getPlaceSegments, setVisibleSegments} from "./utils/Segmentation";
import {onDisplay, setScreenParam} from "./utils/Common";

export var Floor = {
    place: null,
    app: null,
    handlers: null,
    selectedNodes: null,
    updatePlace: null,
    reRenderPlace: null,
    initSegmentPlace: null,
    activeSquare: null,
    groupList: null,
    map: null
}

const App = ({data, handlers, app, config}) => {
    const [isInitFloor, setIsInitFloor] = useState(false)

    useEffect(() => {
        initFloor()
        MouseProcessor()
        StageEvents()
        setScreenParam()
        centeringCanvas()
        app.stage.addChild(worldContainer)
        app.stage.addChild(dotGraphic)
        worldContainer.addChild(graphicsDrawSelect)
        extremumUser.min = data.extremumUser.min
        extremumUser.max = data.extremumUser.max

    }, []);

    useEffect(() => {
        if (!isInitFloor) {
            isSegments ? initSegmentPlace() : updatePlace()
            setIsInitFloor(true)
        } else {
            Floor.place = data.place
            render()
        }
    }, [data?.place]);

    useEffect(() => {
        Floor.groupList = data.groupList
    }, [data?.groupList]);

    useEffect(() => {
        Floor.map = data.map
    }, [data?.map]);

    useEffect(() => {
        Floor.activeSquare = data.activeSquare
        renderActiveSquare()
    }, [data?.activeSquare]);

    const updatePlace = () => {
        render()
    }

    const initFloor = () => {
        Floor = {
            place: data.place,
            app: app,
            handlers: handlers,
            selectedNodes: data.selectedNodes,
            updatePlace: updatePlace,
            reRenderPlace: reRenderPlace,
            initSegmentPlace: initSegmentPlace,
            activeSquare: [],
            groupList: data.groupList,
            map: data.map
        }
    }

    const createGrid = () => {
        // Создаем графику сетки
        dotGraphic.clear()
        dotGraphic.zIndex = -1;
        let step = 36;
        // Учитываем масштаб при расчете количества точек
        const scale = worldContainer.scale.x;
        const numDotsHorizontal = Math.ceil(app.renderer.width / (step * scale));
        const numDotsVertical = Math.ceil(app.renderer.height / (step * scale));
        const sizeDot = 2 * scale;
        const shift = 17 * scale;
        let x = 0;
        let y = 0;
        for (let i = 0; i < numDotsHorizontal; i++) {
            for (let j = 0; j < numDotsVertical; j++) {
                // Координаты точки с учетом масштаба и смещения
                x = (i * step * scale + (worldContainer.x % (step * scale))) + shift;
                y = (j * step * scale + (worldContainer.y % (step * scale))) + shift;

                dotGraphic.roundRect(x, y, sizeDot, sizeDot, 10).stroke("silver")
            }
        }
    }

    const initSegmentPlace = () => {
        setVisibleSegments()
        getPlaceSegments()
        renderSegmentPlace()
    }

    const reRenderPlace = () => {
        setVisibleSegments()
        renderSegmentPlace()
    }

    const renderSegmentPlace = () => {
        let segmentPlace = []
        segments.view.forEach((s) => {
            if (segments.place[s]) {
                segmentPlace.push(...segments.place[s])
            }
        })
        Floor.place = segmentPlace
        render()
    }

    const render = () => {
        clearToolTip()
        const screen = areaOfVisibility()
        worldContainer.removeChildren()
        worldContainer.addChild(graphicsHover)

        graphicsPlace.clear()
        graphicsHover.clear()

        let objects = Floor.place.map((item) => {
            let oldContainer = worldContainer.getChildByName(`id-${item.id}`);
            if (oldContainer) {
                worldContainer.removeChild(oldContainer)
            }

            /* Расчет координат и графики */
            let x, y, h, w, text, tooltipContainer;
            ({x, y, h, w} = calculatePositionAndSize(item));
            let param = {x: x, y: y, w: w, h: h, type: item.type, screen: screen}

            // проверка на видимость
            if (!onDisplay({...param})) {
                return null;
            }

            let fill = getFill(item);
            let stroke = getStroke(item);
            let rounded = item.type === 'table' ? 100 : 0;
            let strText = getValueText(item)
            let colorText = getTextColor(item)
            const container = new CustomContainer();
            container.label = `id-${item.id}`;
            container.interactive = true;
            container['place'] = item;

            container.on('mouseenter', () => {
                if (isDisabledPlace(item)) {
                    document.body.style.cursor = "pointer";
                    if (!item.selected && !item.cart) {
                        graphicsHover.roundRect(x - 1, y - 1, w + 2, h + 2, rounded).fill(fill).stroke({
                            width: 2,
                            color: "#000"
                        });
                    }
                }
                if (container.place.type === 'place') {
                    tooltipContainer = renderTooltipe(tooltipContainer, container, true)
                }
            }).on('mouseleave', () => {
                graphicsHover.clear()
                if (isDisabledPlace(item)) {
                    document.body.style.cursor = "default";
                }
                if (container.place.type === 'place') {
                    tooltipContainer = renderTooltipe(tooltipContainer, container, false)
                }
            });


            graphicsPlace.roundRect(x, y, w, h, rounded).fill(fill).stroke(stroke);
            text = TextPixi({value: strText, x: x, y: y, h: h, w: w, color: colorText});
            container.hitArea = new Rectangle(0, 0, w, h);
            item.error && spriteError(container, param)
            item.status && spriteStatus(container, param, item.status)
            container.addChild(text);
            container.position.set(x, y);
            return container;
        });
        worldContainer.addChild(graphicsPlace);
        worldContainer.addChild(graphicsDrawSelect);
        addToStage(objects);
        if (conf.isDesigner) {
            createGrid()
        }
    }

    const centeringCanvas = () => {
        // центрова канваса
        // if (config.role !== 'user') return

        // Получаем размеры изображения
        const imageHeight = data.extremumUser.max.y - data.extremumUser.min.y;
        const imageWidth = data.extremumUser.max.x - data.extremumUser.min.x;

        // проверяем на наличие мест в схеме, если нет, то происходит создание нового зала
        if (imageHeight && imageWidth) {
            // Вычисляем масштаб для корректного отображения изображения на экране
            const scaleX = containerCanvas.width / imageWidth;
            const scaleY = containerCanvas.height / imageHeight;
            const scale = Math.min(scaleX, scaleY);

            // Вычисляем центр изображения с учетом масштабирования
            const scaledImageCenterX = (data.extremumUser.max.x + data.extremumUser.min.x) / 2 * scale;
            const scaledImageCenterY = (data.extremumUser.max.y + data.extremumUser.min.y) / 2 * scale;

            // Находим центр экрана
            const screenCenterX = containerCanvas.width / 2;
            const screenCenterY = containerCanvas.height / 2;

            // Вычисляем смещение для центрирования изображения
            const offsetX = screenCenterX - scaledImageCenterX;
            const offsetY = screenCenterY - scaledImageCenterY;

            // Применяем смещение и масштаб к контейнеру с изображением
            worldContainer.x = offsetX;
            worldContainer.y = offsetY;
            worldContainer.scale.x = scale;
            worldContainer.scale.y = scale;
        }
        // Обновляем параметры экрана
        setScreenParam();

        // Перерисовываем сцену
        render();
    }

    const renderZone = () => {
        let h = data.extremumUser.max.y - data.extremumUser.min.y
        let w = data.extremumUser.max.x - data.extremumUser.min.x
        graphicsExtremum.rect(data.extremumUser.min.x, data.extremumUser.min.y, w, h).stroke("red")
        worldContainer.addChild(graphicsExtremum);
    }

    const renderActiveSquare = () => {
        graphicsSquare.clear()
        let x, y, w, h, param
        let objects = Floor.activeSquare.map((item) => {
                x = item.x + 6
                y = item.y + 6
                w = h = 24;
                param = {x: x, y: y, w: w, h: h, type: item.type, screen: screen}

                // заселекченное место
                graphicsSquare.rect(x, y, w, h)
                    .fill("#fefefe")
                    .stroke({width: 2, color: "#0067F2"});
            }
        )
        worldContainer.addChild(graphicsSquare)
    }

    const addToStage = (objects: []) => {
        // Удаление всех элементов null из массива objects
        const filteredObjects = objects.filter(object => object !== null);

        if (filteredObjects.length) {
            // Вставка контейнеров на сцену
            const batchSize = 5000;
            let index = 0;

            const addBatch = () => {
                const batch = filteredObjects.slice(index, index + batchSize);
                worldContainer.addChild(...batch);
                index += batchSize;
                if (index < filteredObjects.length) {
                    requestAnimationFrame(addBatch);
                }
            };

            addBatch();
        }
    };

    return <></>

}

export default App;
