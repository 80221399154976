import React, {useEffect, useState} from 'react';
import IconHande from "../../../../../assets/images/icons/16/hande.svg";
import ReactTooltip from "react-tooltip";
import IconZoomPlus from "../../../../../assets/images/icons/16/zoomP.svg";
import IconZoomMinus from "../../../../../assets/images/icons/16/zoomM.svg";
import IconLock from "../../../../../assets/images/icons/12/lock.svg";
import ManagerPlaceStatus from "./components/ManagerPlaceStatus";

const ToolBarManager = ({
                            modeEditor,
                            handleModeEditor,
                            handleScale,
                            statuses,
                            selectedNodes,
                            place,
                            setPlace,
                            groups,
                            setChangeMade,
                            editableStatuses
                        }) => {
        const [status, setStatus] = useState(0);
        const [listStatuses, setListStatuses] = useState(null);
        const [disableDrpd, setDisableDrpd] = useState(false);
        const [groupsName, setGroupsName] = useState('-');
        const [row, setRow] = useState('-');
        const [column, setColumn] = useState('-');
        const [order, setOrder] = useState([]);

        useEffect(() => {
            setListStatuses(editableStatuses)
        }, [])
        useEffect(() => {
        }, [order])

        useEffect(() => {

            initOrderStatus(selectedNodes)
            if (selectedNodes.length === 0) {
                initialValueStatus('-')
                setDisableDrpd(true)
                setRow('-')
                setColumn('-')
                setGroupsName('-')
            }

            if (selectedNodes.length > 0) {

                let arrStatus = [];
                let arrIdGroups = [];
                let editable = true;
                let editableArr = Object.keys(editableStatuses)
                selectedNodes.forEach((item) => {
                    if (editableArr.indexOf(`${item.status}`) == -1 && editable) {
                        editable = false
                    }
                    if (typeof item['group'] !== "undefined") {
                        if (item['group'] !== null) {
                            arrIdGroups.push(item.group.id)
                            arrStatus.push(item.status)
                        }
                    }
                })
                const arrPlaceWithoutDuplicates = [...new Set(arrStatus)];
                const arrGroupWithoutDuplicates = [...new Set(arrIdGroups)];

                if (arrGroupWithoutDuplicates.length === 1) {
                    // Название группы
                    getNameGroups(arrGroupWithoutDuplicates[0])
                } else {
                    setGroupsName('-')
                }

                if (arrPlaceWithoutDuplicates.length > 1) {
                    // статусы разные
                    initialValueStatus('-')
                } else {
                    // проверить наличие сцены
                    let noTypeScene = true;
                    selectedNodes.forEach((item) => {
                        if (item.type === 'scene') noTypeScene = false;
                    })
                    if (noTypeScene) {
                        // если не выбрана сцена
                        setRow(selectedNodes[0].custom.row ? selectedNodes[0].custom.row : '-')
                        setColumn(selectedNodes[0].custom.column ? selectedNodes[0].custom.column : '-')
                        if (editable) {
                            // доступные статусы
                            initialValueStatus(selectedNodes[0].status)
                        } else {
                            initialValueStatus(selectedNodes[0].status)
                            setDisableDrpd(true)
                        }
                    } else {
                        makeDisableDropdown()
                    }
                }

            }

            if (selectedNodes.length > 1) {
                setRow('-')
                setColumn('-')
            }
        }, [selectedNodes])

        const makeDisableDropdown = () => {
            setRow('-')
            setColumn('-')
            setGroupsName('-')
            initialValueStatus('-')
            setDisableDrpd(true)
        }

        const getNameGroups = (id) => {
            for (let i = 0; i < groups.length; i++) {
                if (groups[i].id == id) {
                    setGroupsName(groups[i].name)
                }
            }
        }

        const handleChangeStatus = (val) => {
            setChangeMade(true)
            let arrIdEdit = [];
            selectedNodes.forEach((item) => {
                arrIdEdit.push(item.id)
            })

            setPlace(place.map(item => {
                if (arrIdEdit.indexOf(item.id) != -1) item.status = Number(val);
                if (arrIdEdit.indexOf(item.id) != -1) item.isEdit = true;
                return item
            }))
        }

        const initialValueStatus = (value) => {
            switch (value) {
                case "null":
                    setStatus(0)
                    break
                default :
                    setStatus(value)
            }
            setDisableDrpd(false)
        }
        const initOrderStatus = (selectedNodes) => {
            let arrOrder = [];
            if (selectedNodes.length !== 0) {
                if (typeof selectedNodes[0]['used'] !== "undefined") {
                    if (selectedNodes[0]['used'] && selectedNodes[0]['used'].length !== 0) {
                        arrOrder.push(selectedNodes[0]['used'][0])
                    }
                }
            }
            setOrder(arrOrder)
        }

        return (
            <div style={{
                width: '100%',
                background: '#ffffff',
                display: 'flex',
                alignItems: 'center',
                boxShadow: '0px 2px 8px rgba(114, 128, 170, 0.2)',
                position: 'fixed',
                left: 0,
                top: 0,
                zIndex: 100,
            }}>
                <div className="toolbarManager">
                    <div className="toolbarManager__item toolbarManager__legend">
                        <div className="col-1">
                            <div className="col-1__item">
                                <div className="square square__white"></div>
                                <span>Занято</span>
                            </div>
                            <div className="col-1__item">
                                <div className="square square__gray"></div>
                                <span>Не в продаже</span>
                            </div>
                            <div className="col-1__item">
                                <div className="square square__icon">
                                    <IconLock/>
                                </div>
                                <span>Зарезервировано</span>
                            </div>
                        </div>
                    </div>
                    <div className="toolbarManager__item toolbarManager__order">
                        <div className="col-2">
                            <div className="col-2__item">
                                <span className="title">Заказ №</span>
                                <div className="order">
                                    {order.length === 1 ?
                                        <a className='title_link'
                                           href={order[0].url_details}
                                           key={order[0].order_id}>{order[0].order_id}</a>
                                        : '-'}
                                </div>
                            </div>
                            <div className="col-2__item">
                                <span className="title">Группа</span>
                                <span className="value"> {groupsName} </span>
                            </div>
                            <div className="col-2__item">
                                <span className="title">Ряд</span>
                                <span className="value"> {row} </span>
                            </div>
                            <div className="col-2__item">
                                <span className="title">Место</span>
                                <span className="value"> {column} </span>
                            </div>

                            <div className="col-2__item">
                                <span className="title">Статус:</span>
                                <div className="value_status">
                                    <ManagerPlaceStatus
                                        style={{width: "240px"}}
                                        height={30}
                                        maxItems={8}
                                        size={'small'}
                                        disable={disableDrpd}
                                        placeholder={'-'}
                                        up={false}
                                        initialValue={status}
                                        statuses={statuses}
                                        list={listStatuses ? listStatuses : []}
                                        onChange={(e) => {
                                            handleChangeStatus(e)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="toolbarManager__item toolbarManager__tool">
                        <div className="col-2">
                            <div className={"toolbarManager__item toolbarManager__row"}>
                                <div className="toolbarManager__action">
                                    <button data-tip="React-tooltip" data-for='toolTipHande'
                                            className={modeEditor === 'hande' ? 'a-btn a-btn_size_small a-btn_type_primary-default' : 'a-btn a-btn_size_small a-btn_type_secondary-ghost'}
                                            onClick={() => handleModeEditor('hande')}><IconHande/>
                                    </button>
                                    <ReactTooltip id="toolTipHande" place="top" type="dark" effect="float"
                                                  className="reactTooltip">
                    <span>
                        Включить прокрутку
                    </span>
                                    </ReactTooltip>
                                </div>
                                <div className="toolbarManager__action">
                                    <button data-tip="React-tooltip" data-for='toolTipZoomIn'
                                            className="a-btn a-btn_size_small a-btn_type_secondary-ghost"
                                            onClick={() => handleScale('+')}>
                                        <IconZoomPlus/>
                                    </button>
                                    <ReactTooltip id="toolTipZoomIn" place="top" type="dark" effect="float"
                                                  className="reactTooltip">
                    <span>
                     Увеличить масштаб
                    </span>
                                    </ReactTooltip>
                                </div>
                                <div className="toolbarManager__action">
                                    <button data-tip="React-tooltip" data-for='toolTipZoomOut'
                                            className="a-btn a-btn_size_small a-btn_type_secondary-ghost"
                                            onClick={() => handleScale('-')}>
                                        <IconZoomMinus/>
                                    </button>
                                    <ReactTooltip id="toolTipZoomOut" place="top" type="dark" effect="float"
                                                  className="reactTooltip">
                    <span>
                     Уменьшить масштаб
                    </span>
                                    </ReactTooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
            ;
    }
;

export default ToolBarManager;
