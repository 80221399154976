import React, {useEffect, useRef, useState} from 'react';
import BurgerIcon from '../../../assets/images/icons/16/burger.svg'
import SpeakerIcon from '../../../assets/images/icons/16/speaker.svg'
import PlusIcon from '../../../assets/images/icons/16/plus_simple.svg'
import ProfileIcon from '../../../assets/images/icons/16/user.svg'
import OptionsIcon from '../../../assets/images/icons/16/options.svg'
import LayersIcon from '../../../assets/images/icons/16/bx-layer.svg'
import DesignIcon from '../../../assets/images/icons/16/color-palette.svg'
import UploadIcon from '../../../assets/images/icons/16/upload.svg'
import '../../../scss/layout/Header.scss'
import PublishEvent from "../Events/PublishEvent/PublishEvent";

export const Header = ({toggle, setToggle}) => {
    const is_academy = window.location.host.includes('academy')
    const is_editor = window.location.pathname.includes('editor')
    const is_cabinet = window.location.pathname.split('/').filter(x => x ?? x)[0].includes('cabinet')
    const is_publish = event ? event.publish_date_yyyymmdd : null
    const eventId = event ? event.event_id : null

    const [tariff, setTariff] = useState(null)
    const [isOpen, setIsOpen] = useState(false);
    const buttonRef = useRef(null);
    const dropdownRef = useRef(null);
    const [droplist, setDroplist] = useState([
        {
            position: 10,
            name: 'Профиль организатора',
            href: `/admin/profile/`,
            target: null
        },
        {
            position: 20,
            name: 'Реквизиты',
            href: `/admin/requisites/`,
            target: null
        },
        {
            position: 30,
            name: 'События',
            href: `/admin/events/`,
            target: null
        },
        {
            position: 40,
            name: 'Академия',
            href: `/admin/courses/`,
            target: null
        },
        {
            position: 50,
            name: 'Курсы (ЛК ученика)',
            href: `//${academyHost}/cabinet/courses/`,
            target: null
        },
        {
            position: 60,
            name: 'Сайты',
            href: `https://nethouse.ru/sites`,
            target: null
        },
        {
            position: 70,
            name: 'ID',
            href: `https://nethouse.id/sites`,
            target: null
        },
        {
            position: 80,
            name: 'Домены',
            href: `https://domains.nethouse.ru/login`,
            target: null
        },
        {
            position: 90,
            name: 'Аккаунт',
            href: 'https://accounts.nethouse.ru/',
            target: '_blank'
        },
        {
            position: 110,
            name: 'Выход',
            href: `/admin/logout/`,
            target: null
        },
    ])

    /* Дополнение элементов выпадающего списка */
    useEffect(() => {
        if (!is_academy) {
            setDroplist([
                {
                    position: 100,
                    name: 'Помощь',
                    href: 'https://events.nethouse.ru/help',
                    target: '_blank'
                }
                , ...droplist])
        } else {
            setDroplist([
                {
                    position: 100,
                    name: 'Помощь',
                    href: 'https://academy.nethouse.ru/help',
                    target: '_blank'
                }
                , ...droplist])
        }
    }, [])

    /* Инициализация тарифа */
    useEffect(() => {
        try {
            setTariff(eval('academyTariff'))
        } catch (e) {

        }
    }, [tariff])

    /* Слушатель открытия/закрытия сайдбар-меню */
    const handleToggle = () => {
        setToggle(!toggle)
        if (toggle == false) localStorage.removeItem('sidebarState')
        else localStorage.setItem('sidebarState', '1')
    }

    /* Время формата hh:mm */
    const getTime = () => {
        return new Date(tariff.packetTill).toLocaleTimeString('ru-RU', {hour: '2-digit', minute: '2-digit'})
    }

    /* Время формата DD.MM.YYYY */
    const getDate = () => {
        return new Date(tariff.packetTill).toLocaleDateString('ru-RU')
    }

    const handleButtonClick = () => {
        setIsOpen(!isOpen);
    }

    const handleButtonBlur = (e) => {
        if (e.relatedTarget && e.relatedTarget.classList.contains('nh-cp__header__item')) return;
        setIsOpen(false);
    }

    return (
        <div className={['nh-cp__header', is_cabinet ? 'nh-cp__header__cabinet' : 'nh-cp__header__admin'].join(' ')}>
            <div className="nh-cp__header__col">
                <button
                    className={'nh-cp__header__toggle'}
                    onClick={handleToggle}>
                    <BurgerIcon/>
                </button>
                <a className="nh-cp__header__logo" href={'/'} target="_blank">
                    {is_academy ? <picture>
                        <source srcSet="/assets/images/header/academy/logo_large.svg" media="(min-width:991px)"/>
                        <img src="/assets/images/header/academy/logo_medium.svg"/>
                    </picture> : <picture>
                        <source srcSet="/assets/images/header/events/logo_large.svg" media="(min-width:991px)"/>
                        <img src="/assets/images/header/events/logo_medium.svg"/>
                    </picture>}
                </a>
            </div>
            <div className="nh-cp__header__col">
                {tariff && is_academy && !is_editor ?
                    <a className="nh-cp__header__tariff" href={`//${academyHost}/admin/services/academy/`}
                       target="_blank">
                    <span>
                        Тариф «{tariff.packetName}»
                    </span>
                        <small title={`Тариф истекает в ${getTime()}`}>до {getDate()}</small>
                    </a> : null}
                {!is_editor && !is_cabinet ?
                    <a className="nh-cp__header__adv"
                       href={`/admin/promotion/social`}>
                        <SpeakerIcon/>
                        <span>Заказать рекламу</span>
                    </a> : null}
                {!is_editor && !is_cabinet ?
                    <button className="nh-cp__header__create cp-btn cp-btn_size_medium cp-scheme_success-default">
                        <PlusIcon/>
                        <span>Создать</span>
                    </button> : null}
                {is_editor ? <div>
                    <a className="nh-cp__header__editor header__button type_settings"
                       id="nh-cp__header__editor-settings"
                       href={event.settings_url}>
                        <OptionsIcon/>
                        <span>Настройки</span>
                    </a>
                </div> : null}
                {is_editor ? <div>
                    <button
                        className="nh-cp__header__editor header__button type_sections">
                        <LayersIcon/>
                        <span>Блоки</span>
                    </button>
                </div> : null}
                {is_editor ? <div>
                    <button
                        className="nh-cp__header__editor header__button type_design">
                        <DesignIcon/>
                        <span>Дизайн</span>
                    </button>
                </div> : null}
                {is_editor ? <div>
                    <PublishEvent event={false} template={'editor'} id={eventId}/>
                    {/*<button*/}
                    {/*    className="cp-btn cp-btn_size_medium cp-scheme_primary-default nh-cp__header__publish header__button type_publish"*/}
                    {/*    data-href={`/admin/publish/${eventId}/`}*/}
                    {/*    data-first-publication={is_publish ? 1 : 0}>*/}
                    {/*    <UploadIcon/>*/}
                    {/*    <span>Опубликовать</span>*/}
                    {/*</button>*/}
                </div> : null}
                <nav className={'nh-cp__header__dropdown'} tabIndex={0}
                     ref={buttonRef} onClick={handleButtonClick} onBlur={(e) => handleButtonBlur(e)}>
                    <button
                        className={'nh-cp__header__profile'}>
                        <ProfileIcon/>
                    </button>
                    {isOpen ? <ul ref={dropdownRef}>
                        {droplist.sort((a, b) => a.position - b.position).map((item, item_idx) => <li
                            style={{"border-bottom": item.position === 80 ? '1px solid rgb(227, 230, 238' : ''}}
                            key={item_idx}>
                            <a href={item.href} target={item.target} className={'nh-cp__header__item'}>
                                {item.name}
                            </a>
                        </li>)}
                    </ul> : null}
                </nav>
            </div>
        </div>
    );
};
