import React, {useEffect, useState} from 'react';
import Switchers from './Switchers/Switchers';
import Fields from './Fields/Fields';
import {useSelector} from 'react-redux';
import Preloader from '../../../../../widget/components/preloader';
import SwitchersMock from './Switchers/Switchers.data.json';
import {postData} from '../../../../../utils/sendData';
import {API} from '../../../../../api/API';
import {deserializedData} from '../../../../../utils/deserializedData';
import {useRouteMatch} from 'react-router-dom';
import Notification from "../../../../../UIKit/component/notification/notification";
import './Form.scss';

const Form = () => {
    const router = useRouteMatch();
    const event = useSelector((state) => state.admin.event);
    const [switchersData, setSwitchersData] = useState([]);
    const [fieldsData, setFieldsData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [switchersLoader, setSwitchersLoader] = useState(true)
    const [fieldsLoader, setFieldsLoader] = useState(true)
    const [error, setError] = useState(false);
    const is_course = router.params.type_event_id !== 'events';
    const event_id = router.params.id;

    useEffect(() => {
        event.id ? handleInitData() : null
    }, [event.id]);

    /* Общая инициализация данных компонента <Form/> */
    const handleInitData = async () => {
        setLoader(true)
        try {
            handleInitSwitchers();
            handleInitFields();
        } catch (error) {
            console.error(error)
            setError(error);
            setLoader(false)
            setFieldsLoader(false)
            setSwitchersLoader(false)
        }
    };

    /* Инициализация данных API Switchers */
    const handleInitSwitchers = async () => {
        setLoader(false)
        setSwitchersLoader(true)
        setSwitchersData(handlePrepare(SwitchersMock.map((x) => ({...x, value: event[x.name]}))));
        setSwitchersLoader(false)
    };

    /* Удаление полей Switchers, которые не нужны в интерфейсах Курсов */
    const handlePrepare = (data) => {
        return is_course ? data.filter(x => x.name !== "is_options_open") : data
    }

    /* Инициализация данных API Fields */
    const handleInitFields = async () => {
        setLoader(false)
        setFieldsLoader(true)
        let response;
        response = await postData(null, API.getFieldsForm.path(is_course, event_id), API.getFieldsForm.method('GET'));
        response = response.data ? await deserializedData(response.data) : [];
        response.map((x) => (x.is_edit = false));
        setFieldsData(response);
        setFieldsLoader(false)
    };

    /* Дополнительные параметры, передаваемые дочерним компонентам */
    const params = {
        event_id: event_id,
        is_course: is_course,
    };

    return (
        <div className={'ui-cp__container'}>
            <div className="ui-cp__row ui-cp__row_md">
                <div className="ui-cp__form">
                    <h4 className={'settings-main-title'}>ФОРМА РЕГИСТРАЦИИ</h4>
                    {!error ? (loader ? <Preloader/> : <>
                        {!switchersLoader ? <Switchers data={switchersData} {...params} /> : <Preloader/>}
                        {!fieldsLoader ? <Fields data={fieldsData} {...params} /> : <Preloader/>}
                    </>) : <Notification type={'error'} value={'Произошла ошибка при загрузке данных'}/>}
                </div>
            </div>
        </div>
    );
};

export default Form;