import React from 'react';
import {Rect} from "react-konva";

const ActiveSquare = (pos) => {
    // выбранная пустая ячейка
    return <Rect
        name="gridRect"
        paramRect={"true"}
        x={pos.pos.x + 6}
        y={pos.pos.y + 6}
        height={24}
        width={24}
        stroke="#0067F2"
    />
};

export default ActiveSquare;
