import React, {useEffect, useRef, useState} from 'react';
import styles from './Select.module.scss'
import Message from "../../../widget/components/Message";
import detectElementOverflow from "detect-element-overflow";
import ArrowIcon from '../../../assets/images/icons/16/arrow.svg'

const Select = (
    {
        data = [],
        value,
        error,
        direction = direction || [],
        parentRef = parentRef || {},
        onChange,
        onClick,
        size = 'small',
        label,
        placeholder = 'Не выбрано',
        disabled,
        dropState = false,
        onDropShow,
        onDropHide,
        visibilityItems,
        children,
    }) => {
    const selectRef = useRef(null)
    const listRef = useRef(null)
    const [dropdown, setDropdown] = useState(dropState)
    const [hoveredItem, setHoveredItem] = useState(null)
    const [directionList, setDirectionList] = useState([])

    useEffect(() => {
        dropdown && Object.entries(data).length ? handleCheckCollision() : null
    }, [dropdown, Object.entries(data).length])

    const handleShow = async () => {
        onDropShow ? await onDropShow() : null
        setDropdown(true)
        if (!Object.entries(data).length) return
        setHoveredItem(Object.keys(data).indexOf(value))
    }

    const handleHide = async (e) => {
        if (e && e.relatedTarget && selectRef.current.contains(e.relatedTarget)) return;

        onDropHide ? await onDropHide() : null
        setDropdown(false)
        setHoveredItem(null)
        setDirectionList([])
    }

    const handleSelect = (key) => {
        key ? onChange(key) : null
        handleHide()
        selectRef.current.blur()
    }

    const handleCheckCollision = () => {
        if (direction.length) {
            setDirectionList(direction)
            return
        }
        const {
            collidedTop,
            collidedLeft,
            collidedBottom,
            collidedRight
        } = detectElementOverflow(listRef.current, parentRef.current)

        setDirectionList([
            collidedTop ? 'bottom' : null,
            collidedRight ? 'left' : null,
            collidedBottom ? 'top' : null,
            collidedLeft ? 'right' : null,
            !collidedLeft && !collidedBottom && !collidedRight && !collidedTop ? 'center' : null,
        ].filter(x => x ?? x))
    }

    const handlePress = (e) => {
        if (e.target !== selectRef.current) return
        e.preventDefault();
        e.keyCode === 38 ? handleUp() : null
        e.keyCode === 40 ? handleDown() : null
        e.keyCode === 13 ? handleSelect(Object.keys(data)[hoveredItem]) : null
    }

    const handleUp = () => {
        setHoveredItem(prev => prev - 1 > 0 ? prev - 1 : 0)
    }

    const handleDown = () => {
        setHoveredItem(prev => prev + 1 < Object.keys(data).length ? prev + 1 : Object.keys(data).length - 1)
    }

    return (
        <div className={[
            styles.container,
            styles[size],
            disabled ? styles.disabled : null,
            error ? styles.error : null,
            directionList.filter(x => x ?? x).map(x => styles[x]).join(' ')
        ].filter(x => x ?? x).join(' ')}>
            {label ? <label className={styles.label}>{label}</label> : null}

            <div className={[!children ? styles.text : null, styles.trigger].filter(x => x ?? x).join(' ')}
                 onFocus={handleShow}
                 onBlur={handleHide}
                 onKeyDown={handlePress}
                 ref={selectRef}
                 tabIndex={0}>
                {children ? <div className={styles.children}>
                    {children}
                </div> : <>
                    {value !== null && value !== undefined ? <div className={styles.value}>
                        {data[value]}
                    </div> : null}
                    {placeholder && (value === null || value === undefined || value == -1) ? <div
                        className={styles.placeholder}>
                        {placeholder}
                    </div> : null}
                    <div className={styles.arrow}>
                        <ArrowIcon/>
                    </div>
                </>}
                {dropdown && Object.entries(data).length ? <ul
                    className={[styles.list, children ? styles.width : null].filter(x => x ?? x).join(' ')}
                    style={{maxHeight: 41 * (visibilityItems || 7)}}
                    ref={listRef}>
                    {Object.entries(data).map((x, i) => <li
                        key={x[0]}
                        className={[
                            styles.item,
                            value === x[0] ? styles.active : null,
                            hoveredItem === i && Object.keys(data)[hoveredItem] !== value ? styles.hover : null
                        ].filter(x => x ?? x).join(' ')}
                        onClick={() => handleSelect(x[0])}
                        onTouchMove={(e) => setHoveredItem(i)}
                        onMouseMove={(e) => setHoveredItem(i)}>
                        {x[1]}
                    </li>)}
                </ul> : null}
            </div>

            {error ? <Message value={error} type={'error'}/> : null}
        </div>
    );
};

export default Select;
