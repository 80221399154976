import {API} from "../../../../../api/API";
import {postData} from "../../../../../utils/sendData";
import JSONAPIDeserializer from "jsonapi-serializer/lib/deserializer";
import JSONAPISerializer from "jsonapi-serializer/lib/serializer";

export const methodGetTemplate = async (template_id, event_id) => {
    let response, url, method, deserialized, query

    query = {event_id: event_id}
    url = [
        API.getNotificationTemplate.path(template_id),
        Object.entries(query).map(x => x[1] ? x.join('=') : null).filter(x => x ?? x).join('&')
    ].join('?')
    method = API.getNotificationTemplate.method

    response = await postData({}, url, method)
    deserialized = await deserializedData(response.data)

    return deserialized
}

export const methodCreateNotification = async (params, event_id, is_course) => {
    let response, url, method, serialized, deserialized

    const {
        message,
        name,
        period,
        subject
    } = params.hash ? await methodGetTemplate(params.attributes.template_id, event_id) : params.attributes

    params.attributes = {...params.attributes, message: message, name: name, period: period, subject: subject}
    params.hash === null ? params.attributes.is_active = true : null

    serialized = serializeData(params.attributes, event_id, is_course)
    url = API.createNotificationItem.path
    method = API.createNotificationItem.method

    response = await postData(serialized, url, method)
    deserialized = await deserializedData(response.data)

    return deserialized
}

export const methodUpdateNotification = async (params, event_id, is_course) => {
    let response, serialized, deserialized
    serialized = serializeData(params, event_id, is_course)
    if (params.attributes.layout_id === 100){
        serialized.data.attributes['layout_id'] = 100
    }
    response = await postData(serialized, API.updateNotificationItem.path(params.id), API.updateNotificationItem.method)
    deserialized = await deserializedData(response.data)
    return deserialized
}

export const methodGetNotification = async (params) => {
    let response, url, method, deserialized

    url = API.getNotificationItem.path(params.id)
    method = API.getNotificationItem.method

    response = await postData({}, url, method)
    deserialized = await deserializedData(response.data)

    return deserialized
}

export const methodResetNotification = async (params) => {
    let response, url, method

    url = API.getNotificationItem.path(params.id)
    method = 'DELETE'
    response = await postData({}, url, method)

    return response
}

export const methodResendNotification = async (params) => {
    let response, url, method

    url = API.planResendNotification.path(params.id)
    method = API.planResendNotification.method
    response = await postData(params.attributes, url, method)

    return response
}

export const methodRemoveTemplate = async (params, event_id) => {
    let response, url, method, query

    query = {event_id: event_id}
    url = [
        API.getNotificationTemplate.path(params),
        Object.entries(query).map(x => x[1] ? x.join('=') : null).filter(x => x ?? x).join('&')
    ].join('?')
    method = 'DELETE'

    response = await postData({}, url, method)
    return response
}

export const methodGetUserTemplates = async (event_id) => {
    let response, url, method, deserialized, query

    query = {event_id: event_id, my: 1}
    url = [
        API.getNotificationTemplates.path,
        Object.entries(query).map(x => x[1] ? [`filter[${x[0]}]`, x[1]].join('=') : null).filter(x => x ?? x).join('&')
    ].join('?')
    method = API.getNotificationTemplates.method

    response = await postData({}, url, method)
    deserialized = await deserializedData(response.data)

    return deserialized
}

export const methodCreateTemplate = async (params, event_id) => {
    let response, url, method, deserialized

    url = API.createNotificationTemplate.path(event_id)
    method = API.createNotificationTemplate.method

    response = await postData(params, url, method)
    deserialized = await deserializedData(response.data)

    return deserialized
}

export const methodTestNotification = async (params) => {
    let response, url, method

    url = API.testNotificationItem.path(params.id)
    method = API.testNotificationItem.method

    delete params.id
    response = await postData(params, url, method)

    return response
}

const deserializedData = async (data) => new JSONAPIDeserializer({
    keyForAttribute: 'underscore_case',
}).deserialize(JSON.parse(JSON.stringify(data)), (err, deserializeData) => deserializeData);

const serializeData = (params, event_id, is_course) => {
    let config, result, body

    config = {
        keyForAttribute: 'underscore_case',
        typeForAttribute: (attribute, data) => data.customType,
        event: {ref: 'id', included: false},
        attributes: ['event', 'name', 'is_active', 'message', 'notify_at', 'period', 'subject', 'template_id'],
    }
    body = {...params, ...params.attributes, event: {id: event_id, customType: is_course ? 'courses' : 'events'}};
    result = new JSONAPISerializer('notifications', config).serialize(body);

    return result
}

export const handleErrors = (errors) => {
    errors.forEach((item) => {
        item.detail && showTopMessage('error', 'small', item.detail)
    })
}
