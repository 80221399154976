/* Принимает в себя дату и формат, возвращает дату в указанном формате */
import {isoGetOffset} from "./toIsoString";

export const getFormattedDate = (date, format, timezone) => {
    const dateObject = new Date(new Date(date).toLocaleString('en-US', {timeZone: timezone}));
    let formattedDate = format;

    const months = ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'];
    const days = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'];

    formattedDate = formattedDate.replace(/dd/g, ('0' + dateObject.getDate()).slice(-2));
    formattedDate = formattedDate.replace(/MM/g, ('0' + (dateObject.getMonth() + 1)).slice(-2));
    formattedDate = formattedDate.replace(/yyyy/g, dateObject.getFullYear());
    formattedDate = formattedDate.replace(/yy/g, dateObject.getFullYear().toString().slice(-2));
    formattedDate = formattedDate.replace(/MMM/g, months[dateObject.getMonth()]);
    formattedDate = formattedDate.replace(/MMMM/g, months[dateObject.getMonth()]);
    formattedDate = formattedDate.replace(/ddd/g, days[dateObject.getDay()]);
    formattedDate = formattedDate.replace(/dddd/g, days[dateObject.getDay()]);
    formattedDate = formattedDate.replace(/HH/g, ('0' + dateObject.getHours()).slice(-2));
    formattedDate = formattedDate.replace(/mm/g, ('0' + dateObject.getMinutes()).slice(-2));
    formattedDate = formattedDate.replace(/ss/g, ('0' + dateObject.getSeconds()).slice(-2));
    formattedDate = formattedDate.replace(/a/g, dateObject.getHours() < 12 ? 'AM' : 'PM');

    formattedDate = formattedDate.replace(/Z/g, () => {

        // console.log('now: ' + new Date())
        // console.log('local to utc offset: ' + dateObject.getTimezoneOffset())
        // console.log('local to Moscow offset: ' + isoGetOffset('Europe/Moscow'))
        // console.log('local to ' + timezone + ' offset: ' + isoGetOffset(timezone))

        let
            abs = Math.abs(isoGetOffset(timezone)),
            hours = Math.floor(abs / 60).toString().padStart(2, '0'),
            minutes = (abs % 60).toString().padStart(2, '0')
        return (dateObject.getTimezoneOffset() > 0 ? '-' : '+') + hours + ':' + minutes
    });

    return formattedDate;
}