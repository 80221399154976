/* Принимает в себя объект любой сложности и вложенности и ключ, возвращает сумму значений указанного ключа */
/**
 * Options:
 * infinity - true/false; когда true -> то при наличии в объекте хотя бы одного null вернется Infinity, иначе поле null не будет учитываться при суммировании
 * **/
export const getSumValuesByKeys = (obj, key, options) => {
    let sum = 0;

    function traverse(obj, key) {
        for (const k in obj) {
            if (obj.hasOwnProperty(k)) {
                if (k === key) {
                    if (options?.infinity && obj[k] === null) {
                        sum = Infinity
                        return;
                    }
                    if (obj[k] && typeof obj[k] !== 'number') {
                        throw new Error(`значение ${obj[k]} не является числом`);
                    }
                    sum += obj[k];
                } else if (typeof obj[k] === "object") {
                    traverse(obj[k], key);
                }
            }
        }
    }

    traverse(obj, key);
    return sum;
}