// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Resend-module_body_W7nMd{display:flex;gap:16px;flex-direction:column}.Resend-module_body_W7nMd .flatpickr-time{height:auto}.Resend-module_body_W7nMd .form-control{display:none}.Resend-module_body_W7nMd .flatpickr-time .arrowUp,.Resend-module_body_W7nMd .flatpickr-time .arrowDown{padding:0}.Resend-module_body_W7nMd .flatpickr-calendar{box-shadow:none;border:1px solid var(--wild-blue-yonder, #A7B0CA)}", "",{"version":3,"sources":["webpack://resources/js/react/src/views/Admin/Events/Notifications/Notification/Modal/Resend/Resend.module.scss"],"names":[],"mappings":"AAAA,0BACE,YAAA,CACA,QAAA,CACA,qBAAA,CAGF,0CACE,WAAA,CAGF,wCACE,YAAA,CAGF,wGAEE,SAAA,CAGF,8CACE,eAAA,CACA,iDAAA","sourcesContent":[".body {\n  display: flex;\n  gap: 16px;\n  flex-direction: column;\n}\n\n.body :global(.flatpickr-time) {\n  height: auto;\n}\n\n.body :global(.form-control) {\n  display: none;\n}\n\n.body :global(.flatpickr-time .arrowUp),\n.body :global(.flatpickr-time .arrowDown) {\n  padding: 0;\n}\n\n.body :global(.flatpickr-calendar) {\n  box-shadow: none;\n  border: 1px solid var(--wild-blue-yonder, #A7B0CA);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "Resend-module_body_W7nMd",
	"flatpickrTime": "flatpickr-time",
	"formControl": "form-control",
	"arrowUp": "arrowUp",
	"arrowDown": "arrowDown",
	"flatpickrCalendar": "flatpickr-calendar"
};
export default ___CSS_LOADER_EXPORT___;
