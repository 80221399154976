import React, {useEffect, useRef, useState} from 'react';
import '../../../../scss/pages/panel.scss'
import {API} from "../../../../api/API";
import {useRouteMatch} from "react-router";
import TicketsEditor from "../../../../components/tickets/TicketsEditor";
import {postData} from "../../../../utils/sendData";
import axios from "axios";
import TicketSettings from "../../../../components/tickets/TicketSettings";
import IconAdd from '../../../../assets/images/icons/16/plus_simple.svg'
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
    arrayMove
} from "react-sortable-hoc";
import IconDrag from "../../../../assets/images/icons/16/arrows-v.svg";
import {useDispatch, useSelector} from "react-redux";
import JSONAPIDeserializer from "jsonapi-serializer/lib/deserializer";
import DragIcon from "../../../../assets/images/icons/16/arrows-v.svg";
import WarningIcon from "../../../../assets/images/modals/warning.svg";
import Window from "../../../../UIKit/component/window/window";
import CopyTicket from "../../../../UIKit/component/window/template/copyTicket";
import Agreement from "../../../../UIKit/component/window/template/agreement";


const SettingsEvents = () => {
    const router = useRouteMatch()
    const id = router.params.id
    const dispatch = useDispatch()
    const selector = useSelector(state => state)

    const [sessions, setSessions] = useState(null)
    const [floors, setFloors] = useState(null)
    const [tickets, setTickets] = useState(VIEW?.tickets)
    const [certificates, setCertificates] = useState(VIEW?.certificatePromocodes)
    const [types, setTypes] = useState(VIEW?.ticketTypes)
    const [editor, setEditor] = useState()

    const copyTicketDialog = useRef(null)
    const [status, setStatus] = useState(false)

    useEffect(() => {
        initAdminSelector()
    }, [])

    useEffect(() => {
        if (!sessions) {
            postData(null,
                API.getSchedule.path(id),
                API.getSchedule.method
            ).then((res) => {
                setSessions(res.data.sessions);
            });
        }
    }, [sessions])

    useEffect(() => {
        if (!floors) {
            postData(null,
                API.getAttachedFloors.path(id),
                API.getAttachedFloors.method
            ).then((res) => {
                setFloors(res.data.data)
            });
        }
    }, [floors])

    useEffect(() => {
        if (certificates.length === 0) {
            let copy = types
            delete copy['16']
            setTypes(copy)
        }
    }, [types])

    const initAdminSelector = async () => {
        let constants, event

        constants = await handleGetConstants()
        event = await handleGetEvent()

        dispatch({type: "SET_ADMIN", payload: {...selector.admin, event: event, constants: constants}})
    }

    const handleGetEvent = async () => {
        let response, current, data

        response = await postData(null, API.getEvents.path(id), API.getEvents.method)
        current = response.data.data?.attributes?.is_online
        data = response.data

        /**
         * 0 - офлайн
         * 1 - онлайн
         * 2 - онлайн+офлайн
         */

        if (current == 0) setEmptyTicket(prev => ({...prev, type: 1}))
        if (current == 1) setEmptyTicket(prev => ({...prev, type: 2}))
        if (current == 2) setEmptyTicket(prev => ({...prev, type: 3}))

        const deserializedData = async (data) => new JSONAPIDeserializer({
            keyForAttribute: 'underscore_case',
        }).deserialize(JSON.parse(JSON.stringify(data)), (err, deserializeData) => deserializeData);
        data = await deserializedData(data)
        return data
    }

    const handleGetConstants = async () => {
        let response, data

        response = await postData(null, API.getCourseCreationParams.path, API.getCourseCreationParams.method)
        data = response.data.data

        return data
    }

    const [emptyTicket, setEmptyTicket] = useState({
        buyLink: null,
        count: null,
        countAvailableTicketKeys: null,
        countOrdered: null,
        countTicketKeys: 0,
        deleted: null,
        description: null,
        endsAt: null,
        event_id: null,
        floorGroups: [],
        hasKeys: null,
        has_options: null,
        has_prices: null,
        iframeSrc: null,
        isNotSellingReason: null,
        isScheduleNeeded: null,
        isSelling: true,
        maxFreeAmount: null,
        maxFullAmount: null,
        maxPaidAmount: null,
        name: null,
        needOneOptionParam: false,
        onlyOneOptionParam: false,
        options: [],
        price: null,
        priceFormatted: null,
        priceOriginal: null,
        prices: [],
        prices_up: [],
        promocodeId: null,
        sessions: [],
        startsAt: null,
        statAvailable: null,
        statTotal: null,
        ticket_id: null,
        type: null, //тип билета по умолчанию при добавлении нового билета, указывать id типа билета, сейчас они могут быть: 1,2,3,4,16
        unlimited: null,
        validPeriod: null,
        videoLink: null,
        is_active: true
    })

    const [edit, setEdit] = useState({
        id: null,
        state: null,
    })

    //handlers
    //вызов удаления билета по ticket_id
    const handleRemove = (ticket_id) => {
        let ticket = tickets.find(x => x.ticket_id === ticket_id),
            data = {
                id: `regticket-id_${ticket_id}`
            }

        postData(data, API.deleteTicket.path(id), API.deleteTicket.method).then(e => {
            if (e.data.success === true) {
                setTickets(tickets.filter(x => x.ticket_id !== ticket_id))
                showTopMessage('success', 'small', 'Успешно', `Билет «${ticket.name}» был успешно удален`);
            } else {
                showTopMessage('error', 'small', 'Ошибка', 'При удалении билета произошла ошибка. Повторите попытку позднее')
            }
        }).catch((e) => {
            console.log(e)
            showTopMessage('error', 'small', 'Ошибка', 'При удалении билета произошла ошибка. Повторите попытку позднее')
        })
    }

    //вызов удаления билета по ticket_id
    const handleActiveTicket = (ticket_id, callback) => {
        let ticket = tickets.find(x => x.ticket_id === ticket_id), data = {is_active: !ticket.is_active}
        postData(data,
            API.patchActiveTicket.path(id, ticket.ticket_id),
            API.patchActiveTicket.method
        ).then((res) => {
            if (res.data.success) {
                tickets.map((item) => {
                    if (item.ticket_id == ticket.ticket_id) item.is_active = !ticket.is_active
                    return item
                })
                setTickets(tickets)
                callback()
            }
        });
    }

    const handleSubmit = (data) => {
        let copy = null
        switch (data.action) {
            case 'edit':
                copy = JSON.parse(JSON.stringify(tickets))
                copy[copy.findIndex(x => x.ticket_id == data.data.ticket_id)] = data.data
                setTickets(copy)
                break;
            case 'add':
                copy = JSON.parse(JSON.stringify(tickets))
                copy.push(data.data)
                setTickets(copy)
                break;
        }
        handleSwitchTicketsEditor(null, false)
    }

    const handleSwitchTicketsEditor = (ticket_idx, state) => {
        setEdit({
            id: ticket_idx,
            state: state
        })
    }

    const DragHandle = SortableHandle(() => {
        return <button className={'cp-btn cp-btn_size_small cp-scheme_secondary-ghost ui-cp__tickets__draggable'}
                       disabled={edit.state}>
                <span className={'ui-cp__tickets-actions-drag'}>
                    <DragIcon/>
                </span>
        </button>
    })

    //компонент билета, включает в себя редактор и сам билет
    const Item = SortableElement(props => {
        //редактор билета
        const ItemEditor = () => {
            return <TicketsEditor
                id={id}
                item={props.item}
                key={props.idx}
                idx={props.idx}
                certs={certificates}
                sessions={sessions}
                floors={floors}
                types={types}
                action={'edit'}
                handlers={{handleSwitchTicketsEditor, handleSubmit, setTickets, setTypes, setSessions}}
            />
        }
        //билет
        const ItemStatic = () => {
            return <TicketSettings
                id={id}
                item={props.item}
                key={props.idx}
                index={props.idx}
                certs={certificates}
                action={'edit'}
                tickets={tickets}
                floors={floors}
                dragHandle={<DragHandle/>}
                edit={edit}
                handlers={{
                    handleSwitchTicketsEditor,
                    handleSubmit,
                    handleRemove,
                    handleCopyTicket,
                    onSortEnd,
                    handleActiveTicket
                }}
            />
        }
        return <div
            data-ticket-id={props.item.id}>{edit.state === true && edit.id === props.idx ? ItemEditor() : floors ? ItemStatic() : null}</div>
    });

    //компонент списка билетов
    const List = SortableContainer(({items}) => <div>{items.map((item, index) => {
        return <Item key={`item-${index}`}
                     index={index}
                     idx={index}
                     item={item}/>
    })}</div>);

    //событие окончания сортировки
    const onSortEnd = ({oldIndex, newIndex}) => {
        let arr = arrayMove(tickets, oldIndex, newIndex)
        setTickets(arr)
        if (arr.length > 1) {
            let data = {}, formData = new FormData()
            if (arr.length) {
                for (let i in arr) {
                    data[`list[${i}][id]`] = arr[i].ticket_id
                    data[`list[${i}][position]`] = arr.findIndex(x => x === arr[i]);
                }
            }

            Object.entries(data).forEach(e => {
                if (e[1] || e[1] == 0) formData.append(e[0], e[1])
            })

            axios({
                url: API.shuffleTicket.path(id),
                method: API.shuffleTicket.method,
                data: formData,
                config: {headers: {'Content-Type': 'multipart/form-data'}}
            }).then(e => {

            }).catch((e) => {
                console.log(e)
            })
        }
    };

    const handleCopyTicket = (id) => {
        setStatus(id)
    }

    const handleConfirmCopy = async (fields) => {
        let response, ticket, body

        ticket = tickets.find(x => x.ticket_id === status)
        body = {...fields, id: ticket.ticket_id}

        try {
            response = await postData(body, API.copyTicket.path(ticket.event_id), API.copyTicket.method)
            response = response.data
            showTopMessage('success', 'small', response.message || "Операция выполнена успешно");
        } catch (e) {
            if (e.response && e.response.status === 422) return e.response.data
            showTopMessage('error', 'small', "Произошла ошибка. Попробуйте позже");
        }
        copyTicketDialog.current?.handleClose()
        setTickets( [...tickets,
            {
                ...ticket,
                ...fields,
                ticket_id: response.id,
                options: ticket.options.map((option, index) => ({
                    ...option,
                    id: response.options_ids[index],
                    ticketId: response.id
                }))
            }]
        )
    }

    //рендер шаблона
    return (
        <div className={'ui-cp__container'}>
            {/*новые блоки для вкладки Регистрация и билеты писать здесь */}
            <div className={'ui-cp__tickets'}>
                <Window
                    ref={copyTicketDialog}
                    status={status}
                    setStatus={setStatus}
                    content={<CopyTicket
                        data={{name: tickets.find(x => x.ticket_id === status)?.name}}
                        onCancel={copyTicketDialog.current?.handleClose}
                        onSubmit={handleConfirmCopy}
                    />}
                    dismissible={true}
                    esc={true}
                    selector={'ui-cp__tickets__modal'}
                />
                <div className="ui-cp__row ui-cp__row_md">
                    <div className="ui-cp__tickets">
                        <h4 className={'settings-main-title'}>Билеты</h4>
                        <List transitionDuration={500} lockAxis={'y'} items={tickets} onSortEnd={onSortEnd}
                              useDragHandle/>
                    </div>
                </div>
                {(edit.state === true && edit.id === null) ? <div className="ui-cp__row ui-cp__row_md">
                    <TicketsEditor
                        id={id}
                        item={emptyTicket}
                        key={null}
                        idx={null}
                        certs={certificates}
                        types={types}
                        action={'add'}
                        sessions={sessions}
                        floors={floors}
                        handlers={{handleSwitchTicketsEditor, handleSubmit, setTickets, setTypes, setSessions}}
                    />
                </div> : null}
                <div className="ui-cp__row ui-cp__row_md ui-cp__tickets-actions">
                    <button className={'cp-btn cp-btn_size_small cp-scheme_secondary-default cp-btn_icon_left'}
                            disabled={edit.state}
                            onClick={() => {
                                handleSwitchTicketsEditor(null, true)
                            }}>
                        <IconAdd/>
                        Добавить билет
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SettingsEvents;
