import React, {useRef, useState} from 'react';
import styles from './Notification.module.scss';
import Switcher from '../../../../../UIKit/component/switcher/Switcher';
import EditIcon from '../../../../../assets/images/icons/16/pencil.svg';
import NotificationIcon from '../../../../../assets/images/icons/16/notification.svg';
import DotsIcon from '../../../../../assets/images/icons/16/more-vertical.svg';
import Edit from './Edit/Edit'
import Confirm from "../../../../../UIKit/component/confirm/confirm";
import Window from "../../../../../UIKit/component/window/window";
import PeriodModalContent from './Modal/Datetime/Datetime'
import ResendModalContent from './Modal/Resend/Resend'
import Select from "../../../../../UIKit/component/select/Select";
import JSONAPISerializer from "jsonapi-serializer/lib/serializer";
import {useRouteMatch} from "react-router-dom";
import scrollToElement from "../../../../../utils/scrollToElement";
import {
    handleErrors,
    methodCreateNotification,
    methodCreateTemplate,
    methodGetNotification,
    methodGetUserTemplates,
    methodRemoveTemplate,
    methodResendNotification,
    methodResetNotification,
    methodTestNotification,
    methodUpdateNotification
} from "./Notification.methods";

const Notification = ({data, variables, periods, onChange, onReset}) => {
    const router = useRouteMatch()
    const {event_id, type} = router.params
    const is_course = type === 'course'
    const [status, setStatus] = useState(false)
    const periodModalRef = useRef(null)
    const resendModalRef = useRef(null)
    const notificationRef = useRef(null)
    const ref = useRef(document.querySelector('.settings-container'))

    const handleSaveNotification = async (params, reset) => {
        let response
        params = {...params, attributes: {...params.attributes, is_edit: !data.attributes.is_edit}}
        try {
            response = params.hash === null
                ? await methodCreateNotification({
                    ...params,
                    attributes: {...data.attributes, ...params.attributes},
                    hash: data.hash,
                }, event_id, is_course) :
                await methodUpdateNotification(params, event_id, is_course)
        } catch (e) {
            return e.response
        }

        if (reset) {
            await onReset()
            scrollToElement(notificationRef, {position: 'center', offset: -70})
            highlightNotification()
        }

        return response
    }

    const handleSaveTemplate = async (params) => {
        let response, serialized

        serialized = new JSONAPISerializer(params.type, {
            keyForAttribute: 'underscore_case',
            attributes: ['event', 'name', 'message', 'subject']
        }).serialize(params);

        try {
            response = await methodCreateTemplate(serialized, event_id)
        } catch (e) {
            return e.response
        }

        return response
    }

    const handleRemoveTemplate = async (params) => {
        let response

        try {
            response = await methodRemoveTemplate(params, event_id)
        } catch (e) {
            showTopMessage('error', 'small', 'Произошла ошибка. Повторите попытку позднее')
        }

        return response
    }

    const handleGetUserTemplates = async () => {
        let response

        try {
            response = await methodGetUserTemplates(event_id)
        } catch (e) {
            showTopMessage('error', 'small', 'Произошла ошибка. Повторите попытку позднее')
        }

        return response.map(x => ({...x, id: Number(x.id)}))
    }

    const handleCancelForm = () => {
        onChange(prev => ({
            ...prev, groups: prev.groups.map(g => ({
                ...g,
                list: g.list.map(n => ({
                    ...n,
                    attributes: {...n.attributes, is_disabled: false, is_edit: false}
                })).filter(x => x.hash)
            }))
        }))
    }

    const handleActive = async () => {
        let response, params

        params = {id: data.id || null, attributes: {is_active: !data.attributes.is_active}}

        handleDisableNotification()

        // !params.id ? response = await methodCreateNotification({
        //     ...params,
        //     attributes: {...data.attributes, ...params.attributes},
        //     hash: data.hash
        // }, event_id, is_course) : response = await methodUpdateNotification(params, event_id, is_course)

        if (!params.id) {
            response = await methodCreateNotification({
                ...params,
                attributes: {...data.attributes, ...params.attributes},
                hash: data.hash
            }, event_id, is_course);
        } else {
            try {
                response = await methodUpdateNotification(params, event_id, is_course);
            } catch (error) {
                if (error.response && error.response.status === 422) {
                    handleErrors(error.response.data.errors)
                } else {
                    console.error("An error occurred:", error.message);
                }
                handleDisableNotification()
                return
            }
        }


        handleDisableNotification()
        showTopMessage('success', 'small', 'Операция выполнена успешно')

        onChange(prev => ({
            ...prev, groups: prev.groups.map(g => g.variables === data.variables ? {
                ...g,
                list: g.list.map(n => n.hash === data.hash ? {
                    ...n, id: response ? Number(response.id) : params.id,
                    actions: {...n.actions, ...response.actions},
                    attributes: {...n.attributes, ...params.attributes}
                } : {...n})
            } : {...g})
        }))
    }

    const handleEdit = async () => {
        let response, params

        handleDisableNotification()
        params = {...data, hash: data.hash, attributes: {...data.attributes, is_edit: !data.attributes.is_edit}}
        !data.id ? response = await methodCreateNotification(params, event_id, is_course) : response = await methodGetNotification(params, event_id, is_course)

        params = {
            ...params,
            id: Number(response.id),
            attributes: {
                ...params.attributes,
                ...Object.fromEntries(Object.entries(response).filter(x => x[0] !== 'id'))
            }
        }

        onChange(prev => ({
            ...prev, groups: prev.groups.map(g => g.variables === data.variables ? {
                ...g, list: g.list.map(n => n.hash === data.hash ? {
                    ...n, ...params,
                    attributes: {...n.attributes, ...params.attributes},
                    info: {...n.info, ...response.info},
                    actions: {...n.actions, ...response.actions},
                } : {...n, attributes: {...n.attributes, is_disabled: !n.attributes.is_disabled}})
            } : {
                ...g,
                list: g.list.map(n => ({...n, attributes: {...n.attributes, is_disabled: !n.attributes.is_disabled}}))
            })
        }))
    }

    const handleTestMessage = async (form) => {
        let response, fields

        fields = Object.fromEntries(Object.entries(form.fields).map(x => [x[0], x[1].value]))
        response = await methodTestNotification({...fields, id: data.id})

        return response
    }

    const handleSelectAction = (key) => {
        key === 'resend' ? handleCallWindow(key) : null
        key === 'reset' ? handleConfirm(key) : null
        key === 'remove' ? handleConfirm(key) : null
    }

    const handleReset = async () => {
        try {
            await methodResetNotification({id: data.id})
            await onReset()
            showTopMessage('success', 'small', 'Операция выполнена успешно')
            highlightNotification()
        } catch (e) {
            showTopMessage('error', 'small', 'Произошла ошибка. Повторите попытку позднее')
        }
    }

    const handleCallWindow = async (type) => {
        await handleCreateNotification({})
        setStatus(type)
    }

    const handleSubmitPeriodWindow = async (params) => {
        let response
        params = {
            id: data.id || null,
            hash: data.hash,
            attributes: {period: params.attributes.period, notify_at: params.attributes.notify_at}
        }

        try {
            params.id ? response = await methodUpdateNotification(params, event_id, is_course) : await methodCreateNotification(params, event_id, is_course)
        } catch (e) {
            return e.response
        }

        onChange(prev => ({
            ...prev, groups: prev.groups.map(g => g.variables === data.variables ? {
                ...g, list: g.list.map(n => n.hash === data.hash ? {
                    ...n, attributes: {
                        ...n.attributes, notify_at: params.attributes.notify_at, period: params.attributes.period
                    }, info: {...n.info, planned_at: response.info.planned_at}
                } : {...n})
            } : {...g})
        }))

        scrollToElement(notificationRef, {position: 'center', offset: -70})
        handleCancelPeriodWindow()
        highlightNotification()
        return response
    }

    const handleSubmitResendWindow = async (params) => {
        let response
        params = {...data, attributes: {period: params.attributes.period, notify_at: params.attributes.notify_at}}

        try {
            response = await methodResendNotification(params)
        } catch (e) {
            return e.response
        }

        await onReset()
        handleCancelResendWindow()
        scrollToElement(notificationRef, {position: 'center', offset: -70})
        highlightNotification()
        return response
    }

    const handleCancelPeriodWindow = () => {
        periodModalRef.current.handleClose()
    }

    const handleCreateNotification = async (params) => {
        let response

        handleDisableNotification()
        if (!data.id) {
            response = await methodCreateNotification({
                ...params,
                hash: data.hash,
                attributes: {...data.attributes, ...params.attributes}
            }, event_id, is_course)
            onChange(prev => ({
                ...prev, groups: prev.groups.map(g => g.variables === data.variables ? {
                    ...g,
                    list: g.list.map(n => n.hash === data.hash ? {
                        ...n, id: response ? Number(response.id) : data.id,
                        actions: {...n.actions, ...response.actions},
                        attributes: {...n.attributes, ...params.attributes}
                    } : {...n})
                } : {...g})
            }))
        }
        handleDisableNotification()
        return response
    }

    const handleCancelResendWindow = () => {
        resendModalRef.current.handleClose()
    }

    const handleConfirm = (value) => {
        onChange(prev => ({
            ...prev, groups: prev.groups.map(g => g.variables === data.variables ? {
                ...g,
                list: g.list.map(n => n.hash === data.hash ? {
                    ...n, attributes: {...n.attributes, is_confirm: value}
                } : {
                    ...n, attributes: {...n.attributes, is_disabled: !n.attributes.is_disabled}
                })
            } : {
                ...g,
                list: g.list.map(n => ({...n, attributes: {...n.attributes, is_disabled: !n.attributes.is_disabled}}))
            })
        }))
    }

    const handleDisableNotification = () => {
        onChange(prev => ({
            ...prev, groups: prev.groups.map(g => g.variables === data.variables ? {
                ...g, list: g.list.map(n => n.hash === data.hash ? {
                    ...n,
                    attributes: {...n.attributes, is_disabled: !n.attributes.is_disabled}
                } : {...n})
            } : {...g})
        }))
    }

    const generateAvailableActions = () => {
        let obj = {}

        data.actions.resend ? obj.resend = 'Отправить повторно' : null
        data.actions.reset ? obj.reset = 'Вернуть к базовому шаблону' : null
        data.actions.delete ? obj.remove = 'Удалить шаблон' : null

        return obj
    }

    const generateStatisticText = () => {
        const {count_errors, count_sent, count_viewed, count_unsubscribed} = data.info
        return [
            count_sent ? ['отправлено', count_sent].join(': ') : null,
            count_viewed ? ['просмотрено', count_viewed].join(': ') : null,
            count_unsubscribed ? ['отписано', count_unsubscribed].join(': ') : null,
            count_errors ? ['ошибок', count_errors].join(': ') : null,
        ].filter(x => x ?? x).join(', ')
    }

    const checkGenerateStatistic = () => {
        let {count_sent, count_viewed, count_errors, count_unsubscribed} = data.info
        return count_sent || count_viewed || count_errors || count_unsubscribed
    }

    const highlightNotification = () => {
        setTimeout(() => {
            notificationRef.current ? notificationRef.current.classList.add(styles.highlight) : null
        }, 350)
        setTimeout(() => {
            notificationRef.current ? notificationRef.current.classList.remove(styles.highlight) : null
        }, 500)
    }

    return (
        <div className={styles.container} ref={notificationRef}>
            <Window
                ref={periodModalRef}
                status={status === 'period'}
                setStatus={setStatus}
                dismissible={true}
                esc={true}
                selector={styles.modal}
                content={<PeriodModalContent
                    data={data}
                    periods={periods}
                    onSubmit={handleSubmitPeriodWindow}
                    onCancel={handleCancelPeriodWindow}
                />}
            />
            <Window
                ref={resendModalRef}
                status={status === 'resend'}
                setStatus={setStatus}
                dismissible={true}
                esc={true}
                selector={styles.resend}
                content={<ResendModalContent
                    data={data}
                    onSubmit={handleSubmitResendWindow}
                    onCancel={handleCancelResendWindow}
                />}
            />
            {data.attributes.is_confirm === 'reset' ? <Confirm
                type={'danger'}
                message={`Вернуть к базовым настройкам?`}
                onCancelText={'Отмена'}
                onSubmitText={'Вернуть'}
                direction={'row'}
                onCancel={handleConfirm}
                onSubmit={handleReset}
            /> : null}
            {data.attributes.is_confirm === 'remove' ? <Confirm
                type={'danger'}
                message={`Удалить уведомление?`}
                onCancelText={'Отмена'}
                onSubmitText={'Удалить'}
                direction={'row'}
                onCancel={handleConfirm}
                onSubmit={handleReset}
            /> : null}
            {data.attributes.is_edit ? <Edit
                data={data}
                variables={variables}
                onSaveNotification={handleSaveNotification}
                onSaveTemplate={handleSaveTemplate}
                onRemoveTemplate={handleRemoveTemplate}
                onGetTemplates={handleGetUserTemplates}
                onCancel={handleCancelForm}
                onTest={handleTestMessage}
            /> : <>
                <div className={styles.name}>
                    {data.attributes.name}
                </div>
                <div className={styles.type}>
                    {data.info.ticket_types}
                </div>
                <div className={styles.statistic}>
                    {data.info.planned_at ? <p>
                        {data.info.planned_at}
                    </p> : null}
                    {checkGenerateStatistic() ? <p>
                        {generateStatisticText()}
                    </p> : null}
                </div>
                <div className={styles.actions}>
                    <button
                        className={'a-btn a-btn_size_small a-btn_quare a-btn_type_secondary-ghost'}
                        disabled={data.attributes.is_disabled || !data.actions.edit}
                        onClick={handleEdit}>
                        <EditIcon/>
                    </button>
                    {data.actions.notify ? <button
                        className={'a-btn a-btn_size_small a-btn_quare a-btn_type_secondary-ghost'}
                        disabled={data.attributes.is_disabled}
                        onClick={() => handleCallWindow('period')}>
                        <NotificationIcon/>
                    </button> : null}
                    <Select
                        data={generateAvailableActions()}
                        parentRef={ref}
                        disabled={!Object.keys(generateAvailableActions()).length || data.attributes.is_disabled}
                        onChange={handleSelectAction}
                    >
                        <div
                            className={'a-btn a-btn_size_small a-btn_quare a-btn_type_secondary-ghost'}>
                            <DotsIcon/>
                        </div>
                    </Select>
                </div>
                <div className={styles.switchers}>
                    <Switcher
                        id={`notification-switcher__${data.hash}`}
                        size={'small'}
                        parent={document.querySelector('main')}
                        value={null}
                        checked={data.attributes.is_active}
                        disabled={data.attributes.is_disabled || !data.actions.disable}
                        onChange={handleActive}
                        error={null}
                    />
                </div>
            </>}
        </div>
    );
};

export default Notification;
