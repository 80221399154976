import React from 'react';
import {Circle, Text, Layer, Rect, Stage, Tag, Image} from "react-konva";
import {STAGESTEP} from "@/views/Admin/Floors/Editor/utils/constants";
import {calcFigureHeightWidth, sizeText} from "@/views/Admin/Floors/Editor/utils/common";

const PlaceScene = ({param}) => {
    const shapeRef = React.useRef();
    let height = calcFigureHeightWidth(param.map_height);
    let width = calcFigureHeightWidth(param.map_width);
    let textStr = 'Сцена';

    const sizeTextZone = sizeText(textStr, width)

    return <>
        <Rect
            {...param}
            x={param.coords.start.column * STAGESTEP + 4}
            y={param.coords.start.row * STAGESTEP + 4}
            height={height - 8}
            width={width - 8}
            stroke={param.selected ? '#0067F2' : ''}
            fill="#DDD"
            ref={shapeRef}
            name="scene"
        />
        <Text
            {...param}
            name="sceneText"
            fontFamily={"Inter, sans-serif"}
            fontStyle={'bold'}
            x={param.coords.start.column * STAGESTEP + 4}
            y={param.coords.start.row * STAGESTEP + 4}
            height={height - 8}
            width={width - 8}
            verticalAlign={'middle'}
            align={'center'}
            // rotation={width < 50 && width != height ? 270 : 0}
            fontSize={sizeTextZone}
            text={'Сцена'}
            stroke='#000'
            fill='#000'
            strokeWidth={0}
        />
    </>
};

export default PlaceScene;
