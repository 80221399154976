import React, {useEffect, useRef, useState} from 'react';
import SessionSelect from "./sessionFilter/SessionSelect";
import {isoMergeDateAndTime, isoSplitDateAndTime} from "../utils/toIsoString";
import {postData} from "../utils/sendData";
import {getFormattedDate} from "../utils/getFormattedDate";

import WarningIcon from "../assets/images/modals/warning.svg";
import Window from "../UIKit/component/window/window";


const StatisticMember = (orderTicket) => {
    const [edit, setEdit] = useState(false)
    const [canSave, setCanSave] = useState(true)
    const [modalSubmit, setModalSubmit] = useState(false)
    const elData = document.getElementById(`memberTicket-${orderTicket}`)

    const eventId = useRef(elData.getAttribute('data-event-id')).current
    const orderId = useRef(elData.getAttribute('data-order-id')).current
    const ticketId = useRef(elData.getAttribute('data-order-ticket-id')).current
    const [sessionId, setSessionId] = useState(useRef(elData.getAttribute('data-session-id')).current)
    const [sessionDateTime, setSessionDateTime] = useState(useRef(elData.getAttribute('data-session-datetime')).current)
    const [sessionText, setSessionText] = useState(useRef(elData.getAttribute('data-session-text')).current)
    const timezone = useRef(elData.getAttribute('data-timezone')).current
    const timezoneAbbr = useRef(elData.getAttribute('data-timezone-abbr')).current

    const [date, time, from] = isoSplitDateAndTime(sessionDateTime, timezone)
    const [data, setData] = useState({
        id: sessionId,
        date: date,
        time: time,
        from: from,
        tz: timezone,
        send_notifications_on_change: false
    })
    const [sessionName, setSessionName] = useState(useRef(elData.getAttribute('data-session-name')).current)

    const handleSessionChanged = (id, date, time, name) => {
        // TODO: тут бы ещё проверить что говорит API
        setCanSave((id && date && time) || (!id && !date && !time));

        if (id && date && time) {
            setData({id: id, date: date, time: time, tz: timezone})
            setSessionName(name)
        }
    }

    const sessionSave = () => {
        setModalSubmit(true)
    }

    const handleSubmit = (notifications) => {
        let fromData = structuredClone(data)
        fromData.send_notifications_on_change = notifications
        let url = `/admin/statistic/${eventId}/order/${orderId}/ticket/${ticketId}/schedule/`;
        postData(fromData, url, 'POST')
            .then((response) => {
                if (response.data.changed) {

                    let datetime = isoMergeDateAndTime(data.date, data.time, timezone)
                    let textValue = sessionName
                        + ' ' + getFormattedDate(datetime, 'dd.MM.yyyy, HH:mm', timezone)
                        + ' (' + timezoneAbbr + ')'

                    setSessionId(data.id)
                    setSessionDateTime(datetime)
                    setSessionText(textValue)
                    setEdit(false)
                    setModalSubmit(false)
                }
            })
            .catch(({response}) => {
                console.log(response)
            })
    }

    const handEdit = async (save, value) => {
        if (save) {
            alert(value);
        }
        setEdit(!edit)
    }

    return (sessionId && <div className="order-member__field order-member__field_editable order-member__field--schedule"
                              data-id="164" data-event-id="32">
        <div style={{display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center'}}>
            {!edit && <div className="order-member__field--title">Расписание</div>}

            {!edit && <div className="order-member__field--main">
                <span className="order-member__field--content">
                    {sessionText}
                </span>
            </div>}

            {edit && <div className="order-member__field--editor">
                <div className="member-editor">
                    <div className="member-editor__content">
                        <div className="member-editor__box">
                            <div>
                                <SessionSelect
                                    apiUrl={`/admin/statistic/${eventId}/order/${orderId}/ticket/${ticketId}/schedule`}
                                    sessionId={sessionId}
                                    sessionDate={date}
                                    sessionTime={time}
                                    sessionFrom={from}
                                    timezone={timezone}
                                    timezoneAbbr={timezoneAbbr}
                                    handleUpdated={handleSessionChanged}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="member-editor__buttons">
                        <button onClick={() => handEdit(false)}
                                className="e-btn e-btn_type_tertiary-ghost e-btn_size_small"
                                data-action="fieldDecline">Отмена
                        </button>
                        <button onClick={sessionSave}
                                disabled={!canSave}
                                className="e-btn e-btn_type_primary-default e-btn_size_small"
                                data-action="fieldAccept">Сохранить
                        </button>
                    </div>
                </div>
            </div>}
        </div>

        {!edit && <div className="order-member__action cp-action cp-action_size_small">
            <span className="cp-tooltip">Редактировать</span>
            <button onClick={() => handEdit(false)} className="cp-action__btn cp-scheme_secondary-ghost_turn">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M11 1C11.5288 1 11.9869 1.20949 12.3544 1.57991L14.4217 3.64718C14.7924 4.01786 15 4.47274 15 5C15 5.52385 14.794 6.06728 14.4217 6.43959L6.89021 13.9675C6.36661 14.5715 5.62438 14.9426 4.7564 15.0016H1V14.0016L1.00325 11.1647C1.06698 10.3758 1.43373 9.64229 1.98196 9.15991L9.56093 1.58101C9.93285 1.20718 10.4754 1 11 1ZM3.35157 10.6161C3.14601 10.7979 3.01885 11.0522 3 11.2452V13.0025L4.68578 13.004C4.95369 12.9851 5.20307 12.8604 5.42749 12.6054L9.80933 8.22354L7.77751 6.19172L3.35157 10.6161ZM9.19197 4.77776L11.2235 6.80933L12.9895 5.04339L10.9582 3.01212L9.19197 4.77776Z"
                          fill="currentColor"></path>
                </svg>
            </button>
        </div>}

        {modalSubmit && <Window
            status={modalSubmit}
            setStatus={setModalSubmit}
            content={{
                icon: <WarningIcon/>,
                type: 'warning',
                title: 'Отправка уведомлений',
                message: 'Отправить участникам уведомление об изменении времени проведения события ?',
                cancelText: 'не отправлять',
                submitText: 'да, отправлять',
                cancelScheme: 'primary-outline',
                submitScheme: 'primary-default',
                handleCancel: () => handleSubmit(false),
                handleSubmit: () => handleSubmit(true),
            }}
            dismissible={true}
            esc={true}
            selector={'member_modal'}
        />}

    </div>);
};

export default StatisticMember;
