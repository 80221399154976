import {
    conf,
    isSegments,
    screenParam,
    worldContainer
} from "./Const";
import {detectCollision} from "./DetectCollision";
import {Floor} from "../App";
import {getSelectionBounds} from "./DrawSelect";

export const getPlaceFromSelect = () => {
    // поиск мест в области выделения

    let collision = detectCollision()
    Floor.handlers.pixiMouseUp(collision.places, collision.arrId, Floor.groupList)
    if (!collision.places.length) {
        // пусто, создаем пустые клетки
        createEmptyContainer()
    }
}

const createEmptyContainer = () => {
    // создание пустык контейнеров
    let selectionBounds = getSelectionBounds()
    Floor.handlers.createCoordsRegion(null, selectionBounds, Floor.map)

}

export const setStatusSelected = ({place, flag}) => {
    // установить флаг selected для мест

}

export const onDisplay = ({x, y, w, h, type, screen}) => {
    // проверка попадания фигуры в область видимости
    if (isSegments) {
        // если установлен флаг сегментации, то проверка не выполняется
        return true
    }

    if (type !== 'place') {
        return true
        // TODO добавить проверку по координатам для фигур которые занимают больше одной клетки
        // как вариант вычислять центр фигуры и проверять его на вхождение в экран,
        // но если фигура большая то можем словить момент что фигура не отрисуется когда попадёт в область видимости
        // if (screen.x0 < x && screen.y0 < y && screen.y1 > ((y + h) / 2) && screen.x1 > ((x + w) / 2)) {
        //     return true
        // } else {
        //     return false
        // }
    }

    // по координатам
    if (screen.x0 < x && screen.y0 < y && screen.y1 > (y + h) && screen.x1 > (x + w)) {
        return true
    } else {
        return false
    }
}

export const setScreenParam = () => {
    screenParam.scaledWidth = Floor.app.screen.width / worldContainer.scale.x;
    screenParam.scaledHeight = Floor.app.screen.height / worldContainer.scale.y;
}

export const areaOfVisibility = () => {
    const factor = 2;
    // Координаты верхнего левого угла экрана
    const screenLeft = -worldContainer.x / worldContainer.scale.x;
    const screenTop = -worldContainer.y / worldContainer.scale.y;

    // Координаты верхнего правого угла экрана
    const screenRight = screenLeft + screenParam.scaledWidth;
    const screenBottom = screenTop + screenParam.scaledHeight;

    return {
        x0: screenLeft - screenParam.scaledWidth / factor,
        x1: screenRight + screenParam.scaledWidth / factor,
        y0: screenTop - screenParam.scaledHeight / factor,
        y1: screenBottom + screenParam.scaledHeight / factor
    }
}

export const setRole = (config) => {
    switch (config.role) {
        case 'user':
            conf.isUser = true
            break
        case 'designer':
            conf.isDesigner = true
            break
        case 'manager':
            conf.isManager = true
            break
        default :
            console.log(config.role)
            break
    }
}

export const isDisabledPlace = (item) => {
    // доуспность места для выбора

    if (conf.isDesigner || conf.isManager) {
        return true
    }

    if (conf.isUser && item.status === 0) {
        if (item.type !== 'scene') {
            return true
        }
    }

    return false
}
