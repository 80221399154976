import React, {useRef, useState} from 'react';
import Window from "@/UIKit/component/window/window";

const PdfViewer = (props) => {
    const pdfReader = useRef()
    return (
        <div>
            <Window
                status={props.url}
                size={'large'}
                setStatus={props.close}
                content={<div>
                    <iframe
                        style={{width: '100%', height: 650, overflow: 'visible'}}
                        onLoad={() => {
                        }}
                        src={props.url !== 'html' ? props.url : null}
                        srcDoc={props.html}
                        width="100%"
                        height={650}
                        scrolling="no"
                        frameBorder="0"
                    />
                    <div style={{display: "flex", justifyContent: "end", padding: "4px 14px"}}>
                        <button
                            className={`a-btn a-btn_size_small a-btn_type_primary-default`}
                            onClick={() => props.close()}>
                            Закрыть
                        </button>
                    </div>
                </div>}
                ref={pdfReader}
                dismissible={true}
                cross={false}
                esc={true}
                afterClose={() => (null)}
                selector={'nh-window__pdfReader'}
            />
        </div>
    );
};

export default PdfViewer;
