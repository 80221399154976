import {Text, TextStyle} from "pixi.js";
import { hexToRgb, sizeText} from "../../utils/common";

interface TextPixiProps {
    value: string;
    x: number;
    y: number;
    color?: string;
    h?: number;
    w?: number;
}

export const TextPixi = ({value, x, y, h, w, color}: TextPixiProps): Text => {
    const style = new TextStyle({
        fontFamily: 'Inter, sans-serif',
        fontSize: (w && value) ? sizeText(value, w): 14,
        // fill: color ? hexToRgb(color) : 'white', TODO реализовать выбор цвета в функции полчении цвета места
        fill: color,
        fontWeight: 'bold',
        align: "center"
    });

    const text = new Text({text: value, style: style});
    text.anchor.set(0.5);
    text.position.set(w/2, h/2);

    text.resolution = 2;
    return text;
};
