import {Container, Text, TextStyle} from "pixi.js";
import {STAGESTEP} from "../../utils/constants";
import CustomContainer from "../components/CustomContainer";
import {graphicsTooltip} from "../utils/Const";

interface TooltipProps {
    place: any;
    zIndex: number;
}

const style = new TextStyle({
    fontFamily: 'Inter, sans-serif',
    fontSize: 12,
    fill: 'white',
    fontWeight: 'bold',
});


export const Tooltip = ({place, zIndex}: TooltipProps): CustomContainer => {

    let row, column, x, y, title = null;
    graphicsTooltip.clear()

    if (place.type === 'place') {
        row = place.custom.row, column = place.custom.column, x = place.x - 50, y = place.y + 30
        title = `ряд ${row ? row : '-'} место ${column ? column : '-'}`
    } else {
        x = place.coords.start.column * STAGESTEP + 4;
        y = place.coords.start.row * STAGESTEP + 4;
        title = place.type === 'scene' ? 'Сцена' : `${place.custom.name}`
    }
    const container = new CustomContainer({label: "tooltip"});

    const rect = graphicsTooltip.roundRect(x, y, 110, 26, 10).fill("#252424");
    const text = new Text({text: title, style: style, interactive: true, x: x + 5, y: y + 5});
    text.resolution = 2;

    // центр контейнера
    const centerX = x + rect.width / 2;
    const centerY = y + rect.height / 2;

    text.x = centerX - text.width / 2;
    text.y = centerY - text.height / 2;

    container.visible = false;
    container.interactive = true
    rect.interactive = true
    container.zIndex = zIndex
    container.typeFigure = 'tooltip'
    container.addChild(rect, text);

    return container;
};


