import {STAGESTEP} from "@/views/Admin/Floors/Editor/utils/constants";
import {Graphics} from "pixi.js";

export const hexToRgb = (c) => {
    // определение цвета шрифта в зависимости от фона
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(c);
    if (result) {
        let r = parseInt(result[1], 16), g = parseInt(result[2], 16), b = parseInt(result[3], 16);
        if (1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255 < 0.5) {
            return '#000'
        } else {
            return '#fff'
        }
    } else {
        return '#000'
    }
    return '#000'
}

export const getGroupColor = (id, groupList) => {
    // поиск цвета группы
    for (let i = 0; i < groupList.length; i++) {
        if (groupList[i].id === id) {
            return groupList[i].color
            break
        }
    }
}

export const calcFigureHeightWidth = (size) => {
    //  расчет высоты фигуры по ряду
    let val;
    if (size == 0) {
        val = STAGESTEP;
        return val
    }
    val = size * STAGESTEP;
    return val - 8
}

export const sizeText = (str, width) => {
    // размер шрифта
    let size = 12;
    let factor = 10;
    let widthText = str.length * factor
    if (width < widthText) {
        factor = widthText / width
        size = size / factor
    }
    return size
}


export const testRender = async () => {
    console.log('initApp')
    await app.init({width: stageWidth, height: stageHeight});

    containerRef.current.appendChild(app.canvas);

    let arr = []
    let gap = 4;

    for (let i = 0; i < 100; i++) {
        for (let k = 0; k < 100; k++) {
            let x = (24 + gap) * i
            let y = (24 + gap) * k
            let obj = new Graphics()
                .rect(x, y, 24, 24)
                .fill(randomColor());
            obj['id'] = `id_${i}_${k}`;
            obj['row'] = k;
            obj['column'] = i;
            obj.interactive = true;
            obj.on('click', () => {
                obj.stroke({width: 4, color: '#0067F2'})
            });
            arr.push(obj)
        }
    }
    app.stage.addChild(...arr);

    centering(arr)
    handlerWheel()
    handleMouseDrag()
}

export const randomColor = () => {
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);
    const color = "#" + red.toString(16).padStart(2, '0') + green.toString(16).padStart(2, '0') + blue.toString(16).padStart(2, '0');
    return color;
}

export const calculatePosition = (item) => {
    let x = item.coords.start.column * STAGESTEP + 4;
    let y = item.coords.start.row * STAGESTEP + 4;
    return {x: x, y: y}
}

export const calculateSize = (item) => {
    let h, w;
    if (item.map_height == 0) {
        h = STAGESTEP;
    } else {
        h = item.map_height * STAGESTEP - 8;
    }

    if (item.map_width == 0) {
        w = STAGESTEP;
    } else {
        w = item.map_width * STAGESTEP - 8;
    }

    return {h: h, w: w}
}

export const calculatePositionAndSize = (item) => {
    if (item.type === 'place') {
        return {x: item.x, y: item.y, h: 24, w: 24};
    }

    let x = item.coords.start.column * STAGESTEP + 4;
    let y = item.coords.start.row * STAGESTEP + 4;

    let h, w;
    if (item.map_height == 0) {
        h = STAGESTEP;
    } else {
        h = item.map_height * STAGESTEP;
    }

    if (item.map_width == 0) {
        w = STAGESTEP;
    } else {
        w = item.map_width * STAGESTEP;
    }

    return {x: x, y: y, h: h - 10, w: w - 10};
}

export const getValueText = (item) => {
    if (item.type === 'place') {
        return item.custom.column
    }
    if (item.type === 'freeZone' || item.type === 'table') {
        return item.custom.name
    }
    if (item.type === 'scene') {
        return 'Сцена'
    }
    return 'error'
}
