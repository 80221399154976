import React, {useEffect, useRef, useState} from 'react';
import App from "./App";
import {Application, Assets, Sprite, Texture} from "pixi.js";
import Preloader from "../../../../../widget/components/preloader";
import {
    graphicsDrawSelect,
    graphicsExtremum, graphicsHover,
    graphicsPlace,
    graphicsSelect,
    graphicsSquare,
    graphicsTooltip,
    sprites,
    worldContainer
} from "./utils/Const";
import {setRole} from "./utils/Common";

var pixiApp = null;

const Index: React.FC<{ data: any, handlers: any, config: any }> = ({data, handlers, config}) => {
    const containerRef: React.MutableRefObject<any> = useRef()
    const [init, setInit] = useState(false)

    useEffect(() => {
        pixiApp = new Application()
        initApp()
        setRole(config)
        return () => {
            pixiApp = null
            graphicsPlace.clear()
            graphicsExtremum.clear()
            graphicsSquare.clear()
            graphicsTooltip.clear()
            graphicsSelect.clear()
            graphicsDrawSelect.clear()
            graphicsHover.clear()
        };
    }, []);

    const initApp = async () => {
        await pixiApp.init({
            background: '#F2F8FF',
            sharedTicker: false,
            antialias: true,
            resizeTo: window,
        });

        containerRef.current.appendChild(pixiApp.canvas);
        pixiApp.stage.label = 'stage'
        pixiApp.stage.hitArea = pixiApp.screen;
        pixiApp.stage.eventMode = 'static';
        worldContainer.interactive = true;
        globalThis.__PIXI_APP__ = pixiApp;

        const alert = await Assets.load('/assets/images/floors/sprite/alert.png');
        const booked = await Assets.load('/assets/images/floors/sprite/booked.png');
        const error = await Assets.load('/assets/images/floors/sprite/error.png');
        const lock = await Assets.load('/assets/images/floors/sprite/lock.png');
        sprites.alert = new Sprite(alert);
        sprites.booked = new Sprite(booked);
        sprites.error = new Sprite(error);
        sprites.lock = new Sprite(lock);

        setRole(config)
        setInit(true)
    }

    return (
        <div>
            {init ? <App data={data} handlers={handlers} app={pixiApp} config={config}/> : <Preloader/>}
            <div className={'pixiApp'} ref={containerRef}></div>
        </div>
    );
};

export default Index;
