import React, {useEffect, useRef, useState} from 'react';
import styles from './Edit.module.scss'
import Input from "../../../../../../components/ui/Input";
import TextIcon from '../../../../../../assets/images/icons/16/text.svg'
import SaveIcon from '../../../../../../assets/images/icons/16/save.svg'
import DownloadIcon from '../../../../../../assets/images/icons/16/download.svg'
import UploadIcon from '../../../../../../assets/images/icons/16/upload.svg'
import MailIcon from '../../../../../../assets/images/icons/16/mail.svg'
import Editor from "../../../../../../UIKit/component/editor/Editor";
import ReactDOM from "react-dom";
import BracketIcon from '../../../../../../assets/images/icons/quill/bracket.svg'
import scrollToElement from "../../../../../../utils/scrollToElement";
import editDataJson from './Edit.data.json'
import TestModalContent from "../Modal/Test/TestModalContent";
import Window from "../../../../../../UIKit/component/window/window";
import Select from "../../../../../../UIKit/component/select/Select";
import Preloader from "../../../../../../widget/components/preloader";
import {useRouteMatch} from "react-router-dom";
import TrashIcon from "../../../../../../assets/images/icons/16/trash.svg";
import {handleErrors} from "../Notification.methods";
import {postData} from "@/utils/sendData";
import {API} from "@/api/API";
import Textarea from "@/components/ui/Textarea";
import {useSelector} from "react-redux";

const Edit = (
    {
        data,
        variables,
        onSaveNotification,
        onSaveTemplate,
        onCancel,
        onTest,
        onGetTemplates,
        onRemoveTemplate
    }) => {
    const [form, setForm] = useState({
        ...editDataJson, fields: {
            ...editDataJson.fields,
            subject: {...editDataJson.fields.subject, value: data.attributes.subject || null},
            message: {...editDataJson.fields.message, value: data.attributes.message || null},
            name: {...editDataJson.fields.name, value: data.attributes.name || null},
            layout_id: data.attributes.layout_id
        }
    })
    const router = useRouteMatch()
    const [preloader, setPreloader] = useState(false)
    const [status, setStatus] = useState(false)
    const testModalRef = useRef(null)
    const quillRef = useRef(null)
    const editorRef = useRef(null)
    const firstFieldRef = useRef(null)
    const {event_id, type} = router.params
    const event = useSelector((state) => state.admin.event);
    const modules = {
        toolbar: [
            ['bold', 'italic', 'link', 'underline', 'image'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            ['micromarking']
        ]
    }

    useEffect(() => {
        scrollToElement(editorRef, {position: 'top', offset: -70})
        renderQuillCustomModule()
        handleGetUserTemplates()
    }, []);

    const handleSelectTemplate = (value) => {
        value = Number(value)
        setForm(prev => {
            let template = prev.fields.template, field = template.variants.find(x => x.id === value)
            return {
                ...prev,
                fields: {
                    ...prev.fields,
                    template: {...template, value: value},
                    name: {...prev.fields.name, value: field.name},
                    subject: {...prev.fields.subject, value: field.subject},
                    message: {...prev.fields.message, value: field.message},
                },
            }
        })
        scrollToElement(editorRef, {position: 'top', offset: -70})
    }

    const handleDeleteTemplate = async (event, params) => {
        event.stopPropagation()
        setForm(prev => ({
            ...prev,
            fields: {
                ...prev.fields,
                template: {
                    ...prev.fields.template,
                    variants: prev.fields.template.variants.filter(x => x.id !== params),
                    is_disabled: !Boolean(prev.fields.template.variants.filter(x => x.id !== params).length)
                }
            }
        }))
        await onRemoveTemplate(params)
    }

    const handleGetUserTemplates = async () => {
        let response

        response = await onGetTemplates()
        response.length ? setForm(prev => ({
            ...prev,
            fields: {...prev.fields, template: {...prev.fields.template, variants: response, is_disabled: false}},
        })) : null
    }

    const handleSaveNotification = async (event) => {
        let response
        if (event) event.preventDefault()
        let layout_id = form.fields.layout_id ? form.fields.layout_id : data.attributes.layout_id
        response = await onSaveNotification({
            ...data, attributes: {
                ...data.attributes,
                layout_id: layout_id,
                name: form.fields.name.value,
                subject: form.fields.subject.value,
                message: form.fields.message.value,
                template_id: form.fields.template.value,
            }
        }, event)
        if (response?.status === 422) {
            handleValidateForm(response.data.errors)
            handleErrors(response.data.errors)
            return
        }

        if (response.id && event) {
            showTopMessage('success', 'small', 'Операция выполнена успешно')
        }
    }

    const handleSubmitTest = async (form) => {
        let isSave = await handleSaveNotification(false)
        let response
        try {
            response = await onTest(form)
            testModalRef.current.handleClose()
            return response
        } catch (e) {
            return e.response
        }
    }

    const handleField = (field, value, layout_id) => {
        setForm(prev => ({
            ...prev, fields: {
                ...prev.fields,
                [field]: {...prev.fields[field], value: value, error: null},
                template: {...prev.fields.template, value: null, error: null},
                layout_id: layout_id
            }
        }))
    }

    const handleChangeBracket = (value) => {
        let editor, position, selected

        selected = variables.find((x) => x.var === value);
        editor = quillRef.current?.editor;
        if (!editor) return
        position = editor.getSelection();
        if (!position) return

        if (selected.type === "link") {
            value = `<a href="${selected.var}">{${selected.name}}</a>`;
            editor.clipboard.dangerouslyPasteHTML(position.index, value);
            editor.setSelection(position.index + value.replace(/<[^>]+>/g, '').length);
        } else {
            value = `{${selected.name}}`;
            editor.clipboard.dangerouslyPasteHTML(position.index, value);
            editor.setSelection(position.index + value.length);
        }

        editor = quillRef.current?.editor;
        handleField('message', editor.root.innerHTML, null)
    };

    const handleOpenModal = () => {
        setStatus(true)
    }

    const handleCancelTest = (e) => {
        e.preventDefault()
        testModalRef.current.handleClose()
    }

    const handleSaveTemplate = async () => {
        let response

        setForm(prev => ({...prev, is_disabled: true}))
        response = await onSaveTemplate({
            message: form.fields.message.value,
            name: form.fields.name.value,
            subject: form.fields.subject.value,
            type: 'notification-templates'
        })
        setForm(prev => ({...prev, is_disabled: false}))

        if (response.status && response.status === 422) {
            handleValidateForm(response.data.errors)
            handleErrors(response.data.errors)
            return
        }

        if (!response.id) {
            showTopMessage('error', 'small', 'Произошла ошибка. Повторите попытку позднее')
            return
        }

        await handleGetUserTemplates()
        showTopMessage('success', 'small', 'Операция выполнена успешно')
    }

    const handleValidateForm = (errors) => {
        let data = {}

        const pointer = (x) => x.source.pointer.replace('/data/attributes/', '')
        errors.map(x => data[pointer(x)] = {...form.fields[pointer(x)], error: x.detail})

        setForm(prev => ({...prev, fields: {...prev.fields, ...data}}))
    }

    const generateTemplatesList = () => {
        return form.fields.template.variants.reduce((acc, cur) => ({
            ...acc, [cur.id]: <div className={styles.item}>
                <span>{cur.name}</span>
                <div
                    className={'a-btn a-btn_size_small a-btn_quare a-btn_type_tertiary-ghost'}
                    onClick={(event) => handleDeleteTemplate(event, cur.id)}>
                    <TrashIcon/>
                </div>
            </div>
        }), {})
    }

    const generateVariablesList = () => {
        return variables.reduce((acc, cur) => ({...acc, [cur.var]: `{${cur.name}}`}), {})
    }

    const renderQuillCustomModule = () => {
        let target = document.querySelector('.ql-micromarking')
        target ? ReactDOM.render(<Select
            direction={['bottom', 'right']}
            visibilityItems={7}
            size={'small'}
            data={generateVariablesList()}
            disabled={data.is_disabled}
            onChange={handleChangeBracket}
        >
            <div className={styles.bracket}>
                <BracketIcon/>
            </div>
        </Select>, target) : null;
    }

    const handleDownloadHtml = () => {
        const blob = new Blob([form.fields.message.value], {type: 'text/html'});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        let date = new Date();
        let year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().length == 1 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
        let day = date.getDate().toString().length == 1 ? '0' + date.getDate() : date.getDate();
        let hours = date.getHours().toString().length == 1 ? '0' + date.getHours() : date.getHours();
        let minutes = date.getMinutes().toString().length == 1 ? '0' + date.getMinutes() : date.getMinutes();

        link.download = `Шаблон ${day}.${month}.${year} ${hours}-${minutes}.html`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }

    const handleUploadHtml = async (event) => {
        let reader = new FileReader();
        reader.readAsText(event.target.files[0], 'UTF-8');
        event.target.value = null;
        reader.onloadend = (e) => {
            let str = e.target.result
            if (str.indexOf(`<body>`) != -1) {
                const parser = new DOMParser();
                const doc = parser.parseFromString(str, 'text/html');
                const bodyContent = doc.body.innerHTML;
                str = bodyContent
            }
            handleField('message', str, 100)
        }
    }
    const fileInputRef = useRef(null);

    return (
        <>
            <Window
                ref={testModalRef}
                status={status}
                setStatus={setStatus}
                dismissible={true}
                esc={true}
                selector={styles.modal}
                content={<TestModalContent
                    data={data}
                    onSubmit={handleSubmitTest}
                    onCancel={handleCancelTest}
                />}
            />
            <form className={styles.container} onSubmit={handleSaveNotification} ref={editorRef}>
                <div className={styles.name} ref={firstFieldRef}>
                    <Input
                        size={'medium'}
                        outside={1}
                        placeholder={'Введите наименование рассылки'}
                        label={'Наименование рассылки'}
                        value={form.fields.name.value}
                        error={form.fields.name.error}
                        disabled={form.fields.name.is_disabled}
                        handleChange={(e) => handleField('name', e.target.value, null)}
                    />
                </div>
                <div className={styles.name}>
                    <Input
                        size={'medium'}
                        outside={1}
                        placeholder={'Введите тему письма'}
                        label={'Тема письма'}
                        value={form.fields.subject.value}
                        error={form.fields.subject.error}
                        disabled={form.fields.subject.is_disabled}
                        handleChange={(e) => handleField('subject', e.target.value, null)}
                    />
                </div>
                <div className={styles.textarea}>
                    {preloader ? <div className={styles.preloader}>
                            <Preloader/>
                        </div> :
                        form.fields.layout_id === 100 ?
                            <Textarea
                                size={'medium'}
                                style={{height: "240px"}}
                                outside={true}
                                label={'Шаблон письма'}
                                required={false}
                                handleChange={(e) => handleField('message', e ? e.target.value : '', 100)}
                                disabled={false}
                                value={form.fields.message.value}
                                error={false}
                            />
                            :
                            <Editor
                                ref={quillRef}
                                value={form.fields.message.value}
                                error={form.fields.message.error}
                                onChange={(value) => handleField('message', value, null)}
                                modules={modules}
                            />
                    }
                </div>
                <div className={styles.footer}>
                    <button
                        style={{display: `${data.actions.test ? 'flex' : 'none'}`}}
                        className={'a-btn a-btn_icon_left a-btn_size_small a-btn_type_secondary-default'}
                        disabled={form.is_disabled}
                        type={'button'}
                        onClick={handleOpenModal}>
                        <MailIcon/>
                        Тестовая рассылка
                    </button>
                    {event.is_ads_disabled && <button
                        className={'a-btn a-btn_icon_left a-btn_size_small a-btn_type_secondary-default'}
                        disabled={form.is_disabled}
                        onClick={handleDownloadHtml}
                        type={'button'}>
                        <DownloadIcon/>
                        Скачать HTML
                    </button>}
                    <div
                        className={'a-btn a-btn_icon_left a-btn_size_small a-btn_type_secondary-default'}>
                        <TextIcon/>
                        Шаблоны
                    </div>
                    {event.is_ads_disabled &&
                        <label className={'a-btn a-btn_icon_left a-btn_size_small a-btn_type_secondary-default'}>
                            <input
                                type={'file'}
                                accept="text/html"
                                ref={fileInputRef}
                                disabled={form.is_disabled}
                                onChange={(e) => handleUploadHtml(e)}
                            />
                            <UploadIcon/>
                            загрузить HTML
                        </label>}
                    <Select
                        parentRef={editorRef}
                        visibleItems={5}
                        size={'small'}
                        data={generateTemplatesList()}
                        disabled={form.fields.template.is_disabled || form.is_disabled}
                        onChange={handleSelectTemplate}
                    >
                        <button
                            className={'a-btn a-btn_icon_left a-btn_size_small a-btn_type_secondary-default'}
                            disabled={form.is_disabled}
                            onClick={handleSaveTemplate}
                            type={'button'}>
                            <SaveIcon/>
                            Сохранить как шаблон
                        </button>
                    </Select>
                    <button
                        className={'a-btn a-btn_size_small a-btn_type_tertiary-ghost'}
                        disabled={form.is_disabled}
                        type={'reset'}
                        onClick={onCancel}>
                        Отмена
                    </button>
                    <button
                        className={'a-btn a-btn_size_small a-btn_type_primary-default'}
                        disabled={form.is_disabled}
                        type={'submit'}
                        onClick={handleSaveNotification}>
                        Сохранить
                    </button>
                </div>
            </form>
        </>
    );
};

export default Edit;
