import {stageMP, worldContainer} from "./Const";
import CustomContainer from "../components/CustomContainer";
import {getSelectionBounds} from "./DrawSelect";

export const detectCollision = () => {
    // Поиск контейнеров которые вошли в область выделения
    const collisions = {places: [], arrId: []};
    // границы области выделения
    const selectionBounds = getSelectionBounds()
    // Перебираем все контейнеры
    for (const Container of worldContainer.children as CustomContainer[]) {
        if (Container.place) {
            // Границы контейнера
            const containerBounds = {
                x1: Container.x,
                y1: Container.y,
                width: Container.hitArea['width'],
                height: Container.hitArea['height']
            };
            // Проверяем пересечение границ контейнера и области выделения
            if (isIntersecting(selectionBounds, containerBounds)) {
                collisions.places.push(Container.place);
                collisions.arrId.push(Container.place.id);
            }
        }
    }
    return collisions
}

const isIntersecting = (r1, r2) => {
    // Определение пересечения двух объектов

    return (
        !(r2.x1 > r1.x1 + r1.width ||
            r2.x1 + r2.width < r1.x1 ||
            r2.y1 > r1.y1 + r1.height ||
            r2.y1 + r2.height < r1.y1)
    );


    // return (
    //     rect1.x1 < rect2.x2 &&
    //     rect1.x2 > rect2.x1 &&
    //     rect1.y1 < rect2.y2 &&
    //     rect1.y2 > rect2.y1
    // );

}
