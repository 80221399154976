import React, {useEffect, useRef, useState} from "react";
import CloseIcon from '../../../assets/images/icons/12/close.svg'
import '../../scss/components/toggletip/toggletip.scss'
import detectElementOverflow from "detect-element-overflow";
import scrollToElement from "../../../utils/scrollToElement";

function Toggletip({content, children, theme, parent}) {
    const componentClass = 'nh-toggletip'
    const [isVisible, setIsVisible] = useState(false);
    const tooltipRef = useRef(null);
    const tooltipContentRef = useRef(null);
    const tooltipCloseRef = useRef(null);
    const [placement, setPlacement] = useState("top");

    useEffect(() => {
        if (tooltipRef.current && placement && isVisible) {
            try {
                scrollToElement(tooltipContentRef, {position: 'center'})
            } catch (e) {
                console.log(e)
            }
        }
    }, [tooltipRef.current, placement, isVisible])

    useEffect(() => {
        if (isVisible) {
            const handleResize = () => {
                if (tooltipRef.current && parent && tooltipContentRef.current) {

                    const {
                        collidedTop,
                        collidedRight,
                        collidedBottom,
                        collidedLeft
                    } = detectElementOverflow(tooltipContentRef.current, parent);

                    if (collidedTop) {
                        setPlacement("top")
                        return
                    }
                    if (collidedLeft) {
                        setPlacement("left")
                        return
                    }
                    if (collidedBottom) {
                        setPlacement("bottom")
                        return
                    }
                    if (collidedRight) {
                        setPlacement("right")
                        return
                    }
                }
            };

            handleResize();
            window.addEventListener("resize", handleResize);

            return () => {
                window.removeEventListener("resize", handleResize);
            };
        }
    }, [isVisible]);

    const handleOpenToggle = (e) => {
        setIsVisible(true);
    };

    const handleCloseToggle = (e) => {
        const relatedTarget = e.relatedTarget;

        if (relatedTarget && tooltipRef.current && tooltipRef.current.contains(relatedTarget)) {
            if (!tooltipCloseRef.current || !tooltipCloseRef.current.contains(relatedTarget)) {
                return;
            }
        }

        setIsVisible(false);
    };

    return (
        <div
            tabIndex={1}
            className={[componentClass, `${componentClass}__${theme || 'white'}`, `${componentClass}__${placement}`].filter(x => x ?? x).join(' ')}
            ref={tooltipRef}
            onClick={handleOpenToggle}
            onBlur={(e) => handleCloseToggle(e)}
        >
            {children}
            {isVisible && (
                <div className={`${componentClass}__content`} ref={tooltipContentRef}>
                    <p>
                        {content}
                    </p>
                    <button className={`${componentClass}__close`} onClick={handleCloseToggle} ref={tooltipCloseRef}>
                        <CloseIcon/>
                    </button>
                </div>
            )}
        </div>
    );
}

export default Toggletip;
