import React from 'react';
import '../../../scss/components/window/template/standard.scss'

const Standard = (props) => {
    /* Отменить */
    const handleCancel = () => {
        props.handleClose()
        props.content.handleCancel()
    }

    /* Принять */
    const handleSubmit = () => {
        props.handleClose()
        props.content.handleSubmit()
    }

    return (
        <div className={`nh-window__${props.content.type}`}>
            {props.content?.icon ? <div className="nh-window__icon">{props.content.icon}</div> : null}
            <div className="nh-window__content">
                {props.content?.title ? <div className="nh-window__header">
                    <h6>{props.content.title}</h6>
                </div> : null}
                {props.content?.message ? <div className="nh-window__body">
                    <p>{props.content.message}</p>
                </div> : null}
                <div className="nh-window__footer">
                    <div className="nh-window__btn-group">
                        {props.content?.cancelText ? <button
                            className={`a-btn a-btn_icon_left a-btn_size_small a-btn_type_${props.content.cancelScheme}`}
                            onClick={() => handleCancel()}>
                            {String(props.content.cancelText)}
                        </button> : null}
                        {props.content?.submitText ? <button
                            className={`a-btn a-btn_icon_left a-btn_size_small a-btn_type_${props.content.submitScheme}`}
                            onClick={() => handleSubmit()}>
                            {String(props.content.submitText)}
                        </button> : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Standard;
