import React, {useState} from 'react';
import Input from "../../../../components/ui/Input";

const CopyTicket = ({data, onSubmit, onCancel}) => {
    const [preloader, setPreloader] = useState(false)
    const [fields, setFields] = useState(data)
    const [errors, setErrors] = useState({})

    const handleSubmit = async (e) => {
        let response

        e.preventDefault()
        setPreloader(true)

        response = await onSubmit(fields)
        if (response && response.errors) handleValidate(response.errors)

        setPreloader(false)
    }

    const handleCancel = () => {
        onCancel()
    }

    const handleValidate = (data) => {
        setErrors({...errors, ...data})
        return !Object.values(data).length
    }

    const handleEdit = (field, value) => {
        setFields({...fields, [field]: value})
        setErrors({...errors, [field]: null})
    }

    return (
        <form className={'ui-cp__tickets__modal-copy'} onSubmit={handleSubmit}>
            <h6>
                Копирование билета
            </h6>
            <Input
                label={'Новое название билета'}
                disabled={preloader}
                outside={1}
                size={'small'}
                value={fields.name}
                error={errors.name}
                handleChange={(e) => handleEdit('name', e.target.value)}
            />
            <div className={'ui-cp__tickets__modal-btn-group'}>
                <button
                    type={'reset'}
                    className={`a-btn a-btn_size_small a-btn_type_tertiary-ghost`}
                    disabled={preloader}
                    onClick={handleCancel}>
                    Отмена
                </button>
                <button
                    type={'submit'}
                    className={`a-btn a-btn_size_small a-btn_type_primary-default`}
                    disabled={preloader}>
                    Создать копию
                </button>
            </div>
        </form>
    );
};

export default CopyTicket;