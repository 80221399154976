export default function Paginator(props) {
    const paginable = props.paginable;
    if (paginable.meta.last_page < 2) {
        return null;
    }
    let links = [];
    for (let i = 1; i <= paginable.meta.last_page; i++) {
        let el = (
            <a key={i} href={`${paginable.meta.path}?page=${i}`} className="pagination__element pagination__number">
                {i}
            </a>
        );
        if (i === paginable.meta.current_page) {
            el = <p key={i} className="pagination__element pagination__circle pagination__circle_active">{i}</p>;
        }
        links.push(el);
    }

    return (
        <div className="pagination control-page__pagination">
            <div className="pagination__wrapper">
                <a href={paginable.links.prev}
                   className={`pagination__element pagination__circle pagination__circle_arrow_left ${paginable.meta.current_page !== 1 ? 'pagination__circle_arrow_active' : ''}`}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M10.5858 9.00001H2V7.00001H10.5858L7.29289 3.70712L8.70711 2.29291L14.4142 8.00001L8.70711 13.7071L7.29289 12.2929L10.5858 9.00001Z"
                              style={{fill: "currentColor"}}></path>
                    </svg>
                </a>
                <div className="pagination__block">
                    {links}
                </div>
                <a href={paginable.links.next}
                   className={`pagination__element pagination__circle pagination__circle_arrow_right ${paginable.meta.current_page !== paginable.meta.last_page ? 'pagination__circle_arrow_active' : ''}`}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M10.5858 9.00001H2V7.00001H10.5858L7.29289 3.70712L8.70711 2.29291L14.4142 8.00001L8.70711 13.7071L7.29289 12.2929L10.5858 9.00001Z"
                              style={{fill: "currentColor"}}></path>
                    </svg>
                </a>
            </div>
        </div>
    );
}
