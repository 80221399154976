import {screenParam, segments, worldContainer} from "./Const";
import {areaOfVisibility} from "./Common";
import {Floor} from "../App";

export const setVisibleSegments = () => {
    // создает видимые сегменты в массиве
    let screen = areaOfVisibility()

    const segmentWidth = screenParam.scaledWidth / segments.horizontal;
    const segmentHeight = screenParam.scaledHeight / segments.vertical;

    const arrSegments = [];

    // Рассчитываем индексы начального и конечного сегментов в области видимости
    const startX = Math.floor(screen.x0 / segmentWidth);
    const endX = Math.ceil((screen.x1) / segmentWidth) - 1;
    const startY = Math.floor(screen.y0 / segmentHeight);
    const endY = Math.ceil((screen.y1) / segmentHeight) - 1;

    // Добавляем видимые сегменты в массив
    for (let x = startX; x <= endX; x++) {
        for (let y = startY; y <= endY; y++) {
            const keyX = Math.abs(x) === 0 ? 0 : x;
            const keyY = Math.abs(y) === 0 ? 0 : y;
            //segments.push({ x: correctedX, y: correctedY });
            arrSegments.push(`${keyX}-${keyY}`);
        }
    }
    segments.view = arrSegments
}

export const getPlaceSegments = () => {
    // создаем сегментированные места
    segments.place = {}

    const segmentWidth = screenParam.scaledWidth / segments.horizontal;
    const segmentHeight = screenParam.scaledHeight / segments.vertical;

    function getSegment(x, y, sector) {
        const segmentX = Math.floor(x / segmentWidth);
        const segmentY = Math.floor(y / segmentHeight);
        return {x: segmentX, y: segmentY, sector: sector};
    }

    Floor.place.forEach(obj => {
        const segment = getSegment(obj.x, obj.y, obj.sector);
        const segmentKey = `${segment.x}-${segment.y}`;
        if (!segments.place[segmentKey]) {
            segments.place[segmentKey] = [];
        }
        segments.place[segmentKey].push(obj);
    });

    return segments.place
}
