/** Функция принимает массив дат в качестве аргумента и возвращает ближайшую дату к
 * текущей дате, учитывая только даты из массива. Если массив пустой, функция вернет null.
 *
 * arr - массив дат
 * date - дата, ближайшие значения к которой мы ищем
 * **/
export const getNearestDateFromArray = (arr, date) => {
    const today = date;
    let nearestDate = null;
    let nearestDiff = Infinity;
    arr = arr.filter(x => x ? new Date(x) : null)
    arr.forEach((date) => {
        const diff = Math.abs(today - new Date(date));
        if (diff < nearestDiff) {
            nearestDate = date;
            nearestDiff = diff;
        }
    });
    return nearestDate;
}