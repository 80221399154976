import React, {useState, Suspense, lazy, useEffect, useRef} from "react";
import {Redirect, Route, Switch, useHistory, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Preloader from "./components/Preloader";
import Floor from "./views/Admin/Floors/Floor";
import './i18n';
import {useRouteMatch} from "react-router-dom";
import Sessions from "./views/Admin/Events/Statistic/Sessions/Sessions";
import Apps from "./views/Admin/Apps/Apps";

const Courses = lazy(() => import('./views/Academy/Cabinet/Courses'));
const Course = lazy(() => import('./views/Academy/Cabinet/Course'));
const EditLesson = lazy(() => import('./views/Admin/Courses/EditLesson'));
const NewCourse = lazy(() => import('./views/Admin/Courses/NewCourse'));
const CourseList = lazy(() => import('./views/Admin/Courses/CourseList'));
const FloorsList = lazy(() => import('./views/Admin/Floors/FloorsList'));
const FloorsTabList = lazy(() => import('./views/Admin/Events/Floors/FloorsTabList'));
const FloorsManager = lazy(() => import('./views/Admin/Events/Floors/FloorsManager'));
const Form = lazy(() => import('./widget/Form'));
const Chat = lazy(() => import('./components/chat/Chat'));
const SettingsCourse = lazy(() => import('./views/Admin/Courses/SettingsCourse'));
const RepeatEvents = lazy(() => import('./views/Admin/Events/Schedule/RepeatEvents'));
const Homework = lazy(() => import('./views/Academy/Cabinet/Homework'));
const Statistics = lazy(() => import('./views/Academy/Cabinet/Statistics'));
const Certificates = lazy(() => import('./views/Academy/Cabinet/Certificates'));
const Payments = lazy(() => import('./views/Academy/Cabinet/Payments'));
const Subscriptions = lazy(() => import('./views/Academy/Cabinet/Subscriptions'));
const Lessons = lazy(() => import('./views/Academy/Cabinet/Lessons'));
const Partners = lazy(() => import('./views/Admin/Partners/Partners'));
const Modal = lazy(() => import('./components/modal/Modal'));
const Progress = lazy(() => import('./components/ui/Progress'));
const Notice = lazy(() => import('./components/notice/Notice'));
const Notification = lazy(() => import('./components/notification/Notification'));
const Error = lazy(() => import('./components/error/Error'));
const Requisites = lazy(() => import('./views/Admin/Requisites/Requisites'));
const Documents = lazy(() => import('./views/Admin/Services/SendDocs/Documents'));
const Statistic = lazy(() => import('./views/Admin/Events/Statistic/Statistic'));
const NewEvent = lazy(() => import('./views/Admin/Events/NewEvent/NewEvent'));
const MailingForm = lazy(() => import('./views/Promo/MailingForm/Form'));
const EventPartners = lazy(() => import('./views/Admin/Events/Partners/Partners'));
const Application = lazy(() => import('./views/Admin/Apps/App/Application'));
const Api = lazy(() => import('./views/Admin/Apps/App/Api/Api'));

export function App() {
    const [id, setId] = useState(null);
    const lessonId = useSelector(state => state.idLesson)
    const dispatch = useDispatch();

    useEffect(() => {
        window.nh_client_host = document.getElementById('App').getAttribute('data-api-host');
    }, [])

    const createNewCourse = (id) => {
        setId(id);
    };

    return (
        <>
            {/*TODO added <ErrorBoundary>*/}
            <Suspense fallback={<Preloader/>}>
                <Switch>
                    <Route
                        exact
                        path={'/admin/courses/create'}
                        component={(props) => <NewEvent {...props} isEvent={false}/>}
                    />
                    <Route exact path={'/admin/events/create/'} component={(props) => <NewEvent {...props} isEvent={true}/>}/>
                    <Route exact path={'/admin/partners/cashout/'} component={() => <Partners cashout={true}/>}/>

                    <Route exact path={'/admin/promotion/'} component={() => <MailingForm />}/>
                    <Route exact path={'/admin/promotion/context/'} component={() => <MailingForm />}/>
                    <Route exact path={'/admin/promotion/social/'} component={() => <MailingForm />}/>
                    <Route exact path={'/admin/promotion/info_support/'} component={() => <MailingForm />}/>

                    <Route exact path={'/admin/partners/'} component={() => <Partners cashout={false}/>}/>
                    <Route exact path={'/admin/partners/:tab?/:event_id?/:section?'} component={() => <Partners/>}/>
                    <Route exact path={'/admin/events/:id/partners/:tab?'} component={() => <EventPartners />}/>
                    <Route exact path={'/admin/courses/:id/partners/:tab?'} component={() => <SettingsCourse settingsType={'courses'}/>} />

                    <Route exact path={'/admin/courses'} component={() => <CourseList/>}/>
                    <Route exact path={'/admin/floors/:id'} component={(props) => <Floor {...props}/>}/>
                    <Route exact path={'/admin/floors'} component={() => <FloorsList/>}/>
                    <Route
                        exact
                        path={'/admin/courses/:id/lessons/new-lesson'}
                        component={() => <EditLesson dispatch={dispatch} createNewLesson={'new'}/>}
                    />
                    <Route exact path={'/admin/courses/:id/lessons/:lessonId'}
                           component={() => <EditLesson dispatch={dispatch} createNewLesson={null}/>}/>
                    {/* По userType компонент понимает, для кого он вызывается -- для админа или для студента. Важно, чтобы роуты студента начинались со student  */}
                    <Route exact path={'/:userType/courses/:id/tasks/:taskResultId'} component={() => <Chat/>}/>
                    <Route exact path={'/admin/courses/:id/:tab'}
                           component={() => <SettingsCourse settingsType={'courses'}/>}/>

                    <Route exact path={'/cabinet/homework'} component={() => <Homework/>}/>
                    <Route exact path={'/cabinet/courses'} component={() => <Courses/>}/>

                    <Route exact path={'/cabinet/statistics'} component={() => <Statistics/>}/>
                    <Route exact path={'/cabinet/certificates'} component={() => <Certificates/>}/>
                    <Route exact path={'/cabinet/finance/payments'} component={() => <Payments/>}/>
                    <Route exact path={'/cabinet/finance/subscriptions'} component={() => <Subscriptions/>}/>
                    <Route exact path={'/cabinet/courses/:id'} component={(props) => <Course {...props} />}/>
                    <Route exact path={'/cabinet/lessons/:id'} component={(props) => <Lessons {...props} />}/>

                    {/*СОБЫТИЯ*/}
                    {/* TODO включить при переходе событий на react <Route exact path={'/admin/events/:id/form'} component={() => <SettingsCourse tab={"registration"} settingsType={'events'}/>}/>*/}
                    {/*Давайте не будем дублировать функционал компонента курсов в компоненте билета для событий. SettingsCourse и без того не маленький. Пусть для событий будет отдельный компонент, в пользу дальнейшей поддержки кода и сильно отличного от курсов возможного расширения функционала компонента*/}
                    <Route exact path={'/admin/requisites'} component={() => <Requisites/>}/>
                    <Route exact path={'/admin/services/send-docs/'} component={() => <Documents/>}/>
                    <Route exact path={'/admin/apps/'} component={() => <Apps/>}/>
                    <Route exact path={'/admin/apps/getcourse/'} component={() => <Application/>}/>
                    <Route exact path={'/admin/apps/api/'} component={() => <Application/>}/>
                    <Route exact path={'/admin/events/:id/schedule'} component={(props) => <RepeatEvents {...props}/>}/>
                    <Route exact path={'/admin/events/:id/floors'} component={(props) => <FloorsTabList {...props}/>}/>
                    <Route exact path={'/admin/events/:eventId/floors/:id'}
                           component={(props) => <FloorsManager {...props}/>}/>
                    <Route exact path={'/'} component={(props) => <Form {...props}/>}/>
                    <Route exact path={'/form/:id/'} component={(props) => <Form {...props}/>}/>
                    <Route exact path={'/form/:id/:template'} component={(props) => <Form {...props}/>}/>
                    <Route exact path={'/form/order/:orderId/result'} component={(props) => <Form {...props} result={true}/>}/>
                    <Route exact path={'/form/order/:orderID/:template'}
                           component={(props) => <Form {...props} moderation={true}/>}/>
                    <Route exact path={'/form/order/:orderID/:template/:token'}
                           component={(props) => <Form {...props}/>}/>
                    <Route exact path={'/form/:id/tickets/:ticket_id/'} component={(props) => <Form {...props}/>}/>
                    <Route exact path={'/:region/:id/'} component={(props) => <Form {...props}/>}/>
                    <Route exact path={'/all/:id/'} component={(props) => <Form {...props}/>}/>
                    <Route exact path={'/all/:id/:template'} component={(props) => <Form {...props}/>}/>
                    <Route exact path={'/buy_tickets/:id'} component={(props) => <Form {...props}/>}/>
                    <Route exact path={'/buy_tickets/:id/consult'}
                           component={(props) => <Form {...props} consult={true}/>}/>
                    <Route exact path={'/form/:id/consult'} component={(props) => <Form {...props} consult={true}/>}/>
                    <Route exact path={'/buy_tickets/:id/iframe'} component={(props) => <Form {...props}/>}/>
                    <Route exact path={'/:code'} component={(props) => <Form {...props}/>}/>
                    <Route exact path={'/admin/editor/:id'} component={(props) => <Form {...props}/>}/>
                    <Route exact path={'/admin/preview/:id'} component={(props) => <Form {...props}/>}/>
                    <Route exact path={'/admin/statistic/:id/'} component={(props) => <Statistic {...props}/>}/>
                    <Route exact path={'/admin/statistic/:id/sessions'} component={(props) => <Sessions {...props}/>}/>


                    {/* <Route exact path={'/admin/:eventType/:eventId/statistic/charts'} component={(props) => <div>123</div>}/> */}

                    <Redirect from={'/admin/courses/:id/'} to={'/admin/courses/:id/lessons'}/>
                </Switch>
                <Modal/>
                <Progress/>
                <Notice/>
                <Notification/>
                <Error/>
            </Suspense>
        </>

    );
}
