import React from 'react';
import ReactHtmlParser from "react-html-parser";
import Notification from "../../notification/notification";

const Agreement = (props) => {

    /* Компонент инлайн-уведомления в модальном окне */
    const NotificationModalContent = () => {
        return <div>
            <strong>Внимание!</strong> Бесплатная смена основных реквизитов доступна не чаще 1 раза в год
        </div>
    }

    return (
        <div>
            <div className="nh-window__agreement__header">
                <h6>Условия соглашения</h6>
            </div>
            <div className="nh-window__agreement__body">
                {ReactHtmlParser(props.html)}
            </div>
            <Notification type={'warning'} value={NotificationModalContent()}/>
            <div className="nh-window__agreement__content">
                <button
                    className={`a-btn a-btn_size_small a-btn_type_tertiary-ghost`}
                    onClick={() => props.handleClose()}>
                    Отменить
                </button>
                <button
                    className={`a-btn a-btn_size_small a-btn_type_primary-default`}
                    disabled={props.disabled}
                    onClick={() => props.handleSubmit()}>
                    Принять условия
                </button>
            </div>
        </div>
    );
};

export default Agreement;
