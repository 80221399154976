import {
    conf,
    containerCanvas, extremumUser,
    graphicsPlace,
    isSegments,
    pixiMP,
    screenParam,
    worldContainer
} from "./Const";
import Hammer from 'hammerjs'
import {Floor} from "../App";
import {setScreenParam} from "./Common";

// const initSizeContainer = (count, container) => {
//
//     if (count <= 10) {
//         const containerRect = container.getBoundingClientRect(); // Координаты canvas относительно окна браузера
//         containerCanvas.top = containerRect.top
//         containerCanvas.left = containerRect.left
//         containerCanvas.height = containerRect.height
//         containerCanvas.width = containerRect.width
//         if (!containerCanvas.top) {
//             setTimeout(() => initSizeContainer(++count, container), 500)
//         }
//         console.log(containerCanvas)
//     }
// }

const initSizeContainer = (container) => {
    const containerRect = container.getBoundingClientRect(); // Координаты canvas относительно окна браузера
    containerCanvas.top = containerRect.top
    containerCanvas.left = containerRect.left
    containerCanvas.height = containerRect.height
    containerCanvas.width = containerRect.width
}

export const MouseProcessor = () => {
    window['worldContainer'] = worldContainer; // для дебага из консоли
    const container = document.getElementsByClassName('pixiApp')[0]

    initSizeContainer(container)

    const handleMouseMove = (event) => {
        // навигация мышью по канвасу
        if (pixiMP.isMiddleMouseDown) {
            const deltaX = event.clientX - pixiMP.lastMouseX;
            const deltaY = event.clientY - pixiMP.lastMouseY;
            pixiMP.accumulatedDeltaX += deltaX;
            pixiMP.accumulatedDeltaY += deltaY;
            pixiMP.lastMouseX = event.clientX;
            pixiMP.lastMouseY = event.clientY;
        }
    };

    const handleMouseDown = (event) => {

        if (event.button === 1 || (event.button === 0 && (conf.isUser || pixiMP.grab))) {
            // включение режима навигации для юзера
            pixiMP.lastPositionWorld.x = worldContainer.x
            pixiMP.lastPositionWorld.y = worldContainer.y
            handleSetMiddleMouseDown(true);
        }
        pixiMP.startMouseX = event.clientX;
        pixiMP.startMouseY = event.clientY;
        pixiMP.lastMouseX = event.clientX;
        pixiMP.lastMouseY = event.clientY;
    };

    const handleMouseUp = (event) => {
        if (event.button === 1 || (event.button === 0 && (conf.isUser || pixiMP.grab))) {
            // выключение режима навигации
            setNewPosition()
            handleSetMiddleMouseDown(false)
            if (isSegments) {
                Floor.reRenderPlace()
            } else {
                Floor.updatePlace()
            }
        }

    };

    const handleTouchMove = (event) => {
        if (pixiMP.isMiddleMouseDown) {
            if (event.touches.length === 1) {
                const deltaX = event.touches[0].clientX - pixiMP.lastMouseX;
                const deltaY = event.touches[0].clientY - pixiMP.lastMouseY;
                pixiMP.accumulatedDeltaX += deltaX;
                pixiMP.accumulatedDeltaY += deltaY;
                pixiMP.lastMouseX = event.touches[0].clientX;
                pixiMP.lastMouseY = event.touches[0].clientY;
            }
        }
    };

    const pinchMove = (event) => {
        const container = document.getElementsByClassName('pixiApp')[0]
        initSizeContainer(container)

        graphicsPlace.clear()
        // масштабирование и центровка на сенсрных устр-х
        // Получаем координаты курсора относительно окна браузера
        const mouseX = event.center.x;
        const mouseY = event.center.y;

        // Текущие координаты курсора относительно worldContainer
        const mouseXContainer = mouseX - containerCanvas.left - worldContainer.x;
        const mouseYContainer = mouseY - containerCanvas.top - worldContainer.y;

        // Относительные координаты курсора в предыдущем масштабе относительно worldContainer
        const oldMouseX = mouseXContainer / worldContainer.scale.x;
        const oldMouseY = mouseYContainer / worldContainer.scale.y;

        // Новый масштаб

        // let newScale = worldContainer.scale.x * event.scale; // оригинальный масштаб
        let newScale = worldContainer.scale.x * (1 + (event.scale - 1) * 0.05); // Изменение масштаба медленнее

        // Ограничение масштаба

        newScale = Math.min(pixiMP.maxScale, Math.max(pixiMP.minScale, newScale));
        worldContainer.scale.set(newScale);

        // Определение новых координат worldContainer.x и worldContainer.y, чтобы курсор оставался в том же месте относительно контейнера
        const newX = mouseX - containerCanvas.left - oldMouseX * newScale;
        const newY = mouseY - containerCanvas.top - oldMouseY * newScale;

        worldContainer.x = newX;
        worldContainer.y = newY;
        if (isSegments) {
            Floor.initSegmentPlace()
        }
    }

    const handleWheel = (event) => {
        const container = document.getElementsByClassName('pixiApp')[0]
        initSizeContainer(container)
        graphicsPlace.clear()
        // масштабирование колесом мыши
        // Получаем координаты курсора относительно окна браузера
        event.preventDefault();
        const mouseX = event.clientX;
        const mouseY = event.clientY;

        // Текущие координаты курсора относительно worldContainer
        const mouseXContainer = mouseX - containerCanvas.left - worldContainer.x;
        const mouseYContainer = mouseY - containerCanvas.top - worldContainer.y;

        // Относительные координаты курсора в предыдущем масштабе относительно worldContainer
        const oldMouseX = mouseXContainer / worldContainer.scale.x;
        const oldMouseY = mouseYContainer / worldContainer.scale.y;

        // Новый масштаб
        let newScale;
        if (event.deltaY > 0) {
            newScale = worldContainer.scale.x - worldContainer.scale.x * 0.1;
        } else {
            newScale = worldContainer.scale.x + worldContainer.scale.x * 0.1;
        }
        newScale = Math.round(parseFloat(newScale) * 100) / 100;

        // ограничение масштаба
        newScale = Math.min(pixiMP.maxScale, Math.max(pixiMP.minScale, newScale));
        worldContainer.scale.set(newScale);

        // Определение новых координат worldContainer.x и worldContainer.y, чтобы курсор оставался в том же месте относительно контейнера
        const newX = mouseX - containerCanvas.left - oldMouseX * newScale;
        const newY = mouseY - containerCanvas.top - oldMouseY * newScale;

        worldContainer.x = newX;
        worldContainer.y = newY;
        setScreenParam()
        if (isSegments) {
            Floor.initSegmentPlace()
        } else {
            Floor.updatePlace()
        }
    }

    const handleTouchStart = (event) => {
        if (event.touches.length === 1) {
            // включение режима навигации сенсором
            handleSetMiddleMouseDown(true)
            pixiMP.startMouseX = event.touches[0].clientX;
            pixiMP.startMouseY = event.touches[0].clientY;
            pixiMP.lastMouseX = event.touches[0].clientX;
            pixiMP.lastMouseY = event.touches[0].clientY;
            pixiMP.lastPositionWorld.x = worldContainer.x
            pixiMP.lastPositionWorld.y = worldContainer.y
        } else {
            handleSetMiddleMouseDown(false)
        }
    };

    const handleTouchEnd = (event) => {
        // выключение навигации сенсора
        handleSetMiddleMouseDown(false)
        // setNewPosition()
        if (isSegments) {
            Floor.reRenderPlace()
        } else {
            Floor.updatePlace()
        }
    };

    // мышь
    container.addEventListener('mousedown', handleMouseDown);
    container.addEventListener('mouseup', handleMouseUp);
    container.addEventListener('mousemove', handleMouseMove);
    container.addEventListener('wheel', handleWheel);

    // сенсор
    container.addEventListener('touchstart', handleTouchStart);
    container.addEventListener('touchend', handleTouchEnd);
    container.addEventListener('touchmove', handleTouchMove);

    const hammer = new Hammer(container);
    hammer.get('pinch').set({enable: true});
    hammer.on('pinchmove', pinchMove)

    // const handleAnimationFrame = () => {
    //     if (pixiMP.isMiddleMouseDown) {
    //         worldContainer.x += pixiMP.accumulatedDeltaX;
    //         worldContainer.y += pixiMP.accumulatedDeltaY;
    //         pixiMP.accumulatedDeltaX = 0;
    //         pixiMP.accumulatedDeltaY = 0;
    //     }
    //     requestAnimationFrame(handleAnimationFrame);
    // };

    const handleAnimationFrame = () => {
        if (pixiMP.isMiddleMouseDown) {
            worldContainer.x += pixiMP.accumulatedDeltaX;
            worldContainer.y += pixiMP.accumulatedDeltaY;
            pixiMP.accumulatedDeltaX = 0;
            pixiMP.accumulatedDeltaY = 0;
        }
        requestAnimationFrame(handleAnimationFrame);
    };

    requestAnimationFrame(handleAnimationFrame);
};


const setNewPosition = () => {
    // TODO если возникнут проблемы установкой новых координат, то необходимо вызывать initSizeContainer(container)
    // Очистка графики места перед проверкой видимости
    graphicsPlace.clear();

    if (conf.isUser) {
        // Пересчет координат с учетом масштаба
        const scale = worldContainer.scale.x;

        // Координаты отображаемой области на канвасе
        let displayX1 = -worldContainer.x / scale;
        let displayY1 = -worldContainer.y / scale;
        let displayX2 = (-worldContainer.x + containerCanvas.width) / scale;
        let displayY2 = (-worldContainer.y + containerCanvas.height) / scale;

        // Координаты экстремумов пользователя с учетом смещений
        let maxX1 = extremumUser.max.x;
        let maxY1 = extremumUser.max.y;
        let maxX2 = extremumUser.min.x;
        let maxY2 = extremumUser.min.y;

        // Проверка выхода за пределы допустимой области
        if (displayX1 > maxX1 || displayY1 > maxY1 || displayX2 < maxX2 || displayY2 < maxY2) {
            // Установка последнего валидного положения
            worldContainer.x = pixiMP.lastPositionWorld.x;
            worldContainer.y = pixiMP.lastPositionWorld.y;

        } else {
            // Сохранение текущего валидного положения
            pixiMP.lastPositionWorld.x = worldContainer.x;
            pixiMP.lastPositionWorld.y = worldContainer.y;
        }
    }
};


// const setNewPosition = () => {
//     // проверка видимости схемы после навигации по канвасу
//     graphicsPlace.clear()
//     if (conf.isUser) {
//         //  TODO когда включен масштаб подумать над координатами display, возможно containerCanvas требуется скалировать
//         let displayX1 = -worldContainer.x / worldContainer.scale.x - containerCanvas.left;
//         let displayY1 = -worldContainer.y / worldContainer.scale.y + containerCanvas.top;
//         let displayX2 = (-worldContainer.x / worldContainer.scale.x) + containerCanvas.width + containerCanvas.left;
//         let displayY2 = (-worldContainer.y / worldContainer.scale.y) + containerCanvas.height + containerCanvas.top;
//         let maxX1 = extremumUser.max.x - containerCanvas.left;
//         let maxY1 = extremumUser.max.y - containerCanvas.top;
//         let maxX2 = extremumUser.min.x + containerCanvas.left;
//         let maxY2 = extremumUser.min.y + containerCanvas.top;
//
//         if (displayX1 > maxX1 ||
//             displayY1 > maxY1 ||
//             displayX2 < maxX2 ||
//             displayY2 < maxY2
//         ) {
//             // установка последнего валидного положения
//             worldContainer.x = pixiMP.lastPositionWorld.x
//             worldContainer.y = pixiMP.lastPositionWorld.y
//         }
//     }
// }

export const handleBtnScale = (type) => {
    // масштабирование кнопками
    // Получаем координаты курсора относительно окна браузера
    const mouseX = containerCanvas.width / 2
    const mouseY = containerCanvas.height / 2

    // Текущие координаты курсора относительно worldContainer
    const mouseXContainer = mouseX - containerCanvas.left - worldContainer.x;
    const mouseYContainer = mouseY - containerCanvas.top - worldContainer.y;

    // Относительные координаты курсора в предыдущем масштабе относительно worldContainer
    const oldMouseX = mouseXContainer / worldContainer.scale.x;
    const oldMouseY = mouseYContainer / worldContainer.scale.y;

    // Новый масштаб
    let newScale;
    if (type === "-") {
        newScale = worldContainer.scale.x - worldContainer.scale.x * 0.1;
    } else {
        newScale = worldContainer.scale.x + worldContainer.scale.x * 0.1;
    }
    newScale = Math.round(parseFloat(newScale) * 100) / 100;

    // ограничение масштаба
    newScale = Math.min(pixiMP.maxScale, Math.max(pixiMP.minScale, newScale));
    worldContainer.scale.set(newScale);
    // TODO вычислить центр смещения контейнера без mouseX и mouseY
    // Определение новых координат worldContainer.x и worldContainer.y, чтобы курсор оставался в том же месте относительно контейнера
    const newX = mouseX - containerCanvas.left - oldMouseX * newScale;
    const newY = mouseY - containerCanvas.top - oldMouseY * newScale;

    worldContainer.x = newX;
    worldContainer.y = newY;

    if (isSegments) {
        Floor.initSegmentPlace()
    } else {
        Floor.updatePlace()
    }
}

export const handleSetMiddleMouseDown = (value) => {
    pixiMP.isMiddleMouseDown = value
    if (value) {
        document.body.style.cursor = "grabbing";
    } else {
        pixiMP.grab ? document.body.style.cursor = "grab" : document.body.style.cursor = "default";
    }
}

export const setMiddleMouseDown = (value) => {
    pixiMP.grab = value
    // pixiMP.isMiddleMouseDown = value
}
