// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Owners-module_container_HRQMT{width:100%}.Owners-module_add_N6Yn1{margin-left:auto}", "",{"version":3,"sources":["webpack://resources/js/react/src/views/Admin/Events/Notifications/Owners/Owners.module.scss"],"names":[],"mappings":"AAAA,+BACI,UAAA,CAGJ,yBACI,gBAAA","sourcesContent":[".container {\n    width: 100%;\n}\n\n.add {\n    margin-left: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Owners-module_container_HRQMT",
	"add": "Owners-module_add_N6Yn1"
};
export default ___CSS_LOADER_EXPORT___;
