/* Component props:
 * id => Unique input identifier
 * size => Size of the component; values: xlarge/large/medium/small; default :large;
 * type => Type of input field; values: text/number/password; default: text;
 * value => Default value of input field. String or null
 * label => Label for the input. String or null
 * name => Input name. String.
 * inputRef => Link to input ref.
 * isClearable => Does component have clear icon and functional; values: true/false; default: false
 * isInfinity => Does component have infinity icon and functional; values: true/false; default: false
 * required => Does component have required attribute. Boolean
 * tip => Tip for the input field. String or null
 * error => Error message
 * readOnly => is input readonly
 */

import React from 'react';
import Tip from '../Toggletip';
import IconClear from '../../assets/images/icons/16/x.svg';
import IconInfinity from '../../assets/images/icons/16/Union.svg';
import Message from "../../widget/components/Message";

import '../../scss/ui/a-textarea.scss'

const Textarea = (props) => {
    return (
        <div className={`a-textarea a-textarea_size_${props.size || 'large'}`}>
            <textarea
                type={props.type || 'text'}
                className={'a-textarea__textarea ' + (props.error && !props.disabled ? ' a-textarea__textarea_error' : '') + (props.disabled ? ' a-textarea__textarea_disabled' : '')}
                name={props.name || ''}
                value={props.value || ''}
                ref={props.inputRef}
                placeholder={props.placeholder}
                onChange={props.handleChange}
                id={props.id}
                required={props.required || false}
                readOnly={props.readOnly}
                style={props.style}
                disabled={props.disabled}
            />

            {/* Если присутствует ошибка, значение в инпуте и лейбл сдвигается вверх, */}
            {/* то скрываем его, чтобы не пересекался с ошибкой */}

            {/* Если есть значение, то лейбл сдвигаем вверх. Если есть ошбка и нет значения */}
            {/* то сдвигаем лейбл вниз */}

            {!props.outside && props.label && !(props.error && props.value && props.value.length > 0) ? (
                <label
                    className={
                        (props.outside ? ' a-textarea__label_outside' : 'a-textarea__label') +
                        (props.value //убрал дополнительные условия, чтобы лейбл не перекрывал инпут, если в нём число или дата
                            ? !props.outside ? ' a-textarea__label_fixed' : ''
                            : '') +
                        ((!props.value || props.value.length === 0) && props.error ? props.outside ? '' : ' a-textarea__label_bottom' : '')
                    }
                    htmlFor={props.name}
                >
                    {props.label}
                </label>
            ) : <label
                className={
                    (props.outside ? ' a-textarea__label_outside' : 'a-textarea__label') +
                    (props.value //убрал дополнительные условия, чтобы лейбл не перекрывал инпут, если в нём число или дата
                        ? !props.outside ? ' a-textarea__label_fixed' : ''
                        : '') +
                    ((!props.value || props.value.length === 0) && props.error ? props.outside ? '' : ' a-textarea__label_bottom' : '')
                }
                htmlFor={props.name}
            >
                {props.label}
            </label>}
            {props.error && <Message type={"error"} value={props.error} />}
        </div>
    );
};

export default Textarea;