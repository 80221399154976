import React from 'react';
import {Text, Rect} from "react-konva";
import {STAGESTEP} from "@/views/Admin/Floors/Editor/utils/constants";

import {hexToRgb, getGroupColor, calcFigureHeightWidth, sizeText} from "@/views/Admin/Floors/Editor/utils/common";

const PlaceFreeZone = ({param, groupList, role, constEditableStatus}) => {
    // рендер свободной зоны
    let height = calcFigureHeightWidth(param.map_height);
    let width = calcFigureHeightWidth(param.map_width);
    let color, textColor = null, statusLock = null;

    if (role === 'designer') {
        color = getGroupColor(param.group.id, groupList)
    }

    if (role === 'manager') {
        if (typeof param['status'] !== "undefined") {
            if (param.status == 0) {
                textColor = null;
            }
            if (constEditableStatus.indexOf(Number(param.status)) !== -1) {
                statusLock = true;
                color = '#DDDDDD';
                textColor = '#5E6B92';
            } else if (param.status == 2) {
                color = '#ffffff';
                textColor = '#5E6B92';
            } else if (param.status == 3) {
                color = '#DDDDDD';
                textColor = '#5E6B92';
            } else {
                color = getGroupColor(param.group.id, groupList)
            }
        } else {
            color = getGroupColor(param.group.id, groupList)
        }
    }

    if (role === 'user') {
        if (typeof param['status'] !== "undefined") {
            if (param.status == 0) {
                textColor = null;
                color = getGroupColor(param.group.id, groupList)
            } else if (param.status == 1) {
                color = '#DDDDDD';
                textColor = '#5E6B92';
            } else if (param.status == 2) {
                color = '#ffffff';
                textColor = '#5E6B92';
            } else if (param.status == 3) {
                color = '#DDDDDD';
                textColor = '#5E6B92';
            } else {
                // 4 - зарезервировано орг, 5 - занято орг.
                color = '#DDDDDD';
                textColor = '#5E6B92';
            }
        } else {
            color = getGroupColor(param.group.id, groupList)
        }
    }

    let textStr = param.custom.name !== null ? param.custom.name : param.group.name;

    const sizeTextZone = sizeText(textStr)


    return <>
        <Rect
            // ref={shapeRef.current[getKey]}
            {...param}
            x={param.coords.start.column * STAGESTEP + 4}
            y={param.coords.start.row * STAGESTEP + 4}
            height={height - 8}
            width={width - 8}
            status={`${param.status}`}
            fill={color}
            stroke={param.selected ? '#0067F2' : ''}
            name="freeZone"
        />

        {statusLock && <Text
            {...param}
            name="PlaceText"
            fontFamily={"Inter, sans-serif"}
            fontStyle={'bold'}
            x={param.coords.start.column * STAGESTEP + 4}
            y={param.coords.start.row * STAGESTEP + 4}
            height={height - 8}
            width={width - 8}
            fontSize={12}
            text={'🔒'}
            stroke={'#000'}
            fill={'#000'}
            strokeWidth={0}
        />}
        <Text
            {...param}
            name="freeZoneText"
            fontFamily={"Inter, sans-serif"}
            fontStyle={'bold'}
            x={param.coords.start.column * STAGESTEP + 4}
            y={param.coords.start.row * STAGESTEP + 4}
            fontSize={sizeTextZone}
            verticalAlign={'middle'}
            align={'center'}
            height={height - 8}
            width={width - 8}
            // rotation={width < 50 && width != height ? 270 : 0}
            text={textStr}
            stroke={textColor === null ? hexToRgb(color) : textColor}
            fill={textColor === null ? hexToRgb(color) : textColor}
            strokeWidth={0}
        />
    </>
};

export default PlaceFreeZone;
