import React, {useEffect, useState} from 'react';
import SessionDate from "./SessionDate";
import {postData} from "../../utils/sendData";
import {getFormattedDate} from "../../utils/getFormattedDate";
import Select from "../../UIKit/component/select/Select";

const SessionSelect = (props) => {
    const [loaded, setLoaded] = useState(false)

    const [selectedSessionId, setSelectedSessionId] = useState(props.sessionId)
    const [selectedSessionDate, setSelectedSessionDate] = useState(props.sessionDate)
    const [selectedSessionTime, setSelectedSessionTime] = useState(props.sessionTime)
    const [selectedSessionFrom, setSelectedSessionFrom] = useState(props.sessionFrom)

    const [sessionValues, setSessionValues] = useState([])
    const [dateValues, setDateValues] = useState([])
    const [timeValues, setTimeValues] = useState([])

    useEffect(() => {
        apiLoadAll()
        if (loaded) {
            updateParent()
        }
        setLoaded(true)

    }, [selectedSessionId, selectedSessionDate, selectedSessionTime]);

    useEffect(() => {
        if (loaded) {
            apiGetDates()
        }
    }, [selectedSessionFrom]);

    /**
     * Общий запрос до api
     * @param options - передаваемые GET-параметры
     */
    const apiGetSchedule = (options) => {
        let url = props.apiUrl
        let param = `${constructUrl(options)}`
        if (param) url += `/?${param}`

        return postData(null, url, 'GET')
            .catch(({response}) => {
                console.log(response)
            })
    }

    // Загружаем и рисуем все подходящие данные
    const apiLoadAll = () => {
        apiGetSchedule({
            id: selectedSessionId,
            date: selectedSessionDate,
            time: selectedSessionTime,
            from: selectedSessionFrom,
            tz: props.timezone
        })
            .then((response) => {
                setSessionValues(readSessions(response.data.schedule.sessions))
                setDateValues(readDates(response.data.schedule.dates))
                setTimeValues(readTimes(response.data.schedule.times))
            })
    }

    // Выбираем и рисуем подходящие варианты сеансов
    const apiGetSession = () => {
        apiGetSchedule({
            date: selectedSessionDate,
            time: selectedSessionTime,
            from: selectedSessionFrom,
            tz: props.timezone
        })
            .then((response) => {
                setSessionValues(readSessions(response.data.schedule.sessions))
            })
    }

    // Выбираем и рисуем подходящие варианты дат
    const apiGetDates = () => {
        apiGetSchedule({
            id: selectedSessionId,
            time: selectedSessionTime,
            from: selectedSessionFrom,
            tz: props.timezone
        })
            .then((response) => {
                setDateValues(readDates(response.data.schedule.dates))
            })
    }

    // Выбираем и рисуем подходящие варианты времени
    const apiGetTimes = async () => {
        await apiGetSchedule({
            id: selectedSessionId,
            date: selectedSessionDate,
            from: selectedSessionFrom,
            tz: props.timezone
        })
            .then((response) => {
                setTimeValues(readTimes(response.data.schedule.times))
            })
    }

    const handleSessionChanged = (value) => {
        setSelectedSessionId(value != 0 ? value : null)
    }

    const handleDateChanged = (value) => {
        let result = value.split('T')[0] + 'T' + selectedSessionFrom.split('T')[1]
        setSelectedSessionDate(result)
    }

    const handleTimeChanged = (value) => {
        setSelectedSessionTime(value != 0 ? value : null)
    }

    const handleFromChanged = (value) => {
        let result = value.split('T')[0] + 'T' + selectedSessionFrom.split('T')[1]
        setSelectedSessionFrom(result)
    }

    // Читаем данные сеансов из ответа api и складываем их в state
    const readSessions = (list) => {
        let result = list.map(item => {
            return {
                value: item.id,
                label: item.name,
            }
        })
        result.unshift({value: 0, label: 'Не выбрано'})
        return result
    }

    // Читаем данные времени из ответа api и складываем их в state
    const readDates = (list) => {
        let format = 'dd.MM.yyyy';
        let values = [];
        for (let i in list) {
            values.push({
                value: list[i].split('T')[0] + 'T' + selectedSessionFrom.split('T')[1],
                label: getFormattedDate(Date.parse(list[i]), format, props.timezone),
            })
        }

        return values
    }

    // Читаем данные времени из ответа api и складываем их в state
    const readTimes = (list) => {
        let format = 'HH:mm';
        let values = [{value: 0, label: 'Не выбрано'}];
        for (let i in list) {
            values.push({
                value: list[i],
                label: getFormattedDate(Date.parse(list[i]), format, props.timezone),
            })
        }
        return values
    }

    const constructUrl = (options) => {
        return Object.entries(options).map(x => `${x[0]}=${encodeURIComponent(x[1])}`).join('&')
    }

    const updateParent = () => {
        let sessionName = null
        if (selectedSessionId && selectedSessionDate && selectedSessionTime && sessionValues.length > 0) {
            sessionName = sessionValues.find(x => x.value == selectedSessionId)?.label
        }

        props.handleUpdated(selectedSessionId, selectedSessionDate, selectedSessionTime, sessionName)
    }

    const transformArrayToObject = (array) => {
        return array.reduce((obj, item) => {
            obj[item.value] = item.label;
            return obj;
        }, {});
    }

    return (
        <div className="bottom-bar__wrapper-control">
            <div className="bottom-bar__section bottom-bar__session">
                <div className="bottom-bar__section bottom-bar__date">
                    <div className="bottom-bar__section-label">
                        Дата
                    </div>
                    <div className="bottom-bar__section-calendar">
                        <SessionDate
                            dates={dateValues}
                            selected={selectedSessionDate}
                            from={selectedSessionFrom}
                            timezone={props.timezone}
                            onOpen={apiGetDates}
                            onChange={handleDateChanged}
                            onChangeFrom={handleFromChanged}
                        />
                    </div>
                </div>
            </div>
            <div className="bottom-bar__wrapper-date">
                <div className="bottom-bar__section bottom-bar__time">
                    <div className="bottom-bar__section-label">
                        Сеанс
                    </div>
                    <div className="bottom-bar__section-select">
                        <Select
                            direction={['bottom']}
                            data={transformArrayToObject(sessionValues)}
                            value={selectedSessionId}
                            size={'small'}
                            onDropShow={apiGetSession}
                            onChange={handleSessionChanged}
                        />
                    </div>
                </div>

                <div className="bottom-bar__section bottom-bar__time">
                    <div className="bottom-bar__section-label">
                        Время ({props.timezoneAbbr})
                    </div>
                    <div className="bottom-bar__section-select">
                        <Select
                            direction={['bottom']}
                            data={transformArrayToObject(timeValues)}
                            value={selectedSessionTime}
                            size={'small'}
                            onDropShow={apiGetTimes}
                            onChange={handleTimeChanged}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SessionSelect;
