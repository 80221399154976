import React, {useEffect, useRef} from 'react';
import '../../scss/components/confirm/confirm.scss'
import ReactHtmlParser from "react-html-parser";
import scrollToElement from "../../../utils/scrollToElement";

const Confirm = (props) => {
    const ref = useRef(null)

    useEffect(() => {
        scrollToElement(ref, {position: 'center', offset: 0})
    }, [])

    return (
        <div className={`nh-confirm nh-confirm__${props.type} nh-confirm__direction-${props.direction || 'row'}`}>
            <p className={'nh-confirm__message'} ref={ref}>{ReactHtmlParser(props.message)}</p>
            <div className={'nh-confirm__btn-group'}>
                <button className={'a-btn a-btn_size_small a-btn_type_tertiary-outline'}
                        onClick={() => props.onCancel()}>
                    {props.onCancelText}
                </button>
                <button className={`a-btn a-btn_size_small a-btn_type_${props.type}-default`}
                        onClick={() => props.onSubmit()}>
                    {props.onSubmitText}
                </button>
            </div>
        </div>
    );
};

export default Confirm;