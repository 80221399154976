import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from "react-router-dom";
import UploadIcon from '../../../../assets/images/icons/16/upload.svg'
import Window from "../../../../UIKit/component/window/window";
import Notification from "../../../../UIKit/component/notification/notification";
import '../../../../views/Admin/Events/PublishEvent/PublishEvent.scss'
import {postData} from "../../../../utils/sendData";
import {API} from "../../../../api/API";
import Select from "../../../../UIKit/component/select/Select";
import ReCAPTCHA from "react-google-recaptcha";
import {useSelector} from "react-redux";

const PublishEvent = (props) => {
    const history = useHistory()
    const release_period_label = useSelector(state => state.admin.event.release_period_label)
    const [template, setTemplate] = useState('')
    const [status, setStatus] = useState(false)
    const [period, setPeriod] = useState({})
    const [active, setActive] = useState('')
    const [releasePeriod, setReleasePeriod] = useState('')

    const [recaptchaKey, setRecaptchaKey] = useState(null)
    const [recaptcha, setRecaptcha] = useState(null)

    const refPublish = useRef(null)
    const refRecaptcha = useRef(null)

    useEffect(() => {
        if (history.location.pathname.indexOf('editor') !== -1) {
            setTemplate('editor')
        } else {
            setTemplate('settings')
        }
        releasePeriodLabel()
    }, []);

    useEffect(() => {
        if (status) getConst()
    }, [status]);

    useEffect(() => {
        if (recaptcha !== null) {
            recaptchaAction()
        }
    }, [recaptcha])

    useEffect(() => {
        if (recaptchaKey !== null) {
            handlePublish()
        }
    }, [recaptchaKey]);

    const handleOpenModal = () => {
        setStatus(true)
    }

    const releasePeriodLabel = async () => {
        let name
        if (release_period_label) name = release_period_label
        let id = props?.match?.params?.event_id || props.id
        try {
            name = await postData(null, API.getEvents.path(id), API.getCourse.method)
        } catch (e) {
            name = await postData(null, API.getCourse.path(id), API.getCourse.method)
        }

        name = name?.data?.data?.attributes?.release_period_label
        setReleasePeriod(name)
    }

    const handlePublish = () => {
        let id = props?.match?.params?.event_id || props.id
        let data = {
            release_period: active
        }
        if (recaptchaKey !== null) {
            data['recaptcha'] = recaptchaKey
        }
        postData(data, API.postPublishEvent.path(id), API.postPublishEvent.method).then((r) => {
            let title = 'Публикация прошла успешно';
            let isAcademy = window.location.origin.includes('academy')
            let msg = `Вы можете просмотреть публикацию, перейдя по ссылке: <a target="_blank" href="${window.location.origin}/${isAcademy ? 'course' : 'all'}/${id}/" class="cp-notification__link">Просмотреть</a>`
            showTopMessage('success', 'large', title, msg);
            setTemplate('')
        }).catch(({response}) => {
            if (response.data.errors.dates) {
                showTopMessage('error', 'small', response.data.errors.dates)
                return
            }
            if (response.data.errors) {
                if (response.data.errors.recaptcha) {
                    setRecaptcha(response.data.errors.recaptcha[0])
                }
                if (response.data.errors.state) {
                    showTopMessage('error', 'small', response.data.errors.state[0])
                }
                if (response.data.errors.tariff) {
                    showTopMessage('error', 'small', response.data.errors.tariff[0])
                }
            }
        })
    }


    const recaptchaAction = async () => {
        let token;
        token = await refRecaptcha.current.executeAsync()
        setRecaptchaKey(token)
    }


    const getConst = () => {
        postData(null, API.getEventsConstants.path, API.getEventsConstants.method).then((res) => {
            setPeriod(res.data.data.release_period)
            let defaultActive = Object.keys(res.data.data.release_period)[0]
            setActive(defaultActive)
        })
    }

    const renderBtn = () => {
        if (template === 'editor') {
            return event.state === 0 ? <button
                onClick={() => handleOpenModal()}
                className="cp-btn cp-btn_size_medium cp-scheme_primary-default nh-cp__header__publish">
                <UploadIcon/>
                <span>Опубликовать</span>
            </button> : null
        } else {
            return <button onClick={() => handleOpenModal()}
                           className="cp-btn cp-btn_size_medium cp-scheme_primary-default cp-btn_icon_left">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M11 1C11.5288 1 11.9869 1.20949 12.3544 1.57991L14.4217 3.64718C14.7924 4.01786 15 4.47274 15 5C15 5.52385 14.794 6.06728 14.4217 6.43959L6.89021 13.9675C6.36661 14.5715 5.62438 14.9426 4.7564 15.0016H2H1V14.0016L1.00325 11.1647C1.06698 10.3758 1.43373 9.64229 1.98196 9.15991L9.56093 1.58101C9.93285 1.20718 10.4754 1 11 1ZM3.35157 10.6161C3.14601 10.7979 3.01885 11.0522 3 11.2452V13.0025L4.68578 13.004C4.95369 12.9851 5.20307 12.8604 5.42749 12.6054L9.80933 8.22354L7.77751 6.19172L3.35157 10.6161ZM9.19197 4.77776L11.2235 6.80933L12.9895 5.04339L10.9582 3.01212L9.19197 4.77776Z"
                          fill="currentColor"></path>
                </svg>
                Опубликовать
            </button>
        }
    }

    // const NotificationModalContent = () => {
    //     return <div>
    //         <strong>Внимание!</strong> После публикации события, даты проведения события можно переносить не более чем
    //         на 1 календарный месяц. Если вы планируете перенести мероприятие на более длительный срок, необходимо будет
    //         отменить текущее событие и создать новое событие.
    //     </div>
    // }

    const content = () => {
        return <div>
            <div className="nh-window__publish__header">
                <h6>Публикация события</h6>
            </div>
            <div className="nh-window__publish__body">
                Укажите период, в который услуга для покупателей будет считаться полностью оказанной, и они не смогут
                отправить заявку на возврат.
            </div>
            <Select
                label={releasePeriod}
                direction={['down', 'left']}
                value={active}
                size={'small'}
                data={period}
                onChange={(event) => setActive(event)}
            />
            {/*<Notification type={'warning'} value={NotificationModalContent()}/>*/}
            {recaptcha && <ReCAPTCHA
                sitekey={recaptcha}
                size={'invisible'}
                ref={refRecaptcha}
            />}
            <div className="nh-window__publish__content">
                <button
                    className={`a-btn a-btn_size_small a-btn_type_tertiary-ghost`}
                    onClick={() => setStatus(false)}>
                    Отменить
                </button>
                <button
                    className={`a-btn a-btn_size_small a-btn_type_primary-default`}
                    disabled={false}
                    onClick={() => handlePublish()}>
                    Продолжить
                </button>
            </div>
        </div>
    }

    return (
        <div style={{display: `${template ? 'flex' : 'none'}`}}>
            {template && <> {renderBtn()}
                <Window
                    status={status}
                    setStatus={setStatus}
                    content={content()}
                    ref={refPublish}
                    dismissible={false}
                    esc={true}
                    beforeClose={() => null}
                    afterClose={() => null}
                    selector={'nh-window__publish'}
                />
            </>}
        </div>
    );
};

export default PublishEvent;
