import React from "react";
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ar from './translations/ar/common.json'
import de from './translations/de/common.json'
import en from './translations/en/common.json'
import es from './translations/es/common.json'
import fr from './translations/fr/common.json'
import hy from './translations/hy/common.json'
import it from './translations/it/common.json'
import ja from './translations/ja/common.json'
import ka from './translations/ka/common.json'
import kk from './translations/kk/common.json'
import pt from './translations/pt/common.json'
import ru from './translations/ru/common.json'
import tr from './translations/tr/common.json'
import zh from './translations/zh/common.json'

i18n.use(LanguageDetector)
i18n.use(initReactI18next)
i18n.init({
    debug: false,
    fallbackLng: 'ru',
    interpolation: {
        escapeValue: false,
    },
    resources: {
        ar: {common: ar},
        de: {common: de},
        en: {common: en},
        es: {common: es},
        fr: {common: fr},
        hy: {common: hy},
        it: {common: it},
        ja: {common: ja},
        ka: {common: ka},
        kk: {common: kk},
        pt: {common: pt},
        ru: {common: ru},
        tr: {common: tr},
        zh: {common: zh},
    }
});

export default i18n;
