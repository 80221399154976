export default [
    {
        id: 1,
        name: 'Jivo',
        description: 'Онлайн-консультант',
        path: '/admin/apps/jivosite/',
        img: '/assets/images/apps/jivo.png',
    },
    {
        id: 2,
        name: 'Google Tag Manager',
        description: 'Диспетчер тегов',
        path: '/admin/apps/google_tag_manager/',
        img: '/assets/images/apps/google.png',
    },
    {
        id: 3,
        name: 'Яндекс Метрика',
        description: 'Статистика',
        path: '/admin/apps/metrika/',
        img: '/assets/images/apps/metrica_logo.png',
    },
    {
        id: 4,
        name: 'Пиксель ВКонтакте',
        description: 'Статистика',
        path: '/admin/apps/vk_pixel/',
        img: '/assets/images/apps/vk_logo.png',
    },
    {
        id: 5,
        name: 'Пиксель Facebook',
        description: 'Статистика',
        path: '/admin/apps/fb_pixel/',
        img: '/assets/images/apps/fb_logo.png',
    },
    {
        id: 6,
        name: 'A5000',
        description: 'Регистрация участников',
        path: '/admin/apps/a5000/',
        img: '/assets/images/apps/a5000_logo.jpg',
    },
    {
        id: 7,
        name: 'Ивентишес',
        description: 'Мобильное приложение',
        path: '/admin/apps/eventicious/',
        img: '/assets/images/apps/eventicious_logo.png',
    },
    {
        id: 8,
        name: 'Вебхуки',
        description: 'Прочее',
        path: '/admin/apps/webhook/',
        img: '/assets/images/apps/webhook_logo.png',
    },
    {
        id: 9,
        name: 'Albato',
        description: 'Интеграции',
        path: '/admin/apps/albato/',
        img: '/assets/images/apps/albato.svg',
    },
    {
        id: 10,
        name: 'Передача лидов',
        description: 'Прочее',
        path: '/admin/apps/lead/',
        img: '/assets/images/apps/lead.svg',
    },
    {
        id: 11,
        name: 'GetCourse',
        description: 'Прочее',
        path: '/admin/apps/getcourse/',
        img: '/assets/images/apps/getcourse_logo.png',
    },
    {
        id: 12,
        name: 'Nethouse',
        description: 'Интеграции',
        path: '/admin/apps/api/',
        img: '/assets/images/events/logo_nh.svg',
    },
]
