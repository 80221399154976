import {postData} from '../../../../../../../utils/sendData';
import {API} from '../../../../../../../api/API'

export const getChartData = (type: string, eventId: string, query: Object) => {
    return postData(
        null,
        API.academy.getCourseStatisticCharts.path(type, eventId, query),
        API.academy.getCourseStatisticCharts.method
    )
};
