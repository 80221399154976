/* Принимает в себя дату и формат, возвращает дату в указанном формате */
import {getFormattedDate} from "./getFormattedDate";

export const toIsoString = (date) => {
    let tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function (num) {
            return (num < 10 ? '0' : '') + num;
        };

    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        dif + pad(Math.floor(Math.abs(tzo) / 60)) +
        ':' + pad(Math.abs(tzo) % 60);
}

export const isoSplitDateAndTime = (value, timezone) => {
    let datetime = new Date(Date.parse(value));

    // console.log('+ offset: ' + offset)
    // console.log('+ datetime order: ' + value)
    // console.log('+ datetime server: ' + getFormattedDate(value, 'yyyy-MM-ddTHH:mm:ssZ', 'Europe/Moscow'))

    // Точки отсчёта
    let dateMoscow = getFormattedDate(value, 'yyyy-MM-ddT00:00:00Z', 'Europe/Moscow')
    // console.log('+ date moscow: ' + dateMoscow + ' => 2023-05-02T00:00:00+03:00')
    let monthMoscow = getFormattedDate(value, 'yyyy-MM-01T00:00:00Z', 'Europe/Moscow')
    // console.log('+ month moscow: ' + monthMoscow + ' => 2023-05-01T00:00:00+03:00')

    // Дата
    let date = getFormattedDate(new Date(Date.parse(dateMoscow)), 'yyyy-MM-ddTHH:mm:ssZ', timezone)
    //console.log('+ date: ' + date + ' => 2023-05-02T09:00:00+12:00')

    // От
    let from = getFormattedDate(new Date(Date.parse(monthMoscow)), 'yyyy-MM-ddTHH:mm:ssZ', timezone)
    // console.log('+ from: ' + from + ' => 2023-05-02T09:00:00+12:00')

    // Время
    let time = getFormattedDate(new Date(), 'yyyy-MM-ddT', timezone) //
        + getFormattedDate(datetime, 'HH:mm:ssZ', timezone)
    // console.log('+ time: ' + time + ' => 2023-05-01T08:00:00+12:00')

    // console.log('date: ' + date + ', time: ' + time + ', from: ' + from)

    return [date, time, from]
}

export const isoMergeDateAndTime = (date, time, timezone) => {
    let datetime = getFormattedDate(date, 'yyyy-MM-dd', 'Europe/Moscow')
        + 'T' + getFormattedDate(time, 'HH:mm:ssZ', 'Europe/Moscow')

    return getFormattedDate(datetime, 'yyyy-MM-ddTHH:mm:ssZ', timezone);
}

export const replaceTimezoneInIso = (datetime, timezone) => {
    return datetime
    /* TODO: привести таймзоны */
    return datetime.replace('+03:00', '+05:00')
}

export const isoGetOffset = (timezone = 'Europe/Moscow', date = new Date()) => {
    const utcDate = new Date(date.toLocaleString('en-US', {timeZone: 'UTC'}));
    const tzDate = new Date(date.toLocaleString('en-US', {timeZone: timezone}));
    return (tzDate.getTime() - utcDate.getTime()) / 6e4;
}
