import {conf, lockStatus, sprites} from "./Const";
import {getGroupColor, hexToRgb} from "../../utils/common";
import {Sprite} from "pixi.js";

export const getFill = (item) => {
    if (item.type === 'scene' || item.cart) return "#DDD"
    //console.log(item)
    if (conf.isUser) {
        if (item.status == 0) {
            return item.group.color
        } else if (item.status == 1) {
            return '#DDDDDD'
        } else if (item.status == 2) {
            return '#ffffff'
        } else if (item.status == 3) {
            return '#DDDDDD';
        } else {
            // 4 - зарезервировано орг, 5 - занято орг.
            return '#DDDDDD';
        }
        return item.group.color
    }

    if (conf.isDesigner) {
        return item.group.color
    }

    if (conf.isManager) {
        if (lockStatus.indexOf(Number(item.status)) !== -1) {
            return '#DDDDDD'
        } else if (item.status == 2) {
            return '#ffffff'
        } else if (item.status == 3) {
            return '#DDDDDD'
        }
        return item.group.color
    }

}

export const getStroke = (item) => {
    if (item.cart) {
        return {width: 2, color: "#000"}
    }

    if (item.selected) {
        return {width: 2, color: "#0067F2"}
    }

    return {width: 0, color: "#000"}
}

export const getTextColor = (item) => {
    // цвет текста
    if (item.type === 'scene') {
        return "#000"
    }
    if (conf.isDesigner) {
        return hexToRgb(item.group.color)
    }

    if (conf.isManager) {
        if (lockStatus.indexOf(Number(item.status)) !== -1) {
            return '#5E6B92'
        } else if (item.status == 2) {
            return '#5E6B92'
        } else if (item.status == 3) {
            return '#5E6B92'
        }
        return hexToRgb(item.group.color)
    }

    if (conf.isUser) {
        if (item.status == 0) {
            return hexToRgb(item.group.color);
        } else if (item.status == 1) {
            return '#5E6B92';
        } else if (item.status == 2) {
            return '#5E6B92';
        } else if (item.status == 3) {
            return '#5E6B92';
        } else {
            return '#5E6B92';
        }
    }
}

export const spriteError = (container, param) => {
    // рендер иконки ошибки у места
    if (conf.isDesigner) {
        const error = new Sprite(sprites.alert.texture);
        error.width = 10
        error.height = 10
        error.x = param.w - 5
        error.y = -5
        container.addChild(error);
    }
}

export const spriteStatus = (container, param, status) => {
    // рендер иконки статуса у места
    if (conf.isManager) {
        if (lockStatus.indexOf(Number(status)) !== -1) {
            const error = new Sprite(sprites.lock.texture);
            error.width = 10
            error.height = 10
            error.x = param.w - 5
            error.y = -5
            container.addChild(error);
        }
    }
}
