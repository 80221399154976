// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChartsDataPicker-module_container_0w-UK{display:flex;align-items:center;height:40px}.ChartsDataPicker-module_picker_SNfwx{padding:0 17px}.ChartsDataPicker-module_label_93NdN{font-family:\"Inter\",sans-serif;font-style:normal;font-weight:600;font-size:12px;line-height:15px;letter-spacing:.04px;margin:0}.ChartsDataPicker-module_input_aXg2e{width:116px}", "",{"version":3,"sources":["webpack://resources/js/react/src/views/Admin/Courses/Course/Lessons/Statistic/ChartsDataPicker/ChartsDataPicker.module.scss"],"names":[],"mappings":"AAAA,yCACI,YAAA,CACA,kBAAA,CACA,WAAA,CAGJ,sCACI,cAAA,CAGJ,qCACI,8BAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,oBAAA,CACA,QAAA,CAGJ,qCACI,WAAA","sourcesContent":[".container {\n    display: flex;\n    align-items: center;\n    height: 40px;\n}\n\n.picker {\n    padding: 0 17px;\n}\n\n.label {\n    font-family: 'Inter', sans-serif;\n    font-style: normal;\n    font-weight: 600;\n    font-size: 12px;\n    line-height: 15px;\n    letter-spacing: 0.04px;\n    margin: 0;\n}\n\n.input {\n    width: 116px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ChartsDataPicker-module_container_0w-UK",
	"picker": "ChartsDataPicker-module_picker_SNfwx",
	"label": "ChartsDataPicker-module_label_93NdN",
	"input": "ChartsDataPicker-module_input_aXg2e"
};
export default ___CSS_LOADER_EXPORT___;
