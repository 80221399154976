import React, {useEffect, useState} from 'react';
import switchers from './System.data.json'
import Switcher from "../../../../../UIKit/component/switcher/Switcher";
import WarningIcon from "../../../../../assets/images/icons/16/warning_fill.svg";
import "../../Registration/Form/Switchers/Switchers.scss"
import styles from './System.module.scss'
import page from "../../Events.module.scss";
import {API} from "../../../../../api/API";
import {postData} from "../../../../../utils/sendData";
import JSONAPIDeserializer from "jsonapi-serializer/lib/deserializer";
import JSONAPISerializer from "jsonapi-serializer/lib/serializer";
import {useRouteMatch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Preloader from "../../../../../widget/components/preloader";
import Notification from "../../../../../UIKit/component/notification/notification";
import ReactHtmlParser from "react-html-parser";

const System = () => {
    const router = useRouteMatch()
    const {event_id, type} = router.params
    const [data, setData] = useState({...switchers})
    const is_course = type === 'course'
    const admin = useSelector(state => state.admin)
    const event = admin.event
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(null)
    useEffect(() => {
        event.id ? handleGetData() : null
    }, [event])

    const handleGetData = () => {
        setLoading(1)
        setData(prev => ({
            ...prev,
            is_send_owners: {...prev.is_send_owners, value: event.is_send_owners},
            is_send_users: {...prev.is_send_users, value: event.is_send_users}
        }))
    }

    const handleChange = async (switcher, boolean) => {
        setData(prev => ({...prev, [switcher]: {...prev[switcher], is_disabled: true, error: null}}))
        try {
            await methodChangeSystemControl({[switcher]: boolean})
            setData(prev => ({...prev, [switcher]: {...prev[switcher], is_disabled: false, error: null}}))
            showTopMessage('success', 'small', 'Операция выполнена успешно')
        } catch (e) {
            showTopMessage('error', 'small', 'Произошла ошибка. Повторите попытку позднее')
            setData(prev => ({...prev, [switcher]: {...prev[switcher], is_disabled: false, error: null}}))
            return
        }

        dispatch({type: "SET_ADMIN", payload: {...admin, event: {...event, [switcher]: boolean}}})
        setData(prev => ({...prev, [switcher]: {...prev[switcher], value: boolean, error: null}}))
    }

    const methodChangeSystemControl = async (params) => {
        let response, url, method, serialized, deserialized

        serialized = serializeData(params)
        url = API.changeCourseInfo.path(event_id, is_course)
        method = API.changeCourseInfo.method
        response = await postData(serialized, url, method)
        deserialized = await deserializedData(response.data)

        return deserialized
    }

    const deserializedData = async (data) => new JSONAPIDeserializer({
        keyForAttribute: 'underscore_case',
    }).deserialize(JSON.parse(JSON.stringify(data)), (err, deserializeData) => deserializeData);

    /* Сериализация данных в принимаемый формат API */
    const serializeData = (params) => {
        let config, result, body, allowed

        allowed = ['is_send_owners', 'is_send_users']

        config = {
            keyForAttribute: 'underscore_case',
            attributes: allowed,
            typeForAttribute: (attribute, data) => data.customType,
            event: {
                ref: 'id',
                included: false
            },
        }

        body = {
            ...params,
            id: event_id,
        };

        result = new JSONAPISerializer(type, config).serialize(body);
        return result
    }

    return (
        <>
            {loading === null ? <div className={page.section}>
                <h3 className={page.title}>
                    Отправлять системные уведомления
                </h3>
                <Preloader/>
            </div> : null}

            {loading === 1 ? <div className={page.section}>
                <h3 className={page.title}>
                    Отправлять системные уведомления
                </h3>
                <div className={[styles.switchers, 'ui-cp__form-switchers'].join(' ')}>
                    <div className={styles.row}>
                        <div className={styles.content}>
                            <h6 className={styles.title}>{data.is_send_users.title}</h6>
                            <p className={styles.text}>{ReactHtmlParser(data.is_send_users.text)}</p>
                        </div>
                        <div className={styles.switcher}>
                            <Switcher
                                id={`is_send_users`}
                                size={'medium'}
                                placeholder={data.is_send_users.placeholder}
                                checked={data.is_send_users.value}
                                disabled={data.is_send_users.is_disabled}
                                onChange={(boolean) => handleChange('is_send_users', boolean)}
                            />
                            {!data.is_send_users.value ? <p>
                                <WarningIcon/>
                                <span>Уведомления выключены</span>
                            </p> : null}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.content}>
                            <h6 className={styles.title}>{data.is_send_owners.title}</h6>
                            <p className={styles.text}>{ReactHtmlParser(data.is_send_owners.text)}</p>
                        </div>
                        <div className={styles.switcher}>
                            <Switcher
                                id={`is_send_owners`}
                                size={'medium'}
                                placeholder={data.is_send_owners.placeholder}
                                checked={data.is_send_owners.value}
                                disabled={data.is_send_owners.is_disabled}
                                onChange={(boolean) => handleChange('is_send_owners', boolean)}
                            />
                            {!data.is_send_owners.value ? <p>
                                <WarningIcon/>
                                <span>Уведомления выключены</span>
                            </p> : null}
                        </div>
                    </div>
                </div>
            </div> : null}

            {loading === 0 ? <div className={page.section}>
                <h3 className={page.title}>

                    Отправлять системные уведомления
                </h3>
                <div className={styles.notification}>
                    <Notification
                        value={'Произошла ошибка загрузки модуля уведомлений, повторите попытку позднее'}
                        type={'error'}
                    />
                </div>
            </div> : null}
        </>
    );
};

export default System;