
export const handleErrors = (response, typeAction, dispatch, noticeData, modalData, courseId) => {
    // Обработка ошибок

    const handleProgress = (percent) => {
        dispatch({type: "SET_PERCENT_UPLOAD_FILE", payload: percent})
    }

    const handleNotice = (msg) => {
        dispatch({type: "SET_NOTICE", payload: {...noticeData, open: true, type: 'error', message: msg, closingTime: 5}})
    }
    const handleModal = (data) => {
        dispatch({
            type: "SET_MODAL", payload: {
                ...modalData, open: true,
                title: data.title,
                content: data.detail,
                handler: null,
                courseId: courseId,
                recaptcha: data.recaptcha ? data.recaptcha : null,
                buttons: data.buttons
            }
        })
    }

    const createModalRecaptcha = (data) => {
        let modal = {
            title: data.detail,
            content: '',
            recaptcha: data.meta.key,
            handler: null,
            courseId: courseId,
            buttons: []
        };
        handleModal(modal);
    }

    if (typeAction === 'uploadFile') {
        handleProgress(0); // hide preloader
        // getUpdateLessonData();
        // Ошибки загрузки файла
        if (!Array.isArray(response.data.errors)) {
            for (let key in response.data.errors) {
                // Поля ошибок
                if (Array.isArray(response.data.errors[key])) {
                    // Проверили ошибку на тип массива
                    let arrErrors = response.data.errors[key];
                    for (let i = 0; i < arrErrors.length; i++) {
                        // Ошибки коллекции
                        let error = arrErrors[i];
                        if (error.action === 'modal' && error.modal) {
                            let modalData = {
                                open: true,
                                title: error.modal.header,
                                detail: error.modal.detail,
                                style: error.modal.style,
                                buttons: error.modal.buttons
                            };
                            handleModal(modalData);
                        } else if (error.action === 'alert') {
                            handleNotice(error.alert.detail);
                        } else {
                            console.log(error)
                            handleNotice(error);
                        }
                    }
                }
            }
        } else {
            for (let i = 0; i < response.data.errors.length; i++) {
                if (response.data.errors[i].title === 'recaptcha') {
                    createModalRecaptcha(response.data.errors[i]);
                }
            }
        }
    } else {
        handleNotice('unknown type handler');
    }
}
