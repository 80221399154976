import React, {useEffect, useState} from 'react';
import '../../../scss/layout/Sidebar.scss'
import {SidebarItem} from "./SidebarItem"

export const Sidebar = ({toggle, setToggle}) => {

    /* sidebar прокинута из /resources/views/admin/layout/left.blade.php:129 */
    const [list, setList] = useState(sidebarData)
    const [activeSubitem, setActiveSubitem] = useState(null)

    useEffect(() => {
        checkOpenedSubitem()
    }, [])

    const checkOpenedSubitem = () => {
        let result
        sidebarData.map(item => item.menus && item.menus.map(b => {
            if (window.location.pathname.includes(b.url)) {
                result = item.type
            }
        }))
        setActiveSubitem(result)
    }

    return (
        <ul className={['nh-cp__sidebar', toggle ? 'nh-cp__sidebar__opened' : null].filter(x => x ?? x).join(' ')}>
            {list.map((item, item_idx) => (item.hasOwnProperty('if') ? item.if : true) ?
                <SidebarItem
                    key={item_idx}
                    item={item}
                    toggle={toggle}
                    activeSubitem={activeSubitem}
                    setActiveSubitem={setActiveSubitem}
                /> : null)}
        </ul>
    );
};