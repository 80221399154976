// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LineChart-module_container_6U0ye{padding-bottom:40px}.LineChart-module_info_Rdplb{padding-top:15px}.LineChart-module_collapse_fhpm0{padding-top:5px}.LineChart-module_legends_szT5k{font-family:\"Inter\",sans-serif;font-style:normal;font-weight:600;font-size:12px;line-height:15px;letter-spacing:.04px;margin-left:20px}.LineChart-module_btn_s4yZX{font-size:12px;line-height:15px;letter-spacing:.04px;margin-left:20px;border-bottom:1px solid #000;opacity:.9}", "",{"version":3,"sources":["webpack://resources/js/react/src/views/Admin/Courses/Course/Lessons/Statistic/LineChart/LineChart.module.scss"],"names":[],"mappings":"AAAA,kCACI,mBAAA,CAGJ,6BACI,gBAAA,CAGJ,iCACI,eAAA,CAGJ,gCACI,8BAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,oBAAA,CACA,gBAAA,CAGJ,4BACI,cAAA,CACA,gBAAA,CACA,oBAAA,CACA,gBAAA,CACA,4BAAA,CACA,UAAA","sourcesContent":[".container {\n    padding-bottom: 40px;\n}\n\n.info {\n    padding-top: 15px;\n}\n\n.collapse {\n    padding-top: 5px;\n}\n\n.legends {\n    font-family: 'Inter', sans-serif;\n    font-style: normal;\n    font-weight: 600;\n    font-size: 12px;\n    line-height: 15px;\n    letter-spacing: 0.04px;\n    margin-left: 20px;\n}\n\n.btn {\n    font-size: 12px;\n    line-height: 15px;\n    letter-spacing: 0.04px;\n    margin-left: 20px;\n    border-bottom: 1px solid #000;\n    opacity: 0.9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "LineChart-module_container_6U0ye",
	"info": "LineChart-module_info_Rdplb",
	"collapse": "LineChart-module_collapse_fhpm0",
	"legends": "LineChart-module_legends_szT5k",
	"btn": "LineChart-module_btn_s4yZX"
};
export default ___CSS_LOADER_EXPORT___;
